import { Authenticator } from '@aws-amplify/ui-react'
import { RouterProvider } from '@tanstack/react-router'
import { Box, Spinner } from 'grommet'

import Notifications from './Notifications'
import logo from './assets/logo.png'
import { useTidio } from './hooks/customerService'
import LoadingProvider from './hooks/loading'
import { useDispatchUserRole } from './hooks/roles'
import { router } from './routes'

import './App.css'

const LoadingComponent = () => {
  return (
    <Box
      justify="center"
      align="center"
      alignContent="center"
      width="100vw"
      height="100vh"
    >
      <Spinner size="xlarge" />
    </Box>
  )
}

const AppImpl = () => {
  useTidio()
  useDispatchUserRole()

  return (
    <Box
      width={'100vw'}
      height={'100vh'}
    >
      <LoadingProvider>
        <RouterProvider
          router={router}
          defaultPendingComponent={LoadingComponent}
        />
      </LoadingProvider>

      <Notifications />
    </Box>
  )
}

const components = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Header: () => {
    return (
      <Box
        margin={{vertical: '3rem'}}
        direction="row"
        justify="center"
      >
        <img
          src={logo}
          alt="Podform3D logo"
          width="275px"
        />
      </Box>
    )
  },
}

const App = () => {
  return (
    <Authenticator components={components}>
      {() => <AppImpl />}
    </Authenticator>
  )
}

export default App
