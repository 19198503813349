
import { Text, TextInput } from 'grommet'
import { FormEdit } from 'grommet-icons'
import { useState } from 'react'

type Props = {
  value: string,
  onChange: (newValue: string) => unknown,
  confirmChanges: () => unknown,
}

const UpdatableField = (props: Props) => {
  const [modifyable, setModifiable] = useState<boolean>(false)

  return (
    <>
      {modifyable
        ? (
          <Text>
            {props.value}
          </Text>
        )
        : (
          <TextInput
            onChange={(event) => props.onChange(event.currentTarget.value)}
            value={props.value}
          />)
      }
      <FormEdit
        onClick={() => {
          setModifiable(b => !b)
          if (modifyable) props.confirmChanges()
        }}
      />
    </>
  )
}

export default UpdatableField
