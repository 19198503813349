
import { Trans } from '@lingui/macro'
import { Route, useParams } from '@tanstack/react-router'
import { Box, Heading, Text } from 'grommet'
import { useRef } from 'react'

import CollapsiblePanel from '../../components/CollapsiblePanel'
import NotesEditor from '../../components/NotesEditor'
import { getOrder, ordersKeys } from '../../fetchers/orders'
import { useQueryOrder, useUpdateOrderInternalClinicNotes } from '../../queries/orders'
import { getQueryClient } from '../../queryClient'
import { Order } from '../../types/Order'
import MainPanel from '../home/MainPanel'

type Props = {
  order: Order,
}

const InternalClinicNotesPanel = (props: Props) => {
  const params = useParams({ from: '/orders/clinics/$clinicId/orders/$orderId/internal-clinic-notes' })

  const updateOrderInternalClinicNotes = useUpdateOrderInternalClinicNotes()

  const getValueRef = useRef<(() => string) | null>(null)

  const handleNotesChange = (notes: string) => {
    updateOrderInternalClinicNotes.mutate({
      clinicId: params.clinicId,
      orderId: params.orderId,
      value: notes,
    })
  }

  return (
    <CollapsiblePanel
      onClose={() => {
        updateOrderInternalClinicNotes.mutate({
          clinicId: params.clinicId,
          orderId: params.orderId,
          value: getValueRef.current?.() ?? '',
        })
      }}
    >
      <Box height={'90vh'}>
        <NotesEditor
          debounce={true}
          header={
            <>
              <Heading
                level="2"
                margin={{top: 'none', bottom: '0.5rem'}}
              >
                <Trans>Internal Clinic Notes - {props.order.patient.lastName}, {props.order.patient.firstName}</Trans>
              </Heading>
              <Text margin={{bottom: '0.5rem'}}>
                <Trans>
                  These notes are visible only to your clinic,
                  and are not used in any way by Podform 3D.
                </Trans>
              </Text>
            </>
          }
          notes={props.order.internalClinicNotes}
          onChange={handleNotesChange}
          getValue={ref => getValueRef.current = ref}
        />
      </Box>
    </CollapsiblePanel>
  )
}

const InternalClinicNotesPanelLoader = () => {
  const params = useParams({ from: '/orders/clinics/$clinicId/orders/$orderId/internal-clinic-notes' })
  const order = useQueryOrder(params.clinicId, params.orderId)

  if (!order.data) return null

  return <InternalClinicNotesPanel order={order.data} />
}

InternalClinicNotesPanel.route = new Route({
  getParentRoute: () => MainPanel.route,
  path: 'clinics/$clinicId/orders/$orderId/internal-clinic-notes',
  parseParams: params => ({
    clinicId: Number(params.clinicId),
    orderId: Number(params.orderId),
  }),
  onLoad: async ({ params, signal }) => {
    const queryClient = getQueryClient()

    await queryClient.prefetchQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ordersKeys.details(params.clinicId, params.orderId),
      queryFn: () => getOrder(params.clinicId, params.orderId, signal ?? null),
    })
  },
  component: InternalClinicNotesPanelLoader,
})

export default InternalClinicNotesPanel
