
import { Plural, Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Link } from '@tanstack/react-router'
import { Box, Button, ColumnConfig, Tip } from 'grommet'
import { Deliver } from 'grommet-icons'
import { useState } from 'react'

import { Foot, Orthotic } from '../../../components/icons/Icons'
import OnlineViewerModal from '../../../components/modals/OnlineViewerModal'
import { Order } from '../../../types/Order'
import { isStatusPast, orderStatuses } from '../../../types/OrderStatus'
import { scanStatuses } from '../../../types/ScanStatus'
import { getEstimatedDate, msInDay } from '../../../utils/date'

import { getBillingStatusColumn, getClinicianColumn, getCreatedAtColumn, getLocationColumn, getPatientFirstNameColumn, getPatientLastNameColumn, getPrescriptionColumn, getProductInfoColumn, getStatusColumn } from './Columns'
import { Data } from './Data'

const now = new Date()

const estimationToDelivery = 11 // real days

const getExpectedDeliveryColumn = (): ColumnConfig<Order> => ({
  property: 'submittedAt',
  header: <Trans>Expected delivery</Trans>,
  render: function SubmittedAtCell(order) {
    const lingui = useLingui()
    const i18n = lingui.i18n

    if (isStatusPast(order.activeStatus, orderStatuses.shipped)) return null

    if (order.shippingLink !== null) {
      return (
        <Button
          icon={<Deliver />}
          pad="0"
          a11yTitle={t(i18n)`Open shipment tracking link`}
          href={order.shippingLink}
          target="_blank"
          rel="noopener noreferrer"
        />
      )
    }

    if (!order.submittedAt) return null

    const estimated = getEstimatedDate(order.submittedAt, estimationToDelivery)

    const days = Math.floor((estimated.getTime() - now.getTime()) / msInDay)

    if (days < 0) {
      return (
        <Tip content={t(i18n)`We cannot estimate the delivery date at this time`}>
          <Box>
            <Trans>N/A</Trans>
          </Box>
        </Tip>
      )
    }

    const estimatedString = estimated
      .toLocaleDateString(
        i18n.locale,
        {
          dateStyle: 'full',
        },
      )

    return (
      <Tip content={t(i18n)`Expected on ${estimatedString}`}>
        <Box>
          <Plural
            value={days}
            zero="Today"
            one="# day"
            other="# days"
          />
        </Box>
      </Tip>
    )
  },
})

type ViewerButtonProps = Readonly<{
  order: Order,
}>

const ScanViewerButton = (props: ViewerButtonProps) => {
  const { i18n } = useLingui()

  const [show, setShow] = useState<boolean>(false)

  return (
    <>
      <Tip content={t(i18n)`View rendering of scans`}>
        <Button
          a11yTitle={t(i18n)`View rendering of scans`}
          pad="0"
          margin={{ right: '0.5rem' }}
          icon={<Foot />}
          onClick={() => setShow(true)}
        />
      </Tip>
      <OnlineViewerModal
        key={props.order.id}
        show={show}
        onClose={() => setShow(false)}
        order={props.order}
        files="scans"
      />
    </>
  )
}

const OrthoticViewerButton = (props: ViewerButtonProps) => {
  const { i18n } = useLingui()

  const [show, setShow] = useState<boolean>(false)

  return (
    <>
      <Tip content={t(i18n)`View rendering of orthotics`}>
        <Button
          a11yTitle={t(i18n)`View rendering of orthotics`}
          pad="0"
          icon={<Orthotic />}
          onClick={() => setShow(true)}
        />
      </Tip>
      <OnlineViewerModal
        key={props.order.id}
        show={show}
        onClose={() => setShow(false)}
        order={props.order}
        files="files"
      />
    </>
  )
}

function getInternalClinicNotesColumn(): ColumnConfig<Order> {
  return {
    property: 'comments',
    header: <Trans>Internal Clinic Notes</Trans>,
    render: (order) => (
      <Link
        to="/orders/clinics/$clinicId/orders/$orderId/internal-clinic-notes"
        params={{
          clinicId: order.clinicId,
          orderId: order.id,
        }}
      >
        {order.internalClinicNotes !== null ? order.internalClinicNotes.substring(0, 10) + '...' : '...'}
      </Link>
    ),
  }
}

function getClinicianColumns(data: Data<false, Order>): Array<ColumnConfig<Order>> {
  return [
    getStatusColumn(data),
    getBillingStatusColumn(data),
    getProductInfoColumn(data),
    getPatientFirstNameColumn(),
    getPatientLastNameColumn(),
    getPrescriptionColumn(
      data,
      (order) => {
        return (
          <>
            {
              order.scanStatus !== scanStatuses.missing &&
              <ScanViewerButton order={order} />
            }
            {
              order.filesCount > 0 &&
              <OrthoticViewerButton order={order} />
            }
          </>
        )
      },
    ),
    getClinicianColumn(),
    getLocationColumn(),
    getExpectedDeliveryColumn(),
    getCreatedAtColumn(),
    getInternalClinicNotesColumn(),
  ]
}

export {
  getClinicianColumns,
}
