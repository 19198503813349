
import { useLingui } from '@lingui/react'
import { DateInput, FormField } from 'grommet'
import { ComponentProps, Fragment, ReactNode, useState } from 'react'

import { fromDateIsoToDateOnly } from '../../utils/date'

import getCalendarHeader from './CalendarHeader'

type Props = Readonly<{
  date: Date | string | null | undefined,
  onChange: (dateOnly: string | null) => unknown,

  wrapFormField?: boolean,
  formFieldProps?: ComponentProps<typeof FormField>,
  label?: ReactNode,
}>

/**
 * Augments Grommet's DateInput so as to facilitate picking a year set in the
 * past. Whereas Grommet's DateInput can only switch between months, this
 * PastSingleDateInput can switch between years, making it more suitable
 * for picking dates such as birthdates.
 * @param props The props for this component.
 * @returns The React component.
 */
const PastSingleDateInput = (props: Props) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const [overrideDate, setOverrideDate] = useState<Date | null>(null)

  const value = typeof props.date === 'string'
    ? props.date
    : (props.date?.toISOString() ?? '')

  const Wrapper = props.wrapFormField === true
    ? FormField
    : Fragment

  return (
    <Wrapper>
      {props.label ?? null}
      <DateInput
        dropProps={{ align: { top: 'bottom', right: 'right' } }}
        format="yyyy-mm-dd"
        value={value}
        onChange={({ value }) => {
          props.onChange(
            fromDateIsoToDateOnly(typeof value === 'string' ? value : value[0] as string),
          )
          setOverrideDate(null)
        }}
        calendarProps={{
          locale: i18n.locale,
          range: false,
          size: 'medium',
          date: overrideDate?.toISOString() ?? (value === '' ? undefined : value) ?? undefined as never,
          header: getCalendarHeader(setOverrideDate),
        }}
      />
    </Wrapper>
  )
}

export default PastSingleDateInput
