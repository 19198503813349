import { getOmsApiUri } from '../constants/runtime'
import { BillingStatus } from '../types/BillingStatus'
import { Order, OrderDto, OrderForManufacturer, dtoToOrder, dtoToOrderForManufacturer } from '../types/Order'
import { GetOrdersFilters } from '../types/OrderFilters'
import { OrderStatus, orderStatusNone } from '../types/OrderStatus'
import { triggerDownload } from '../utils/download'
import { getRecordQueryString } from '../utils/queryString'

import { fetch } from './fetch'
import { getHeaders, getMutationHeaders } from './utils'

const ordersKeys = {
  all: () => ['orders'] as const,
  count: (filters: Record<string, unknown>) => [...ordersKeys.all(), 'count', filters],
  list: (filters?: Record<string, unknown> | undefined) =>
    filters
      ? [...ordersKeys.all(), 'list', filters] as const
      : [...ordersKeys.all(), 'list'] as const,
  details: (clinicId: number, orderId: number) => [...ordersKeys.all(), 'details', clinicId, orderId] as const,
}

const getOrder = async (
  clinicId: number,
  orderId: number,
  signal: AbortSignal | null,
): Promise<Order> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}`,
    {
      headers: await getHeaders(),
      signal,
    },
  )

  const dto = await response.json() as OrderDto

  return dtoToOrder(dto)
}

export type GetOrderStatusFilters = OrderStatus[]

export type Enveloppe<T> = {
  nextId: number | null,
  nextDate: number | null,
  count: number,
  totalCount: number,
  data: T[],
}

function doIf<T>(
  value: T | undefined | null,
  toString: (value: T) => string,
  action: (value: string) => unknown,
) {
  if (value !== null && value !== undefined) {
    action(
      typeof value === 'string'
        ? value.trim()
        : toString(value),
    )
  }
}

function doManyIf<T>(
  values: T[] | undefined | null,
  toString: (value: T) => string,
  action: (value: string) => unknown,
) {
  if (values !== null && values !== undefined) {
    for (const value of values) {
      action(
        typeof value === 'string'
          ? value.trim()
          : toString(value),
      )
    }
  }
}

const boolToString = (b: boolean): string =>
  b ? 'true' : 'false'

const getQuery = (
  filters: Partial<GetOrdersFilters>,
  nextId: number,
  nextDate: number,
) => {
  const query = new URLSearchParams()

  const setQuery = (name: string) => (value: string) => query.append(name, value)

  query.set('nextId', String(nextId))
  query.set('nextDate', String(nextDate))
  doIf(filters.pageSize, String, setQuery('pageSize'))
  doIf(filters.clinicId, String, setQuery('clinicId'))
  doIf(filters.locationId, String, setQuery('locationId'))
  doIf(filters.clinicianId, String, setQuery('clinicianId'))
  doIf(filters.from, x => x.toISOString(), setQuery('from'))
  doIf(filters.to, x => x.toISOString(), setQuery('to'))
  doIf(filters.fromUpdated, x => x.toISOString(), setQuery('fromUpdated'))
  doIf(filters.toUpdated, x => x.toISOString(), setQuery('toUpdated'))
  doIf(filters.patientFirstName, String, setQuery('patientFirstName'))
  doIf(filters.patientLastName, String, setQuery('patientLastName'))
  doIf(filters.patientBirthdate, String, setQuery('patientBirthdate'))
  doIf(filters.orderNumber, String, setQuery('orderNumber'))
  doIf(filters.includeNoPrescription, boolToString, setQuery('includeNoPrescription'))
  doIf(filters.onlyHybrid, boolToString, setQuery('onlyHybrid'))
  doIf(filters.onlyTopCover, boolToString, setQuery('onlyTopCover'))
  doIf(filters.onlySingle, boolToString, setQuery('onlySingle'))

  doManyIf(filters.includeBillingStatuses, String, setQuery('includeBillingStatuses'))
  doManyIf(filters.excludeTopCoverMaterials, String, setQuery('excludeTopCoverMaterials'))

  if (filters.includeStatuses) {
    if (filters.includeStatuses.length === 0) {
      query.append('includeStatuses', String(orderStatusNone))
    } else {
      for (const status of filters.includeStatuses) {
        query.append('includeStatuses', String(status))
      }
    }
  }

  return query.toString()
}

async function getOrders<
  TIsManufacturer extends true | false,
  TOrder extends TIsManufacturer extends true ? OrderForManufacturer : Order,
>(
  isManufacturer: TIsManufacturer,
  nextId: number,
  nextDate: number,
  filters: GetOrdersFilters,
  signal: AbortSignal | null,
): Promise<Enveloppe<TOrder>> {
  const query = getQuery(filters, nextId, nextDate)

  const response = await fetch(
    `${getOmsApiUri()}/orders?${query}`,
    {
      headers: await getHeaders(),
      signal,
    },
  )

  const dto = await response.json() as Enveloppe<TIsManufacturer extends true ? OrderForManufacturer : OrderDto>

  if (isManufacturer) {
    return {
      ...dto,
      data: dto.data.map(dtoToOrderForManufacturer as never),
    }
  } else {
    return {
      ...dto,
      data: dto.data.map(dtoToOrder as never),
    }
  }
}

const getOrdersCount = async (
  filters: Omit<GetOrdersFilters, 'pageSize'>,
  signal: AbortSignal | null,
): Promise<number> => {
  const query = getQuery(filters, -1, -1)

  const response = await fetch(
    `${getOmsApiUri()}/orders/count?${query}`,
    {
      headers: await getHeaders(),
      signal,
    },
  )

  const count = await response.json() as number

  return count
}

export type GetOrdersCountsByOrderStatusesFilters = {
  clinicId?: number | null | undefined,
  locationId?: number | null | undefined,
  clinicianId?: number | null | undefined,
}

const getOrdersCountsByOrderStatuses = async (
  filters: GetOrdersCountsByOrderStatusesFilters,
  signal: AbortSignal | null,
): Promise<Record<OrderStatus, number>> => {
  const query = getRecordQueryString(filters)

  const response = await fetch(
    `${getOmsApiUri()}/orders/counts-by-statuses?${query}`,
    {
      headers: await getHeaders(),
      signal,
    },
  )

  const count = await response.json() as Record<OrderStatus, number>

  return count
}

export type PostOrderRequest = {
  locationId: number,
  patientId: number,
  clinicianId: number,
}

const postOrder = async (
  clinicId: number,
  request: PostOrderRequest,
): Promise<Order> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify(request),
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as OrderDto

  return dtoToOrder(dto)
}

const duplicateOrder = async (
  clinicId: number,
  orderId: number,
): Promise<Order> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/duplicates`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as OrderDto

  return dtoToOrder(dto)
}

function makePutUpdater<T>(
  segment: string,
  wrapValue: (value: T) => Record<string, unknown>,
): (clinicId: number, orderId: number, value: T) => Promise<void> {
  return async (clinicId, orderId, value) => {
    const response = await fetch(
      `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/${segment}`,
      {
        method: 'PUT',
        headers: await getMutationHeaders(),
        body: JSON.stringify(wrapValue(value)),
      },
    )

    if (response.status !== 204) {
      throw new Error(`Unexpected response status ${response.status}.`)
    }
  }
}

const downloadAllFilesByOrderImpl = async (
  orderIds: number[],
  updateStatus: OrderStatus | undefined,
): Promise<Blob> => {
  const query = new URLSearchParams()
  for (const id of orderIds) {
    query.append('orderIds', String(id))
  }

  if (updateStatus !== undefined) {
    query.append('updateStatus', String(updateStatus))
  }

  const queryString = query.toString()

  const response = await fetch(
    `${getOmsApiUri()}/orders/bulk-files${queryString === '' ? '' : `?${queryString}`}`,
    {
      headers: await getHeaders(),
    },
  )

  if (response.status !== 200) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const blob = await response.blob()

  return blob
}

const downloadAllFilesByOrder = async (
  orderIds: number[],
  updateStatus?: OrderStatus | undefined,
): Promise<void> => {
  const blob = await downloadAllFilesByOrderImpl(orderIds, updateStatus)
  triggerDownload(blob, 'bulk-download', 'zip')
}

const downloadVerifiedFiles = async (updateStatus?: OrderStatus | undefined): Promise<void> => {
  const blob = await downloadAllFilesByOrderImpl([], updateStatus)
  triggerDownload(blob, 'bulk-download', 'zip')
}

const putBillingStatus = makePutUpdater<BillingStatus>('billing-status', status => ({status}))
const putOrderComments = makePutUpdater<string>('comments', comments => ({comments}))
const putOrderInternalClinicNotes = makePutUpdater<string>(
  'internal-clinic-notes',
  internalClinicNotes => ({internalClinicNotes}),
)
const putOrderClinician = makePutUpdater<number>('clinician', clinicianId => ({clinicianId}))
const putOrderClinic = makePutUpdater<number>('clinic', newClinicId => ({newClinicId}))
const putOrderLocation = makePutUpdater<number>('location', locationId => ({locationId}))

export type UpdateOrderStatusExtra = {
  shippingLink?: string | null,
  manufacturerShippingLink?: string | null,
  cancellationReason?: string | null,
}

const putOrderStatus = async (
  clinicId: number,
  orderId: number,
  value: OrderStatus,
  extra: UpdateOrderStatusExtra | undefined,
) => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/status`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify({
        status: value,
        ...extra,
      }),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

const pushToVirtualRhino = async (
  clinicId: number,
  orderId: number,
  rxPath: string,
): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/scans/processing`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify({
        rxPath,
      }),
    },
  )

  if (response.status < 200 || response.status > 299) {
    const body = await response.text()
    if (body !== '') {
      throw new Error(`Unexpected response status: ${response.status} ${response.statusText}. Body: ${body}`)
    } else {
      throw new Error(`Unexpected response status: ${response.status} ${response.statusText}`)
    }
  }
}

const processVerified = async (orderIds: number[]): Promise<void> => {
  const query = new URLSearchParams()
  for (const id of orderIds) {
    query.append('orderIds', String(id))
  }

  const queryString = query.size === 0 ? '' : `?${query.toString()}`

  const response = await fetch(
    `${getOmsApiUri()}/orders/verified/process${queryString}`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify({}),
    },
  )

  if (response.status < 200 || response.status > 299) {
    throw new Error(`Error while processing verified orders: ${response.status} ${response.statusText}`)
  }
}

export {
  ordersKeys,
  getOrder,
  getOrders,
  getOrdersCount,
  getOrdersCountsByOrderStatuses,
  postOrder,
  duplicateOrder,
  putOrderStatus,
  putBillingStatus,
  putOrderComments,
  putOrderInternalClinicNotes,
  putOrderClinician,
  putOrderClinic,
  putOrderLocation,
  downloadAllFilesByOrder,
  downloadVerifiedFiles,
  pushToVirtualRhino,
  processVerified,
}
