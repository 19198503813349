import { Trans } from '@lingui/macro'
import { Box, Button, Heading, Layer, Spinner, Text } from 'grommet'
import { StatusCritical, StatusGood } from 'grommet-icons'

const Loading = () => {
  return (
    <>
      <Box
        align="center"
        margin={{top: '1rem', bottom: '1rem'}}
      >
        <Spinner
          size="xlarge"
        />
      </Box>
      <Text>
        <Trans>
          Please wait until the upload is complete to receive success confirmation.
        </Trans>
      </Text>
      <Text>
        <Trans>
          The upload process can take a while.
        </Trans>
      </Text>
    </>
  )
}

const Success = (props: {onClose: () => unknown}) => {
  return (
    <>
      <Box
        align="center"
        margin={{top: '1rem', bottom: '1rem'}}
      >
        <StatusGood
          size="xlarge"
          color="green"
        />
      </Box>
      <Text>
        <Trans>
          The upload of orthotics was successful.
        </Trans>
      </Text>
      <Box
        align="center"
        margin={{top: '1rem'}}
      >
        <Button
          label={<Trans>Close</Trans>}
          onClick={props.onClose}
        />
      </Box>
    </>
  )
}

const Error = (props: {message: string; onClose: () => unknown}) => {
  return (
    <>
      <Box
        align="center"
        margin={{top: '1rem', bottom: '1rem'}}
      >
        <StatusCritical
          size="xlarge"
          color="red"
        />
      </Box>
      <Text>
        <Trans>Oops, an error occured:</Trans>
      </Text>
      <Text>
        {props.message}
      </Text>
      <Text>
        <Trans>Contact the support team if the problem persists.</Trans>
      </Text>
      <Box
        align="center"
        margin={{top: '1rem'}}
      >
        <Button
          label={<Trans>Close</Trans>}
          onClick={props.onClose}
        />
      </Box>
    </>
  )
}

type Props = Readonly<{
  status: null | 'uploading' | 'success' | {type: 'error'; message: string},
  onClose: () => unknown,
}>

const UploadingToManufacturer = (props: Props) => {
  if (props.status === null) return null

  return (
    <Layer modal={true}>
      <Box
        round={true}
        pad="1rem"
      >
        <Heading
          level="2"
          margin={{top: '0', bottom: '0'}}
        >
          <Trans>Manufacturer upload</Trans>
        </Heading>
        { props.status === 'uploading' && <Loading /> }
        { props.status === 'success' && <Success onClose={props.onClose} /> }
        {
          typeof props.status === 'object' &&
          <Error
            message={props.status.message}
            onClose={props.onClose}
          />
        }
      </Box>
    </Layer>
  )
}

export default UploadingToManufacturer
