
import { Box, FormField, Tip } from 'grommet'
import { CircleQuestion } from 'grommet-icons'
import { ReactNode } from 'react'

import FormHeader from './FormHeader'

type Props = {
  label: string,
  level?: 1 | 2 | 3 | 4 | 5 | 6,
  separator?: boolean,
  name?: string,
  printing?: boolean | undefined,
  helpContent?: ReactNode,
  children: ReactNode,
}

const FormInput = (props: Props) => {
  return (
    <Box
      fill
    >
      <Box
        fill
        direction="row"
        border={props.separator !== true ? 'bottom' : false}
      >
        <FormHeader
          label={props.label}
          level={props.level ?? 5}
          onCheck={null}
          name={props.name ?? props.label}
        />
        {
          props.helpContent !== undefined &&
          <Tip
            plain
            content={(
              <Box
                animation="fadeIn"
                height={{ max: '20rem' }}
                width={{ max: '20rem' }}
                background="black"
                round
                fill
              >
                {props.helpContent}
              </Box>)
            }
          >
            <CircleQuestion />
          </Tip>
        }
      </Box>
      <FormField>
        {props.children}
      </FormField>
    </Box>
  )

}

export default FormInput
