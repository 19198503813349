/* eslint-disable @typescript-eslint/naming-convention */
import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

/// Note(Antoine): Months in Javascript are labeled from 0, which is baffling
// Months, in english at least, are capitalized and so are the variables to the month enums
export const months = {
  'January': 0,
  'February': 1,
  'Mars': 2,
  'April': 3,
  'May': 4,
  'June': 5,
  'July': 6,
  'August': 7,
  'September': 8,
  'October': 9,
  'November': 10,
  'December': 11,
} as const

export type Month = typeof months[keyof typeof months]
const allMonthOptions: Month[] = Object.values(months).map(month => month)

const getAllMonthsLabel = (i18n: I18n, month: Month) => {
  switch (month) {
  case months.January: return t(i18n)`January`
  case months.February: return t(i18n)`February`
  case months.Mars: return t(i18n)`Mars`
  case months.April: return t(i18n)`April`
  case months.May: return t(i18n)`May`
  case months.June: return t(i18n)`June`
  case months.July: return t(i18n)`July`
  case months.August: return t(i18n)`August`
  case months.September: return t(i18n)`September`
  case months.October: return t(i18n)`October`
  case months.November: return t(i18n)`November`
  case months.December: return t(i18n)`December`
  default: return t(i18n)`Error`
  }
}

const getAllMonthsOptions = (i18n: I18n): Array<{ value: Month; label: string }> => {
  return allMonthOptions
    .map(value => ({ value, label: getAllMonthsLabel(i18n, value) }))
}

const defaultYearsToSuggest = 150
const now = new Date()

const getYearOptions = (yearsToSuggest?: number) => {
  const yearOptions = []
  for (let offset = 0; offset < (yearsToSuggest ?? defaultYearsToSuggest); offset++) {
    yearOptions.push((now.getFullYear() - offset).toString())
  }
  return yearOptions
}

export { getYearOptions, getAllMonthsLabel, getAllMonthsOptions }
