import { Box } from 'grommet'
import { ReactNode, useMemo, useState } from 'react'

import { useWindowHeight } from '../hooks/window'

import Footer from './home/Footer'
import { EmptyHeader } from './home/Header'

type RenderData = Readonly<{
  availableHeightPx: number,
}>

type Props = Readonly<{
  header?: ReactNode | undefined,
  footer?: ReactNode | undefined,
  padContent?: boolean,
  children: ReactNode | ((data: RenderData) => ReactNode),
}>

const PageWithBanners = (props: Props) => {
  const windowHeight = useWindowHeight()

  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [footerHeight, setFooterHeight] = useState<number>(0)

  const handleHeaderRef = (ref: HTMLDivElement | null) => {
    if (!ref) return
    setHeaderHeight(ref.getBoundingClientRect().height)
  }

  const handleFooterRef = (ref: HTMLDivElement | null) => {
    if (!ref) return
    setFooterHeight(ref.getBoundingClientRect().height)
  }

  const renderData: RenderData = useMemo(
    () => ({
      availableHeightPx: windowHeight - headerHeight - footerHeight,
    }),
    [
      windowHeight,
      headerHeight,
      footerHeight,
    ],
  )

  return (
    <Box fill>
      <Box ref={handleHeaderRef}>
        {props.header ?? <EmptyHeader />}
      </Box>
      <Box
        pad={props.padContent === true ? { horizontal: '1rem' } : undefined as never}
        height={`${window.innerHeight - headerHeight - footerHeight}px`}
      >
        {typeof props.children === 'function' ? props.children(renderData) : props.children}
      </Box>
      <Box ref={handleFooterRef}>
        {props.footer ?? <Footer />}
      </Box>
    </Box>
  )
}

export default PageWithBanners
