import { getOmsApiUri } from '../constants/runtime'
import { Logo, LogoData, LogoDto, NewLogo, dtoToLogo } from '../types/Logo'

import { fetch } from './fetch'
import { getHeaders, getMutationHeaders } from './utils'

const logosKeys = {
  all: () => ['logos'],
  list: (clinicId: number, filters?: Record<string, unknown> | undefined) =>
    filters
      ? [...logosKeys.all(), 'list', clinicId, filters]
      : [...logosKeys.all(), 'list', clinicId],
  details: (logoId: number) =>
    [...logosKeys.all(), 'details', logoId],
}

const getLogos = async (
  id: {clinicId: number; logoId?: undefined} | {clinicId?: undefined; logoId: number},
  signal: AbortSignal | null,
): Promise<Logo[]> => {
  const query = new URLSearchParams()
  if (id.clinicId !== undefined) query.append('clinicId', String(id.clinicId))
  if (id.logoId !== undefined) query.append('logoId', String(id.logoId))

  const response = await fetch(
    `${getOmsApiUri()}/logos?${query.toString()}`,
    {
      signal,
      headers: await getHeaders(),
    },
  )

  const dtos = await response.json() as LogoDto[]

  return dtos.map(dtoToLogo)
}

const postLogo = async (
  clinicId: number,
  logo: NewLogo,
): Promise<Logo> => {
  const body = new FormData()
  body.append(`fileName`, logo.fileName)
  body.append(`extension`, logo.extension)
  body.append(`filePath`, logo.filePath)
  body.append(`description`, logo.description)
  body.append(`isMainLogo`, logo.isMainLogo ? 'true' : 'false')
  body.append(`file`, logo.file)

  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/logos`,
    {
      method: 'POST',
      headers: await getHeaders(),
      body,
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as LogoDto

  return dtoToLogo(dto)
}

const putLogo = async (
  clinicId: number,
  logo: LogoData,
): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/logos/${logo.id}`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify(logo),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

const deleteLogo = async (
  clinicId: number,
  logoId: number,
): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/logos/${logoId}`,
    {
      method: 'DELETE',
      headers: await getMutationHeaders(),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

export {
  logosKeys,
  getLogos,
  postLogo,
  putLogo,
  deleteLogo,
}
