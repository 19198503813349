
import { useLingui } from '@lingui/react'
import { Outlet } from '@tanstack/react-router'
import { Box, DataTable, Spinner } from 'grommet'
import { useMemo, useState } from 'react'

import { useLoading } from '../../hooks/loading'
import { useGetBestRole, useIsAdmin, useIsClinician, useIsManufacturer } from '../../hooks/roles'
import { useQueryClinicians } from '../../queries/clinicians'
import { useQueryLocations } from '../../queries/locations'
import { useUpdateBillingStatus, useUpdateOrderClinic, useUpdateOrderClinician, useUpdateOrderLocation } from '../../queries/orders'
import { userContextActions } from '../../store/actions/userContext'
import { useDispatch, useSelector } from '../../store/store'
import { BillingStatus } from '../../types/BillingStatus'
import { Order, OrderForManufacturer } from '../../types/Order'
import { defaultGetOrdersFilters } from '../../types/OrderFilters'
import { OrderStatus } from '../../types/OrderStatus'
import { SelectOption } from '../../types/SelectOption'
import { getVisibleOrderStatuses } from '../../utils/orderStatus'
import { getPersonFullName } from '../../utils/person'

import ownStyles from './OrderTable.module.css'
import { Data } from './columns/Data'
import { getAdminColumns } from './columns/admin'
import { getClinicianColumns } from './columns/clinician'
import { getManufacturerColumns } from './columns/manufacturer'
import { getShippingColumns } from './columns/shipping'

function makeOptionsLookup<T extends { id: number; clinicId: number }>(
  data: T[] | null | undefined,
  labelSelector: (item: T) => string,
): Record<number, Array<SelectOption<number>>> {
  if (!data) return {}

  const optionsBuilder = new Map<number, Map<string, SelectOption<number>>>()
  for (const item of data) {
    let existing = optionsBuilder.get(item.clinicId)
    if (!existing) {
      existing = new Map()
      optionsBuilder.set(item.clinicId, existing)
    }

    const option: SelectOption<number> = {
      value: item.id,
      label: labelSelector(item),
    }

    if (existing.has(option.label)) continue
    existing.set(option.label, option)
  }

  const options: Record<number, Array<SelectOption<number>>> = {}
  for (const [clinicId, optionsLookup] of optionsBuilder) {
    options[clinicId] = Array
      .from(optionsLookup.values())
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  return options
}

// NOTE(pascal): Table size is not working exactly as I'd like, as the size
// is applied to the tbody, not to the whole table.
// As such, the thead adds up more space than we'd want.
// To improve later.
// NOTE(antoine): Table size could be automagically managed with the tag pin
// and we could then also add resizeable, altho, this could give unwanted
// results such as a stuttering table
const magicTableHeaderSize = 31.39
const magicExpandedExtraHeaderSize = 26

type Props<
  TIsManufacturer extends true | false,
  TOrder extends TIsManufacturer extends true ? OrderForManufacturer : Order,
> = Readonly<{
  heightPx: number,
  isLoading: boolean,
  orders: Array<TOrder>,
  ordersCountsByOrderStatuses: Record<OrderStatus, number>,
  selectedOrderIds: number[],
  onSelectedOrderIdsChange: (setter: (currentIds: number[]) => number[]) => unknown,
  filteredBillingStatuses: BillingStatus[],
  onFilteredBillingStatusesChange: (statuses: BillingStatus[]) => unknown,
  onFetchNextPage: () => unknown,
  onUpdateOrderStatus: (
    order: TOrder,
    newStatus: OrderStatus,
  ) => unknown,
}>

function OrderTable<
  TIsManufacturer extends true | false,
  // eslint-disable-next-line max-len
  TOrder extends TIsManufacturer extends true ? OrderForManufacturer : Order = TIsManufacturer extends true ? OrderForManufacturer : Order,
>(props: Props<TIsManufacturer, TOrder>) {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const dispatch = useDispatch()

  const availableClinics = useSelector(state => state.userContext.availableClinics)
  const clinic = useSelector(state => state.userContext.clinic)
  const orderFilters = useSelector(state => state.userContext.orderFilters)
  const ordersView = useSelector(state => state.userContext.ordersView)

  const isAdmin = useIsAdmin()
  const isClinician = useIsClinician()
  const isManufacturer = useIsManufacturer()
  const userRole = useGetBestRole()

  const { setLoading } = useLoading()

  const [expandedHeadersCount, setExpandedHeadersCount] = useState(0)

  let clinicIdForFetch
  if (clinic.name === '*') {
    clinicIdForFetch = isAdmin ? 'all' as const : null
  } else {
    clinicIdForFetch = clinic.id
  }

  const clinicians = useQueryClinicians(clinicIdForFetch)
  const locations = useQueryLocations(clinicIdForFetch)

  const updateBillingStatus = useUpdateBillingStatus()
  const updateOrderClinician = useUpdateOrderClinician()
  const updateOrderClinic = useUpdateOrderClinic()
  const updateOrderLocation = useUpdateOrderLocation()

  const rows = props.orders

  const filteredOrderStatuses = useMemo(
    () => getVisibleOrderStatuses(userRole, orderFilters.includeStatuses),
    [
      userRole,
      orderFilters.includeStatuses,
    ],
  )

  const clinicOptions: Array<SelectOption<number>> = useMemo(
    () => {
      if (!isAdmin) return []

      const options: Array<SelectOption<number>> = availableClinics
        .map(clinic => ({
          value: clinic.id,
          label: clinic.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      return options
    },
    [
      isAdmin,
      availableClinics,
    ],
  )

  const locationOptions = useMemo(
    () => makeOptionsLookup(locations.data, location => location.description),
    [locations.data],
  )

  const clinicianOptions = useMemo(
    () => makeOptionsLookup(clinicians.data, getPersonFullName),
    [clinicians.data],
  )

  const columns = useMemo(
    () => {
      const data: Data<TIsManufacturer, TOrder> = {
        i18n,
        isAdmin,
        isManufacturer: isManufacturer as TIsManufacturer,
        isLoading: props.isLoading,
        isClinician,
        setLoading,
        rows,
        ordersCountsByOrderStatuses: props.ordersCountsByOrderStatuses,
        selectedOrderIds: props.selectedOrderIds,
        setSelectedOrderIds: props.onSelectedOrderIdsChange,
        filteredOrderStatuses: filteredOrderStatuses,
        setFilteredOrderStatuses: (statuses) =>
          dispatch(userContextActions.setOrderFilters({
            ...orderFilters,
            includeStatuses: statuses,
          })),
        filteredBillingStatuses: props.filteredBillingStatuses,
        setFilteredBillingStatuses: props.onFilteredBillingStatusesChange,
        updateOrderClinic,
        updateOrderLocation,
        updateOrderClinician,
        updateBillingStatus,
        clinicOptions,
        locationOptions,
        clinicianOptions,
        onHeaderExpand: expanded =>
          // NOTE(pascal): Using counts here instead of more obvious approaches
          // like booleans, because there can be more than one header
          // opened/closed at the same time.
          // We need this info to calculate the height the table occupies to
          // prevent rows from being cutoff visually, so the most important
          // notion here is to make sure we always know if at least one header
          // is expanded (usually through its filters), and make sure that this
          // is synced with the actual UI, which this counting does correctly.
          setExpandedHeadersCount(count => expanded ? count + 1 : count - 1),
        onUpdateOrderStatus: props.onUpdateOrderStatus,
        orderFilters,
        setOrderFilters: (filters) => dispatch(userContextActions.setOrderFilters(filters)),
      }

      if (isAdmin && ordersView === 'adminView') return getAdminColumns(i18n, data as never)
      if (isAdmin && ordersView === 'shippingView') return getShippingColumns(i18n, data as never)
      if (isManufacturer) return getManufacturerColumns(data as never)
      return getClinicianColumns(data as never)
    },
    [
      ordersView,
      orderFilters,
      filteredOrderStatuses,
      dispatch,
      i18n,
      isAdmin,
      isManufacturer,
      isClinician,
      setLoading,
      rows,
      props.isLoading,
      props.ordersCountsByOrderStatuses,
      props.selectedOrderIds,
      props.onSelectedOrderIdsChange,
      props.filteredBillingStatuses,
      props.onFilteredBillingStatusesChange,
      props.onUpdateOrderStatus,
      updateOrderClinic,
      updateOrderLocation,
      updateOrderClinician,
      updateBillingStatus,
      clinicOptions,
      locationOptions,
      clinicianOptions,
    ],
  )

  const headerExtraHeight =
    expandedHeadersCount > 0
      ? magicExpandedExtraHeaderSize
      : 0

  const tableHeight = props.heightPx - magicTableHeaderSize - headerExtraHeight

  return (
    <Box
      direction="column"
      height={`${props.heightPx}px`}
    >
      <Box
        direction="row"
        width="100vw"
        overflow="hidden"
      >
        <DataTable
          primaryKey="id"
          background={['light-1', 'light-3']}
          replace={true}
          columns={columns}
          data={rows as never}
          step={defaultGetOrdersFilters.pageSize}
          size={`${tableHeight}px`}
          placeholder={
            props.isLoading &&
            <Box
              className={ownStyles['load-more-container']}
              align="center"
              justify="center"
              pad="0.5rem 0"
              width="100vw"
              background="white"
              style={{
                position: 'absolute',
                bottom: 0,
              }}
            >
              <Spinner size="medium" />
            </Box>
          }
          onMore={props.onFetchNextPage}
        />
        <Outlet />
      </Box>
    </Box>
  )
}

export default OrderTable
