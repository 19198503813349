import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ordersKeys } from '../fetchers/orders'
import {
  PostPrescription,
  PutPrescription,
  getPrescription,
  postPrescription,
  prescriptionsKeys,
  updatePrescription,
} from '../fetchers/prescriptions'

const useQueryPrescription = (
  clinicId: number,
  orderId: number,
  prescriptionId: number | undefined | null,
) => {
  return useQuery({
    queryKey: prescriptionsKeys.details(clinicId, orderId),
    queryFn: ({signal}) => getPrescription(clinicId, orderId, signal),
    enabled:
      !Number.isNaN(clinicId) &&
      !Number.isNaN(orderId) &&
      prescriptionId != null &&
      prescriptionId > 0,
    refetchOnWindowFocus: false,
  })
}

const useCreatePrescription = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {prescription: PostPrescription}) =>
      postPrescription(data.prescription),
    onSuccess: () => {
      void queryClient.invalidateQueries({queryKey: prescriptionsKeys.all()})
      void queryClient.invalidateQueries({queryKey: ordersKeys.all()})
    },
  })
}

const useSavePrescription = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {prescription: PutPrescription}) =>
      updatePrescription(data.prescription),
    onSuccess: () => {
      void queryClient.invalidateQueries({queryKey: prescriptionsKeys.all()})
      void queryClient.invalidateQueries({queryKey: ordersKeys.all()})
    },
  })
}

export {
  useCreatePrescription,
  useSavePrescription,
  useQueryPrescription,
}
