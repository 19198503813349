import { Patient } from '../../types/Patient'
import { Prescription } from '../../types/Prescription'
import { topCoverLengths } from '../../types/PrescriptionEnums/TopCoverLength'

const isPatientValid = (patient: Patient): boolean => {
  return (
    patient.firstName !== '' &&
    patient.lastName !== ''
  )
}

const isPrescriptionValid = (prescription: Prescription): boolean => {
  return (
    !prescription.rxOrthotic.topCoverActive ||
    prescription.rxOrthotic.topCoverLength === topCoverLengths.mets ||
    (prescription.section.shoeSizeValue !== '' &&
      prescription.rxOrthotic.footbedLength !== '0' &&
      prescription.rxOrthotic.footbedWidth !== '0')
  )
}

const isFormValid = (
  patient: Patient,
  prescription: Prescription,
): boolean =>
  isPatientValid(patient) &&
  isPrescriptionValid(prescription)

export {
  isPatientValid,
  isPrescriptionValid,
  isFormValid,
}
