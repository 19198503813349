
import { Trans } from '@lingui/macro'
import { Box, Button, Drop, Header as GrommetHeader, Heading, Spinner } from 'grommet'
import { ReactNode, useRef, useState } from 'react'

import SignOut from '../../components/SignOut'
import HeightCollapsible from '../../components/grommet-sub/Collapsible'
import { applicationVersion } from '../../constants/version'
import { useLoading } from '../../hooks/loading'
import { useIsAdmin, useIsClinician, useIsManufacturer } from '../../hooks/roles'
import { useSelector } from '../../store/store'
import { NewClinic, getNewClinic } from '../../types/Clinic'
import { hardReload } from '../../utils/reload'
import { ClinicEditorImpl } from '../settings/ClinicEditor'

import AdminHeader from './headers/admin'
import ClinicianHeader from './headers/clinician'
import HeaderLogo from './headers/logo'
import ManufacturerHeader from './headers/manufacturer'

type HeaderContainerProps = Readonly<{
  noGap?: true,
  children: ReactNode,
}>

const HeaderContainer = (props: HeaderContainerProps) => {
  const latestVersion = useSelector(state => state.notifications.latestAppVersion)

  return (
    <>
      <HeightCollapsible open={latestVersion !== applicationVersion}>
        <Box
          direction="column"
          justify="center"
          align="center"
          background="orange"
        >
          <Box
            fill
            height={{ min: '0.75rem', max: '0.75rem', height: '0.75rem' }}
          />
          <Heading
            fill
            level="3"
            textAlign="center"
            margin="none"
            color="black"
          >
            <Trans>
              This version of the portal is outdated
            </Trans>
          </Heading>
          <Box
            fill
            height={{ min: '0.5rem', max: '0.5rem', height: '0.5rem' }}
          />
          <Button
            color="white"
            primary={true}
            label={<Trans>Update now</Trans>}
            onClick={() => {
              // Hopefully this updates the app to the latest version!
              hardReload()
            }}
            style={{
              borderColor: 'black',
              fontWeight: 'bold',
            }}
          />
          <Box
            fill
            height={{ min: '0.75rem', max: '0.75rem', height: '0.75rem' }}
          />
        </Box>
      </HeightCollapsible>
      <GrommetHeader
        gap={props.noGap === true ? undefined as never : '0.2rem'}
        pad="1rem"
      >
        {props.children}
      </GrommetHeader>
    </>
  )
}

const ClinicCreator = () => {
  const isAdmin = useIsAdmin()
  const buttonRef = useRef<HTMLElement | null>(null)
  const [clinic, setClinic] = useState<NewClinic | null>(null)

  if (!isAdmin) return null

  return (
    <Box direction="row">
      <Button
        ref={buttonRef as never}
        label={<Trans>Create clinic</Trans>}
        onClick={() => setClinic(clinic => clinic === null ? getNewClinic() : null)}
      />
      {
        clinic !== null &&
        buttonRef.current &&
        <Drop
          target={buttonRef.current}
          round
          pad=".8rem"
          width={{ min: '300px' }}
          margin={{ top: '1rem', right: '4rem' }}
          align={{ top: 'top', right: 'left' }}
          elevation="medium"
        >
          <ClinicEditorImpl
            item={clinic}
          />
        </Drop>
      }
    </Box>
  )
}

type Props = {
  handleSettingsPressed: () => unknown,
}

const Header = (props: Props) => {
  const isAdmin = useIsAdmin()
  const isClinician = useIsClinician()
  const isManufacturer = useIsManufacturer()

  const availableClinics = useSelector(state => state.userContext.availableClinics)

  let content = null

  if (availableClinics.length === 0 && !isManufacturer) {
    content =
      <>
        <ClinicCreator />
        <SignOut />
      </>
  }

  if (content !== null) {
    return (
      <HeaderContainer
        noGap={true}
      >
        <HeaderLogo />
        {content}
      </HeaderContainer>
    )
  }

  return (
    <Box
      style={{
        zIndex: '20',
      }}
    >
      <HeaderContainer
        noGap={true}
      >
        {
          isClinician &&
          <ClinicianHeader
            onHandleSettingsPressed={props.handleSettingsPressed}
          />
        }
        {
          isAdmin &&
          <AdminHeader
            onHandleSettingsPressed={props.handleSettingsPressed}
          />
        }
        {
          isManufacturer &&
          <ManufacturerHeader />
        }

      </HeaderContainer>
    </Box>
  )
}

const EmptyHeader = () => {
  const { isLoading } = useLoading()

  return (
    <HeaderContainer>
      <Box
        direction="row"
        align="center"
        gap="1rem"
      >
        <HeaderLogo />
        {isLoading && <Spinner size="medium" />}
      </Box>
      <SignOut />
    </HeaderContainer>
  )
}

export default Header

export {
  EmptyHeader,
}
