import { useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { UserRoleManager } from '../types/UserRole'
import { getCognitoToken } from '../utils/cognito'

type TidioProperties = {
  userRole?: string | undefined,
  clinicName?: string,
  locationName?: string,
  clinicianName?: string,
}

type TidioChatApi = {
  readyEventWasFired: boolean,
  setContactProperties: (properties: TidioProperties) => unknown,
}

declare const window: Window & {
  tidioPropertiesQueue?: TidioProperties[] | null | undefined,
  tidioChatApi?: TidioChatApi | null | undefined,
  queueTidioProperties: TidioChatApi,
}

declare const document: Document & {
  tidioIdentify: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    distinct_id?: string | undefined,
    email?: string | undefined,
    tags?: string[] | undefined,
  },
}

window.queueTidioProperties = {
  readyEventWasFired: false,
  setContactProperties: (properties) => {
    if (!window.tidioPropertiesQueue) window.tidioPropertiesQueue = []
    window.tidioPropertiesQueue.push(properties)
  },
}

const useTidio = () => {
  useLayoutEffect(
    () => {
      const pending = document.querySelector('.tidio-pending')
      if (pending) return

      const existing = document.querySelector('.tidio-script')
      if (existing) return

      document.body.classList.add('tidio-pending')

      getCognitoToken()
        .then(payload => {
          const sub = payload['sub']
          const email = payload['email']
          const groups = payload['cognito:groups']

          let role = 'Clinician'
          if (Array.isArray(groups)) {
            const userRoleManager = new UserRoleManager(groups as string[])
            if (userRoleManager.isAdmin) role = 'Admin'
            if (userRoleManager.isManufacturer) role = 'Manufacturer'
            if (userRoleManager.isClinicOwner) role = 'Clinic owner'
          }

          document.tidioIdentify = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            distinct_id: typeof sub === 'string' ? sub : undefined,
            email: typeof email === 'string' ? email : undefined,
          }

          const script = document.createElement('script')

          script.className = 'tidio-script'
          script.async = true
          script.src = `//code.tidio.co/ptt5djku7ryztjsgsmhe47bpi0lupzok.js`

          script.onload = () => {
            document.body.classList.remove('tidio-pending')

            window.tidioChatApi?.setContactProperties({
              userRole: role,
            })

            if (window.tidioPropertiesQueue) {
              while (window.tidioPropertiesQueue.length > 0) {
                const item = window.tidioPropertiesQueue.pop()
                if (!item) continue
                window.tidioChatApi?.setContactProperties(item)
              }
            }
          }

          document.body.append(script)
        })
        .catch(console.error)
    },
    [],
  )

  useLayoutEffect(
    () => {
      return () => {
        const existing = document.querySelector('.tidio-script')
        if (existing) {
          document.body.removeChild(existing)
        }

        const tidioChatCode = document.querySelector('#tidio-chat-code')
        if (tidioChatCode) {
          document.body.removeChild(tidioChatCode)
        }

        const tidioChat = document.querySelector('#tidio-chat')
        if (tidioChat) {
          document.body.removeChild(tidioChat)
        }
      }
    },
    [],
  )
}

const useTidioChatApi = (): TidioChatApi => {
  const [isApiSet, setIsApiSet] = useState<boolean>(false)

  useEffect(
    () => {
      if (isApiSet) return

      const intervalId = setInterval(
        () => {
          if (window.tidioChatApi?.readyEventWasFired === true) {
            setIsApiSet(true)
          }
        },
        10,
      )

      return () => {
        clearInterval(intervalId)
      }
    },
    [isApiSet],
  )

  return useMemo(
    () => {
      if (!isApiSet) return window.queueTidioProperties
      return window.tidioChatApi ?? window.queueTidioProperties
    },
    [isApiSet],
  )
}

export {
  useTidio,
  useTidioChatApi,
}
