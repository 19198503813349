import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const cancellationReasons = {
  clientOrderCancellation: 'clientOrderCancellation',
  orderError: 'orderError',
  prescriptionError: 'prescriptionError',
  scanQuality: 'scanQuality',
  other: 'other',
} as const

export type CancellationReason = typeof cancellationReasons[keyof typeof cancellationReasons]

export const getCancellationReasonLabel = (i18n: I18n, reason: CancellationReason | string): string => {
  switch (reason) {
  case cancellationReasons.clientOrderCancellation: return t(i18n)`Client order cancellation`
  case cancellationReasons.orderError: return t(i18n)`Order error`
  case cancellationReasons.prescriptionError: return t(i18n)`Prescription error`
  case cancellationReasons.scanQuality: return t(i18n)`Scan quality`
  case cancellationReasons.other: return t(i18n)`Other`
  default: return t(i18n)`Other: ${reason}`
  }
}
