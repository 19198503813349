
import { Box, Button, Drop} from 'grommet'
import { AddCircle } from 'grommet-icons'
import { ReactNode, isValidElement, useRef, useState } from 'react'

type Option = {
  label?: string,
  onClick: () => unknown,
}

type Props = {
  icon?: ReactNode,
  title?: string,
  options: Array<Option | ReactNode>,
}

const OverlayButtonMenu = (props: Props) => {
  const [isOpen, setOpen] = useState(false)
  const boxRef = useRef<HTMLDivElement | null>(null)

  const closePanel = () => {
    setOpen(false)
  }

  return (
    <Box
      direction="row"
    >
      <Box
        onClick={() => {
          setOpen((isOpen) => !isOpen)
        }}
        ref={boxRef}
        align="center"
        gap="0.5rem"
      >
        {props.title !== undefined &&
         <Box>
           {props.title}
         </Box>
        }
        {props.icon ?? <AddCircle />}
      </Box>
      {isOpen && (
        <Drop
          // align={{ bottom: 'top' }}
          target={boxRef.current ?? {}}
          onClickOutside={closePanel}
          elevation="large"
          round
        >
          {/* <Collapsible direction="horizontal" open={isOpen}> */}
          <Box
            gap="0.3rem"
            pad="0.3rem"
          >
            {props.options.map((option, index) => {
              if (isValidElement(option)) return option

              const opt = option as Option
              if (opt.label === undefined) return null

              return (
                <Button
                  key={`${opt.label}, ${index}`}
                  label={opt.label}
                  onClick={() => {
                    opt.onClick()
                    closePanel()
                  }}
                />
              )
            })}
          </Box>
          {/* </Collapsible> */}
        </Drop>
      )}
    </Box>
  )
}

export default OverlayButtonMenu
