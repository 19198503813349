import { I18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { Button, ColumnConfig, FormField } from 'grommet'
import { Performance } from 'grommet-icons'

import OverlayButtonMenu from '../../../components/OverlayButtonMenu'
import { Select } from '../../../components/grommet-sub'
import { Order } from '../../../types/Order'

import { getBillingStatusColumn, getClinicNameColumn, getCreatedAtColumn, getFilesColumn, getInternalCommentsColumn, getManufacturerDeliveryColumn, getOrderNumberColumn, getPatientFirstNameColumn, getPatientLastNameColumn, getPrescriptionColumn, getProductInfoColumn, getScansColumn, getSelectColumn, getStatusColumn } from './Columns'
import { Data } from './Data'
import { forEachSelected } from './selection'

function getOptionsColumn(data: Data<false, Order>): ColumnConfig<Order> {
  const {
    rows,
    setLoading,
    selectedOrderIds,
    clinicOptions,
    locationOptions,
    clinicianOptions,
    updateOrderClinic,
    updateOrderLocation,
    updateOrderClinician,
  } = data

  return {
    property: 'options',
    header: <Trans>Options</Trans>,
    size: '6%',
    render: (order) => (
      <OverlayButtonMenu
        icon={<Performance />}
        options={[
          <FormField
            key={`clinic-${order.id}`}
            label="Clinic: "
          >
            <Select
              options={clinicOptions}
              value={order.clinicId}
              onChange={value => {
                setLoading(true)

                forEachSelected(
                  rows,
                  order.id,
                  selectedOrderIds,
                  order =>
                    updateOrderClinic.mutateAsync({
                      clinicId: order.clinicId,
                      orderId: order.id,
                      newClinicId: value,
                    }),
                )
                  .finally(() => setLoading(false))
              }}
            />
          </FormField>,
          <FormField
            key={`clinician-${order.id}`}
            label="Clinician: "
          >
            <Select
              options={clinicianOptions[order.clinicId] ?? []}
              value={order.clinician?.id ?? -1}
              onChange={value => {
                setLoading(true)

                forEachSelected(
                  rows,
                  order.id,
                  selectedOrderIds,
                  order =>
                    updateOrderClinician.mutateAsync({
                      clinicId: order.clinicId,
                      orderId: order.id,
                      clinicianId: value,
                    }),
                )
                  .finally(() => setLoading(false))
              }}
            />
          </FormField>,
          <FormField
            key={`location-${order.id}`}
            label="Location: "
          >
            <Select
              options={locationOptions[order.clinicId] ?? []}
              value={order.location?.id ?? -1}
              onChange={value => {
                setLoading(true)

                forEachSelected(
                  rows,
                  order.id,
                  selectedOrderIds,
                  order =>
                    updateOrderLocation.mutateAsync({
                      clinicId: order.clinicId,
                      orderId: order.id,
                      locationId: value,
                    }),
                )
                  .finally(() => setLoading(false))
              }}
            />
          </FormField>,
          <Button
            key={`delete-${order.id}`}
            label="delete order"
            primary
            color={'red'}
          />,
        ]}
      />
    ),
  }
}

function getAdminColumns(
  i18n: I18n,
  data: Data<false, Order>,
): Array<ColumnConfig<Order>> {
  return [
    getSelectColumn(data),
    getStatusColumn(data),
    getBillingStatusColumn(data),
    getProductInfoColumn(data),
    getOrderNumberColumn(),
    getPatientFirstNameColumn(),
    getPatientLastNameColumn(),
    getPrescriptionColumn(data),
    getScansColumn(i18n),
    getFilesColumn(),
    getClinicNameColumn(),
    getManufacturerDeliveryColumn(),
    getCreatedAtColumn(),
    getOptionsColumn(data),
    getInternalCommentsColumn(),
  ]
}

export {
  getAdminColumns,
}
