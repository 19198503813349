
import { Plural, Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, CheckBox, ColumnConfig, DataTable, FileInput } from 'grommet'
import { Close } from 'grommet-icons'
import { useCallback, useMemo, useState } from 'react'

import UpdatableField from '../../components/UpdatableField'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { useClinic } from '../../hooks/clinic'
import { useShowSuccessToast } from '../../hooks/toasts'
import { useCreateLogo, useDeleteLogo, useQueryLogos, useUpdateLogo } from '../../queries/logos'
import { Logo, NewLogo } from '../../types/Logo'
import { getFilenameAndExtension } from '../../utils/files'

const LogoSelector = () => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const showSuccessToast = useShowSuccessToast()

  const clinic = useClinic()

  const [description, setDescription] = useState<string>('Description')
  const [newLogos, setLogos] = useState<File[]>([])
  const [confirmNoLogoActive, setConfirmNoLogoActive] = useState<[Logo, boolean] | null>(null)
  const [confirmDelete, setConfirmDelete] = useState<Logo | null>(null)

  const logos = useQueryLogos({
    clinicId: clinic.id,
  })

  const createLogo = useCreateLogo()
  const updateLogo = useUpdateLogo()
  const deleteLogo = useDeleteLogo()

  const updateMainLogo = useCallback(
    async (
      logo: Logo,
      isMainLogo: boolean,
    ) => {
      await updateLogo.mutateAsync({
        clinicId: clinic.id,
        logo: {
          ...logo,
          isMainLogo,
        },
      })

      showSuccessToast(
        'settings.logos.update-logo',
        <Trans>Logo updated successfully</Trans>,
      )
    },
    [
      clinic.id,
      showSuccessToast,
      updateLogo,
    ],
  )

  const handleChangeMainLogo = useCallback(
    async (
      logo: Logo,
      isMainLogo: boolean,
    ) => {
      if (!isMainLogo) {
        setConfirmNoLogoActive([logo, isMainLogo])
        return
      }

      await updateMainLogo(logo, isMainLogo)
    },
    [
      updateMainLogo,
    ],
  )

  const handleConfirmNoLogoActive = async () => {
    if (!confirmNoLogoActive) return
    await updateMainLogo(...confirmNoLogoActive)
    setConfirmNoLogoActive(null)
  }

  const handleCancelNoLogoActive = () => {
    setConfirmNoLogoActive(null)
  }

  const handleConfirmDescription = useCallback(
    (
      logo: Logo,
    ) => {
      updateLogo.mutate(
        {
          clinicId: clinic.id,
          logo: {
            ...logo,
            description: description,
          },
        },
      )
    },
    [
      clinic.id,
      description,
      updateLogo,
    ])

  const handleChangeDescription = (newDescription: string) => {
    setDescription(newDescription)
  }

  const handleCreateLogo = async (file: File) => {
    const { fileName, extension } = getFilenameAndExtension(file.name)

    const logo: NewLogo = {
      isMainLogo: true,
      description: description,
      extension: extension,
      fileName: fileName,
      filePath: `${clinic.name}/logos`,
      file: file,
    }

    await createLogo.mutateAsync({
      clinicId: clinic.id,
      logo,
    })
  }

  const handleCreateLogos = async () => {
    if (newLogos.length === 0) return

    for (const file of newLogos) {
      await handleCreateLogo(file)
    }

    showSuccessToast(
      'settings.logos.create-logo',
      <Plural
        value={newLogos.length}
        one="Logo created successfully"
        other="Logos created successfully"
      />,
    )
  }

  const handleDelete = (logo: Logo) => {
    setConfirmDelete(logo)
  }

  const handleConfirmDelete = () => {
    if (!confirmDelete) return

    deleteLogo.mutate(
      {
        clinicId: clinic.id,
        logoId: confirmDelete.id,
      },
      {
        onSuccess: () => {
          setConfirmDelete(null)

          showSuccessToast(
            'settings.logos.delete-logo',
            <Trans>Logo deleted successfully</Trans>,
          )
        },
      },
    )
  }

  const handleCancelDelete = () => {
    setConfirmDelete(null)
  }

  const columns = useMemo(
    () => {
      const logoColumns: Array<ColumnConfig<Logo>> = [
        {
          property: 'logoActive',
          header: <Trans>Main logo</Trans>,
          render: (logo) => (
            <CheckBox
              checked={logo.isMainLogo}
              onClick={event => handleChangeMainLogo(logo, event.currentTarget.checked)}
            />
          ),
        },
        {
          property: 'fileName',
          header: <Trans>File name</Trans>,
        },
        {
          property: 'Description',
          header: <Trans>Description </Trans>,
          render: (logo: Logo) => (
            <UpdatableField
              value={logo.description}
              onChange={newValue => handleChangeDescription(newValue)}
              confirmChanges={() => handleConfirmDescription(logo)}
            />
          ),
        }
        ,
        {
          property: 'delete',
          header: <Trans>Delete</Trans>,
          render: (logo) => (
            <Button
              icon={<Close />}
              a11yTitle={t(i18n)`Delete the logo`}
              onClick={() => handleDelete(logo)}
            />
          ),
        },
      ]
      return logoColumns
    },
    [
      handleChangeMainLogo,
      handleConfirmDescription,
      i18n,
    ],
  )

  return (
    <>
      <DataTable<Logo>
        background={{ pinned: 'light-2' }}
        columns={columns}
        data={logos.data ?? []}
      />

      <FileInput
        messages={{
          browse: t(i18n)`Browse`,
          files: t(i18n)`Logos`,
          dropPrompt: t(i18n)`Drag and drop logos here`,
          dropPromptMultiple: t(i18n)`Drag and drop logos here`,
          remove: t(i18n)`Remove`,
          removeAll: t(i18n)`Remove all`,
        }}
        multiple={true}
        onChange={(_, target) => {
          if (!target) return
          setLogos([...target.files])
        }}
      />
      <Button
        label={<Trans>Upload new logo</Trans>}
        onClick={handleCreateLogos}
      />

      <ConfirmModal
        show={confirmDelete !== null}
        title={<Trans>Delete logo?</Trans>}
        message={<Trans>Are you sure you want to delete this logo?</Trans>}
        confirmLabel={<Trans>Yes, delete this logo</Trans>}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />

      <ConfirmModal
        show={confirmNoLogoActive !== null}
        title={<Trans>Disable all logos?</Trans>}
        message={
          <Trans>
            Are you sure you want to deselect this logo?
            This will fall back on using hardwired logo configurations,
            which is likely to produce unsatisfactory results.
          </Trans>
        }
        confirmLabel={<Trans>Yes, disable all logos</Trans>}
        onConfirm={handleConfirmNoLogoActive}
        onCancel={handleCancelNoLogoActive}
      />
    </>
  )
}

export default LogoSelector
