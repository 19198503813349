
import { useLingui } from '@lingui/react'
import { Box, Button } from 'grommet'
import { useMemo } from 'react'

import { userContextActions } from '../store/actions/userContext'
import { useDispatch, useSelector } from '../store/store'
import { OrderStatus } from '../types/OrderStatus'
import { getOrderStatusDisplays } from '../utils/statusDisplays'

type Props = {
  statuses: OrderStatus[],
  counts: Record<OrderStatus, number | undefined>,
  toggle?: boolean,
}

const StatusButtonFilter = (props: Props) => {
  const lingui = useLingui()

  const dispatch = useDispatch()

  const currentFilters = useSelector(state => state.userContext.orderStatusFilters)
  const activeStatuses = new Set(currentFilters)

  const buttons = useMemo(() => {
    const displays = getOrderStatusDisplays(lingui.i18n)
    return props.statuses.map(status => ({
      value: {
        ...displays[status],
        label: `${displays[status].label} (${props.counts[status] ?? 0})`,
      },
      status,
    }))
  },
  [
    lingui.i18n,
    props.statuses,
    props.counts,
  ])

  const handleSelection = (status: OrderStatus) => {
    if (props.toggle === true) {
      dispatch(userContextActions.setOrderStatusFilters([status]))
    } else {
      const updatedFilters = currentFilters.includes(status)
        ? currentFilters.filter(filter => filter !== status)
        : [...currentFilters, status]

      dispatch(userContextActions.setOrderStatusFilters(
        updatedFilters,
      ))
    }
  }

  return (
    <Box
      gap="0.5rem"
      direction="row"
      height={'1.75rem'}
    >
      { buttons
        .map(status => {
          return (
            <Button
              key={status.status}
              label={status.value.label}
              color={status.value.color}
              primary={activeStatuses.has(status.status)}
              onClick={() => handleSelection(status.status)}
            />
          )
        }) }
    </Box>
  )
}

export default StatusButtonFilter
