
import { useQueryClient } from '@tanstack/react-query'
import { Box, Collapsible, FormField, Heading, Nav } from 'grommet'
import { FormNext, FormPrevious } from 'grommet-icons'
import { useEffect, useState } from 'react'

import { Select } from '../../../components/grommet-sub'
import { cliniciansKeys, getClinicians } from '../../../fetchers/clinicians'
import { getLocations, locationsKeys } from '../../../fetchers/locations'
import { useTidioChatApi } from '../../../hooks/customerService'
import { useIsClinician } from '../../../hooks/roles'
import { userContextActions } from '../../../store/actions/userContext'
import { useDispatch, useSelector } from '../../../store/store'
import { allClinic } from '../../../types/Clinic'
import { allClinician } from '../../../types/Clinician'
import { allLocation } from '../../../types/Location'
import { getPersonFullName } from '../../../utils/person'

const Selector = () => {

  const [showMore, handleMore] = useState<boolean>(false)

  const isClinician = useIsClinician()

  return (
    <Nav
      direction="row"
      align="center"
      gap="medium"
    >
      <ClinicSelector />
      {
        isClinician
          ? (
            <Box
              direction="row"
              gap=".5rem"
              align="center"
            >
              <LocationSelector />
              <ClinicianSelector />
            </Box>)
          : (
            <>
              <Box
                onClick={() => {
                  handleMore(more => !more)
                }}
              >
                <Collapsible
                  direction="vertical"
                  open={showMore}
                >
                  <FormPrevious />
                </Collapsible>
                <Collapsible
                  direction="vertical"
                  open={!showMore}
                >
                  <FormNext />
                </Collapsible>
              </Box>
              {showMore &&
                <Box
                  direction="row"
                  gap=".5rem"
                  align="center"
                >
                  <LocationSelector />
                  <ClinicianSelector />
                </Box>
              }
            </>
          )
      }

    </Nav>
  )
}

export const ClinicSelector = () => {
  const tidioChatApi = useTidioChatApi()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const availableClinics = useSelector(state => state.userContext.availableClinics)
  const activeClinic = useSelector(state => state.userContext.clinic)

  const clinicOptions = availableClinics.length > 1
    ? [
      '*',
      ...Array.from(new Set(availableClinics.map(clinic => clinic.name))).sort(),
    ]
    : availableClinics.map(clinic => clinic.name)

  useEffect(
    () => {
      if (!availableClinics[0]) return
      if (availableClinics[0].name === '*') {
        dispatch(userContextActions.setAvailableClinicians([allClinician]))
        dispatch(userContextActions.setAvailableLocations([allLocation]))
        return
      }

      queryClient
        .ensureQueryData({
          queryKey: locationsKeys.list(activeClinic.id),
          queryFn: ({ signal }) => getLocations(activeClinic.id, signal),
        })
        .then(locations => {
          const firstLocation = locations.sort()[0]

          if (!firstLocation) return
          tidioChatApi.setContactProperties({
            locationName: firstLocation.description,
          })

          dispatch(userContextActions.setAvailableLocations(locations))
          dispatch(userContextActions.setLocation(locations.length > 1 ? allLocation : firstLocation))
        })
        .catch(console.error)

      queryClient
        .ensureQueryData({
          queryKey: cliniciansKeys.list(activeClinic.id),
          queryFn: ({ signal }) => getClinicians(activeClinic.id, false, signal),
        })
        .then(clinicians => {
          const firstClinician = clinicians
            .sort((a, b) => getPersonFullName(a).localeCompare(getPersonFullName(b)))[0]

          if (!firstClinician) return
          tidioChatApi.setContactProperties({
            clinicianName: getPersonFullName(firstClinician),
          })

          dispatch(userContextActions.setAvailableClinicians(clinicians))
          dispatch(userContextActions.setClinician(clinicians.length > 1 ? allClinician : firstClinician))
        })
        .catch(console.error)

      if (clinicOptions.length === 1) {
        dispatch(userContextActions.setClinic(availableClinics[0]))
        return
      }

    },
    [
      activeClinic.id,
      activeClinic.name,
      availableClinics,
      dispatch,
      clinicOptions.length,
      queryClient,
      tidioChatApi],
  )

  const handleClinicSelected = (clinicName: string) => {
    if (clinicName === '*') {
      dispatch(userContextActions.setClinic(allClinic))
      dispatch(userContextActions.setLocation(allLocation))
      dispatch(userContextActions.setClinician(allClinician))
      dispatch(userContextActions.setAvailableLocations([allLocation]))
      dispatch(userContextActions.setAvailableClinicians([allClinician]))

      tidioChatApi.setContactProperties({
        clinicName: '*',
        locationName: '*',
        clinicianName: '*',
      })

      return
    }

    const clinic = availableClinics.find(clinic => clinic.name === clinicName)
    if (!clinic) return

    dispatch(userContextActions.setClinic(clinic))
    dispatch(userContextActions.setLocation(allLocation))

    tidioChatApi.setContactProperties({
      clinicName: clinic.name,
    })
  }
  return (
    <Box
      direction="row"
      gap=".5rem"
      align="center"
    >
      {availableClinics.length === 1 &&
        <Heading
          level={3}
          margin="0"
        >
          {activeClinic.name}
        </Heading>}
      {availableClinics.length > 1 &&
        <FormField>
          <Select
            name="header.selector.select-clinic"
            options={clinicOptions}
            value={activeClinic.name}
            onChange={value => handleClinicSelected(value)}
          />
        </FormField>
      }
    </Box>
  )
}

export const LocationSelector = () => {

  const tidioChatApi = useTidioChatApi()
  const dispatch = useDispatch()

  const availableLocations = useSelector(state => state.userContext.availableLocations)
  const activeLocation = useSelector(state => state.userContext.location.description)

  const locationOptions = () => {
    if (availableLocations.length === 1) {
      return [
        ...Array.from(new Set(availableLocations.map(location => location.description))).sort(),
      ]
    }
    return [
      '*',
      ...Array.from(new Set(availableLocations.map(location => location.description))).sort(),
    ]
  }

  const handleLocationSelected = (locationName: string) => {
    tidioChatApi.setContactProperties({
      locationName,
    })

    if (locationName === '*') {
      dispatch(userContextActions.setLocation(allLocation))
      return
    }

    const location = availableLocations.find(location => location.description === locationName)
    if (!location) return

    dispatch(userContextActions.setLocation(location))
  }

  return (

    <FormField>
      <Select
        name="header.selector.select-location"
        options={locationOptions()}
        value={activeLocation}
        onChange={value => handleLocationSelected(value)}
      />
    </FormField>

  )
}

export const ClinicianSelector = () => {

  const tidioChatApi = useTidioChatApi()
  const dispatch = useDispatch()

  const availableClinicians = useSelector(state => state.userContext.availableClinicians)
  const activeClinician = useSelector(state => getPersonFullName(state.userContext.clinician))

  const clinicianOptions = availableClinicians.length === 1 ? [
    ...Array.from(new Set(availableClinicians.map(clinician => getPersonFullName(clinician)))).sort(),
  ]
    : [
      '*',
      ...Array.from(new Set(availableClinicians.map(clinician => getPersonFullName(clinician)))).sort(),
    ]

  const handleClinicianName = (clinicianName: string) => {
    tidioChatApi.setContactProperties({
      clinicianName,
    })

    if (clinicianName === '*') {
      dispatch(userContextActions.setClinician(allClinician))
      return
    }

    const clinician = availableClinicians.find(clinician => getPersonFullName(clinician) === clinicianName)
    if (!clinician) return

    dispatch(userContextActions.setClinician(clinician))
  }

  return (
    <FormField>
      <Select
        name="header.selector.select-clinician"
        options={clinicianOptions}
        value={activeClinician}
        onChange={value => handleClinicianName(value)}
      />
    </FormField>
  )
}

export default Selector
