import { I18n } from '@lingui/core'
import { Plural, Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Link } from '@tanstack/react-router'
import { Box, Button, CheckBox, ColumnConfig, Tag, Text, Tip } from 'grommet'
import { Alert, Copy, Deliver, DocumentText } from 'grommet-icons'
import { ReactNode } from 'react'

import ColumnFilter from '../../../components/ColumnFilter'
import { BillingStatusDropdown, OrderStatusDropdown } from '../../../components/StatusDropdown'
import { BillingStatusLabel, OrderStatusLabel, PrescriptionStatusLabel } from '../../../components/StatusLabel'
import { useIsManufacturer } from '../../../hooks/roles'
import { Order, OrderForManufacturer, isOrder } from '../../../types/Order'
import { isStatusBefore, orderStatuses } from '../../../types/OrderStatus'
import { getProductTypeLabel, productTypes } from '../../../types/PrescriptionEnums/ProductType'
import { getRequestedSideProductLabel, requestedSides } from '../../../types/PrescriptionEnums/RequestedSide'
import { getTopCoverLengthLabel, topCoverLengths } from '../../../types/PrescriptionEnums/TopCoverLength'
import { getScanStatusLabel, scanStatuses } from '../../../types/ScanStatus'
import { ProductFilter, getActiveProductFilters, getProductFiltersOptions, setActiveProductFilters } from '../../../types/filters/ProductFilters'
import { toTableDate } from '../../../utils/date'
import { getPersonFullName } from '../../../utils/person'

import { Data } from './Data'
import { forEachSelected } from './selection'

function getSelectColumn<
  TIsManufacturer extends true | false,
  TOrder extends TIsManufacturer extends true ? OrderForManufacturer : Order,
>(
  data: Data<TIsManufacturer, TOrder>,
): ColumnConfig<TOrder> {
  const {
    rows,
    selectedOrderIds,
    setSelectedOrderIds,
    isLoading,
  } = data

  return {
    property: 'select',
    size: '1.5rem',
    header: (
      <CheckBox
        checked={selectedOrderIds.length > 0 && rows.length === selectedOrderIds.length}
        indeterminate={selectedOrderIds.length > 0 && selectedOrderIds.length < rows.length}
        disabled={isLoading}
        onChange={() => {
          setSelectedOrderIds(currentIds => {
            if (currentIds.length > 0) return []
            return rows.map(order => order.id)
          })
        }
        }
      />
    ),
    render: (order) => (
      <CheckBox
        checked={selectedOrderIds.includes(order.id)}
        onChange={() => {
          setSelectedOrderIds(currentIds => {
            if (currentIds.includes(order.id)) {
              return currentIds.filter(id => id !== order.id)
            } else {
              return [...currentIds, order.id]
            }
          })
        }}
      />
    ),
  }
}

function getStatusColumn<
  TIsManufacturer extends true | false,
  TOrder extends TIsManufacturer extends true ? OrderForManufacturer : Order,
>(
  data: Data<TIsManufacturer, TOrder>,
): ColumnConfig<TOrder> {
  const {
    isAdmin,
    isManufacturer,
    isClinician,
    filteredOrderStatuses,
    setFilteredOrderStatuses,
    ordersCountsByOrderStatuses,
    onUpdateOrderStatus,
    onHeaderExpand,
  } = data

  const size = isAdmin ? '12%'
    : (Boolean(isClinician) &&
      (ordersCountsByOrderStatuses[orderStatuses.shipped] > 0 ||
        ordersCountsByOrderStatuses[orderStatuses.receivedByClinic] > 0)
      ? '15rem'
      : undefined as never)

  return {
    property: 'activeStatus',
    size: size,
    header:
      <>
        {!isManufacturer
          ? (
            <OrderStatusDropdown
              title={<Trans>Status</Trans>}
              multiple={true}
              showFilter={true}
              values={filteredOrderStatuses}
              counts={ordersCountsByOrderStatuses}
              onChange={statuses => setFilteredOrderStatuses(statuses)}
              onOpen={onHeaderExpand}
            />
          )
          : <Trans>Status</Trans>
        }
      </>,
    render: (order) => {
      let extraContent = null
      if (!isManufacturer) {
        const normalOrder = order as Order

        extraContent =
          <>
            {
              normalOrder.scanStatus === scanStatuses.missing &&
              <Tip
                plain
                content={
                  <Box
                    pad="0.8rem"
                    background={'light-1'}
                  >
                    <Trans>Scans missing</Trans>
                  </Box>
                }
              >
                <Alert
                  size="20px"
                  color="brand"
                />
              </Tip>
            }
            {
              isAdmin &&
              normalOrder.errors.length > 0 &&
              <Tip
                plain
                content={
                  <Box
                    pad="0.8rem"
                    background={'light-1'}
                  >
                    <Plural
                      value={normalOrder.errors.length}
                      one="# error detected (click to show)"
                      other="# errors detected (click to show)"
                    />
                  </Box>
                }
              >
                <Link
                  to="/orders/clinics/$clinicId/orders/$orderId/errors"
                  params={{
                    clinicId: order.clinicId,
                    orderId: order.id,
                  }}
                >
                  <Alert
                    size="20px"
                    color="red"
                  />
                </Link>
              </Tip>
            }
          </>
      }

      return (
        <Box
          gap="0.8rem"
          direction="row"
          align="center"
        >
          <Box width="100%">
            {
              (isAdmin || isManufacturer) &&
              <OrderStatusDropdown
                order={isManufacturer ? null : order as Order}
                value={order.activeStatus}
                onChange={newStatus => {
                  onUpdateOrderStatus(order, newStatus)
                }}
              />
            }
            {
              (!isAdmin && !isManufacturer) &&
              <OrderStatusLabel
                order={order as Order}
                status={order.activeStatus}
                onChange={newStatus => {
                  onUpdateOrderStatus(order, newStatus)
                }}
              />
            }
          </Box>
          {extraContent}
        </Box>
      )
    },
  }
}

function getBillingStatusColumn<
  TIsManufacturer extends true | false,
  TOrder extends TIsManufacturer extends true ? OrderForManufacturer : Order,
>(data: Data<TIsManufacturer, TOrder>): ColumnConfig<Order> {
  const {
    isAdmin,
    setLoading,
    rows,
    selectedOrderIds,
    filteredBillingStatuses,
    setFilteredBillingStatuses,
    updateBillingStatus,
    onHeaderExpand,
  } = data

  return {
    property: 'billingStatus',
    size: isAdmin ? '12%' : undefined as never,
    header:
      <BillingStatusDropdown
        title={<Trans>Billing Status</Trans>}
        multiple={true}
        showFilter={true}
        values={filteredBillingStatuses}
        onChange={statuses => setFilteredBillingStatuses(statuses)}
        onOpen={onHeaderExpand}
      />,
    render: (order) => {
      if (isStatusBefore(order.activeStatus, orderStatuses.submitted)) return null

      return (
        <Box
          gap="0.8rem"
          direction="row"
        >
          <Box width="100%">
            {
              isAdmin &&
              <BillingStatusDropdown
                value={order.billingStatus}
                onChange={newStatus => {
                  setLoading(true)

                  forEachSelected(
                    rows,
                    order.id,
                    selectedOrderIds,
                    order =>
                      updateBillingStatus.mutateAsync({
                        clinicId: order.clinicId,
                        orderId: order.id,
                        value: newStatus,
                      }),
                  )
                    .finally(() => setLoading(false))
                }}
              />
            }
            {
              !isAdmin &&
              <BillingStatusLabel status={order.billingStatus} />
            }
          </Box>
        </Box>
      )
    },
  }
}

const poundSign = '#'

function getOrderNumberColumn<TOrder>(): ColumnConfig<TOrder> {
  return {
    primary: true,
    property: 'orderNumber',
    header: <Trans>Order {poundSign}</Trans>,
  }
}

function getPatientFirstNameColumn<TOrder>(): ColumnConfig<TOrder> {
  return {
    property: 'patient.firstName',
    header: <Trans>First Name</Trans>,
  }
}

function getPatientLastNameColumn<TOrder>(): ColumnConfig<TOrder> {
  return {
    property: 'patient.lastName',
    header: <Trans>Last Name</Trans>,
  }
}

function getPrescriptionColumn(
  data: Data<false, Order>,
  getExtraIcons?: ((order: Order) => ReactNode) | undefined,
): ColumnConfig<Order> {
  const {
    isAdmin,
  } = data

  return {
    property: 'orderForm',
    header: <Trans>Prescriptions</Trans>,
    render: (order: Order) => (
      <Box
        direction="row"
        align="center"
        gap="0.4rem"
      >
        <Box
          direction="row"
          align="center"
          alignContent="stretch"
          width="70%"
          gap="0.8rem"
        >
          <Box
            direction="row"
            gap="0.5rem"
            align="center"
          >
            <PrescriptionStatusLabel status={order.prescriptionStatus} />
            <Link
              to="/orders/clinics/$clinicId/orders/$orderId/prescription"
              params={{
                clinicId: order.clinicId,
                orderId: order.id,
              }}
            >
              {
                isAdmin &&
                  typeof order.originalOrderId === 'number'
                  ? <Copy />
                  : <DocumentText />
              }
            </Link>
            {getExtraIcons?.(order)}
          </Box>
        </Box>
      </Box>
    ),
  }
}

function getProductInfoColumn<
  TIsManufacturer extends true | false,
  TOrder extends TIsManufacturer extends true ? OrderForManufacturer : Order,
>(data: Data<TIsManufacturer, TOrder>): ColumnConfig<TOrder> {
  const {
    i18n,
    orderFilters,
    isAdmin,
    setOrderFilters,
    onHeaderExpand,
  } = data

  return {
    property: 'productInfo',
    header:
      isAdmin
        ? (
          <ColumnFilter<ProductFilter>
            label={<Trans>Product</Trans>}
            included={getActiveProductFilters(orderFilters)}
            options={getProductFiltersOptions(i18n)}
            onChange={selected => setOrderFilters(setActiveProductFilters(selected, { ...orderFilters }))}
            onExpand={onHeaderExpand}
          />
        )
        : <Trans>Product</Trans>,
    sortable: false,
    render: function ProductInfo(order) {
      const { i18n } = useLingui()

      // Don't show if the prescription is not submitted yet!
      if (isOrder(order) && order.prescriptionId === null) return null
      if (isStatusBefore(order.activeStatus, orderStatuses.submitted)) return null

      return (
        <Box
          direction="row"
          wrap={true}
          gap="0.2rem"
          style={{
            rowGap: '0.2rem',
          }}
        >
          {
            order.productType !== productTypes.shell
              ? (
                <Tip content={t(i18n)`Product type`}>
                  <Tag
                    size="xsmall"
                    value={getProductTypeLabel(i18n, order.productType)}
                  />
                </Tip>
              )
              : null
          }
          {
            order.topCoverLength !== topCoverLengths.none
              ? (
                <Tip content={getTopCoverLengthLabel(i18n, order.topCoverLength)}>
                  <Tag
                    size="xsmall"
                    value={t(i18n)`Top cover`}
                  />
                </Tip>
              )
              : null
          }
          {
            order.requestedSide !== requestedSides.both
              ? (
                <Tip content={getRequestedSideProductLabel(i18n, order.requestedSide)}>
                  <Tag
                    size="xsmall"
                    value={t(i18n)`Single`}
                  />
                </Tip>
              )
              : null
          }
          {
            order.originalOrderId !== null
              ? (
                <Tip content={t(i18n)`Additional pair`}>
                  <Tag
                    size="xsmall"
                    value={t(i18n)`+ Pair`}
                  />
                </Tip>
              )
              : null
          }
        </Box>
      )
    },
  }
}

function getClinicianColumn(): ColumnConfig<Order> {
  return {
    property: 'clinician.firstName',
    header: <Trans>Clinician</Trans>,
    sortable: true,
    render: (order) =>
      <Text>{getPersonFullName(order.clinician)}</Text>,
  }
}
function getLocationColumn(): ColumnConfig<Order> {
  return {
    property: 'location.description',
    header: <Trans>Location</Trans>,
    sortable: true,
    render: (order) =>
      <Text>{order.location?.description ?? ''}</Text>,
  }
}

function getCreatedAtColumn(): ColumnConfig<Order> {
  return {
    property: 'createdAt',
    header: <Trans>Date Created</Trans>,
    render: (order) => {
      return toTableDate(order.createdAt)
    },
  }
}

function getSubmittedAtColumn(): ColumnConfig<Order> {
  return {
    property: 'submittedAt',
    header: <Trans>Date Submitted</Trans>,
    render: (order) => {
      return order.submittedAt
        ? toTableDate(order.submittedAt)
        : null
    },
  }
}

function getScansColumn(i18n: I18n): ColumnConfig<Order> {
  return {
    property: 'scanStatus',
    header: <Trans>Scans</Trans>,
    render: (order) => (
      <Link
        to="/orders/clinics/$clinicId/orders/$orderId/scans"
        params={{
          clinicId: order.clinicId,
          orderId: order.id,
        }}
      >
        {getScanStatusLabel(i18n, order.scanStatus).toLocaleUpperCase()}
      </Link>
    ),
  }
}

function getFilesColumn<TOrder extends Order | OrderForManufacturer>(): ColumnConfig<TOrder> {
  return {
    property: 'filesCount',
    header: <Trans>Files</Trans>,
    render: (order) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const isManufacturer = useIsManufacturer()

      if (isManufacturer && order.filesCount === 0) return <Trans>NONE</Trans>

      return (
        <Link
          to="/orders/clinics/$clinicId/orders/$orderId/files"
          params={{
            clinicId: order.clinicId,
            orderId: order.id,
          }}
        >
          {order.filesCount === 0 ? <Trans>NONE</Trans> : `( ${order.filesCount} )`}
        </Link>
      )
    },
  }
}

function getClinicNameColumn(): ColumnConfig<Order> {
  return {
    property: 'clinicName',
    header: <Trans>Clinic</Trans>,
  }
}

function getInternalCommentsColumn(): ColumnConfig<Order> {
  return {
    property: 'comments',
    header: <Trans>Internal Comments</Trans>,
    render: (order) => (
      <Tip
        content={
          <Box width={{ max: 'medium' }}>
            {order.comments}
          </Box>
        }
      >
        <Link
          to="/orders/clinics/$clinicId/orders/$orderId/comments"
          params={{
            clinicId: order.clinicId,
            orderId: order.id,
          }}
        >
          {order.comments !== null ? order.comments.substring(0, 10) + '...' : '...'}
        </Link>
      </Tip>

    ),
  }
}

function getManufacturerDeliveryColumn<TOrder extends Order | OrderForManufacturer>(): ColumnConfig<TOrder> {
  return {
    property: 'manufacturerShippingLink',
    header: <Trans>Shipping</Trans>,
    render: function Cell(order) {
      const { i18n } = useLingui()

      if (order.manufacturerShippingLink === null) return null

      return (
        <Button
          icon={<Deliver />}
          pad="0"
          a11yTitle={t(i18n)`Open shipment tracking link`}
          href={order.manufacturerShippingLink}
          target="_blank"
          rel="noopener noreferrer"
        />
      )
    },
  }
}

export {
  getSelectColumn,
  getStatusColumn,
  getBillingStatusColumn,
  getOrderNumberColumn,
  getPatientFirstNameColumn,
  getPatientLastNameColumn,
  getPrescriptionColumn,
  getProductInfoColumn,
  getClinicianColumn,
  getLocationColumn,
  getCreatedAtColumn,
  getSubmittedAtColumn,
  getScansColumn,
  getFilesColumn,
  getClinicNameColumn,
  getInternalCommentsColumn,
  getManufacturerDeliveryColumn,
}
