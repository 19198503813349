import { FileBase } from './File'

type LogoBase<TDate> =
  & FileBase<TDate>
  & LogoData

export type LogoData = {
  id: number,
  description: string,
  isMainLogo: boolean,
}

export type Logo = LogoBase<Date>
export type LogoDto = LogoBase<string>

export const dtoToLogo = (dto: LogoDto): Logo => ({
  ...dto,
  createdAt: new Date(dto.createdAt),
  updatedAt: new Date(dto.updatedAt),
})

export type NewLogo = Omit<Logo, 'id' | 'createdAt' | 'updatedAt'> & {file: File}

export const defaultLogo: Logo = {
  createdAt: new Date(),
  description: 'A logo',
  extension: '.stp',
  fileName: 'Podform3d',
  filePath: 'Podform3d/Logos',
  id: 0,
  isMainLogo: false,
  updatedAt: new Date(),
}
