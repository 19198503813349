import { Prescription, standardDiagnosticOptions, standardHeelLift } from '../types/Prescription'
import { flexMidfootPerforatedValues } from '../types/PrescriptionEnums/FlexMidfootPerforated'
import { forefootWidthValues } from '../types/PrescriptionEnums/ForefootWidth'
import { genders } from '../types/PrescriptionEnums/Gender'
import { lowerMedialArches } from '../types/PrescriptionEnums/LowerMedialArch'
import { postingCorrections } from '../types/PrescriptionEnums/PostingCorrection'
import { postingOptions } from '../types/PrescriptionEnums/PostingOption'
import { postingPositions } from '../types/PrescriptionEnums/PostingPosition'
import { prescriptionStatuses } from '../types/PrescriptionEnums/PrescriptionStatus'
import { productTypes } from '../types/PrescriptionEnums/ProductType'
import { requestedSides } from '../types/PrescriptionEnums/RequestedSide'
import { retroPadOrientations, retroPadSizes } from '../types/PrescriptionEnums/RetroPads'
import { shellRigidities } from '../types/PrescriptionEnums/ShellRigidity'
import { shoeSizeSystems } from '../types/PrescriptionEnums/ShoeSizeSystem'
import { stiffMidfootBars } from '../types/PrescriptionEnums/StiffMidFootBar'
import { topCoverLengths, topCoverMaterials } from '../types/PrescriptionEnums/TopCoverLength'
import { topCoverThicknesses } from '../types/PrescriptionEnums/TopCoverThickness'

const defaultPrescription: Prescription = {
  id: 0,
  version: 1,
  description: 'Default form provided by Podform3d',
  name: 'Default',
  consultationDate: new Date().toISOString(),
  status: prescriptionStatuses.incomplete,
  type: productTypes.shell,
  section: {
    shoeSizeSystem: shoeSizeSystems.us,
    shoeSizeValue: '',
    gender: genders.man,
    childGrowth: false,
    sideDiagnostic: requestedSides.both,
    diagnostic: standardDiagnosticOptions[0] ?? '',
    specifyDiagnostic: '',
    diagnosticOtherSide: standardDiagnosticOptions[0] ?? '',
    specifyDiagnosticOtherSide: '',
    production: requestedSides.both,
    productType: 'shell',

  },
  shellModification: {
    halluxExtension: 'none',
    gaitPlate: 'none',
    fasciaGroove: 'none',
    medialHeelSkive: 'none',
    medialFlange: 'none',
    lateralFlange: 'none',
    firstRayCutOut: 'none',
    heelHole: 'none',
    heelFork: 'none',
    topCoverThickness: topCoverThicknesses.none,
    kineticExtensionLeft: [],
    kineticExtensionRight: [],
    retroPadSizeLeft: retroPadSizes.small,
    retroPadSizeRight: retroPadSizes.small,
    retroPadOrientationLeft: retroPadOrientations['2-3 mets'],
    retroPadOrientationRight: retroPadOrientations['2-3 mets'],
    kineticExtension: false,
    retroPad: false,
    sideRetroPad: requestedSides.both,
    sideKineticExtension: requestedSides.both,
  },
  specialInstructions: '',
  rxOrthotic: {
    shellRigidity: shellRigidities.standard,
    heelCupHeight: 'Standard - 15 mm',
    forefootWidth: forefootWidthValues.standard,
    flexMidfootPerforated: flexMidfootPerforatedValues.none,
    sideFlexMidfootPerforated: 'none',
    flexMidfootOtherSide: flexMidfootPerforatedValues.none,
    stiffMidfootBar: stiffMidfootBars.none,
    sideStiffMidfootBar: 'none',
    stiffMidfootOtherSide: stiffMidfootBars.none,
    lowerMedialArch: lowerMedialArches.noFlex,
    sideLowerMedialArch: 'none',
    lowerMedialArchOtherSide: lowerMedialArches.noFlex,
    heelLift: standardHeelLift[0] ?? 'None',
    sideHeelLift: 'none',
    heelLiftOtherSide: standardHeelLift[0] ?? 'None',
    topCoverActive: false,
    footbedLength: '0',
    footbedWidth: '0',
    topCoverLength: topCoverLengths.none,
    topCoverMaterial: topCoverMaterials.active,
    apart: false,
    shellModificationOptions: false,
    postingOptions: false,
  },
  posting: {
    sidePosting: requestedSides.both,
    leftPostingOption: postingOptions.none,
    leftPostingPosition: postingPositions.rearfootAndForefoot,
    leftPostingCorrection: postingCorrections.none,
    rightPostingOption: postingOptions.none,
    rightPostingPosition: postingPositions.rearfootAndForefoot,
    rightPostingCorrection: postingCorrections.none,
  },
}

export default defaultPrescription
