import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { SelectOption } from './SelectOption'

export const scanStatuses = {
  onlyOneSide: -100,
  missing: 0,
  raw: 1,
  uploaded: 2,
  treated: 3,
  reviewed: 4,
  completed: 5,
} as const

export type ScanStatus = typeof scanStatuses[keyof typeof scanStatuses]

export const getScanStatusLabel = (i18n: I18n, scanStatus: ScanStatus): string => {
  switch (scanStatus) {
  case scanStatuses.onlyOneSide: return t(i18n)`Missing?`
  case scanStatuses.missing: return t(i18n)`Missing`
  case scanStatuses.raw: return t(i18n)`Raw`
  case scanStatuses.uploaded: return t(i18n)`Uploaded`
  case scanStatuses.treated: return t(i18n)`Treated`
  case scanStatuses.reviewed: return t(i18n)`Reviewed`
  case scanStatuses.completed: return t(i18n)`Completed`
  default: return t(i18n)`Unknown`
  }
}

const excludedOptions = new Set<ScanStatus>([scanStatuses.onlyOneSide])
export const getScanStatusOptions = (i18n: I18n): Array<SelectOption<ScanStatus>> =>
  Object
    .values(scanStatuses)
    .filter(status => !excludedOptions.has(status))
    .map(status => ({
      value: status,
      label: getScanStatusLabel(i18n, status),
    }))
