
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Box, Button, Spinner, Text, Tip } from 'grommet'
import { ReactNode, useMemo } from 'react'

import { useLoading } from '../hooks/loading'
import { useIsClinician } from '../hooks/roles'
import { BillingStatus } from '../types/BillingStatus'
import { getCancellationReasonLabel } from '../types/CancellationReason'
import { Order } from '../types/Order'
import { OrderStatus, orderStatuses } from '../types/OrderStatus'
import { PrescriptionStatus } from '../types/PrescriptionEnums/PrescriptionStatus'
import { getBillingStatusDisplays, getOrderStatusDisplays, getPrescriptionStatusDisplays } from '../utils/statusDisplays'

type Props = {
  color: string,
  label: ReactNode,
  description: string,
  mode: 'full' | 'tip' | 'no-color',
}

function StatusLabel(props: Props) {
  let content
  if (props.mode === 'tip') {
    content =
      <Tip
        content={
          <Box
            width="medium"
          >
            <Text>
              {props.label}
            </Text>
            <Text>
              {props.description}
            </Text>
          </Box>
        }
      >
        <Box
          alignSelf="center"
          style={{ color: props.color }}
        >
          {' ⬤ '}
        </Box>
      </Tip>
  } else if (props.mode === 'no-color') {
    content =
      <Tip
        content={
          <Box
            width="medium"
          >
            {props.description}
          </Box>
        }
      >
        {props.label}
      </Tip>
  } else {
    content =
      <Box
        gap="0.2rem"
        direction="row"
        align="center"
      >
        <Box
          alignSelf="center"
          style={{ color: props.color }}
          margin={{ top: '-3px' }}
        >
          <Tip content={
            <Box
              width="medium"
            >
              {props.description}
            </Box>}
          >
            {' ⬤ '}
          </Tip>
        </Box>
        <Box alignSelf="center">{props.label}</Box>
      </Box>
  }

  return (
    <Box
      direction="row"
      gap="0.2rem"
      overflow="visible"
      style={{
        minWidth: 'unset',
      }}
    >
      {content}
    </Box>
  )
}

function OrderStatusLabel(
  props: {
    status: OrderStatus,
    count?: number | undefined,
    order: Order | null,
    hideLabel?: true,
    onChange?: (value: OrderStatus) => unknown,
  },
) {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const displays = useMemo(() => getOrderStatusDisplays(lingui.i18n), [lingui.i18n])
  const display = displays[props.status]

  const isClinician = useIsClinician()
  const { isLoading } = useLoading()

  const content =
    <Box
      direction="row"
      fill
      gap="1rem"
    >
      <StatusLabel
        mode="full"
        label={
          props.count !== undefined
            ? `${display.label} (${props.count})`
            : display.label
        }
        color={display.color}
        description={display.tip}
      />
      {(props.status === orderStatuses.shipped) && isClinician && props.hideLabel === undefined &&
        (isLoading ? <Spinner size="small" />
          : (
            <Tip content={t(i18n)`Product was received by the clinic`}>
              <Button
                label={t(i18n)`Received`}
                onClick={() => {
                  if (props.order === null) return
                  props.onChange?.(orderStatuses.receivedByClinic)
                }}
              />
            </Tip>
          )
        )
      }
      {(props.status === orderStatuses.receivedByClinic) && isClinician && props.hideLabel === undefined &&
        <Tip content={t(i18n)`Product was delivered to the patient`}>
          <Button
            label={t(i18n)`Delivered`}
            primary
            color={display.color}
            onClick={() => {
              if (props.order === null) return
              props.onChange?.(orderStatuses.deliveredToPatient)
            }}
          />
        </Tip>
      }
    </Box>

  const cancellationReason =
    props.order?.cancellationReason != null &&
      props.order.cancellationReason !== '' &&
      props.order.activeStatus === orderStatuses.cancelled
      ? props.order.cancellationReason
      : null

  return cancellationReason !== null
    ? (
      <Tip content={t(i18n)`Reason:` + ' ' + getCancellationReasonLabel(i18n, cancellationReason)}>
        <Box>
          {content}
        </Box>
      </Tip>
    )
    : content
}

const BillingStatusLabel = (props: { status: BillingStatus }) => {
  const lingui = useLingui()

  const displays = useMemo(() => getBillingStatusDisplays(lingui.i18n), [lingui.i18n])
  const display = displays[props.status]

  return (
    <StatusLabel
      mode="no-color"
      label={display.label}
      color={display.color}
      description={display.tip}
    />
  )
}

const PrescriptionStatusLabel = (props: { status: PrescriptionStatus }) => {
  const lingui = useLingui()

  const displays = useMemo(() => getPrescriptionStatusDisplays(lingui.i18n), [lingui.i18n])
  const display = displays[props.status]

  return (
    <StatusLabel
      mode="tip"
      label={display.label}
      color={display.color}
      description={display.tip}
    />
  )
}

export default StatusLabel

export {
  OrderStatusLabel,
  BillingStatusLabel,
  PrescriptionStatusLabel,
}
