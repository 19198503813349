import { InfiniteData, QueryKey, UseInfiniteQueryResult, UseQueryResult, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Enveloppe, GetOrdersCountsByOrderStatusesFilters, PostOrderRequest, UpdateOrderStatusExtra, duplicateOrder, getOrder, getOrders, getOrdersCount, getOrdersCountsByOrderStatuses, ordersKeys, postOrder, processVerified, pushToVirtualRhino, putBillingStatus, putOrderClinic, putOrderClinician, putOrderComments, putOrderInternalClinicNotes, putOrderLocation, putOrderStatus } from '../fetchers/orders'
import { scansKeys } from '../fetchers/scans'
import { useHasFullOrdersAccess, useIsAdmin, useIsManufacturer } from '../hooks/roles'
import { Order, OrderForManufacturer } from '../types/Order'
import { GetOrdersFilters } from '../types/OrderFilters'
import { OrderStatus, orderStatuses } from '../types/OrderStatus'

const useQueryOrder = (
  clinicId: number,
  orderId: number,
  disabled = false,
) => {
  return useQuery({
    queryKey: ordersKeys.details(clinicId, orderId),
    queryFn: ({signal}) => getOrder(clinicId, orderId, signal),
    enabled: !Number.isNaN(clinicId) && !Number.isNaN(orderId) && !disabled,
  })
}

function useQueryOrders<
  TIsManufacturer extends boolean,
  TOrder extends TIsManufacturer extends true ? OrderForManufacturer : Order,
>(
  filters: GetOrdersFilters,
): UseInfiniteQueryResult<InfiniteData<Enveloppe<TIsManufacturer extends true ? OrderForManufacturer : Order>>> {
  const isManufacturer = useIsManufacturer()
  const fullAccess = useHasFullOrdersAccess()

  return useInfiniteQuery({
    queryKey: ordersKeys.list({...filters, isManufacturer}),
    queryFn: async ({pageParam, signal}) => {
      const result = await getOrders<typeof isManufacturer, TOrder>(
        isManufacturer,
        pageParam?.[0] ?? -1,
        pageParam?.[1] ?? -1,
        filters,
        signal,
      )

      return result
    },
    defaultPageParam: [-1, -1] as readonly [number, number] | undefined,
    getNextPageParam: (lastPage) =>
      lastPage.nextId !== null &&
      lastPage.nextDate !== null
        ? [lastPage.nextId, lastPage.nextDate] as const
        : undefined,
    enabled: fullAccess || (filters.clinicId !== null && filters.clinicId !== -1),
  })
}

const useOrdersToBeValidated = (): UseQueryResult<Enveloppe<Order>> => {
  const filters: GetOrdersFilters = {
    pageSize: 32767,
    includeStatuses: [orderStatuses.processed],
    excludeTopCoverMaterials: [],
  }

  return useQuery({
    queryKey: ordersKeys.list({...filters}),
    queryFn: ({signal}) => getOrders(false, -1, -1, filters, signal),
    select: (orders) => {
      const newOrders = []
      for (const order of orders.data) {
        if (order.prescriptionId !== null) {
          newOrders.push(order)
        }
      }

      return {
        ...orders,
        count: newOrders.length,
        data: newOrders,
      }
    },
  })
}

const useCreateOrder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: PostOrderRequest & {clinicId: number}) =>
      postOrder(data.clinicId, data),
    onSuccess: (order) => {
      void queryClient.invalidateQueries({queryKey: ordersKeys.list()})
      queryClient.setQueryData(ordersKeys.details(order.clinicId, order.id), order)
    },
  })
}

const useDuplicateOrder = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; orderId: number}) =>
      duplicateOrder(data.clinicId, data.orderId),
    onSuccess: (order) => {
      void queryClient.invalidateQueries({queryKey: ordersKeys.list()})
      queryClient.setQueryData(ordersKeys.details(order.clinicId, order.id), order)
    },
  })
}

function makeUseUpdateOrderValue<T>(
  mutationFn: (clinicId: number, orderId: number, value: T) => Promise<unknown>,
  updateOrder: (value: T, order: Order) => Order,
  queryKey?: QueryKey,
) {
  return () => {
    const queryClient = useQueryClient()

    return useMutation({
      mutationFn: (data: {clinicId: number; orderId: number; value: T}) =>
        mutationFn(data.clinicId, data.orderId, data.value),
      onSuccess: (_, data) => {
        void queryClient.invalidateQueries({queryKey: queryKey ?? ordersKeys.list()})

        queryClient.setQueryData(
          ordersKeys.details(data.clinicId, data.orderId),
          (order: Order | undefined) =>
            order
              ? updateOrder(data.value, order)
              : order,
        )
      },
    })
  }
}

const useUpdateOrderStatus = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (
      data: {
        clinicId: number,
        orderId: number,
        value: OrderStatus,
        extra?: UpdateOrderStatusExtra,
      },
    ) =>
      putOrderStatus(data.clinicId, data.orderId, data.value, data.extra),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: ordersKeys.all()})

      queryClient.setQueryData(
        ordersKeys.details(data.clinicId, data.orderId),
        (order: Order | undefined) =>
          order
            ? {
              ...order,
              activeStatus: data.value,
              ...data.extra,
            }
            : order,
      )
    },
  })
}

const useUpdateBillingStatus = makeUseUpdateOrderValue(
  putBillingStatus,
  (value, order) => ({
    ...order,
    billingStatus: value,
  }),
)

const useUpdateOrderComments = makeUseUpdateOrderValue(
  putOrderComments,
  (value, order) => ({
    ...order,
    comments: value,
  }),
)

const useUpdateOrderInternalClinicNotes = makeUseUpdateOrderValue(
  putOrderInternalClinicNotes,
  (value, order) => ({
    ...order,
    internalClinicNotes: value,
  }),
)

const useUpdateOrderClinician = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; orderId: number; clinicianId: number}) =>
      putOrderClinician(data.clinicId, data.orderId, data.clinicianId),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: ordersKeys.list()})
      void queryClient.invalidateQueries({queryKey: ordersKeys.details(data.clinicId, data.orderId)})
    },
  })
}

const useUpdateOrderClinic = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; orderId: number; newClinicId: number}) =>
      putOrderClinic(data.clinicId, data.orderId, data.newClinicId),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: ordersKeys.list()})
      void queryClient.invalidateQueries({queryKey: ordersKeys.details(data.clinicId, data.orderId)})
    },
  })
}

const useUpdateOrderLocation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; orderId: number; locationId: number}) =>
      putOrderLocation(data.clinicId, data.orderId, data.locationId),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: ordersKeys.list()})
      void queryClient.invalidateQueries({queryKey: ordersKeys.details(data.clinicId, data.orderId)})
    },
  })
}

const usePushToVirtualRhino = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; orderId: number; rxPath: string}) =>
      pushToVirtualRhino(data.clinicId, data.orderId, data.rxPath),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: ordersKeys.list()})
      void queryClient.invalidateQueries({queryKey: ordersKeys.details(data.clinicId, data.orderId)})
      void queryClient.invalidateQueries({queryKey: scansKeys.list(data.clinicId, data.orderId)})
    },
  })
}

const useVerifiedOrdersCount = (isNotClinician: boolean) => {
  const filters = {
    includeStatuses: [orderStatuses.verified],
    includeNoPrescription: true,
    excludeTopCoverMaterials: [],
  }

  return useQuery({
    queryKey: ordersKeys.count(filters),
    queryFn: ({signal}) => getOrdersCount(filters, signal),
    enabled: isNotClinician,
  })
}

const useOrdersCount = (statuses: OrderStatus[], enabled: boolean) => {
  const filters = {
    includeStatuses: statuses,
    excludeTopCoverMaterials: [],
  }

  return useQuery({
    queryKey: ordersKeys.count(filters),
    queryFn: ({signal}) => getOrdersCount(filters, signal),
    enabled,
  })
}

const useOrdersCountsByOrderStatuses = (filters: GetOrdersCountsByOrderStatusesFilters) => {
  const isAdmin = useIsAdmin()
  const isManufacturer = useIsManufacturer()

  return useQuery({
    queryKey: ordersKeys.count(filters),
    queryFn: ({signal}) => getOrdersCountsByOrderStatuses(filters, signal),
    enabled: isAdmin || isManufacturer || typeof filters.clinicId === 'number',
  })
}

const useProcessVerified = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {selectedIds: number[]}) => processVerified(data.selectedIds),
    onSuccess: () => {
      void queryClient.invalidateQueries({queryKey: ordersKeys.list()})
    },
  })
}

export {
  useQueryOrder,
  useQueryOrders,
  useCreateOrder,
  useDuplicateOrder,
  useUpdateOrderStatus,
  useUpdateBillingStatus,
  useUpdateOrderComments,
  useUpdateOrderInternalClinicNotes,
  useUpdateOrderClinician,
  useUpdateOrderClinic,
  useUpdateOrderLocation,
  usePushToVirtualRhino,
  useVerifiedOrdersCount,
  useOrdersCount,
  useOrdersCountsByOrderStatuses,
  useOrdersToBeValidated,
  useProcessVerified,
}
