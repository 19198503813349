import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const lowerMedialArches = {
  noFlex: 0, // (0 mm)
  flexible: -2, // (-2 mm)
  veryFlexible: -4, // (-4 mm)
} as const

export type LowerMedialArch = typeof lowerMedialArches[keyof typeof lowerMedialArches]

export const getLowerMedialArchLabel = (i18n: I18n, value: LowerMedialArch) => {
  switch (value) {
  case lowerMedialArches.noFlex: return t(i18n)`None`
  case lowerMedialArches.flexible: return t(i18n)`-2 mm (Flexible Foot)`
  case lowerMedialArches.veryFlexible: return t(i18n)`-4 mm (Collapsed Foot)`
  default: return t(i18n)`Unknown lower medial arch option`
  }
}
