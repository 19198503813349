
import { Plural, Trans } from '@lingui/macro'
import { Route, useNavigate } from '@tanstack/react-router'
import { Box, Button, Grid, Heading, Layer, Spinner, Text } from 'grommet'
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'

import ButtonLinkV2 from '../../components/ButtonLinkV2'
import NotesEditor from '../../components/NotesEditor'
import { getOnlineViewerUri } from '../../constants/runtime'
import { downloadFile } from '../../fetchers/files'
import { downloadAllFilesByOrder, getOrders } from '../../fetchers/orders'
import { downloadScan } from '../../fetchers/scans'
import { useJwtOnce } from '../../hooks/cognito'
import { useLoading } from '../../hooks/loading'
import { useShowErrorToast, useShowSuccessToast, useShowWarningToast } from '../../hooks/toasts'
import { useQueryClinics } from '../../queries/clinics'
import { useQueryFiles } from '../../queries/files'
import { useUpdateOrderComments, useUpdateOrderStatus } from '../../queries/orders'
import { useQueryPrescription } from '../../queries/prescriptions'
import { useQueryScans } from '../../queries/scans'
import { File, leftFinder, rightFinder } from '../../types/File'
import { Order } from '../../types/Order'
import { GetOrdersFilters } from '../../types/OrderFilters'
import { orderStatuses } from '../../types/OrderStatus'
import { Scan } from '../../types/Scan'
import { findStls } from '../../utils/files'
import PageWithBanners from '../PageWithBanners'
import Root from '../Root'
import Footer from '../home/Footer'
import { PrescriptionViewerImpl } from '../prescriptions/PrescriptionViewer'

type UnvalidatableOrdersProps = Readonly<{
  ordersWithoutRx: number,
  ordersWithoutFiles: number,
}>

const UnvalidatableOrders = (props: UnvalidatableOrdersProps) => {
  return (
    <>
      {
        props.ordersWithoutRx !== 0 &&
        <Text>
          <Plural
            value={props.ordersWithoutRx}
            one="# processed order without a prescription"
            other="# processed orders without a prescription"
          />
        </Text>
      }
      {
        props.ordersWithoutFiles !== 0 &&
        <Text>
          <Plural
            value={props.ordersWithoutFiles}
            one="# processed order without any files"
            other="# processed orders without any files"
          />
        </Text>
      }
    </>
  )
}

type ViewerFile = {
  name: string,
  arrayBuffer: ArrayBuffer,
}

const sendFilesToViewer = (
  left: ViewerFile | null,
  right: ViewerFile | null,
  viewer: HTMLIFrameElement | null,
) => {
  if (!viewer) return

  const files: ViewerFile[] = []
  if (left) files.push(left)
  if (right) files.push(right)

  if (files.length === 0) return

  viewer
    .contentWindow
    ?.postMessage(
      { message: 'sendValidation', value: files },
      '*',
    )
}

const sendRawScanToViewer = (
  scan: ViewerFile | null,
  viewer: HTMLIFrameElement | null,
) => {
  if (!viewer) return
  if (!scan) return

  let listened = false
  const origin = getOnlineViewerUri()

  const pongListener = (event: MessageEvent<{ message: string }>) => {
    if (event.origin !== origin) return
    if (event.data.message === 'pong') {
      listened = true
    }
  }

  const ping = () => {
    viewer.contentWindow?.postMessage({ message: 'ping' }, '*')
  }

  const wait = (resolve: (value: undefined) => unknown) => {
    if (listened) {
      resolve(undefined)
    } else {
      ping()
      setTimeout(() => wait(resolve), 10)
    }
  }

  window.addEventListener(`message`, pongListener)
  ping()
  new Promise(resolve => wait(resolve))
    .then(() => {
      window.removeEventListener('message', pongListener)

      viewer.contentWindow
        ?.postMessage({ message: 'sendPlyFile', value: scan.arrayBuffer }, origin)
    })
    .catch(console.error)
}

const emptySet = new Set<number>()
// Note(Antoine): We are currently using the same key for all notifications
// within this page to avoid the components getting stacked up. In the future,
// it would be possible to show them as a list or a grid of notifications,
// which would allow the user to get all of the messages and not the lastest,
// message sent. Using some sort of notification queue would also allow to
// navigate through them and validate previous information.
const commonValidationNotification = 'validation.notification.toast'

type ValidatorProps = Readonly<{
  ordersCount: number,
  orderPosition: number,
  order: Order,
  heightPx: number,
  ordersWithoutRx: number,
  ordersWithoutFiles: number,
}>

const Validator = (props: ValidatorProps) => {
  const jwt = useJwtOnce()

  const modelViewerRef = useRef<HTMLIFrameElement | null>(null)
  const compoundViewerRef = useRef<HTMLIFrameElement | null>(null)
  const leftRawScanViewerRef = useRef<HTMLIFrameElement | null>(null)
  const rightRawScanViewerRef = useRef<HTMLIFrameElement | null>(null)

  const fileIds = useRef<Set<number>>(emptySet)
  const scanIds = useRef<Set<number>>(emptySet)
  const leftRef = useRef<ViewerFile | null>(null)
  const rightRef = useRef<ViewerFile | null>(null)
  const leftScanRef = useRef<ViewerFile | null>(null)
  const rightScanRef = useRef<ViewerFile | null>(null)
  const leftRawScanRef = useRef<ViewerFile | null>(null)
  const rightRawScanRef = useRef<ViewerFile | null>(null)

  const clinic = useQueryClinics()
  const prescription = useQueryPrescription(props.order.clinicId, props.order.id, props.order.prescriptionId)
  const files = useQueryFiles(props.order.clinicId, props.order.id)
  const scans = useQueryScans(props.order.clinicId, props.order.id)

  const [showScans, setShowScans] = useState<boolean>(false)

  const updateOrderComments = useUpdateOrderComments()

  const handleModelViewerRef = (ref: HTMLIFrameElement | null) => {
    const current = modelViewerRef.current
    modelViewerRef.current = ref

    if (current) return // Already initialized!
    if (!ref) return // Being cleared!

    sendFilesToViewer(leftRef.current, rightRef.current, ref)
  }

  const handleCompoundViewerRef = (ref: HTMLIFrameElement | null) => {
    const current = compoundViewerRef.current
    compoundViewerRef.current = ref

    if (current) return // Already initialized!
    if (!ref) return // Being cleared!

    sendFilesToViewer(leftScanRef.current, rightScanRef.current, ref)
  }

  const handleLeftRawViewerRef = (ref: HTMLIFrameElement | null) => {
    const current = leftRawScanViewerRef.current
    leftRawScanViewerRef.current = ref

    if (current) return // Already initialized!
    if (!ref) return // Being cleared!

    sendRawScanToViewer(leftRawScanRef.current, ref)
  }
  const handleRightRawViewerRef = (ref: HTMLIFrameElement | null) => {
    const current = rightRawScanViewerRef.current
    rightRawScanViewerRef.current = ref

    if (current) return // Already initialized!
    if (!ref) return // Being cleared!

    sendRawScanToViewer(rightRawScanRef.current, ref)
  }

  const getCommentRef = useRef<(() => string) | null>(null)

  const handleCommentsChange = (comments: string) => {
    updateOrderComments.mutate({
      clinicId: props.order.clinicId,
      orderId: props.order.id,
      value: comments,
    })
  }

  useEffect(
    () => {
      if (!files.data) return

      // Prevent files auto-refresh to re-trigger downloads,
      // as downloads are more expensive.
      if (files.data.every(file => fileIds.current.has(file.id))) return
      fileIds.current = new Set(files.data.map(file => file.id))

      let controller: AbortController | null = new AbortController()
      const requests: Array<() => Promise<unknown>> = []

      const getRequestIf = (
        file: File | null,
        ref: MutableRefObject<ViewerFile | null>,
      ): Promise<unknown> => {
        if (!file) return Promise.resolve(undefined)

        return downloadFile(props.order.clinicId, file.id, controller?.signal)
          .then(blob => blob.arrayBuffer())
          .then(buffer => {
            ref.current = {
              name: `${file.fileName}.${file.extension}`,
              arrayBuffer: buffer,
            }
          })
      }

      const stls = findStls(files.data)
      requests.push(
        () => Promise
          .allSettled([
            getRequestIf(stls.left, leftRef),
            getRequestIf(stls.right, rightRef),
          ])
          .then(() => {
            sendFilesToViewer(
              leftRef.current,
              rightRef.current,
              modelViewerRef.current,
            )
          }),
      )

      requests.push(
        () => Promise
          .allSettled([
            getRequestIf(stls.leftScan, leftScanRef),
            getRequestIf(stls.rightScan, rightScanRef),
          ])
          .then(() => {
            sendFilesToViewer(
              leftScanRef.current,
              rightScanRef.current,
              compoundViewerRef.current,
            )
          }),
      )

      Promise
        .allSettled(requests.map(request => request()))
        .finally(() => {
          controller = null
        })

      return () => {
        controller?.abort()
        controller = null
      }
    },
    [
      props.order.clinicId,
      files.data,
    ],
  )

  useEffect(
    () => {
      if (!scans.data) return
      if (!showScans) return

      // Prevent files auto-refresh to re-trigger downloads,
      // as downloads are more expensive.
      if (scans.data.every(scan => scanIds.current.has(scan.id))) return
      scanIds.current = new Set(scans.data.map(scan => scan.id))

      let controller: AbortController | null = new AbortController()
      const requests: Array<() => Promise<unknown>> = []

      const getRequestIf = (
        scan: Scan | null,
        ref: MutableRefObject<ViewerFile | null>,
      ): Promise<unknown> => {
        if (!scan) return Promise.resolve(undefined)

        return downloadScan(props.order.clinicId, scan.id, controller?.signal)
          .then(blob => blob.arrayBuffer())
          .then(buffer => {
            ref.current = {
              name: `${scan.fileName}.${scan.extension}`,
              arrayBuffer: buffer,
            }
          })
      }

      const ply = scans.data.filter(scan => scan.extension === 'ply')
      const leftRawScan = ply.find(leftFinder) ?? null
      const rightRawScan = ply.find(rightFinder) ?? null

      requests.push(
        () => Promise
          .allSettled([
            getRequestIf(leftRawScan, leftRawScanRef),
          ])
          .then(() => {
            sendRawScanToViewer(
              leftRawScanRef.current,
              leftRawScanViewerRef.current,
            )
          }),
      )
      requests.push(
        () => Promise
          .allSettled([
            getRequestIf(rightRawScan, rightRawScanRef),
          ])
          .then(() => {
            sendRawScanToViewer(
              rightRawScanRef.current,
              rightRawScanViewerRef.current,
            )
          }),
      )

      Promise
        .allSettled(requests.map(request => request()))
        .finally(() => {
          controller = null
        })

      return () => {
        controller?.abort()
        controller = null
      }
    },
    [
      props.order.clinicId,
      scans.data,
      showScans,
    ],
  )

  return (
    <Box
      justify="start"
      alignContent="start"
      overflow="hidden"
      height={'100%'}
    >
      <Grid
        columns={['350px', 'flex']}
        height={'100%'}
        gap="1rem"
      >
        <Box
          height={'100%'}
        >
          <Heading
            level="2"
            margin={{ top: '0', bottom: '0.25rem' }}
          >
            <Trans>Validating order {props.orderPosition}/{props.ordersCount}
              - {clinic.data?.find(item => item.id === props.order.clinicId)?.name}
            </Trans>
          </Heading>
          <UnvalidatableOrders
            {...props}
          />
          {
            prescription.data
              ? (
                <>
                  <PrescriptionViewerImpl
                    printContainerId="validation.viewer.no-print"
                    size="350px"
                    order={props.order}
                    prescription={prescription.data}
                  />
                  <Box
                    height={'14rem'}
                  >
                    <NotesEditor
                      debounce={true}
                      wrapHeader={true}
                      header={
                        <Trans>Notes</Trans>
                      }
                      notes={props.order.comments}
                      onChange={handleCommentsChange}
                      getValue={ref => getCommentRef.current = ref}
                    />
                    <Button
                      label="See Scans"
                      onClick={() => setShowScans(b => !b)}
                    />
                  </Box>
                  {
                    !showScans || jwt === ''
                      ? null
                      : (
                        <Layer
                          onClickOutside={() => {
                            setShowScans(false)
                          }}
                        >
                          <Box
                            height={'80vh'}
                            width={'80vw'}
                          >
                            <Box
                              direction="row"
                              height="100%"
                              width="100%"
                            >
                              <>
                                <iframe
                                  key={props.order.id}
                                  ref={handleLeftRawViewerRef}
                                  title="validation.online-viewer.leftraw"
                                  src={`${getOnlineViewerUri()}/public/index.html?token=${jwt}&viewerOnly=true`}
                                  height="100%"
                                  width="100%"
                                />

                              </>
                              <>
                                <iframe
                                  key={props.order.id}
                                  ref={handleRightRawViewerRef}
                                  title="validation.online-viewer.rightraw"
                                  src={`${getOnlineViewerUri()}/public/index.html?token=${jwt}&viewerOnly=true`}
                                  height="100%"
                                  width="100%"
                                />
                              </>
                            </Box>
                          </Box>
                        </Layer>
                      )
                  }
                </>
              )
              : <Spinner size="large" />
          }
        </Box>

        {
          jwt === ''
            ? null
            : (
              <Box
                id="ValidationViewerBox"
                direction={(window.innerWidth - 405) > 630 * 2 ? 'row' : 'column'}
                gap="1rem"
                height={'100%'}
                width={'100%'}
              >
                <Box
                  height={(window.innerWidth - 405) > 630 * 2 ? '100%' : '49%'}
                  width={{
                    width: (window.innerWidth - 405) > 630 * 2 ? '50%' : '100%',
                    min: '630px',
                  }}
                  border
                >
                  <iframe
                    key={props.order.id}
                    ref={handleCompoundViewerRef}
                    title="validation.online-viewer.both"
                    src={`${getOnlineViewerUri()}/public/index.html?token=${jwt}`}
                    height="100%"
                  />
                </Box>
                <Box
                  height={(window.innerWidth - 405) > 630 * 2 ? '100%' : '49%'}
                  width={{
                    width: (window.innerWidth - 405) > 630 * 2 ? '50%' : '100%',
                    min: '630px',
                  }}
                  border
                >
                  <iframe
                    key={props.order.id}
                    ref={handleModelViewerRef}
                    title="validation.online-viewer.left-or-right"
                    src={`${getOnlineViewerUri()}/public/index.html?token=${jwt}`}
                    height="100%"
                  />
                </Box>
              </Box>
            )
        }
      </Grid>
    </Box>
  )
}

const ValidationPage = () => {
  const navigate = useNavigate()

  const showSuccessToast = useShowSuccessToast()
  const showErrorToast = useShowErrorToast()
  const showWarningToast = useShowWarningToast()

  const { setLoading } = useLoading()

  const [orders, setOrders] = useState<Order[] | null>(null)
  const [currentOrder, setCurrentOrder] = useState<[number, Order] | null>(null)
  const [ordersWithoutRx, setOrdersWithoutRx] = useState<number>(0)
  const [ordersWithoutFiles, setOrdersWithoutFiles] = useState<number>(0)
  const [rejectedOrderIds, setRejectedOrderIds] = useState<number[]>([])

  const [processing, setProcessing] = useState<boolean>(true)

  const updateOrderStatus = useUpdateOrderStatus()

  const isLast = currentOrder !== null && currentOrder[0] === orders?.length

  useEffect(
    () => {
      const filters: GetOrdersFilters = {
        pageSize: 32767,
        includeStatuses: [orderStatuses.processed],
        excludeTopCoverMaterials: [],
      }

      let abortController: AbortController | null = new AbortController()

      getOrders(false, -1, -1, filters, abortController.signal)
        .then(orders => {
          let ordersWithoutRx = 0
          let ordersWithoutFiles = 0
          const validatableOrders: Order[] = []
          for (const order of orders.data) {
            if (order.prescriptionId === null) {
              ordersWithoutRx++
            }

            if (order.filesCount === 0) {
              ordersWithoutFiles++
            }

            if (order.prescriptionId !== null && order.filesCount > 0) {
              validatableOrders.push(order)
            }
          }

          return [validatableOrders, ordersWithoutRx, ordersWithoutFiles] as const
        })
        .then(([orders, ordersWithoutRx, ordersWithoutFiles]) => {
          setOrders(orders)
          setOrdersWithoutRx(ordersWithoutRx)
          setOrdersWithoutFiles(ordersWithoutFiles)

          const first = orders[0]
          if (!first) return

          setCurrentOrder([1, first])
        })
        .catch(error => {
          if (
            typeof (error as Error).message === 'string' &&
            (error as Error).message.includes('abort')
          ) {
            return
          }

          console.error(error)
        })
        .finally(() => {
          abortController = null
          setProcessing(false)
        })

      return () => {
        abortController?.abort()
        abortController = null
      }
    },
    [],
  )

  const moveNext = (currentIndex: number, rejectedOrderId?: number) => {
    if (!orders) return

    if (currentIndex < orders.length) {
      const nextOrder = orders[currentIndex]
      if (!nextOrder) return

      setCurrentOrder([currentIndex + 1, nextOrder])
    } else {

      setProcessing(true)

      const rejectedOrders = [...rejectedOrderIds]
      if (rejectedOrderId !== undefined) rejectedOrders.push(rejectedOrderId)

      showSuccessToast(
        commonValidationNotification,
        rejectedOrders.length > 0
          ? <Trans>Validation completed</Trans>
          : <Trans>Validation completed, downloading rejected orders</Trans>,

      )

      handleDownloads(rejectedOrders)
        .then(() => {
          return navigate({
            to: '/',
          })
        })
        .catch(console.error)
        .finally(() => {
          setProcessing(false)
        })
    }
  }

  const handleDownloads = async (rejectedOrders: number[]) => {
    if (rejectedOrders.length === 0) return
    setLoading(true)

    await downloadAllFilesByOrder(rejectedOrders)

    setLoading(false)

  }

  const handleSkip = () => {
    if (processing) return

    if (!currentOrder) return
    const [orderIndex] = currentOrder

    showWarningToast(
      commonValidationNotification,
      <Trans>Order skipped</Trans>,
    )

    moveNext(orderIndex)
  }

  const handleReject = () => {
    if (processing) return

    if (!currentOrder) return
    const [orderIndex, order] = currentOrder

    setRejectedOrderIds(rejects => [...rejects, order.id])

    showErrorToast(
      commonValidationNotification,
      <Trans>Order rejected</Trans>,
    )

    moveNext(orderIndex, order.id)
  }

  const handleApprove = () => {
    if (processing) return

    if (!currentOrder) return
    const [orderIndex, order] = currentOrder

    setProcessing(true)

    updateOrderStatus.mutateAsync({
      clinicId: order.clinicId,
      orderId: order.id,
      value: orderStatuses.validated,
    })
      .then(() => {
        showSuccessToast(
          commonValidationNotification,
          <Trans>Order approved</Trans>,
        )

        moveNext(orderIndex)
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  let getContent: (availableHeightPx: number) => ReactNode
  if (orders && currentOrder && orders.length > 0) {
    getContent = (availableHeightPx: number) =>
      <Validator
        key={currentOrder[1].id}
        heightPx={availableHeightPx}
        order={currentOrder[1]}
        orderPosition={currentOrder[0]}
        ordersCount={orders.length}
        ordersWithoutRx={ordersWithoutRx}
        ordersWithoutFiles={ordersWithoutFiles}
      />
  } else if (orders && orders.length === 0) {
    getContent = () =>
      <Box
        fill
        justify="center"
        align="center"
      >
        <Heading level="2">
          <Trans>No orders to validate</Trans>
        </Heading>
        <UnvalidatableOrders
          ordersWithoutRx={ordersWithoutRx}
          ordersWithoutFiles={ordersWithoutFiles}
        />
      </Box>
  } else {
    getContent = () =>
      <Box
        fill
        justify="center"
        align="center"
      >
        <Spinner size="xlarge" />
      </Box>
  }

  return (
    <PageWithBanners
      padContent={true}
      footer={
        <Footer
          bottom={{
            leftActions: [
              {
                key: 'validation.footer.left.stop-validation',
                action:
                  <ButtonLinkV2
                    to="/"
                    disabled={processing}
                    label={<Trans>Stop validation</Trans>}
                  />,
              },
            ],
            rightActions: [
              {
                key: 'validation.footer.right.approve',
                action:
                  <Button
                    secondary
                    color="green"
                    disabled={processing || !orders || orders.length === 0}
                    label={
                      isLast
                        ? <Trans>Approve and finish</Trans>
                        : <Trans>Approve</Trans>
                    }
                    onClick={handleApprove}
                  />,
              },
              {
                key: 'validation.footer.right.reject',
                action:
                  <Button
                    secondary
                    color="red"
                    disabled={processing || !orders || orders.length === 0}
                    label={
                      isLast
                        ? <Trans>Reject and finish</Trans>
                        : <Trans>Reject</Trans>
                    }
                    onClick={handleReject}
                  />,
              },
              {
                key: 'validation.footer.right.skip',
                action:
                  <Button
                    secondary
                    disabled={processing || !orders || orders.length === 0}
                    label={
                      isLast
                        ? <Trans>Skip and finish</Trans>
                        : <Trans>Skip</Trans>
                    }
                    onClick={handleSkip}
                  />,
              },
              {
                key: 'validation.footer.right.rejected-count',
                action:
                  <Plural
                    value={rejectedOrderIds.length}
                    one="One rejected order"
                    other="# rejected orders"
                  />,
                showIf: rejectedOrderIds.length > 0,
              },
            ],
          }}
        />
      }
    >
      {({ availableHeightPx }) => getContent(availableHeightPx)}
    </PageWithBanners>
  )
}

ValidationPage.route = new Route({
  getParentRoute: () => Root.route,
  path: 'validation',
  component: ValidationPage,
})

export default ValidationPage
