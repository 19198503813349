import { getOmsApiUri } from '../constants/runtime'
import { AdminClinician, Clinician, NewClinician } from '../types/Clinician'

import { fetch } from './fetch'
import { getHeaders, getMutationHeaders } from './utils'

const cliniciansKeys = {
  all: () => ['clinicians'],
  list: (clinicId: number | 'all' | null, filters?: Record<string, unknown> | undefined) =>
    filters
      ? [...cliniciansKeys.all(), 'list', clinicId, filters]
      : [...cliniciansKeys.all(), 'list', clinicId],
  details: (clinicianId: number) => [...cliniciansKeys.all(), 'details', clinicianId],
}

async function getClinicians<TQueryAdmin extends boolean>(
  clinicId: number | 'all',
  queryAdmin: TQueryAdmin,
  signal: AbortSignal | null,
): Promise<TQueryAdmin extends true ? AdminClinician[] : Clinician[]> {
  let uri = clinicId === 'all'
    ? `${getOmsApiUri()}/clinicians`
    : `${getOmsApiUri()}/clinics/${clinicId}/clinicians`

  if (queryAdmin) {
    uri += `?admin=true`
  }

  const response = await fetch(
    uri,
    {
      signal,
      headers: await getHeaders(),
    },
  )

  if (response.status !== 200) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dtos = await response.json() as (TQueryAdmin extends true ? AdminClinician[] : Clinician[])

  return dtos
}

const postClinician = async (clinicId: number, clinician: NewClinician): Promise<Clinician> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/clinicians`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify(clinician),
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as Clinician

  return dto
}

const putClinician = async (clinicId: number, clinician: Clinician | AdminClinician): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/clinicians/${clinician.id}`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify(clinician),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

const deleteClinician = async (clinicId: number, clinicianId: number): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/clinicians/${clinicianId}`,
    {
      method: 'DELETE',
      headers: await getMutationHeaders(),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

export {
  cliniciansKeys,
  getClinicians,
  postClinician,
  putClinician,
  deleteClinician,
}
