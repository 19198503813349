import { ActiveSide } from './ActiveSide'
import { FileBase } from './File'
import { ScanMethod } from './ScanMethod'
import { ScanStatus } from './ScanStatus'

type ScanBase<TDate> =
  & FileBase<TDate>
  & {
    side: ActiveSide,
    scanMethod: ScanMethod,
    status: ScanStatus,
  }

export type Scan = ScanBase<Date>
export type ScanDto = ScanBase<string>

export const dtoToScan = (dto: ScanDto): Scan => ({
  ...dto,
  createdAt: new Date(dto.createdAt),
  updatedAt: new Date(dto.updatedAt),
})

export type NewScan = Omit<Scan, 'id' | 'createdAt' | 'updatedAt' | 'scanMethod'> & {file: File}
