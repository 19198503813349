
import { Trans } from '@lingui/macro'
import { Box, Button, Form, FormField, Heading, TextInput } from 'grommet'
import { ReactNode, useState } from 'react'

import ConfirmModal from '../../components/modals/ConfirmModal'
import { useClinicId } from '../../hooks/clinic'
import { useCreateLocation, useDeleteLocation, useQueryLocations, useUpdateLocation } from '../../queries/locations'
import { Location, NewLocation, getNewLocation } from '../../types/Location'

import EditableList, { EditorProps } from './EditableList'

type FormItemProps = Readonly<{
  label: ReactNode,
  value: string,
  onChange: (value: string) => unknown,
}>

const FormItem = (props: FormItemProps) => {
  return (
    <FormField
      label={
        <Heading
          level="4"
          margin="none"
        >
          {props.label}
        </Heading>
      }
    >
      <TextInput
        value={props.value}
        onChange={event => props.onChange(event.currentTarget.value)}
      />
    </FormField>
  )
}

const isExistingLocation = (value: Location | NewLocation): value is Location =>
  (value as Record<string, unknown>)['id'] !== undefined

const LocationEditor = (props: EditorProps<Location, NewLocation>) => {
  const clinicId = useClinicId()

  const [civicNumber, setCivicNumber] = useState<string>(props.item.civicNumber)
  const [streetName, setStreetName] = useState<string>(props.item.streetName)
  const [city, setCity] = useState<string>(props.item.city)
  const [state, setState] = useState<string>(props.item.state)
  const [country, setCountry] = useState<string>(props.item.country)
  const [zipCode, setZipCode] = useState<string>(props.item.zipCode)
  const [description, setDescription] = useState<string>(props.item.description)

  const createLocation = useCreateLocation()
  const updateLocation = useUpdateLocation()

  const isDirty =
    civicNumber !== props.item.civicNumber ||
    streetName !== props.item.streetName ||
    city !== props.item.city ||
    state !== props.item.state ||
    country !== props.item.country ||
    zipCode !== props.item.zipCode ||
    description !== props.item.description

  const handleSave = () => {
    if (!isDirty) return

    if (isExistingLocation(props.item)) {
      updateLocation.mutate(
        {
          clinicId,
          location: {
            id: props.item.id,
            civicNumber,
            streetName,
            city,
            state,
            country,
            zipCode,
            description,
            clinicId,
          },
        },
        {
          onSuccess: () => {
            props.onSaved?.()
          },
        },
      )
    } else {
      createLocation.mutate(
        {
          clinicId,
          location: {
            civicNumber,
            streetName,
            city,
            state,
            country,
            zipCode,
            description,
          },
        },
        {
          onSuccess: () => {
            props.onSaved?.()
          },
        },
      )
    }
  }

  const handleRevert = () => {
    if (!isDirty && !props.onCancel) return

    setCivicNumber(props.item.civicNumber)
    setStreetName(props.item.streetName)
    setCity(props.item.city)
    setState(props.item.state)
    setCountry(props.item.country)
    setZipCode(props.item.zipCode)
    setDescription(props.item.description)

    props.onCancel?.()
  }

  return (
    <Form>
      <FormItem
        label={<Trans>Description</Trans>}
        value={description}
        onChange={setDescription}
      />
      <FormItem
        label={<Trans>Civic number</Trans>}
        value={civicNumber}
        onChange={setCivicNumber}
      />
      <FormItem
        label={<Trans>Street name</Trans>}
        value={streetName}
        onChange={setStreetName}
      />
      <FormItem
        label={<Trans>City</Trans>}
        value={city}
        onChange={setCity}
      />
      <FormItem
        label={<Trans>State</Trans>}
        value={state}
        onChange={setState}
      />
      <FormItem
        label={<Trans>Country</Trans>}
        value={country}
        onChange={setCountry}
      />
      <FormItem
        label={<Trans>Zip code</Trans>}
        value={zipCode}
        onChange={setZipCode}
      />

      <Box
        direction="row"
        justify="end"
        gap="0.5rem"
      >
        <Button
          primary={true}
          label={
            updateLocation.isPending
              ? <Trans>Saving...</Trans>
              : <Trans>Save</Trans>
          }
          onClick={handleSave}
          disabled={!isDirty || updateLocation.isPending}
        />
        {
          props.onDelete &&
          <Button
            secondary
            color="red"
            label={<Trans>Delete</Trans>}
            onClick={props.onDelete}
          />
        }
        <Button
          label={<Trans>Cancel</Trans>}
          onClick={handleRevert}
          disabled={(!isDirty && !props.onCancel) || updateLocation.isPending}
        />
      </Box>
    </Form>
  )
}

const LocationsEditor = () => {
  const clinicId = useClinicId()
  const locations = useQueryLocations(clinicId)

  const [confirmDelete, setConfirmDelete] = useState<Location | null>(null)

  const deleteLocation = useDeleteLocation()

  return (
    <>
      <EditableList
        query={locations}
        editor={LocationEditor}
        checkClinicSet={true}
        onNewItem={getNewLocation}
        onSort={(a, b) => a.description.localeCompare(b.description)}
        onShowItem={location => location.description}
        onDelete={(location) => setConfirmDelete(location)}
      />

      <ConfirmModal
        show={confirmDelete !== null}
        title={<Trans>Delete location?</Trans>}
        message={
          <Trans>
            Are you sure you want to delete this location?
            This operation can be very hard to undo.
          </Trans>
        }
        confirmLabel={<Trans>Yes, delete this location</Trans>}
        onConfirm={() => {
          if (!confirmDelete) return

          deleteLocation.mutate(
            {clinicId, locationId: confirmDelete.id},
            {
              onSuccess: () => {
                setConfirmDelete(null)
              },
            },
          )
        }}
        onCancel={() => setConfirmDelete(null)}
      />
    </>
  )
}

export default LocationsEditor
