export const userRoles = {
  unknown: 'unknown', // While the information is being loaded for the first time!
  admin: 'userRoleAdmin',
  clinicOwner: 'userRoleClinicOwner',
  clinician: 'userRoleClinician',
  manufacturer: 'userRoleManufacturer',
} as const

export type UserRole = typeof userRoles[keyof typeof userRoles]

export const cognitoGroups = {
  admins: 'Admins',
  manufacturers: 'Manufacturers',
  clinicOwners: 'ClinicOwners',
  clinicians: 'Clinicians',
} as const

export type CognitoGroup = typeof cognitoGroups[keyof typeof cognitoGroups]

const getRoleLookup = (currentRoles: string[]): Partial<Record<UserRole, boolean>> => {
  const lookup: Partial<Record<UserRole, boolean>> = {}
  for (const role of currentRoles) {
    switch (role) {
    case cognitoGroups.admins:
      lookup[userRoles.admin] = true
      break

    case cognitoGroups.manufacturers:
      lookup[userRoles.manufacturer] = true
      break

    case cognitoGroups.clinicOwners:
      lookup[userRoles.clinicOwner] = true
      break

    case cognitoGroups.clinicians:
      lookup[userRoles.clinician] = true
      break

    default:
      break
    }
  }

  return lookup
}

type Lookup = Partial<Record<UserRole, boolean>> & {rolesCount: number}

const emptyLookup: Lookup = {
  ...getRoleLookup([]),
  rolesCount: 0,
}

const lookups: Record<string, unknown> = {}

const readLookup = (
  groups: string[],
  createLookup: () => Lookup,
): Lookup => {
  if (groups.length === 0) return emptyLookup

  let currentNode = lookups

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i]
    if (group === undefined) continue

    if (i < groups.length - 1) {
      let nextNode = currentNode[group] as Record<string, unknown> | undefined
      if (nextNode === undefined) {
        currentNode[group] = nextNode = {}
      }

      currentNode = nextNode
      continue
    }

    let lookup = currentNode[group] as Lookup | undefined

    if (lookup === undefined) currentNode[group] = lookup = createLookup()

    return lookup
  }

  return createLookup()
}

export class UserRoleManager {
  #lookup: Lookup

  public get isAdmin(): boolean {
    return this.#lookup[userRoles.admin] === true
  }

  public get isManufacturer(): boolean {
    return this.#lookup[userRoles.manufacturer] === true
  }

  public get isClinicOwner(): boolean {
    return this.#lookup[userRoles.clinicOwner] === true
  }

  public get isClinician(): boolean {
    return this.#lookup[userRoles.clinician] === true || this.#lookup.rolesCount === 0
  }

  public constructor(groups: string[]) {
    this.#lookup = readLookup(
      groups,
      () => ({
        ...getRoleLookup(groups),
        rolesCount: groups.length,
      }),
    )
  }

  public isRole(role: UserRole): boolean {
    if (role === userRoles.clinician) return this.isClinician
    return this.#lookup[role] === true
  }

  public getBestRole(): UserRole | null {
    if (this.isAdmin) return userRoles.admin
    if (this.isClinicOwner) return userRoles.clinicOwner
    if (this.isManufacturer) return userRoles.manufacturer
    if (this.isClinician) return userRoles.clinician
    return null
  }

  public getRoles(): UserRole[] {
    if (this.#lookup.rolesCount === 0) return [userRoles.clinician]

    const roles: UserRole[] = []
    for (const key in this.#lookup) {
      const role = key as UserRole
      if (this.#lookup[role] === true) {
        roles.push(role)
      }
    }

    return roles
  }
}
