import { Box } from 'grommet'
import { Fragment, ReactNode } from 'react'

import { applicationVersion } from '../../constants/version'

const now = new Date()

type Action = Readonly<{
  key: string,
  showIf?: boolean,
  action: ReactNode,
}>

type ActionsProps = Readonly<{
  actions: Action[] | undefined,
}>

const Actions = (props: ActionsProps) => {
  if (props.actions === undefined) return null

  return props
    .actions
    .filter(action => action.showIf !== false)
    .map(action =>
      <Fragment key={action.key}>
        {action.action}
      </Fragment>,
    )
}

type LeftActionsRow = Readonly<{
  leftActions: Action[],
  rightActions?: undefined,
}>

type RightActionsRow = Readonly<{
  leftActions?: undefined,
  rightActions: Action[],
}>

type LeftRightActionsRow = Readonly<{
  leftActions: Action[],
  rightActions: Action[],
}>

type ActionsRowType =
  | LeftActionsRow
  | RightActionsRow
  | LeftRightActionsRow

const isLeftActionsRow = (row: ActionsRowType): row is LeftActionsRow =>
  typeof (row as LeftActionsRow).leftActions !== 'undefined' &&
  typeof (row as RightActionsRow).rightActions === 'undefined'

const isFullActionsRow = (row: ActionsRowType): row is LeftRightActionsRow =>
  typeof (row as LeftRightActionsRow).leftActions !== 'undefined' &&
  typeof (row as LeftRightActionsRow).rightActions !== 'undefined'

type ActionsRowProps = Readonly<{
  row: ActionsRowType | undefined,
}>

const ActionsRow = (props: ActionsRowProps) => {
  if (!props.row) return null

  if (isFullActionsRow(props.row)) {
    return (
      <Box
        direction="row"
        justify="between"
      >
        <Box
          wrap
          direction="row"
          style={{
            columnGap: '0.5rem',
            rowGap: '0.5rem',
          }}
        >
          <Actions actions={props.row.leftActions} />
        </Box>

        <Box
          wrap
          direction="row-reverse"
          style={{
            columnGap: '0.5rem',
            rowGap: '0.5rem',
            paddingRight: '225px',
          }}
        >
          <Actions actions={props.row.rightActions} />
        </Box>
      </Box>
    )
  } else {
    const actions = isLeftActionsRow(props.row)
      ? props.row.leftActions
      : props.row.rightActions

    return (
      <Box
        wrap
        direction={isLeftActionsRow(props.row) ? 'row' : 'row-reverse'}
        style={{
          columnGap: '0.5rem',
          rowGap: '0.5rem',
        }}
      >
        <Actions actions={actions} />
      </Box>
    )
  }
}

type Props = Readonly<{
  top?: ActionsRowType,
  middle?: ActionsRowType,
  bottom?: ActionsRowType,
}>

const Footer = (props: Props) => {
  return (
    <footer
      style={{
        padding: '0.5rem',
      }}
    >
      <ActionsRow row={props.top} />

      <ActionsRow row={props.middle} />

      <Box
        style={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '1fr 100px 1fr',
        }}
      >
        <Box
          wrap
          direction="row"
          style={{
            columnGap: '0.5rem',
            rowGap: '0.5rem',
          }}
        >
          <Actions actions={props.bottom?.leftActions} />
        </Box>

        <Box
          direction="column"
          justify="center"
          align="center"
          alignSelf="end"
          style={{
            fontSize: '10px',
            color: '#818181',
          }}
        >
          <Box>Podform3D{' '}{now.getFullYear()}</Box>
          <Box
            style={{
              color: '#81818199',
              fontSize: '8px',
              lineHeight: '8px',
            }}
          >
            ver. {applicationVersion}
          </Box>
        </Box>

        <Box
          wrap
          direction="row-reverse"
          style={{
            columnGap: '0.5rem',
            rowGap: '0.5rem',
            paddingRight: '225px',
          }}
        >
          <Actions actions={props.bottom?.rightActions} />
        </Box>
      </Box>
    </footer>
  )
}

export default Footer
