
import { Trans } from '@lingui/macro'
import { Box } from 'grommet'
import { ReactNode } from 'react'

import NonDisabledTip from '../../components/NonDisabledTip'

type WrapTooltipIfProps = Readonly<{
  wrap: boolean,
  content: ReactNode,
  children: ReactNode,
}>

const WrapTooltipIf = (props: WrapTooltipIfProps) => {
  if (!props.wrap) return <>{props.children}</>

  return (
    <NonDisabledTip
      plain={true}
      content={props.content}
    >
      {props.children}
    </NonDisabledTip>
  )
}

const InvalidFormTip = () => {
  return (
    <Box
      pad="0.8rem"
      background="light-3"
      width={{max: '10rem'}}
    >
      <Trans>
        The form is currently missing fields and cannot be saved.
        Please fill in all mandatory fields marked with asterisks
        or currently highlighted in red.
      </Trans>
    </Box>
  )
}

export {
  WrapTooltipIf,
  InvalidFormTip,
}
