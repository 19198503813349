import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { SelectOption } from '../SelectOption'

export const productTypes = {
  shell: 0,
  hybrid: 1,
} as const

export type ProductType = typeof productTypes[keyof typeof productTypes]

export const getProductTypeLabel = (i18n: I18n, productType: ProductType): string => {
  switch (productType) {
  case productTypes.shell: return t(i18n)`Shell`
  case productTypes.hybrid: return t(i18n)`Hybrid`
  default: return t(i18n)`Unknown product type`
  }
}

export const getProductTypeOptions = (i18n: I18n): Array<SelectOption<ProductType>> =>
  Object
    .values(productTypes)
    .map(productType => ({
      value: productType,
      label: getProductTypeLabel(i18n, productType),
    }))
