import { getOmsApiUri } from '../constants/runtime'
import { NewPatient, Patient } from '../types/Patient'

import { fetch } from './fetch'
import { getMutationHeaders } from './utils'

const patientsKeys = {
  all: () => ['patients'],
  list: (clinicId: number, filters?: Record<string, unknown> | undefined) =>
    filters
      ? [...patientsKeys.all(), 'list', clinicId, filters]
      : [...patientsKeys.all(), 'list', clinicId],
  details: (patientId: number) => [...patientsKeys.all(), 'details', patientId],
}

const postPatient = async (clinicId: number, patient: NewPatient): Promise<Patient> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/patients`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify(patient),
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as Patient

  return dto
}

const putPatient = async (clinicId: number, patient: Patient): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/patients/${patient.id}`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify(patient),
    },
  )

  if (response.status < 200 || response.status > 299) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

export {
  patientsKeys,
  putPatient,
  postPatient,
}
