export type Clinician = {
  id: number,
  firstName: string,
  lastName: string,
  clinicId: number,
}

export type AdminClinician =
  & Clinician
  & {
    userId: string,
  }

export const allClinician: Clinician = {
  id: -1,
  firstName: '*',
  lastName: '',
  clinicId: -1,
}

export type NewClinician =
  & Omit<Clinician, 'id' | 'clinicId'>
  & {
    userId: string,
  }

export const getNewClinician = (): NewClinician => ({
  userId: '',
  firstName: '',
  lastName: '',
})
