export type OrderErrorBase<TDate> = {
  id: number,
  source: string,
  error: string,
  timestamp: TDate,
}

export type OrderError = OrderErrorBase<Date>
export type OrderErrorDto = OrderErrorBase<string>

export const dtoToOrderError = (dto: OrderErrorDto): OrderError => ({
  ...dto,
  timestamp: new Date(dto.timestamp),
})
