import { OrderStatus, orderStatusesForManufacturer } from '../types/OrderStatus'
import { UserRole, userRoles } from '../types/UserRole'

const getVisibleOrderStatuses = (
  role: UserRole,
  currentStatuses: OrderStatus[] | null | undefined,
): OrderStatus[] => {
  let statuses = currentStatuses ?? []

  if (role === userRoles.manufacturer) {
    statuses = statuses.filter(status => orderStatusesForManufacturer.includes(status))
  }

  return statuses
}

export {
  getVisibleOrderStatuses,
}
