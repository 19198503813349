import { useLayoutEffect, useState } from 'react'

type WindowSizes = Readonly<{
  width: number,
  height: number,
}>

const emptySizes: WindowSizes = {
  width: 0,
  height: 0,
}

const useWindowSizes = (): WindowSizes => {
  const [sizes, setSizes] = useState<WindowSizes>(emptySizes)

  useLayoutEffect(
    () => {
      const handleResize = () => {
        setSizes(() => ({
          width: window.innerWidth,
          height: window.innerHeight,
        }))
      }

      handleResize() // Load initial size!
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    },
    [],
  )

  return sizes
}

const useWindowWidth = (): number => {
  return useWindowSizes().width
}

const useWindowHeight = (): number => {
  return useWindowSizes().height
}

export {
  useWindowSizes,
  useWindowWidth,
  useWindowHeight,
}
