export type Clinic = {
  id: number,
  name: string,
}

export type AdminClinic = {
  id: number,
  isClinicActive: boolean,
  ownerId: string,
  name: string,
}

export const allClinic: Clinic = {
  id: -1,
  name: '*',
}

export type NewClinic = {
  isClinicActive: boolean,
  ownerId: string,
  name: string,
}

export const getNewClinic = (): NewClinic => ({
  isClinicActive: true,
  ownerId: '',
  name: '',
})

export const isAdminClinic = (adminClinic: AdminClinic | NewClinic): adminClinic is AdminClinic => {
  return typeof (adminClinic as unknown as AdminClinic).id !== 'undefined'
}
