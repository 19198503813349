type SimpleSerializableTypes = string | number | boolean

const getRecordQueryString = (
  record: Record<string, SimpleSerializableTypes | null | undefined>,
): string => {
  const query = new URLSearchParams()

  for (const key in record) {
    const value = record[key]
    if (value === null || value === undefined) continue

    query.append(key, typeof value === 'string' ? value.trim() : String(value))
  }

  return query.toString()
}

export {
  getRecordQueryString,
}
