import { Auth } from '@aws-amplify/auth'

const getCognitoToken = (): Promise<Record<string, unknown>> =>
  Auth
    .currentSession()
    .then(session => {
      return session.getIdToken().decodePayload()
    })

const getCognitoGroups = (): Promise<string[]> =>
  Auth
    .currentSession()
    .then((session) => {
      const groups = (session
        .getAccessToken()
        .payload['cognito:groups']) as (string[] | undefined)

      return groups ?? []
    })
    .catch(error => {
      console.error(`Failed to get user's Cognito groups. `, error)
      return ['invalid']
    })

export {
  getCognitoToken,
  getCognitoGroups,
}
