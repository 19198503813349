import { Heading, TextArea } from 'grommet'
import { ReactNode, useEffect, useRef, useState } from 'react'

import { useDebounce } from '../hooks/debounce'

type Props = Readonly<{
  debounce?: boolean,
  header: ReactNode,
  wrapHeader?: boolean,
  notes: string | null,
  onChange: (notes: string) => unknown,
  getValue?: (ref: () => string) => unknown,
}>

const NotesEditor = (props: Props) => {
  const [notes, setNotes] = useState<string>(props.notes ?? '')
  const debouncedNotes = useDebounce(notes, 1000)

  const onChangeRef = useRef(props.onChange)
  useEffect(() => {
    onChangeRef.current = props.onChange
  }, [props.onChange])

  const getValue = props.getValue
  const getValueRef = useRef(getValue)
  useEffect(() => {
    getValueRef.current = getValue
  }, [getValue])

  useEffect(
    () => {
      if (!getValueRef.current) return
      getValueRef.current(() => notes)
    },
    [notes],
  )

  useEffect(
    () => {
      if (props.debounce === true) {
        onChangeRef.current(debouncedNotes)
      }
    },
    [
      props.debounce,
      debouncedNotes,
    ],
  )

  useEffect(
    () => {
      if (props.debounce !== true) {
        onChangeRef.current(notes)
      }
    },
    [
      props.debounce,
      notes,
    ],
  )

  return (
    <>
      {
        props.wrapHeader === true ||
        typeof props.header === 'string'
          ? (
            <Heading level="2">
              {props.header}
            </Heading>
          )
          : props.header
      }
      <TextArea
        value={notes}
        fill
        onChange={(input) => setNotes(input.currentTarget.value)}
      />
    </>
  )
}

export default NotesEditor
