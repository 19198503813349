import { getOmsApiUri } from '../constants/runtime'
import { NewScan, Scan, ScanDto, dtoToScan } from '../types/Scan'
import { ScanMethod } from '../types/ScanMethod'
import { ScanStatus } from '../types/ScanStatus'

import { fetch } from './fetch'
import { getHeaders, getMutationHeaders } from './utils'

const scansKeys = {
  all: () => ['scans'],
  list: (clinicId: number, orderId: number, filters?: Record<string, unknown> | undefined) =>
    filters
      ? [...scansKeys.all(), 'list', clinicId, orderId, filters]
      : [...scansKeys.all(), 'list', clinicId, orderId],
  details: (clinicId: number, orderId: number, scanId: number) =>
    [...scansKeys.all(), 'details', clinicId, orderId, scanId],
}

const getScans = async (
  clinicId: number,
  orderId: number,
  signal: AbortSignal | null,
): Promise<Scan[]> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/scans`,
    {
      signal,
      headers: await getHeaders(),
    },
  )

  const dtos = await response.json() as ScanDto[]

  return dtos.map(dtoToScan)
}

const postScan = async (
  clinicId: number,
  orderId: number,
  scan: Omit<NewScan, 'file'>,
): Promise<Scan> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/scans`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify(scan),
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as ScanDto

  return dtoToScan(dto)
}

const downloadScan = async (
  clinicId: number,
  scanId: number,
  signal?: AbortSignal,
): Promise<Blob> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/scans/${scanId}`,
    {
      headers: await getHeaders(),
      signal: signal ?? null,
    },
  )

  return await response.blob()
}

const postScans = async (
  clinicId: number,
  orderId: number,
  scans: NewScan[],
): Promise<Scan[]> => {
  const body = new FormData()

  for (let i = 0; i < scans.length; i++) {
    const scan = scans[i]
    if (!scan) continue

    const key = `scans[${i}]`
    body.append(`${key}.fileName`, scan.fileName)
    body.append(`${key}.extension`, scan.extension)
    body.append(`${key}.filePath`, scan.filePath)
    body.append(`${key}.side`, String(scan.side))
    body.append(`${key}.status`, String(scan.status))
    body.append(`${key}.file`, scan.file)
  }

  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/scans-with-files`,
    {
      method: 'POST',
      headers: await getHeaders(),
      body,
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as ScanDto[]

  return dto.map(dtoToScan)
}

const putScanStatus = async (
  clinicId: number,
  scanId: number,
  status: ScanStatus,
): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/scans/${scanId}/status`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify({
        status,
      }),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

const putScanMethods = async (
  clinicId: number,
  scanId: number,
  scanMethod: ScanMethod,
): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/scans/${scanId}/scanMethod`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify({
        scanMethod,
      }),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}`)
  }
}

const putMultipleScanMethods = async (
  clinicId: number,
  scanIds: number[],
  scanMethod: ScanMethod,
): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/scans/scanMethods`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify({
        scanIds,
        scanMethod,
      }),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}`)
  }
}

const deleteScan = async (
  clinicId: number,
  orderId: number,
  scanId: number,
): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/scans/${scanId}`,
    {
      method: 'DELETE',
      headers: await getMutationHeaders(),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

export {
  scansKeys,
  getScans,
  downloadScan,
  postScan,
  postScans,
  putScanStatus,
  putScanMethods,
  putMultipleScanMethods,
  deleteScan,
}
