
import { Trans } from '@lingui/macro'
import { Box, Button, CheckBox, Form, FormField, Heading, Spinner, TextInput } from 'grommet'
import { useState } from 'react'

import { useClinicId } from '../../hooks/clinic'
import { useCreateClinic, useQueryClinicAdmin, useUpdateClinic } from '../../queries/clinics'
import { AdminClinic, NewClinic, allClinic, isAdminClinic } from '../../types/Clinic'

import { EditorProps } from './EditableList'

const isExistingClinic = (clinic: AdminClinic | NewClinic): clinic is AdminClinic =>
  (clinic as Record<string, unknown>)['id'] !== undefined

const ClinicEditorImpl = (props: EditorProps<AdminClinic, NewClinic>) => {
  const [isClinicActive, setIsClinicActive] = useState<boolean>(props.item.isClinicActive)
  const [name, setName] = useState<string>(props.item.name)
  const [ownerId, setOwnerId] = useState<string>(props.item.ownerId)

  const createClinic = useCreateClinic()
  const updateClinic = useUpdateClinic()

  const isDirty =
    isClinicActive !== props.item.isClinicActive ||
    name !== props.item.name ||
    ownerId !== props.item.ownerId

  const handleSave = () => {
    if (!isDirty) return

    if (isExistingClinic(props.item)) {
      updateClinic.mutate(
        {
          id: props.item.id,
          isClinicActive,
          ownerId,
          name,
        },
        {
          onSuccess: () => {
            props.onSaved?.()
          },
        },
      )
    } else {
      createClinic.mutate(
        {
          isClinicActive,
          ownerId,
          name,
        },
        {
          onSuccess: () => {
            props.onSaved?.()
          },
        },
      )
    }
  }

  const handleRevert = () => {
    if (!isDirty && !props.onCancel) return

    setIsClinicActive(props.item.isClinicActive)
    setName(props.item.name)
    setOwnerId(props.item.ownerId)

    props.onCancel?.()
  }

  return (
    <Form>
      <FormField
        label={
          <Heading
            level="4"
            margin="none"
          >
            <Trans>Name</Trans>
            {(isAdminClinic(props.item)) &&
              <>
                {'['} {props.item.id} {']'}
              </>
            }
          </Heading>
        }
      >
        <TextInput
          value={name}
          onChange={event => setName(event.currentTarget.value)}
        />
      </FormField>

      <FormField
        label={
          <Heading
            level="4"
            margin="none"
          >
            <Trans>Is active?</Trans>
          </Heading>
        }
      >
        <CheckBox
          checked={isClinicActive}
          onChange={event => setIsClinicActive(event.currentTarget.checked)}
        />
      </FormField>

      <FormField
        label={
          <Heading
            level="4"
            margin="none"
          >
            <Trans>Owner ID</Trans>
          </Heading>
        }
      >
        <TextInput
          value={ownerId}
          onChange={event => setOwnerId(event.currentTarget.value)}
        />
      </FormField>

      <Box
        direction="row"
        justify="end"
        gap="0.5rem"
      >
        <Button
          primary={true}
          label={
            updateClinic.isPending
              ? <Trans>Saving...</Trans>
              : <Trans>Save</Trans>
          }
          onClick={handleSave}
          disabled={!isDirty || updateClinic.isPending}
        />
        {
          props.onDelete &&
          <Button
            secondary
            color="red"
            label={<Trans>Delete</Trans>}
            onClick={props.onDelete}
          />
        }
        <Button
          label={
            props.onCancel
              ? <Trans>Cancel</Trans>
              : <Trans>Revert</Trans>
          }
          onClick={handleRevert}
          disabled={(!isDirty && !props.onCancel) || updateClinic.isPending}
        />
      </Box>
    </Form>
  )
}

const ClinicEditor = () => {
  const clinicId = useClinicId()
  const clinic = useQueryClinicAdmin(clinicId)

  return (
    <Box>
      {
        clinicId === allClinic.id
          ? <Trans>No clinic selected</Trans>
          : (
            clinic.data
              ? (
                <ClinicEditorImpl
                  key={clinic.data.id}
                  item={clinic.data}
                />
              )
              : (
                <Box>
                  <Spinner />
                </Box>
              )
          )
      }
    </Box>
  )
}

export default ClinicEditor

export {
  ClinicEditorImpl,
}
