import { Trans, t } from '@lingui/macro'
import { QueryClient } from '@tanstack/react-query'

import { i18n } from './i18n'
import { notificationActions } from './store/actions/notifications'
import { Dispatch } from './store/store'

let queryClient: QueryClient | undefined

const createQueryClient = (dispatch: Dispatch): QueryClient =>
  queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 15_000, // Is this too long? Get Antoine input.
      },
      mutations: {
        onError: (error) => {
          console.error(error)
          dispatch(notificationActions.showErrorToast(
            'global-error-handler',
            <Trans>
              The request could not be saved.
              Please try again, or contact us if the problem persists
            </Trans>,
            t(i18n)`Save error`,
          ))
        },
      },
    },
  })

const getQueryClient = (): QueryClient => {
  if (queryClient) return queryClient
  throw new Error(`QueryClient not yet configured!`)
}

export {
  createQueryClient,
  getQueryClient,
}
