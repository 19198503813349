
import { Plural, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Route } from '@tanstack/react-router'
import { Box, Button, Collapsible, Spinner } from 'grommet'
import { useEffect, useMemo, useRef, useState } from 'react'

import ButtonLinkV2 from '../../components/ButtonLinkV2'
import AddOrder from '../../components/modals/AddOrder'
import AddPatient from '../../components/modals/AddPatient'
import OrderCancellationReasonPrompt from '../../components/modals/OrderCancellationReasonPrompt'
import { UpdateOrderStatusExtra, downloadAllFilesByOrder } from '../../fetchers/orders'
import { useLoading } from '../../hooks/loading'
import { useGetBestRole, useHasFullOrdersAccess, useIsAdmin, useIsClinician, useIsManufacturer, useRoleManager } from '../../hooks/roles'
import { printOrders } from '../../printViews/ordersPdf/printOrdersPdf'
import { useQueryClinics } from '../../queries/clinics'
import { useOrdersCount, useOrdersCountsByOrderStatuses, useProcessVerified, useQueryOrders, useUpdateOrderStatus, useVerifiedOrdersCount } from '../../queries/orders'
import { userContextActions } from '../../store/actions/userContext'
import { useDispatch, useSelector } from '../../store/store'
import { BillingStatus, billingStatuses } from '../../types/BillingStatus'
import { allClinic } from '../../types/Clinic'
import { allClinician } from '../../types/Clinician'
import { allLocation } from '../../types/Location'
import { Order, OrderForManufacturer } from '../../types/Order'
import { OrderStatus, getEmptyOrdersCounts, orderStatuses, orderStatusesList } from '../../types/OrderStatus'
import { getVisibleOrderStatuses } from '../../utils/orderStatus'
import { getPersonFullName } from '../../utils/person'
import { getOrderStatusDisplays } from '../../utils/statusDisplays'
import PageWithBanners from '../PageWithBanners'
import Root from '../Root'
import OrderTable from '../orders/OrderTable'
import { forEachSelected } from '../orders/columns/selection'
import ManufacturerBulkDownload from '../orders/modals/ManufacturerBulkDownload'
import SettingsPanel from '../settings/SettingsPanel'

import Footer from './Footer'
import Header from './Header'
import ShippingLinkPrompt from './ShippingLinkPrompt'
import UploadingToManufacturer from './UploadingToManufacturer'

const maxPageSize = 32767

const billingStatusesList = Object.values(billingStatuses)

type ShippingLinkPromptData = [
  order: Order,
  newOrderStatus: OrderStatus,
  updater: (shippingLink: string | null) => UpdateOrderStatusExtra,
]

const MainPanel = () => {
  const { i18n } = useLingui()
  const dispatch = useDispatch()

  const clinic = useSelector(state => state.userContext.clinic)
  const location = useSelector(state => state.userContext.location)
  const clinician = useSelector(state => state.userContext.clinician)
  const orderFilters = useSelector(state => state.userContext.orderFilters)

  const roleManager = useRoleManager()
  const userRole = useGetBestRole()
  const isAdmin = useIsAdmin()
  const isManufacturer = useIsManufacturer()
  const isClinician = useIsClinician()
  const hasFullOrdersAccess = useHasFullOrdersAccess()

  const { isLoading, setLoading } = useLoading()

  const [manufacturerUploadStatus, setManufacturerUploadStatus] = useState<
    null | 'uploading' | 'success' | {type: 'error'; message: string}
  >(null)

  const handlePrintFullPageRef = useRef<((rows: Order[] | OrderForManufacturer[]) => unknown) | null>(null)

  const orderStatusFilters = useSelector(app => app.userContext.orderStatusFilters)

  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([])
  const [filteredBillingStatuses, setFilteredBillingStatuses] = useState<BillingStatus[]>(billingStatusesList)

  const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false)
  const [showCollapse, setCollapse] = useState<boolean>(false)

  const [confirmManufacturerDownload, setConfirmManufacturerDownload] = useState<boolean>(false)
  const [showPatientModal, setPatientModal] = useState(false)
  const [showOrderModal, setShowOrderModal] = useState(false)
  const [promptShippingLink, setPromptShippingLink] = useState<ShippingLinkPromptData | null>(null)
  const [promptOrderCancellation, setPromptOrderCancellation] = useState<Order | null>(null)

  const clinics = useQueryClinics()
  const verifiedOrdersCount = useVerifiedOrdersCount(isManufacturer || isAdmin)
  const processedOrdersCount = useOrdersCount([orderStatuses.processed], !isManufacturer)

  const updateOrderStatus = useUpdateOrderStatus()
  const processVerified = useProcessVerified()

  const ordersCountsByStatuses = useOrdersCountsByOrderStatuses({
    clinicId: clinic.id === allClinic.id ? null : clinic.id,
    locationId: location.id === allLocation.id ? null : location.id,
    clinicianId: isAdmin ? null : (clinician.id === allClinician.id ? null : clinician.id),
  })

  useEffect(
    () => {
      if (!ordersCountsByStatuses.data) return
      dispatch(userContextActions.setOrdersCountsByStatuses(ordersCountsByStatuses.data))
    },
    [
      dispatch,
      ordersCountsByStatuses.data,
    ],
  )

  const filteredOrderStatuses = useMemo(
    () => getVisibleOrderStatuses(userRole, orderFilters.includeStatuses),
    [
      userRole,
      orderFilters.includeStatuses,
    ],
  )

  const ordersQuery = useQueryOrders<
    typeof isManufacturer extends true ? true : false,
    typeof isManufacturer extends true ? OrderForManufacturer : Order
  >({
    ...orderFilters,
    clinicId: clinic.name === '*'
      ? null
      : clinic.id,
    locationId: location.description !== '' && location.description !== '*'
      ? location.id
      : null,
    clinicianId: hasFullOrdersAccess || clinician.id === -1
      ? null
      : clinician.id,
    includeStatuses:
      orderFilters.includeStatuses === null ||
        filteredOrderStatuses.length === orderStatusesList.length
        ? null
        : filteredOrderStatuses,
    includeBillingStatuses: filteredBillingStatuses.length === billingStatusesList.length
      ? null
      : filteredBillingStatuses,
  })

  const [rows, ordersCountDb] = useMemo(
    () => {
      const rows = ordersQuery.data?.pages.flatMap(page => page.data) ?? []

      const pagesCount = ordersQuery.data?.pages.length

      const ordersCountDb = pagesCount !== undefined
        ? (ordersQuery.data?.pages[pagesCount - 1]?.totalCount ?? null)
        : null

      return [rows, ordersCountDb]
    },
    [
      ordersQuery.data,
    ],
  )

  const selectedVerifiedOrders = useMemo(
    () => {
      const verifiedOrders = []
      for (const id of selectedOrderIds) {
        const order = rows.find(order => order.id === id)
        if (!order) continue
        if (order.activeStatus === orderStatuses.verified) {
          verifiedOrders.push(order)
        }
      }

      return verifiedOrders
    },
    [
      rows,
      selectedOrderIds,
    ],
  )

  const orderStatusDisplays = useMemo(
    () => getOrderStatusDisplays(i18n),
    [i18n],
  )

  const createNewOrderForm = () => {
    setShowOrderModal(true)
  }

  const handleDownloadBulk = async () => {
    if (isManufacturer) {
      setConfirmManufacturerDownload(true)
      return
    }

    setLoading(true)

    const orderIds = []
    for (const id of selectedOrderIds) {
      const order = rows.find(order => order.id === id)
      if (!order) continue
      if (order.filesCount <= 0) continue
      orderIds.push(id)
    }

    await downloadAllFilesByOrder(orderIds)

    setLoading(false)
  }

  function handlePrintOrders<TOrder extends Order | OrderForManufacturer>(orders: TOrder[]) {
    if (!roleManager) return

    setLoading(true)

    printOrders(i18n, orders, roleManager)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handlePrintSelectedOrders = () => {
    const orders = rows.filter(row => selectedOrderIds.includes(row.id))
    handlePrintOrders(orders)
  }

  const handlePrintAll = () => {
    if (ordersCountDb !== null && rows.length >= ordersCountDb) {
      // Fast-track available: if the user already has the whole table downloaded,
      // don't try to refetch. Also, React Query will refuse to refetch twice
      // before the cache expiration, if the user clicks on the Print
      // button twice.
      handlePrintOrders(rows)
      return
    }

    dispatch(userContextActions.setPageSize(maxPageSize))

    // Flow: Define a handle that will be called when React Query, reacting
    // to our new maximum page size, will detect that there are no further pages
    // to load.
    handlePrintFullPageRef.current = (rows) => {
      handlePrintOrders(rows)
    }
  }

  useEffect(
    () => {
      // Effect to detect when all pages are loaded, to trigger bulk PDF print.
      if (!handlePrintFullPageRef.current) return
      const handler = handlePrintFullPageRef.current

      if (ordersCountDb === null) return
      if (rows.length < ordersCountDb) return

      handlePrintFullPageRef.current = null
      handler(rows)
    },
    [
      rows,
      ordersCountDb,
    ],
  )

  useEffect(
    () => {
      if (!clinics.data) return
      dispatch(userContextActions.setAvailableClinics(clinics.data))
      setInitialLoadDone(true)
    },
    [
      dispatch,
      clinics.data,
    ],
  )

  const handleLoadAll = () => {
    dispatch(userContextActions.setPageSize(maxPageSize))
  }

  const handleSetShipping = (shippingLink: string | null) => {
    if (!promptShippingLink) return
    const [order, newOrderStatus, updater] = promptShippingLink

    setLoading(true)

    forEachSelected(
      rows,
      order.id,
      selectedOrderIds,
      order =>
        updateOrderStatus.mutateAsync({
          clinicId: order.clinicId,
          orderId: order.id,
          value: newOrderStatus,
          extra: updater(shippingLink),
        }),
    )
      .finally(() => {
        setLoading(false)
        setPromptShippingLink(null)
      })
  }

  const handleCancellationReason = (cancellationReason: string | null) => {
    if (!promptOrderCancellation) return

    setLoading(true)

    forEachSelected(
      rows,
      promptOrderCancellation.id,
      selectedOrderIds,
      order =>
        updateOrderStatus.mutateAsync({
          clinicId: order.clinicId,
          orderId: order.id,
          value: orderStatuses.cancelled,
          extra: {
            cancellationReason,
          },
        }),
    )
      .finally(() => {
        setLoading(false)
        setPromptOrderCancellation(null)
      })
  }

  const handleUpdateOrderStatus = (order: Order, newStatus: OrderStatus) => {
    if (
      isAdmin &&
      newStatus === orderStatuses.shipped
    ) {
      setPromptShippingLink([
        order,
        orderStatuses.shipped,
        shippingLink => ({ shippingLink }),
      ])
      return
    }

    if (
      (isManufacturer || isAdmin) &&
      newStatus === orderStatuses.finished
    ) {
      setPromptShippingLink([
        order,
        orderStatuses.finished,
        shippingLink => ({ manufacturerShippingLink: shippingLink }),
      ])
      return
    }

    if (
      !isManufacturer &&
      newStatus === orderStatuses.cancelled
    ) {
      setPromptOrderCancellation(order)
      return
    }

    setLoading(true)

    forEachSelected(
      rows,
      order.id,
      selectedOrderIds,
      order =>
        updateOrderStatus.mutateAsync({
          clinicId: order.clinicId,
          orderId: order.id,
          value: newStatus,
        }),
    )
      .finally(() => setLoading(false))
  }

  const handleSendVerified = () => {
    if (selectedOrderIds.length > 0 && selectedVerifiedOrders.length === 0) return
    if (verifiedOrdersCount.data === undefined) return
    if (verifiedOrdersCount.data === 0) return

    setManufacturerUploadStatus('uploading')

    const selectedIds = []
    if (selectedOrderIds.length > 0) {
      for (const selected of selectedVerifiedOrders) {
        selectedIds.push(selected.id)
      }
    }

    processVerified.mutate(
      {
        selectedIds,
      },
      {
        onSuccess: () => {
          setManufacturerUploadStatus('success')
        },
        onError: (error) => {
          setManufacturerUploadStatus({type: 'error', message: error.message})
        },
      },
    )
  }

  if (!initialLoadDone) {
    return (
      <Box
        height="100vh"
        justify="center"
        align="center"
      >
        <Spinner size="xlarge" />
      </Box>
    )
  }

  const firstStatusFilter = orderStatusFilters[0]

  const currentStatusFilter = orderStatusFilters.length === 1 && firstStatusFilter !== undefined
    ? firstStatusFilter
    : null

  return (
    <>
      <PageWithBanners
        padContent={false}
        header={
          <Header
            handleSettingsPressed={() => {
              setCollapse(showCollapse => !showCollapse)
            }}
          />
        }
        footer={
          <Footer
            middle={{
              leftActions: [
                {
                  key: 'main-panel.footer.middle.left.table-stats',
                  showIf: ordersCountDb !== null,
                  action:
                    <Box
                      direction="row"
                      align="center"
                      gap="0.25rem"
                    >
                      <Box>
                        {
                          !isClinician &&
                          <>
                            <Plural
                              value={selectedOrderIds.length}
                              one="# selected"
                              other="# selected"
                            />
                            {' '}|{' '}
                          </>
                        }
                        <Plural
                          value={rows.length}
                          other="Showing # of"
                        />
                        {' '}
                        <Plural
                          value={ordersCountDb ?? 0}
                          one="# result"
                          other="# results"
                        />
                      </Box>
                      {
                        isAdmin &&
                        <Box>
                          <Button
                            label={<Trans>Load all</Trans>}
                            disabled={
                              ordersQuery.isFetchingNextPage ||
                              rows.length === ordersCountDb
                            }
                            onClick={handleLoadAll}
                          />
                        </Box>
                      }
                    </Box>,
                },
              ],
              rightActions: [
                {
                  key: 'footer.left.create-order',
                  showIf: isAdmin,
                  action:
                    <Button
                      label={<Trans>Create order</Trans>}
                      onClick={createNewOrderForm}
                    />,
                },
                {
                  key: 'footer.left.download-files',
                  showIf: isAdmin || isManufacturer,
                  action:
                    <Button
                      label={
                        isManufacturer
                          ? (
                            selectedOrderIds.length > 0
                              ? <Trans>Download selection</Trans>
                              : <Trans>Download all verified</Trans>
                          )
                          : <Trans>Download files</Trans>
                      }
                      disabled={
                        isManufacturer
                          ? (
                            confirmManufacturerDownload ||
                            (
                              selectedOrderIds.length === 0 &&
                              (
                                verifiedOrdersCount.data === undefined ||
                                verifiedOrdersCount.data === 0
                              )
                            )
                          )
                          : (
                            selectedOrderIds.length === 0 ||
                            rows
                              .filter(row => selectedOrderIds.includes(row.id))
                              .reduce((sum, order) => sum += order.filesCount, 0) === 0 ||
                            isLoading
                          )
                      }
                      onClick={handleDownloadBulk}
                    />,
                },
                {
                  key: 'footer.left.download-orders-list',
                  showIf: !isClinician,
                  action:
                    isManufacturer && selectedOrderIds.length === 0 && currentStatusFilter !== null
                      ? (
                        <Button
                          label={
                            <Trans>
                              Print all {orderStatusDisplays[currentStatusFilter].label.toLocaleLowerCase()}
                            </Trans>
                          }
                          disabled={isLoading || rows.length === 0}
                          onClick={handlePrintAll}
                        />
                      )
                      : (
                        <Button
                          label={<Trans>Print selection</Trans>}
                          disabled={isLoading || selectedOrderIds.length === 0}
                          onClick={handlePrintSelectedOrders}
                        />
                      ),
                },
                {
                  key: 'footer.left.send-verified-to-iorthotics',
                  showIf: isAdmin,
                  action:
                    <Button
                      label={
                        selectedOrderIds.length > 0
                          ? <Trans>Send sel. verif. to iOrthotics</Trans>
                          : <Trans>Send all verified to iOrthotics</Trans>
                      }
                      disabled={
                        (selectedOrderIds.length > 0 && selectedVerifiedOrders.length === 0) ||
                        (verifiedOrdersCount.data === undefined || verifiedOrdersCount.data === 0)
                      }
                      onClick={handleSendVerified}
                    />,
                },
                {
                  key: 'footer.left.begin-validation',
                  showIf: isAdmin,
                  action:
                    <ButtonLinkV2
                      to="/validation"
                      label={<Trans>Begin validation</Trans>}
                      disabled={processedOrdersCount.data === undefined || processedOrdersCount.data === 0}
                    />,
                },
              ],
            }}
          />
        }
      >
        {
          ({ availableHeightPx }) =>
            <Box direction="row">
              <Collapsible
                direction="horizontal"
                open={showCollapse}
              >
                <Box
                  width="30vw"
                  overflow="scroll"
                  style={{
                    boxShadow: 'black 25px 0px 25px -35px',
                    zIndex: '10',
                  }}
                >
                  <SettingsPanel />
                </Box>
              </Collapsible>
              <Box direction="column">
                <OrderTable
                  heightPx={availableHeightPx}
                  isLoading={ordersQuery.isFetchingNextPage}
                  orders={rows}
                  ordersCountsByOrderStatuses={ordersCountsByStatuses.data ?? getEmptyOrdersCounts()}
                  selectedOrderIds={selectedOrderIds}
                  onSelectedOrderIdsChange={setSelectedOrderIds}
                  filteredBillingStatuses={filteredBillingStatuses}
                  onFilteredBillingStatusesChange={setFilteredBillingStatuses}
                  onFetchNextPage={() => ordersQuery.fetchNextPage()}
                  onUpdateOrderStatus={handleUpdateOrderStatus}
                />
              </Box>
            </Box>
        }
      </PageWithBanners>
      {
        showPatientModal &&
        <AddPatient
          showPatientModal={showPatientModal}
          clinicId={clinic.id}
          onClose={() => setPatientModal(false)}
        />
      }
      {
        showOrderModal &&
        <AddOrder
          clinicianName={getPersonFullName(clinician)}
          locationName={location.description}
          showModal={showOrderModal}
          clinicId={clinic.id}
          onClose={() => setShowOrderModal(false)}
        />
      }
      {
        confirmManufacturerDownload &&
        <ManufacturerBulkDownload
          selectedOrderIds={selectedOrderIds.length === 0 ? null : selectedOrderIds}
          onClose={() => setConfirmManufacturerDownload(false)}
        />
      }
      {
        promptShippingLink &&
        <ShippingLinkPrompt
          onConfirm={handleSetShipping}
          onCancel={() => setPromptShippingLink(null)}
        />
      }
      <OrderCancellationReasonPrompt
        show={promptOrderCancellation !== null}
        onConfirm={handleCancellationReason}
        onCancel={() => setPromptOrderCancellation(null)}
      />
      {
        isAdmin &&
        <UploadingToManufacturer
          status={manufacturerUploadStatus}
          onClose={() => setManufacturerUploadStatus(null)}
        />
      }
    </>
  )
}

MainPanel.route = new Route({
  getParentRoute: () => Root.route,
  path: 'orders',
  component: MainPanel,
})

export default MainPanel
