import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { GetOrdersFilters } from '../OrderFilters'

export const productFilters = {
  hybrid: 0,
  topCover: 1,
  single: 2,
} as const

export type ProductFilter = typeof productFilters[keyof typeof productFilters]

export const getProductFiltersOptions = (i18n: I18n) => [
  {
    value: productFilters.hybrid,
    label: t(i18n)`Hybrid`,
  },
  {
    value: productFilters.topCover,
    label: t(i18n)`Top cover`,
  },
  {
    value: productFilters.single,
    label: t(i18n)`Single`,
  },
]

export const getActiveProductFilters = (orderFilters: GetOrdersFilters): ProductFilter[] => {
  const activeFilters: ProductFilter[] = []

  if (orderFilters.onlyHybrid === true) activeFilters.push(productFilters.hybrid)
  if (orderFilters.onlyTopCover === true) activeFilters.push(productFilters.topCover)
  if (orderFilters.onlySingle === true) activeFilters.push(productFilters.single)

  return activeFilters
}

export const setActiveProductFilters = (
  selected: ProductFilter[],
  orderFilters: GetOrdersFilters,
): GetOrdersFilters => {
  orderFilters.onlyHybrid = false
  orderFilters.onlyTopCover = false
  orderFilters.onlySingle = false

  for (const filter of selected) {
    if (filter === productFilters.hybrid) orderFilters.onlyHybrid = true
    else if (filter === productFilters.topCover) orderFilters.onlyTopCover = true
    else orderFilters.onlySingle = true
  }

  return orderFilters
}
