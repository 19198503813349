// https://github.com/grommet/grommet/blob/master/src/js/themes/base.js#L129
// Left commented out original Grommet values since we'll need them sooner or later.
// const scale = 6
const baseSpacing = 24
const baseFontSize = baseSpacing * 0.75
// const fontScale = baseSpacing / scale

const themeColour = {
  global: {
    colors: ({
      // Overriding existing grommet colors
      brand: '#E8DF66',
      'accent-1': '#6FFFB0',
      'accent-2': '#7FFFB0',
      'accent-3': '#8FFFB0',
      'accent-4': '#9FFFB0',
      'neutral-1': '#10873D',
      'neutral-2': '#20873D',
      'neutral-3': '#30873D',
      'neutral-4': '#40873D',
      focus: '#000',
      // Setting new colors
      blue: '#00C8FF',
      green: '#17EBA0',
      teal: '#82FFF2',
      purple: '#F740FF',
      red: '#FC6161',
      orange: '#FFBC44',
      yellow: '#FFEB59',
      // you can also point to existing grommet colors
      brightGreen: 'accent-1',
      deepGreen: 'neutral-2',
    } as const),
    input: {
      font: {
        weight: 'normal',
      },
    },
    size: {
      'more-medium-than-large': '375px',
    },
    focus: {
      shadow: {
        color: 'rgba(220, 220, 220, 0.5)',
        size: '0px',
      },
    },
  },
  table: {
    footer: {
      background: {
        color: 'background-back',
      },
    },
  },
  dataTable: {
    pinned: {
      header: {
        background: {
          color: 'light-1',
          opacity: '75%',
        },
        extend: `backdrop-filter: blur(5px) contrast(120%);`,
      },
      footer: {
        background: { color: 'light-1' },
      },
    },
  },
  fileInput: {
    pad: {
      vertical: '6rem',
    },
  },
  calendar: {
    // Introducing "more medium than large"!
    // 'more-medium-than-large' is larger than medium, thus allowing the header
    // to fit entirely, without causing width changes due to long month names
    // (e.g. November, January; vs May)
    // But it still is a 'manageable' size, compared to 'large' which is ginormous!
    // 'large' is actually almost the full height of my 14-inch screen.
    // This was ridiculous. But 'medium' was too small.
    // You can see the 'large' sizes easily by going here:
    // https://v2.grommet.io/calendar?c=DwYQhgNgpgdgJmATgKAASoM4EsBeUC8ARBEgOZSFqoIAuBA3gBQxQDuqAImHYwJS8A6GgHsAkgGUA8uJqIsMUnwC+VYTHFRoAYxr4mtKL1T4AfKnpKVAehNA
    // So here is "more medium than large", allowing the Calendar to be of
    // a manageable size for normal screens while avoiding width resizes.
    'more-medium-than-large': {
      fontSize: `${baseFontSize}px`,
      lineHeight: 1.0,
      daySize: `${(baseSpacing * 13) / 7}px`,
      slideDuration: '0.55s',
    },
  },
  checkBox: {
    size: '1rem',
    toggle: {
      size: '2.4rem',
      color: 'dark-1',
    },
    color: 'dark-1',
  },
  tip: {
    content: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  icon: {
    size: {
      'more-medium-than-large': '35px',
    },
  },
} as const

export type ThemeColor = keyof typeof themeColour['global']['colors']

export { themeColour }
