import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ordersKeys } from '../fetchers/orders'
import { deleteScan, getScans, postScan, postScans, putMultipleScanMethods, putScanMethods, putScanStatus, scansKeys } from '../fetchers/scans'
import { NewScan, Scan } from '../types/Scan'
import { ScanMethod } from '../types/ScanMethod'
import { ScanStatus } from '../types/ScanStatus'

const useQueryScans = (
  clinicId: number,
  orderId: number,
  disabled?: boolean | undefined,
) => {
  return useQuery({
    queryKey: scansKeys.list(clinicId, orderId),
    queryFn: ({ signal }) => getScans(clinicId, orderId, signal),
    enabled: disabled !== true,
  })
}

const useCreateScan = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { clinicId: number; orderId: number; scan: Omit<NewScan, 'file'> }) =>
      postScan(data.clinicId, data.orderId, data.scan),
    onSuccess: (scan, data) => {
      void queryClient.invalidateQueries({ queryKey: scansKeys.list(data.clinicId, data.orderId) })
      void queryClient.invalidateQueries({ queryKey: ordersKeys.all() })
      queryClient.setQueryData(scansKeys.details(data.clinicId, data.orderId, scan.id), scan)
    },
  })

}

const useCreateScans = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { clinicId: number; orderId: number; scans: NewScan[] }) =>
      postScans(data.clinicId, data.orderId, data.scans),
    onSuccess: (scans, data) => {
      void queryClient.invalidateQueries({ queryKey: scansKeys.list(data.clinicId, data.orderId) })
      void queryClient.invalidateQueries({ queryKey: ordersKeys.all() })
      for (const scan of scans) {
        queryClient.setQueryData(scansKeys.details(data.clinicId, data.orderId, scan.id), scan)
      }
    },
  })
}

const useUpdateScanStatus = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { clinicId: number; orderId: number; scanId: number; status: ScanStatus }) =>
      putScanStatus(data.clinicId, data.scanId, data.status),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({ queryKey: scansKeys.list(data.clinicId, data.orderId) })
      queryClient.setQueryData(
        scansKeys.details(data.clinicId, data.orderId, data.scanId),
        (scan: Scan | undefined) =>
          scan
            ? ({
              ...scan,
              status: data.status,
            })
            : scan,
      )
    },
  })
}

const useUpdateScanScanMethod = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { clinicId: number; orderId: number; scanId: number; scanMethod: ScanMethod }) =>
      putScanMethods(data.clinicId, data.scanId, data.scanMethod),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({ queryKey: scansKeys.list(data.clinicId, data.orderId) })
      queryClient.setQueryData(
        scansKeys.details(data.clinicId, data.orderId, data.scanId),
        (scan: Scan | undefined) =>
          scan
            ? ({
              ...scan,
              scanMethod: data.scanMethod,
            })
            : scan,
      )
    },
  })
}

const useUpdateMultipleScanScanMethod = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { clinicId: number; orderId: number; scanIds: number[]; scanMethod: ScanMethod }) =>
      putMultipleScanMethods(data.clinicId, data.scanIds, data.scanMethod),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({ queryKey: scansKeys.list(data.clinicId, data.orderId) })
      data.scanIds.forEach(scanId => {
        queryClient.setQueryData(
          scansKeys.details(data.clinicId, data.orderId, scanId),
          (scan: Scan | undefined) =>
            scan
              ? ({
                ...scan,
                scanMethod: data.scanMethod,
              })
              : scan,
        )
      })
    },
  })
}

const useDeleteScan = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { clinicId: number; orderId: number; scanId: number }) =>
      deleteScan(data.clinicId, data.orderId, data.scanId),
    onSuccess: (_, data) => {
      queryClient.removeQueries({ queryKey: scansKeys.details(data.clinicId, data.orderId, data.scanId) })
      queryClient.removeQueries({ queryKey: scansKeys.list(data.clinicId, data.orderId) })
      void queryClient.invalidateQueries({ queryKey: ordersKeys.all() })
    },
  })
}

export {
  useQueryScans,
  useCreateScan,
  useCreateScans,
  useUpdateScanStatus,
  useUpdateScanScanMethod,
  useUpdateMultipleScanScanMethod,
  useDeleteScan,
}
