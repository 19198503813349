
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Route, useNavigate, useParams } from '@tanstack/react-router'
import { Box, Button, CheckBox, Collapsible, DateInput, Form, FormField, Heading, Spinner, Stack, TextInput } from 'grommet'
import { Lock } from 'grommet-icons'
import { useMemo, useState } from 'react'

import CollapsiblePanel from '../../components/CollapsiblePanel'
import CustomRadioButton from '../../components/CustomRadioButton'
import FormInput from '../../components/FormInput'
import FormSection from '../../components/FormSection'
import Slider from '../../components/Slider'
import { Select } from '../../components/grommet-sub'
import PastSingleDateInput from '../../components/grommet-sub/PastSingleDateInput'
import InformationPresenter from '../../components/modals/InformationPresenter'
import OrderCancellationReasonPrompt from '../../components/modals/OrderCancellationReasonPrompt'
import { getOrder, ordersKeys } from '../../fetchers/orders'
import { useLoading } from '../../hooks/loading'
import { useShowSuccessToast } from '../../hooks/toasts'
import { useQueryOrder, useUpdateOrderClinician, useUpdateOrderLocation, useUpdateOrderStatus } from '../../queries/orders'
import { useSavePatient } from '../../queries/patients'
import { useCreatePrescription, useQueryPrescription, useSavePrescription } from '../../queries/prescriptions'
import { getQueryClient } from '../../queryClient'
import { useSelector } from '../../store/store'
import { Order } from '../../types/Order'
import { isStatusBefore, orderStatuses } from '../../types/OrderStatus'
import { Patient } from '../../types/Patient'
import { Prescription, PrescriptionRecursiveKey, PrescriptionRecursiveValue, handlePostingChange, heelCupHeights, standardDiagnosticOptions, standardHeelLift, standardShoeSizeEu, standardShoeSizeUs } from '../../types/Prescription'
import { postingOptions as postingOptionValues } from '../../types/PrescriptionEnums/PostingOption'
import { PrescriptionStatus, prescriptionStatuses } from '../../types/PrescriptionEnums/PrescriptionStatus'
import { productTypes } from '../../types/PrescriptionEnums/ProductType'
import { requestedSides } from '../../types/PrescriptionEnums/RequestedSide'
import { retroPadOrientations, retroPadSizes } from '../../types/PrescriptionEnums/RetroPads'
import { shoeSizeSystems } from '../../types/PrescriptionEnums/ShoeSizeSystem'
import { footbedLength, footbedWidth, topCoverLengths, topCoverMaterials } from '../../types/PrescriptionEnums/TopCoverLength'
import { getDefaultTopCoverThickness } from '../../types/PrescriptionEnums/TopCoverThickness'
import defaultPrescription from '../../utils/DefaultPrescription'
import { fromOms, toOms, toUpdateOms } from '../../utils/PrescriptionMapper'
import { getPersonFullName } from '../../utils/person'
import MainPanel from '../home/MainPanel'

import ownStyles from './PrescriptionPanel.module.css'
import PrescriptionViewer from './PrescriptionViewer'
import { InvalidFormTip, WrapTooltipIf } from './Tooltips'
import { getFlexMidfootPerforatedOptions, getForefootWidthOptions, getGenderOptions, getKineticExtensionOptions, getLowerMedialArchOptions, getPostingCorrectionOptions, getPostingOptions, getPostingPositionOptions, getProductionOptions, getRetroPadOrientations, getRetroPadSizeOptions, getShellRigidityOptions, getSideOptions, getSideWithNoneOptions, getStiffMidfootBarOptions, getTopCoverLengthOptions, getTopCoverMaterialOptions, getTopCoverThicknessOptions } from './options'
import { isFormValid } from './validation'

type OwnProps = {
  patient: Patient,
  onLocationChange: (locationId: number) => unknown,
  onClinicianChange: (clinicianId: number) => unknown,
  onPrescriptionChange: (handler: (prescription: Prescription) => Prescription) => unknown,
  onPatientChange: (handler: (patient: Patient) => Patient) => unknown,
}

type CommonProps = {
  order: Order,
  prescription: Prescription,
}

const PrescriptionPanelImpl = (props: OwnProps & CommonProps) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const [headerData, setHeader] = useState<{ location?: string; clinician?: string; patient?: Patient }>({
    location: props.order.location?.description ?? '',
    clinician: getPersonFullName(props.order.clinician),
    patient: props.order.patient,
  })

  const locations = useSelector(state => state.userContext.availableLocations)
  const clinicians = useSelector(state => state.userContext.availableClinicians)

  const prescription = props.prescription
  const patient = props.patient

  const productionOptions = useMemo(() => getProductionOptions(i18n), [i18n])
  const genderOptions = useMemo(() => getGenderOptions(i18n), [i18n])
  const sideOptions = useMemo(() => getSideOptions(i18n), [i18n])
  const sideWithNoneOptions = useMemo(() => getSideWithNoneOptions(i18n), [i18n])
  const shellRigidityOptions = useMemo(() => getShellRigidityOptions(i18n), [i18n])
  const forefootWidthOptions = useMemo(() => getForefootWidthOptions(i18n), [i18n])
  const flexMidfootPerforatedOptions = useMemo(() => getFlexMidfootPerforatedOptions(i18n), [i18n])
  const stiffMidfootBarOptions = useMemo(() => getStiffMidfootBarOptions(i18n), [i18n])
  const lowerMedialArchOptions = useMemo(() => getLowerMedialArchOptions(i18n), [i18n])
  const postingOptions = useMemo(() => getPostingOptions(i18n), [i18n])
  const postingPositionOptions = useMemo(() => getPostingPositionOptions(i18n), [i18n])
  const postingCorrectionOptions = useMemo(() => getPostingCorrectionOptions(i18n), [i18n])
  const topCoverLengthOptions = useMemo(() => getTopCoverLengthOptions(i18n), [i18n])
  const topCoverMaterialOptions = useMemo(() => getTopCoverMaterialOptions(i18n), [i18n])
  const topCoverThicknessOptions = useMemo(() => getTopCoverThicknessOptions(i18n), [i18n])
  const kineticExtentionOptions = useMemo(() => getKineticExtensionOptions(i18n), [i18n])
  const retroPadSizeOptions = useMemo(() => getRetroPadSizeOptions(i18n), [i18n])
  const retroPadOrientationOptions = useMemo(() => getRetroPadOrientations(i18n), [i18n])

  const onChangeDate = (event: { value: string | string[] }) => {
    if (Array.isArray(event.value)) return

    const castedValue: string = event.value

    props.onPrescriptionChange(prescription => ({
      ...prescription,
      consultationDate: castedValue,
    }))
  }

  function handleValue<TKey extends PrescriptionRecursiveKey>(
    key: TKey,
  ): PrescriptionRecursiveValue<TKey> {
    for (const parentKey of Object.keys(prescription)) {
      if (parentKey === key) {
        return prescription[key as keyof Prescription] as PrescriptionRecursiveValue<TKey>
      } else {
        const k = parentKey as keyof Prescription
        const v = prescription[k] as PrescriptionRecursiveValue<TKey> | null | undefined

        if (v === null || v === undefined) continue
        if (typeof v !== 'object') continue

        for (const secondKey of Object.keys(v)) {
          if (secondKey === key) {
            return v[secondKey as keyof typeof v] as PrescriptionRecursiveValue<TKey>
          }
        }
      }
    }
    throw new Error(`Provided key (${key}) does not exists in PrescriptionRecursiveValue<TKey> `)
  }

  function handleChange<TKey extends PrescriptionRecursiveKey>(
    key: TKey,
    value: PrescriptionRecursiveValue<TKey>,
  ) {

    props.onPrescriptionChange(prescription => {
      for (const parentKey of Object.keys(prescription)) {
        if (parentKey === key) {
          return {
            ...prescription,
            [key]: value,
          }
        } else {
          const k = parentKey as keyof Prescription
          const v = prescription[k] as PrescriptionRecursiveValue<TKey> | null | undefined

          if (v === null || v === undefined) continue
          if (typeof v !== 'object') continue

          for (const secondKey of Object.keys(v)) {
            if (secondKey === key) {
              return {
                ...prescription,
                [k]: {
                  ...v,
                  [key]: value,
                },

              }
            }
          }
        }
      }
      return prescription
    })
  }

  return (
    <Box
      overflow="auto"
      width={{ width: '50vw', min: '600px' }}
      height="100vh"
      background="white"
      className={ownStyles['prescription-form-container']}
    >
      <Stack
        anchor="top-right"
      >
        <Form
          validate="change"
        >
          {/* // Patient information section */}
          <FormSection
            title="Patient"
          >
            <Box
              gap="medium"
              pad={{ right: 'medium' }}
              align="start"
              direction="row"
              fill
            >
              <Box fill>
                <FormField>
                  <Heading
                    margin={{ top: 'none', bottom: 'xsmall' }}
                    level={5}
                  >
                    Location
                  </Heading>
                  <Select
                    options={[...new Set(locations.map(location => location.description))]}
                    value={headerData.location ?? ''}
                    onChange={value => {
                      setHeader((data) => ({
                        ...data,
                        location: value,
                      }))

                      const location = locations.find(location => location.description === value)

                      if (location) {
                        props.onLocationChange(location.id)
                      }
                    }}
                  />
                </FormField>
              </Box>
              <Box fill>
                <FormField>
                  <Heading
                    margin={{ top: 'none', bottom: 'xsmall' }}
                    level={5}
                  >
                    Clinician
                  </Heading>
                  <Select
                    options={[
                      ...new Set(
                        clinicians.map((clinician) => {
                          return getPersonFullName(clinician)
                        }),
                      ),
                    ]}
                    value={headerData.clinician ?? ''}
                    onChange={value => {
                      setHeader((data) => ({
                        ...data,
                        clinician: value,
                      }))
                      const clinician = clinicians
                        .find(clinician => value === (getPersonFullName(clinician)))
                      if (clinician) {
                        props.onClinicianChange(clinician.id)
                      }
                    }}
                  />
                </FormField>
              </Box>
              <Box fill>
                <Heading
                  margin={{ top: 'none', bottom: 'xsmall' }}
                  level={5}
                >
                  <Trans>Consultation date</Trans>*
                </Heading>
                <FormField>
                  <DateInput
                    format="yyyy-mm-dd"
                    name="consultationDate"
                    required={true}
                    value={prescription.consultationDate}
                    onChange={onChangeDate}
                    dropProps={{ align: { top: 'bottom', right: 'right' } }}
                  />
                </FormField>
              </Box>
            </Box>
            <Box
              fill
              gap="1rem"
              align="start"
              direction="row"
              pad={{ right: 'medium' }}
            >
              <Box fill>
                <FormField>
                  <Heading
                    margin={{ top: 'none', bottom: 'xsmall' }}
                    level={5}
                  >
                    <Trans>First name</Trans>
                  </Heading>
                  <TextInput
                    required={true}
                    value={patient.firstName}
                    onChange={(event) => {
                      props.onPatientChange((patient) => ({
                        ...patient,
                        firstName: event.currentTarget.value,
                      }))
                    }}
                  />
                </FormField>
              </Box>
              <Box fill>
                <FormField required>
                  <Heading
                    margin={{ top: 'none', bottom: 'xsmall' }}
                    level={5}
                  >
                    <Trans>Last name</Trans>
                  </Heading>
                  <TextInput
                    required={true}
                    value={patient.lastName}
                    onChange={(event) => {
                      props.onPatientChange((patient) => ({
                        ...patient,
                        lastName: event.currentTarget.value,
                      }))
                    }}
                  />
                </FormField>
              </Box>
              <Box fill>
                <FormField required>
                  <Heading
                    margin={{ top: 'none', bottom: 'xsmall' }}
                    level={5}
                  >
                    <Trans>Patient ID</Trans>
                  </Heading>
                  <TextInput
                    value={patient.patientId?.toString() ?? ''}
                    onChange={(event) => {
                      const newValue = Number(event.currentTarget.value)
                      if (Number.isNaN(newValue)) return
                      props.onPatientChange((patient) => ({
                        ...patient,
                        patientId: event.currentTarget.value,
                      }))
                    }}
                  />
                </FormField>
              </Box>
              <Box fill>
                <PastSingleDateInput
                  wrapFormField={true}
                  label={
                    <Heading
                      margin={{ top: 'none', bottom: 'xsmall' }}
                      level={5}
                    >
                      <Trans>Birthdate</Trans>
                    </Heading>
                  }
                  date={patient.birthdate}
                  onChange={date => {
                    props.onPatientChange(patient => ({
                      ...patient,
                      birthdate: date,
                    }))
                  }}
                />
              </Box>
            </Box>

            {/* // Diagnostic section */}
            <Box
              gap="1rem"
              align="center"
              direction="row"
              fill
              pad={{ right: 'medium' }}
            >
              <Box
                width={prescription.section.sideDiagnostic !== requestedSides.both ? '33%' : '50%'}
                fill
              >
                <FormField>
                  <Heading
                    margin={{ top: 'none', bottom: 'xsmall' }}
                    level={5}
                  >
                    <Trans>Diagnostic</Trans>
                  </Heading>
                  <Select
                    options={standardDiagnosticOptions}
                    value={prescription.section.diagnostic}
                    onChange={value => {
                      handleChange('diagnostic', value)
                    }}
                  />
                </FormField>
                {
                  prescription.section.diagnostic === 'Specify:' &&
                  <TextInput
                    name="specifyDiagnostic"
                    placeholder="Diagnostic"
                    value={prescription.section.specifyDiagnostic}
                    onChange={event => handleChange('specifyDiagnostic', event.currentTarget.value)}
                  />
                }
              </Box>
              {
                prescription.section.diagnostic !== '' &&
                <Box
                  direction="row"
                  fill
                >
                  <CustomRadioButton
                    hideLine={true}
                    options={sideOptions}
                    value={prescription.section.sideDiagnostic}
                    fill
                    name="sideDiagnostic"
                    onChange={handleChange}
                  />
                </Box>
              }
              {
                (
                  prescription.section.sideDiagnostic === requestedSides.left ||
                  prescription.section.sideDiagnostic === requestedSides.right
                ) &&
                <Box
                  direction="column"
                  fill
                >
                  <FormField>
                    <Heading
                      margin={{ top: 'none', bottom: 'xsmall' }}
                      level={5}
                    >
                      Diagnostic {(prescription.section.sideDiagnostic === requestedSides.left
                        ? 'Right'
                        : 'Left') + ' Side'}
                    </Heading>
                    <Select
                      options={standardDiagnosticOptions}
                      value={prescription.section.diagnosticOtherSide}
                      onChange={value => {
                        handleChange('diagnosticOtherSide', value)
                      }}
                    />
                  </FormField>
                  {prescription.section.diagnosticOtherSide ===
                    'Specify:' &&
                    (
                      <TextInput
                        name="specifyDiagnosticOtherSide"
                        placeholder="Diagnostic"
                        value={prescription.section.specifyDiagnosticOtherSide}
                        onChange={event => handleChange('specifyDiagnosticOtherSide', event.currentTarget.value)}
                      />
                    )}
                </Box>
              }
            </Box>
          </FormSection>
          {/* // Order section */}
          <FormSection
            title="Product"
          >
            <Box
              direction="row"
              gap="0.5rem"
              pad={{ right: 'medium' }}
            >
              <Box
                fill
                direction="column"
              >
                <Box
                  direction="row"
                >
                  <FormField
                    fill
                  >
                    <Heading
                      margin={{
                        top: 'none',
                        bottom: 'xsmall',
                      }}
                      level={5}
                    >
                      <Trans>Type</Trans>
                    </Heading>
                    <Select
                      options={['shell']} //add hybrid later...
                      value={'shell'}
                      onChange={() => handleChange('productType', 'shell')}
                    />
                  </FormField>
                </Box>
              </Box>
              <Box
                fill
                direction="column"
              >
                <Box
                  gap="0.5rem"
                  direction="row"
                >
                  <Heading
                    level={5}
                    margin="none"
                  >
                    <Trans>Produce Only One-Side</Trans>
                  </Heading>
                  <CheckBox
                    checked={prescription.section.production !== requestedSides.both}
                    onChange={() => {
                      props.onPrescriptionChange(prescription => ({
                        ...prescription,
                        section: {
                          ...prescription.section,
                          production: prescription.section.production === requestedSides.both
                            ? requestedSides.left
                            : requestedSides.both,
                        },
                      }))
                    }}
                  />
                </Box>
                <Box>
                  {
                    prescription.section.production !== requestedSides.both &&
                    <CustomRadioButton
                      name="production"
                      hideLine={true}
                      value={prescription.section.production}
                      options={productionOptions}
                      onChange={(_, production) => {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          section: {
                            ...prescription.section,
                            production,
                          },
                        }))
                      }}
                    />
                  }
                </Box>
              </Box>
              <Box
                direction="row"
                gap="1rem"
                align="center"
                fill
              >
                <Heading
                  margin="none"
                  level={5}
                >
                  <Trans>Child Growth</Trans>
                </Heading>
                <CheckBox
                  className={ownStyles['child-growth-checkbox']}
                  name="childGrowth"
                  checked={prescription.section.childGrowth}
                  onChange={event => handleChange('childGrowth', event.currentTarget.checked)}
                />
              </Box>
            </Box>

            {/* // Order Specification section */}
            <Box
              gap="1rem"
              direction="row"
              pad={{ right: 'medium' }}
              fill
            >
              <FormField
                fill
              >
                {/* {Remove when hybrid is selected} */}
                <Heading
                  margin={{
                    top: 'none',
                    bottom: 'xsmall',
                  }}
                  level={5}
                >
                  <Trans>Shell Rigidity</Trans>
                </Heading>
                <Select
                  name="shellRigidity"
                  options={shellRigidityOptions}
                  value={prescription.rxOrthotic.shellRigidity}
                  onChange={value => handleChange('shellRigidity', value)}
                />
              </FormField>
              <FormField
                fill
              >
                <Heading
                  margin={{
                    top: 'none',
                    bottom: 'xsmall',
                  }}
                  level={5}
                >
                  <Trans>Heel Cup Height</Trans>
                </Heading>
                <Select
                  options={Object.keys(heelCupHeights) as Array<keyof typeof heelCupHeights>}
                  value={prescription.rxOrthotic.heelCupHeight}
                  onChange={value => handleChange('heelCupHeight', value)}
                />
              </FormField>
              <FormField
                fill
              >
                <Heading
                  margin={{
                    top: 'none',
                    bottom: 'xsmall',
                  }}
                  level={5}
                >
                  <Trans>Forefoot Width</Trans>
                </Heading>
                <Select
                  name="forefootWidth"
                  options={forefootWidthOptions}
                  value={prescription.rxOrthotic.forefootWidth}
                  onChange={value => handleChange('forefootWidth', value)}
                />
              </FormField>
            </Box>
          </FormSection>
          {/* // Order Modification section */}
          <FormSection
            title="Modifications"
            isChecked={prescription.rxOrthotic.shellModificationOptions}
            onCheck={(checked) => {
              handleChange('shellModificationOptions', checked)
              if (!checked) {
                props.onPrescriptionChange(prescription => {
                  return {
                    ...prescription,
                    shellModification: {
                      ...defaultPrescription.shellModification,
                      topCoverThickness: getDefaultTopCoverThickness(prescription.rxOrthotic.topCoverLength),
                    },
                    rxOrthotic: {
                      ...prescription.rxOrthotic,
                      flexMidfootOtherSide: defaultPrescription.rxOrthotic.flexMidfootOtherSide,
                      flexMidfootPerforated: defaultPrescription.rxOrthotic.flexMidfootPerforated,
                      sideFlexMidfootPerforated: defaultPrescription.rxOrthotic.sideFlexMidfootPerforated,
                      stiffMidfootBar: defaultPrescription.rxOrthotic.stiffMidfootBar,
                      stiffMidfootOtherSide: defaultPrescription.rxOrthotic.stiffMidfootOtherSide,
                      sideStiffMidfootBar: defaultPrescription.rxOrthotic.sideStiffMidfootBar,
                      lowerMedialArch: defaultPrescription.rxOrthotic.lowerMedialArch,
                      lowerMedialArchOtherSide: defaultPrescription.rxOrthotic.lowerMedialArchOtherSide,
                      sideLowerMedialArch: defaultPrescription.rxOrthotic.sideLowerMedialArch,
                      heelLift: defaultPrescription.rxOrthotic.heelLift,
                      heelLiftOtherSide: defaultPrescription.rxOrthotic.heelLiftOtherSide,
                      sideHeelLift: defaultPrescription.rxOrthotic.sideHeelLift,
                    },
                  }
                })
              }
            }}
          >
            <Box
              direction="column"
              gap="medium"
              fill
            >
              {/* {Remove when hybrid is selected} */}
              <FormSection
                title="Flex Midfoot (Perforated)"
                level={4}
                isChecked={prescription.rxOrthotic.sideFlexMidfootPerforated !== 'none'}
                onCheck={(checked) => {
                  handleChange('sideFlexMidfootPerforated', checked ? requestedSides.both : 'none')
                }}
                isBilateral={prescription.rxOrthotic.sideFlexMidfootPerforated === requestedSides.both}
                sideKey="sideFlexMidfootPerforated"
                setValue={(key, value) => {
                  props.onPrescriptionChange(prescription => ({
                    ...prescription,
                    rxOrthotic: {
                      ...prescription.rxOrthotic,
                      [key]: value,
                      flexMidfootOtherSide: prescription.rxOrthotic.flexMidfootPerforated,
                    },
                  }))
                }}
                getValue={handleValue}
              >
                <Box
                  direction="row"
                  fill
                  gap="0.5rem"
                  align="end"
                >
                  <CustomRadioButton
                    label="Left"
                    name="flexMidfootPerforated"
                    value={prescription.rxOrthotic.flexMidfootPerforated}
                    options={flexMidfootPerforatedOptions}
                    onChange={(_, value) => {
                      if (prescription.rxOrthotic.sideFlexMidfootPerforated === requestedSides.both) {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          rxOrthotic: {
                            ...prescription.rxOrthotic,
                            flexMidfootOtherSide: value,
                            flexMidfootPerforated: value,
                          },
                        }))
                      } else {
                        handleChange('flexMidfootPerforated', value)
                      }
                    }}
                    fill
                    vertical
                    hideLine
                  />
                  <CustomRadioButton
                    label="Right"
                    name="flexMidfootOtherSide"
                    value={prescription.rxOrthotic.flexMidfootOtherSide}
                    options={flexMidfootPerforatedOptions}
                    onChange={(_, value) => {
                      if (prescription.rxOrthotic.sideFlexMidfootPerforated === requestedSides.both) {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          rxOrthotic: {
                            ...prescription.rxOrthotic,
                            flexMidfootOtherSide: value,
                            flexMidfootPerforated: value,
                          },
                        }))
                      } else {
                        handleChange('flexMidfootOtherSide', value)
                      }
                    }}
                    fill
                    vertical
                    hideLine
                  />
                </Box>
              </FormSection>
              {/* {Remove on hybrid selection} */}
              <FormSection
                title="Stiff Midfoot (Bar)"
                level={4}
                isChecked={prescription.rxOrthotic.sideStiffMidfootBar !== 'none'}
                onCheck={(checked) => {
                  handleChange('sideStiffMidfootBar', checked ? requestedSides.both : 'none')
                }}
                isBilateral={prescription.rxOrthotic.sideStiffMidfootBar === requestedSides.both}
                sideKey="sideStiffMidfootBar"
                setValue={(key, value) => {
                  props.onPrescriptionChange(prescription => ({
                    ...prescription,
                    rxOrthotic: {
                      ...prescription.rxOrthotic,
                      [key]: value,
                      stiffMidfootOtherSide: prescription.rxOrthotic.stiffMidfootBar,
                    },
                  }))
                }}
                getValue={handleValue}
              >
                <Box
                  direction="row"
                  fill
                  gap="0.5rem"
                  align="end"
                >
                  <CustomRadioButton
                    label="Left"
                    name="stiffMidfootBar"
                    value={prescription.rxOrthotic.stiffMidfootBar}
                    options={stiffMidfootBarOptions}
                    onChange={(_, value) => {
                      if (prescription.rxOrthotic.sideStiffMidfootBar === requestedSides.both) {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          rxOrthotic: {
                            ...prescription.rxOrthotic,
                            stiffMidfootBar: value,
                            stiffMidfootOtherSide: value,
                          },
                        }))
                      } else {
                        handleChange('stiffMidfootBar', value)
                      }
                    }}
                    fill
                    vertical
                    hideLine
                  />
                  <CustomRadioButton
                    label="Right"
                    name="stiffMidfootOtherSide"
                    value={prescription.rxOrthotic.stiffMidfootOtherSide}
                    options={stiffMidfootBarOptions}
                    onChange={(_, value) => {
                      if (prescription.rxOrthotic.sideStiffMidfootBar === requestedSides.both) {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          rxOrthotic: {
                            ...prescription.rxOrthotic,
                            stiffMidfootBar: value,
                            stiffMidfootOtherSide: value,
                          },
                        }))
                      } else {
                        handleChange('stiffMidfootOtherSide', value)
                      }
                    }}
                    fill
                    vertical
                    hideLine
                  />
                </Box>
              </FormSection>

              <FormSection
                title="Lower Medial Arch"
                level={4}
                isChecked={prescription.rxOrthotic.sideLowerMedialArch !== 'none'}
                onCheck={(checked) => {
                  handleChange('sideLowerMedialArch', checked ? requestedSides.both : 'none')
                }}
                isBilateral={prescription.rxOrthotic.sideLowerMedialArch === requestedSides.both}
                sideKey="sideLowerMedialArch"
                setValue={(key, value) => {
                  props.onPrescriptionChange(prescription => ({
                    ...prescription,
                    rxOrthotic: {
                      ...prescription.rxOrthotic,
                      [key]: value,
                      lowerMedialArchOtherSide: prescription.rxOrthotic.lowerMedialArch,
                    },
                  }))
                }}
                getValue={handleValue}
              >
                <Box
                  direction="row"
                  fill
                  gap="0.5rem"
                  align="end"
                >
                  <CustomRadioButton
                    label="Left"
                    name="lowerMedialArch"
                    value={prescription.rxOrthotic.lowerMedialArch}
                    options={lowerMedialArchOptions}
                    onChange={(_, value) => {
                      if (prescription.rxOrthotic.sideLowerMedialArch === requestedSides.both) {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          rxOrthotic: {
                            ...prescription.rxOrthotic,
                            lowerMedialArch: value,
                            lowerMedialArchOtherSide: value,
                          },
                        }))
                      } else {
                        handleChange('lowerMedialArch', value)
                      }
                    }}
                    fill
                    vertical
                    hideLine
                  />
                  <CustomRadioButton
                    label="Right"
                    name="lowerMedialArchOtherSide"
                    value={prescription.rxOrthotic.lowerMedialArchOtherSide}
                    options={lowerMedialArchOptions}
                    onChange={(_, value) => {
                      if (prescription.rxOrthotic.sideLowerMedialArch === requestedSides.both) {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          rxOrthotic: {
                            ...prescription.rxOrthotic,
                            lowerMedialArch: value,
                            lowerMedialArchOtherSide: value,
                          },
                        }))
                      } else {
                        handleChange('lowerMedialArchOtherSide', value)
                      }
                    }}
                    fill
                    vertical
                    hideLine
                  />
                </Box>
              </FormSection>
              {/* {Remove on hybrid selection} */}
              <FormSection
                title="Heel lift"
                level={4}
                isChecked={prescription.rxOrthotic.sideHeelLift !== 'none'}
                onCheck={(checked) => {
                  handleChange('sideHeelLift', checked ? requestedSides.both : 'none')
                }}
                isBilateral={prescription.rxOrthotic.sideHeelLift === requestedSides.both}
                sideKey="sideHeelLift"
                setValue={(key, value) => {
                  props.onPrescriptionChange(prescription => ({
                    ...prescription,
                    rxOrthotic: {
                      ...prescription.rxOrthotic,
                      [key]: value,
                      heelLiftOtherSide: prescription.rxOrthotic.heelLift,
                    },
                  }))
                }}
                getValue={handleValue}
              >
                <Box
                  direction="row"
                  fill
                  gap="0.5rem"
                  align="end"
                >
                  <Slider
                    title={'Left'}
                    options={standardHeelLift}
                    selector={prescription.rxOrthotic.heelLift}
                    onChange={value => {
                      if (prescription.rxOrthotic.sideHeelLift === requestedSides.both) {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          rxOrthotic: {
                            ...prescription.rxOrthotic,
                            heelLiftOtherSide: value,
                            heelLift: value,
                          },
                        }))
                      } else {
                        handleChange('heelLift', value)
                      }
                    }}
                  />
                  <Slider
                    title={'Right'}
                    options={standardHeelLift}
                    selector={prescription.rxOrthotic.heelLiftOtherSide}
                    onChange={value => {
                      if (prescription.rxOrthotic.sideHeelLift === requestedSides.both) {
                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          rxOrthotic: {
                            ...prescription.rxOrthotic,
                            heelLiftOtherSide: value,
                            heelLift: value,
                          },
                        }))
                      } else {
                        handleChange('heelLiftOtherSide', value)
                      }
                    }}
                  />
                </Box>
              </FormSection>

              <FormSection
                title={prescription.type === productTypes.shell ? 'Kinetic Extension' : 'Isolation'}
                level={4}
                isChecked={prescription.shellModification.kineticExtension}
                onCheck={(check) => {
                  handleChange('kineticExtension', check)
                }}
                isBilateral={prescription.shellModification.sideKineticExtension === requestedSides.both}
                sideKey="sideKineticExtension"
                setValue={(key, value) => {
                  props.onPrescriptionChange(prescription => ({
                    ...prescription,
                    shellModification: {
                      ...prescription.shellModification,
                      [key]: value,
                      kineticExtensionRight: prescription.shellModification.kineticExtensionLeft,
                    },
                  }))
                }}
                getValue={handleValue}
              >
                <Box
                  direction="row"
                  margin="none"
                  gap="1rem"
                  pad="none"
                  width="100%"
                >
                  <Box width="50%">
                    <CustomRadioButton
                      label={<Trans>Left</Trans>}
                      key="kineticExtensionLeft"
                      name="kineticExtensionLeft"
                      options={kineticExtentionOptions}
                      vertical
                      value={prescription.shellModification.kineticExtensionLeft}
                      onChange={(key, values) => {
                        let newArray = prescription.shellModification.kineticExtensionLeft
                        if (newArray.includes(values)) {
                          newArray = newArray.filter(value => value !== values)
                        } else {
                          newArray.push(values)
                        }
                        if (prescription.shellModification.sideKineticExtension === requestedSides.both) {
                          props.onPrescriptionChange(prescription => ({
                            ...prescription,
                            shellModification: {
                              ...prescription.shellModification,
                              kineticExtensionLeft: newArray,
                              kineticExtensionRight: newArray,
                            },
                          }))
                        } else {
                          handleChange(key, newArray)
                        }
                      }}
                      checkBox
                      hideLine
                    />
                  </Box>
                  <Box width="50%">
                    <CustomRadioButton
                      label={<Trans>Right</Trans>}
                      name="kineticExtensionRight"
                      key="kineticExtensionRight"
                      options={kineticExtentionOptions}
                      vertical
                      value={prescription.shellModification.kineticExtensionRight}
                      onChange={(key, values) => {
                        let newArray = prescription.shellModification.kineticExtensionRight
                        if (newArray.includes(values)) {
                          newArray = newArray.filter(value => value !== values)
                        } else {
                          newArray.push(values)
                        }
                        if (prescription.shellModification.sideKineticExtension === requestedSides.both) {
                          props.onPrescriptionChange(prescription => ({
                            ...prescription,
                            shellModification: {
                              ...prescription.shellModification,
                              kineticExtensionLeft: newArray,
                              kineticExtensionRight: newArray,
                            },
                          }))
                        } else {
                          handleChange(key, newArray)
                        }
                      }}
                      checkBox
                      hideLine
                    />
                  </Box>
                </Box>
              </FormSection>
              <FormSection
                title="Retro Pad"
                level={4}
                isChecked={prescription.shellModification.retroPad}
                onCheck={() => {
                  handleChange('retroPad', !prescription.shellModification.retroPad)
                }}
                isBilateral={
                  prescription.shellModification.sideRetroPad === requestedSides.both ||
                  prescription.shellModification.sideRetroPad === 'none'
                }
                sideKey="sideRetroPad"
                setValue={(key, value) => {
                  props.onPrescriptionChange(prescription => ({
                    ...prescription,
                    shellModification: {
                      ...prescription.shellModification,
                      [key]: value,
                      retroPadSizeRight: prescription.shellModification.retroPadSizeLeft,
                      retroPadOrientationRight: prescription.shellModification.retroPadOrientationLeft,
                    },
                  }))
                }}
                getValue={handleValue}
              >
                <Box
                  direction="row"
                  width="100%"
                  fill
                >
                  <FormSection
                    title="Left"
                    level={4}
                    fill
                  >
                    <Box
                      direction="row"
                      pad="none"
                      margin="none"
                      fill
                    >
                      <CustomRadioButton
                        label={<Trans>Size</Trans>}
                        name="retroPadSizeLeft"
                        options={retroPadSizeOptions}
                        value={prescription.shellModification.retroPadSizeLeft}
                        onChange={(key, value) => {
                          const isBilateral = prescription.shellModification.sideRetroPad === requestedSides.both ||
                            prescription.shellModification.sideRetroPad === 'none'
                          props.onPrescriptionChange(prescription => ({
                            ...prescription,
                            shellModification: {
                              ...prescription.shellModification,
                              [key]: value,
                              retroPadSizeRight: isBilateral ? value : prescription.shellModification.retroPadSizeRight,
                              retroPadOrientationLeft: value === retroPadSizes.none
                                ? retroPadOrientations.none
                                : prescription.shellModification.retroPadOrientationLeft,
                              retroPadOrientationRight: value === retroPadSizes.none && isBilateral
                                ? retroPadOrientations.none
                                : prescription.shellModification.retroPadOrientationRight,
                            },
                          }))
                        }}
                        vertical
                        fill
                        hideLine
                      />
                      <CustomRadioButton
                        label={<Trans>Orientation</Trans>}
                        name="retroPadOrientationLeft"
                        options={retroPadOrientationOptions}
                        value={prescription.shellModification.retroPadOrientationLeft}
                        onChange={(key, value) => {
                          const isBilateral = prescription.shellModification.sideRetroPad === requestedSides.both ||
                            prescription.shellModification.sideRetroPad === 'none'
                          props.onPrescriptionChange(prescription => ({
                            ...prescription,
                            shellModification: {
                              ...prescription.shellModification,
                              [key]: value,
                              retroPadOrientationRight: isBilateral
                                ? value : prescription.shellModification.retroPadOrientationRight,
                            },
                          }))
                        }}
                        disabled={prescription.shellModification.retroPadSizeLeft === retroPadSizes.none}
                        vertical
                        fill
                        hideLine
                      />
                    </Box>
                  </FormSection>
                  <FormSection
                    title="Right"
                    level={4}
                    fill
                  >
                    <Box
                      fill
                      direction="row"
                      pad="none"
                      margin="none"
                    >
                      <Box
                        fill
                        pad="none"
                        margin="none"
                      >
                        <CustomRadioButton
                          label={<Trans>Size</Trans>}
                          name="retroPadSizeRight"
                          options={retroPadSizeOptions}
                          value={prescription.shellModification.retroPadSizeRight}
                          onChange={(key, value) => {
                            const isBilateral = prescription.shellModification.sideRetroPad === requestedSides.both ||
                              prescription.shellModification.sideRetroPad === 'none'
                            props.onPrescriptionChange(prescription => ({
                              ...prescription,
                              shellModification: {
                                ...prescription.shellModification,
                                [key]: value,
                                retroPadSizeLeft: isBilateral ? value : prescription.shellModification.retroPadSizeLeft,
                                retroPadOrientationRight: value === retroPadSizes.none
                                  ? retroPadOrientations.none
                                  : prescription.shellModification.retroPadOrientationRight,
                                retroPadOrientationLeft: value === retroPadSizes.none && isBilateral
                                  ? retroPadOrientations.none
                                  : prescription.shellModification.retroPadOrientationLeft,
                              },
                            }))
                          }}
                          vertical
                          fill
                          hideLine
                        />
                      </Box>
                      <Box
                        fill
                        pad="none"
                        margin="none"
                      >
                        <CustomRadioButton
                          label={<Trans>Orientation</Trans>}
                          name="retroPadOrientationRight"
                          options={retroPadOrientationOptions}
                          value={prescription.shellModification.retroPadOrientationRight}
                          onChange={(key, value) => {
                            const isBilateral = prescription.shellModification.sideRetroPad === requestedSides.both ||
                              prescription.shellModification.sideRetroPad === 'none'
                            props.onPrescriptionChange(prescription => ({
                              ...prescription,
                              shellModification: {
                                ...prescription.shellModification,
                                [key]: value,
                                retroPadOrientationLeft: isBilateral
                                  ? value : prescription.shellModification.retroPadOrientationLeft,
                              },
                            }))
                          }}
                          disabled={prescription.shellModification.retroPadSizeRight === retroPadSizes.none}
                          vertical
                          fill
                          hideLine
                        />
                      </Box>
                    </Box>
                  </FormSection>
                </Box>
              </FormSection>
              <Box
                direction="row"
                margin="none"
                pad="none"
                gap="1rem"
                width="100%"
                fill
              >
                <CustomRadioButton
                  label="Fascia Groove"
                  name="fasciaGroove"
                  value={prescription.shellModification.fasciaGroove}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
                {/* {Remove this option with hybride} */}
                <CustomRadioButton
                  label="Heel Hole"
                  name="heelHole"
                  value={prescription.shellModification.heelHole}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
                <CustomRadioButton
                  label="Medial Heel Skive"
                  name="medialHeelSkive"
                  value={prescription.shellModification.medialHeelSkive}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
              </Box>
              <Box
                direction="row"
                margin="none"
                gap="1rem"
                pad="none"
                width="100%"
                fill
              >
                {/* {Remove this option with hybride} */}
                <CustomRadioButton
                  label={<Trans>Hallux Extension</Trans>}
                  name="halluxExtension"
                  value={prescription.shellModification.halluxExtension}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
                {/* {Remove this option with hybride} */}
                <CustomRadioButton
                  label="Gait Plate"
                  name="gaitPlate"
                  value={prescription.shellModification.gaitPlate}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
                {/* {Remove this option with hybride} */}
                <CustomRadioButton
                  label="First Ray Cut Out"
                  name="firstRayCutOut"
                  value={prescription.shellModification.firstRayCutOut}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
              </Box>
              <Box
                direction="row"
                margin="none"
                gap="1rem"
                pad="none"
                width="100%"
                fill
              >
                <CustomRadioButton
                  label="Medial Flange"
                  name="medialFlange"
                  value={prescription.shellModification.medialFlange}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
                <CustomRadioButton
                  label="Lateral Flange"
                  name="lateralFlange"
                  value={prescription.shellModification.lateralFlange}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
                <CustomRadioButton
                  label="Heel Fork"
                  name="heelFork"
                  value={prescription.shellModification.heelFork}
                  options={sideWithNoneOptions}
                  onChange={handleChange}
                  fill
                  hideLine
                />
              </Box>
              <Box
                direction="row"
                gap="1rem"
                pad="none"
                width="100%"
              >
                <CustomRadioButton
                  options={topCoverThicknessOptions}
                  label={<Trans>Scan Expand - Top cover Thickness</Trans>}
                  name="topCoverThickness"
                  value={prescription.shellModification.topCoverThickness}
                  onChange={(key, value) => {
                    handleChange(key, value)
                  }}
                  fill
                  hideLine
                />
              </Box>
            </Box>
          </FormSection>
          {/* // Posting section */}
          {/* {Remove this section with hybrid} */}
          <FormSection
            title="Posting"
            isChecked={prescription.rxOrthotic.postingOptions}
            onCheck={(checked) => {
              handleChange('postingOptions', checked)
              if (!checked) {
                props.onPrescriptionChange(prescription => {
                  return {
                    ...prescription,
                    posting: defaultPrescription.posting,
                  }
                })
              }
            }}
            isBilateral={
              prescription.posting.sidePosting === 'none' ||
              prescription.posting.sidePosting === requestedSides.both
            }
            sideKey="sidePosting"
            setValue={(key, value) => {
              props.onPrescriptionChange(prescription => ({
                ...prescription,
                posting: {
                  ...prescription.posting,
                  [key]: value,
                  rightPostingCorrection: prescription.posting.leftPostingCorrection,
                  rightPostingOption: prescription.posting.leftPostingOption,
                  rightPostingPosition: prescription.posting.leftPostingPosition,
                },
              }))
            }}
            getValue={handleValue}
          >
            <Box
              direction="row"
              pad={{ right: 'small', vertical: 'none' }}
              margin={{ right: 'small', vertical: 'none' }}
              gap="small"
            >
              <Box
                direction="column"
                fill
              >
                <Heading
                  margin={{ top: 'xsmall', bottom: 'xsmall' }}
                  level={4}
                >
                  <Trans>
                    Left
                  </Trans>
                </Heading>
                <Box
                  direction="row"
                  gap="small"
                  overflow={'auto'}
                >
                  <Box
                    width={prescription.posting.leftPostingOption !== postingOptionValues.none ? '50%' : '100%'}
                  >
                    <CustomRadioButton
                      label="Options"
                      name="leftPostingOption"
                      vertical
                      value={prescription.posting.leftPostingOption}
                      onChange={(key, value) => {
                        const isBilateral = prescription.posting.sidePosting === 'none' ||
                          prescription.posting.sidePosting === requestedSides.both

                        const newPosting = handlePostingChange(value,
                          prescription.posting.leftPostingPosition,
                          prescription.posting.leftPostingCorrection)

                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          posting: {
                            ...prescription.posting,
                            [key]: value,
                            leftPostingPosition: newPosting.position,
                            leftPostingCorrection: newPosting.correction,
                            rightPostingOption: isBilateral
                              ? value : prescription.posting.rightPostingOption,
                            rightPostingPosition: isBilateral
                              ? newPosting.position : prescription.posting.rightPostingPosition,
                            rightPostingCorrection: isBilateral
                              ? newPosting.correction : prescription.posting.rightPostingCorrection,
                          },
                        }))
                      }}
                      options={postingOptions}

                    />
                  </Box>
                  <Box
                    width={prescription.posting.leftPostingOption !== postingOptionValues.none ? '50%' : '0%'}
                  >
                    <Collapsible
                      open={prescription.posting.leftPostingOption !== postingOptionValues.none}
                      direction="horizontal"
                    >
                      <CustomRadioButton
                        label="Position"
                        name="leftPostingPosition"
                        vertical
                        value={prescription.posting.leftPostingPosition}
                        onChange={(key, value) => {
                          const isBilateral = prescription.posting.sidePosting === 'none' ||
                            prescription.posting.sidePosting === requestedSides.both
                          props.onPrescriptionChange(prescription => ({
                            ...prescription,
                            posting: {
                              ...prescription.posting,
                              [key]: value,
                              rightPostingPosition: isBilateral
                                ? value : prescription.posting.rightPostingPosition,
                            },
                          }))
                        }}
                        options={postingPositionOptions}
                      />
                    </Collapsible>
                  </Box>
                  <Box
                    width={prescription.posting.leftPostingOption !== postingOptionValues.none &&
                      prescription.posting.leftPostingOption !== postingOptionValues.neutral
                      ? '33%' : '0%'}
                  >
                    <Collapsible
                      open={
                        prescription.posting.leftPostingOption !== postingOptionValues.none &&
                        prescription.posting.leftPostingOption !== postingOptionValues.neutral
                      }
                      direction="horizontal"
                    >
                      <CustomRadioButton
                        label="Correction"
                        name="leftPostingCorrection"
                        options={postingCorrectionOptions}
                        value={prescription.posting.leftPostingCorrection}
                        onChange={(key, value) => {
                          const isBilateral = prescription.posting.sidePosting === 'none' ||
                            prescription.posting.sidePosting === requestedSides.both
                          props.onPrescriptionChange(prescription => ({
                            ...prescription,
                            posting: {
                              ...prescription.posting,
                              [key]: value,
                              rightPostingCorrection: isBilateral
                                ? value : prescription.posting.rightPostingCorrection,
                            },
                          }))
                        }}
                        vertical
                      />
                    </Collapsible>
                  </Box>
                </Box>
              </Box>
              <Box
                direction="column"
                fill
              >
                <Heading
                  margin={{ top: 'xsmall', bottom: 'xsmall' }}
                  level={4}
                >
                  <Trans>Right</Trans>
                </Heading>
                <Box
                  direction="row"
                  gap="small"
                  overflow={'auto'}
                >
                  <Box
                    width={prescription.posting.rightPostingOption !== postingOptionValues.none ? '50%' : '100%'}
                  >
                    <CustomRadioButton
                      label="Options"
                      name="rightPostingOption"
                      vertical
                      value={prescription.posting.rightPostingOption}
                      onChange={(key, value) => {
                        const isBilateral = prescription.posting.sidePosting === 'none' ||
                          prescription.posting.sidePosting === requestedSides.both

                        const newPosting = handlePostingChange(value,
                          prescription.posting.rightPostingPosition,
                          prescription.posting.rightPostingCorrection)

                        props.onPrescriptionChange(prescription => ({
                          ...prescription,
                          posting: {
                            ...prescription.posting,
                            [key]: value,
                            rightPostingPosition: newPosting.position,
                            rightPostingCorrection: newPosting.correction,
                            leftPostingOption: isBilateral
                              ? value : prescription.posting.leftPostingOption,
                            leftPostingPosition: isBilateral
                              ? newPosting.position : prescription.posting.leftPostingPosition,
                            leftPostingCorrection: isBilateral
                              ? newPosting.correction : prescription.posting.leftPostingCorrection,
                          },
                        }))
                      }}
                      options={postingOptions}
                    />
                  </Box>
                  <Box
                    width={prescription.posting.rightPostingOption !== postingOptionValues.none ? '50%' : '0%'}
                  >
                    <Collapsible
                      open={prescription.posting.rightPostingOption !== postingOptionValues.none}
                      direction="horizontal"
                    >
                      <CustomRadioButton
                        label="Position"
                        name="rightPostingPosition"
                        vertical
                        value={prescription.posting.rightPostingPosition}
                        onChange={(key, value) => {
                          const isBilateral = prescription.posting.sidePosting === 'none' ||
                            prescription.posting.sidePosting === requestedSides.both
                          props.onPrescriptionChange(prescription => ({
                            ...prescription,
                            posting: {
                              ...prescription.posting,
                              [key]: value,
                              leftPostingPosition: isBilateral
                                ? value : prescription.posting.leftPostingPosition,
                            },
                          }))
                        }}
                        options={postingPositionOptions}
                      />
                    </Collapsible>
                  </Box>
                  <Box
                    width={prescription.posting.rightPostingOption !== postingOptionValues.neutral &&
                      prescription.posting.rightPostingOption !== postingOptionValues.none ? '33%' : '0%'}
                  >
                    <Collapsible
                      open={
                        prescription.posting.rightPostingOption !== postingOptionValues.neutral &&
                        prescription.posting.rightPostingOption !== postingOptionValues.none
                      }
                      direction="horizontal"
                    >
                      <CustomRadioButton
                        label="Correction"
                        name="rightPostingCorrection"
                        options={postingCorrectionOptions}
                        value={prescription.posting.rightPostingCorrection}
                        onChange={(key, value) => {
                          const isBilateral = prescription.posting.sidePosting === 'none' ||
                            prescription.posting.sidePosting === requestedSides.both
                          props.onPrescriptionChange(prescription => ({
                            ...prescription,
                            posting: {
                              ...prescription.posting,
                              [key]: value,
                              leftPostingCorrection: isBilateral
                                ? value : prescription.posting.leftPostingCorrection,
                            },
                          }))
                        }}
                        vertical
                      />
                    </Collapsible>
                  </Box>
                </Box>
              </Box>
            </Box>
          </FormSection>
          <FormSection
            title="Top Cover"
            isChecked={prescription.rxOrthotic.topCoverActive}
            onCheck={(checked) => {
              props.onPrescriptionChange(prescription => ({
                ...prescription,
                rxOrthotic: {
                  ...prescription.rxOrthotic,
                  topCoverActive: checked,
                  topCoverLength: prescription.rxOrthotic.topCoverLength === topCoverLengths.none
                    ? topCoverLengths.toes : prescription.rxOrthotic.topCoverLength,
                  topCoverMaterial: prescription.rxOrthotic.topCoverMaterial === topCoverMaterials.none
                    ? topCoverMaterials.active : prescription.rxOrthotic.topCoverMaterial,
                },
              }))
            }}
            helper={
              <InformationPresenter
                name="TopCover-Information"
                url={`https://www.canva.com/design/DAFwZrUU6I0/d92SdvV4Dt1LgaTL06-Jrg/view?embed`}
              />
            }
          >
            <Box
              direction="row"
              width="100%"
              fill
              gap="large"
              pad={{ right: 'medium' }}
            >
              <CustomRadioButton
                label="Length"
                name="topCoverLength"
                fill
                options={topCoverLengthOptions}
                value={prescription.rxOrthotic.topCoverLength}
                onChange={handleChange}
                vertical
              />
              <CustomRadioButton
                label="Material"
                name="topCoverMaterial"
                options={topCoverMaterialOptions}
                value={prescription.rxOrthotic.topCoverMaterial}
                onChange={handleChange}
                fill
                vertical
              />
              <CustomRadioButton
                label="Finished"
                name="apart"
                options={['Not Glued']}
                value={prescription.rxOrthotic.apart ? ['Not Glued'] : ['']}
                onChange={() => {
                  handleChange('apart', !prescription.rxOrthotic.apart)
                }}
                fill
                checkBox
              />
            </Box>
            <Collapsible
              open={prescription.rxOrthotic.topCoverLength !== topCoverLengths.mets}
            >

              {/* // Shoe size information section */}
              <Box
                gap="1rem"
                fill
                direction="row"
                justify="between"
                pad={{ right: 'medium' }}
              >
                <Box fill>
                  <Heading
                    margin={{ top: 'none', bottom: 'xsmall' }}
                    level={5}
                  >
                    <Box
                      direction="row"
                      gap=".2rem"
                      style={{
                        alignItems: 'flex-start',
                      }}
                    >
                      <Heading
                        margin={{ top: 'none', bottom: 'xsmall' }}
                        level={5}
                      >
                        <Trans>Shoe Size</Trans>
                      </Heading>
                      -
                      <Trans>US</Trans>
                      <CheckBox
                        checked={prescription.section.shoeSizeSystem === shoeSizeSystems.eu}
                        name="shoeSizeSystem"
                        onChange={(event) => {
                          handleChange(
                            'shoeSizeSystem',
                            event.currentTarget.checked
                              ? shoeSizeSystems.eu
                              : shoeSizeSystems.us,
                          )
                          handleChange('shoeSizeValue', '')
                        }}
                        toggle={true}
                        pad={{ horizontal: '0.2rem' }}
                      />
                      <Trans>EU</Trans>
                    </Box>
                  </Heading>
                  <FormField>
                    <Select
                      name="shoeSizeValue"
                      required={prescription.rxOrthotic.topCoverActive}
                      options={
                        prescription.section.shoeSizeSystem === shoeSizeSystems.eu
                          ? standardShoeSizeEu
                          : standardShoeSizeUs
                      }
                      value={prescription.section.shoeSizeValue}
                      onChange={value => {
                        handleChange('shoeSizeValue', value)
                      }}
                    />
                  </FormField>
                  <Collapsible
                    open={prescription.section.shoeSizeSystem === shoeSizeSystems.us}
                  >
                    <Box fill>
                      <CustomRadioButton
                        label={<Trans>Shoe size (US Gender)</Trans>}
                        name="gender"
                        hideLine={true}
                        options={genderOptions}
                        value={prescription.section.gender}
                        onChange={handleChange}
                      />
                    </Box>
                  </Collapsible>
                </Box>
                <FormInput
                  label="Footbed length (cm)"
                >
                  <Select
                    name="Footbed length (cm)"
                    required={prescription.rxOrthotic.topCoverActive}
                    onChange={(value) => handleChange('footbedLength', value)}
                    options={footbedLength}
                    value={prescription.rxOrthotic.footbedLength}
                  />
                </FormInput>
                <FormInput
                  label="Footbed width (cm)"
                >
                  <Select
                    name="Footbed width (cm)"
                    required={prescription.rxOrthotic.topCoverActive}
                    onChange={(value) => handleChange('footbedWidth', value)}
                    options={footbedWidth}
                    value={prescription.rxOrthotic.footbedWidth}
                  />
                </FormInput>
              </Box>
            </Collapsible>
          </FormSection>
          <Box pad="medium" />
        </Form>
        <Box
          pad="medium"
        >
          <InformationPresenter
            name="RxInformation"
            url="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFtCp02Ev8&#x2F;view?embed"
          />
        </Box>
      </Stack>
    </Box>
  )
}

const PrescriptionPanel = (props: CommonProps) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const { setLoading } = useLoading()
  const navigate = useNavigate()

  const savePatientMutation = useSavePatient()
  const updateOrderClinician = useUpdateOrderClinician()
  const updateOrderLocation = useUpdateOrderLocation()
  const updateOrderStatus = useUpdateOrderStatus()
  const createPrescription = useCreatePrescription()
  const updatePrescription = useSavePrescription()

  const showSuccessToast = useShowSuccessToast()

  const [locationId, setLocationId] = useState<number>(props.order.location?.id ?? -1)
  const [clinicianId, setClinicianId] = useState<number>(props.order.clinician?.id ?? -1)

  const [prescription, setPrescription] = useState<Prescription>(props.prescription)

  const [patient, setPatient] = useState<Patient>({
    id: props.order.patient.id,
    patientId: props.order.patient.patientId,
    firstName: props.order.patient.firstName,
    lastName: props.order.patient.lastName,
    birthdate: props.order.patient.birthdate,
  })

  const [confirmCancel, setConfirmCancel] = useState<boolean>(false)

  const updatePatient = async () => {
    if (
      props.order.patient.firstName !== patient.firstName ||
      props.order.patient.lastName !== patient.lastName ||
      props.order.patient.patientId !== patient.patientId ||
      props.order.patient.birthdate !== patient.birthdate
    ) {
      return await savePatientMutation.mutateAsync({
        clinicId: props.order.clinicId,
        patient,
      })
    }
  }

  const updateLocation = async () => {
    if (props.order.location?.id === locationId) return

    return await updateOrderLocation.mutateAsync({
      clinicId: props.order.clinicId,
      orderId: props.order.id,
      locationId,
    })
  }

  const updateClinician = async () => {
    if (props.order.clinician?.id === clinicianId) return

    return await updateOrderClinician.mutateAsync({
      clinicId: props.order.clinicId,
      orderId: props.order.id,
      clinicianId,
    })
  }

  const updateOrder = async () => {
    await updatePatient()
    await updateLocation()
    await updateClinician()
  }

  const onCancel = () => {
    setConfirmCancel(true)
  }

  const makeUpdateOrder = (status: PrescriptionStatus): () => Promise<void> => async () => {
    setLoading(true)

    if (props.order.prescriptionId !== null && props.order.prescriptionId !== 0) {
      await updateOrder()
      await updatePrescription.mutateAsync({
        prescription: {
          clinicId: props.order.clinicId,
          orderId: props.order.id,
          prescription: toUpdateOms(prescription, status),
        },
      })
    } else {
      await updateOrder()
      await createPrescription.mutateAsync({
        prescription: {
          clinicId: props.order.clinicId,
          orderId: props.order.id,
          prescription: toOms(prescription, status),
        },
      })
    }

    showSuccessToast(
      'prescription-success',
      <Trans>Sucessfully updated prescription</Trans>,
    )

    setLoading(false)
    await navigate({ to: '/' })
  }

  const onDiscardChange = async () => {
    await navigate({ to: '/' })
  }

  const isValid = isFormValid(patient, prescription)

  const handleSave = makeUpdateOrder(prescriptionStatuses.onHold)
  const handleSubmit = makeUpdateOrder(prescriptionStatuses.completed)

  return (
    <>
      <PrescriptionPanelImpl
        order={props.order}
        prescription={prescription}
        patient={patient}
        onLocationChange={setLocationId}
        onClinicianChange={setClinicianId}
        onPrescriptionChange={setPrescription}
        onPatientChange={setPatient}
      />
      <Box
        direction="row"
        align="end"
        justify="end"
        gap=".5rem"
        height="2rem"
      >
        <WrapTooltipIf
          wrap={!isValid}
          content={<InvalidFormTip />}
        >
          <Button
            primary
            onClick={handleSubmit}
            tip={t(i18n)`Submitting will lock the prescription and send it to production`}
            label={
              <Box
                gap="small"
                direction="row"
              >
                <Trans>Submit</Trans>
                <Lock size="small" />
              </Box>
            }
            disabled={!isValid || updatePrescription.isPending || createPrescription.isPending}
          />
        </WrapTooltipIf>
        <WrapTooltipIf
          wrap={!isValid}
          content={<InvalidFormTip />}
        >
          <Button
            onClick={handleSave}
            label={<Trans>Save & Put On Hold</Trans>}
            disabled={!isValid || updatePrescription.isPending || createPrescription.isPending}
          />
        </WrapTooltipIf>
        <Button
          onClick={onDiscardChange}
          label="Discard Changes"
          disabled={updatePrescription.isPending || createPrescription.isPending}
        />
        <Button
          secondary
          color="red"
          onClick={onCancel}
          label="Cancel Order"
          disabled={updatePrescription.isPending || createPrescription.isPending}
        />
      </Box>
      <OrderCancellationReasonPrompt
        show={confirmCancel}
        onConfirm={reason => {
          setLoading(true)
          updateOrderStatus.mutate(
            {
              clinicId: props.order.clinicId,
              orderId: props.order.id,
              value: orderStatuses.cancelled,
              extra: {
                cancellationReason: reason,
              },
            },
            {
              onSettled: () => {
                setLoading(false)
                setConfirmCancel(false)
                navigate({ to: '/' }).catch(console.error)
              },
            },
          )
        }}
        onCancel={() => setConfirmCancel(false)}
      />
    </>
  )
}

const PrescriptionPanelLoader = () => {
  const params = useParams({ from: '/orders/clinics/$clinicId/orders/$orderId/prescription' })

  const order = useQueryOrder(params.clinicId, params.orderId)
  const serverPrescription = useQueryPrescription(params.clinicId, params.orderId, order.data?.prescriptionId)

  if (!order.data) return null

  if (
    order.data.prescriptionId !== null &&
    order.data.prescriptionId > 0 &&
    !serverPrescription.data // Wait for the fetch!
  ) {
    return null
  }

  if (
    (order.data.prescriptionId === null ||
      order.data.prescriptionId <= 0 ||
      (
        serverPrescription.data &&
        serverPrescription.data.prescriptionStatus !== prescriptionStatuses.completed
      )) &&
    isStatusBefore(order.data.activeStatus, orderStatuses.validated)
  ) {
    return (
      <CollapsiblePanel hideArrow={true}>
        {serverPrescription.isFetching
          ? (
            <Box
              overflow="auto"
              width="50vw"
              height="100vh"
              pad="1rem"
              background="white"
            >
              <Spinner size="xlarge" />
            </Box>
          )
          : (
            <PrescriptionPanel
              key={serverPrescription.dataUpdatedAt}
              order={order.data}
              prescription={
                serverPrescription.data
                  ? fromOms(serverPrescription.data)
                  : defaultPrescription
              }
            />
          )
        }
      </CollapsiblePanel>
    )
  }

  return (
    <PrescriptionViewer
      order={order.data}
      prescription={serverPrescription.data ?? toOms(defaultPrescription, prescriptionStatuses.completed)}
    />
  )
}

PrescriptionPanel.route = new Route({
  getParentRoute: () => MainPanel.route,
  path: 'clinics/$clinicId/orders/$orderId/prescription',
  parseParams: (params => ({
    clinicId: Number(params.clinicId),
    orderId: Number(params.orderId),
  })),
  onLoad: async ({ params, signal }) => {
    if (Number.isNaN(params.clinicId) || Number.isNaN(params.orderId)) return

    const queryClient = getQueryClient()

    await queryClient.prefetchQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ordersKeys.details(params.clinicId, params.orderId),
      queryFn: () => getOrder(params.clinicId, params.orderId, signal ?? null),
    })
  },
  component: PrescriptionPanelLoader,
})

export default PrescriptionPanel
