import { Trans } from '@lingui/macro'
import { Box, CheckBox, Heading } from 'grommet'

type Props = {
  label: string,
  level: 1 | 2 | 3 | 4 | 5 | 6,
  separator?: boolean,
  name?: string,
  noFill?: boolean,
  printing?: boolean | undefined,
  isChecked?: boolean | undefined,
  onCheck: ((checked: boolean) => unknown) | null,
}

const FormHeader = (props: Props) => {

  const smallLevel = props.level === 4 || props.level === 5 || props.level === 6
  const border = props.separator === true ? 'bottom' : []
  const topPad = props.printing === true || smallLevel ? 'xsmall' : 'medium'
  const gap = props.printing === true || smallLevel ? 'xsmall' : 'small'

  return (
    <Box
      border={border}
      pad={{ top: topPad, bottom: 'none', left: 'none', right: 'none' }}
      margin="none"
      direction="row"
      gap={gap}
      align="center"
      fill={props.noFill ?? true}
    >
      <Heading
        level={props.level}
        margin="none"
        fill={props.noFill ?? true}
      >
        <Trans>{props.label}</Trans>
      </Heading>
      {props.onCheck !== null && (
        <CheckBox
          checked={props.isChecked ?? false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.currentTarget.checked === props.isChecked) return
            props.onCheck?.(event.currentTarget.checked)
          }}
        />
      )}
    </Box>
  )
}

export default FormHeader
