// Allowing import here, since it's here we define the replacement!
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { BoxProps, CheckBoxGroup as GrommetCheckBoxGroup } from 'grommet'

type BaseProps<TValue extends string | number> = BoxProps & Readonly<{
  value: TValue[],
  name?: string | undefined,
  onChange: (values: TValue[]) => unknown,

  defaultValue?: undefined,
  disabled?: undefined,
}>

type LiteralProps<TValue extends string | number> = BaseProps<TValue> & Readonly<{
  options: readonly TValue[],
}>

type DisabledProps<TValue extends string | number> =
  & Omit<LiteralProps<TValue>, 'value' | 'onChange' | 'defaultValue' | 'disabled'>
  & Readonly<{
    defaultValue: TValue[],
    disabled: true,

    value?: undefined,
    onChange?: undefined,
  }>

type Props<TValue extends string | number> =
  | LiteralProps<TValue>
  | DisabledProps<TValue>

function CheckBoxGroup<TValue extends string | number>(props: Props<TValue>) {
  const handleChange = (event: {value: string} | undefined) => {
    const value = (event?.value as TValue[] | undefined) ?? []
    props.onChange?.(value)
  }

  return (
    <GrommetCheckBoxGroup
      {...props}
      direction={props.direction ?? 'column'}
      gap={props.gap ?? '0.5rem'}
      name={props.name as never}
      options={props.options as never}
      value={props.value as never}
      onChange={handleChange}
      defaultValue={props.defaultValue as never}
      disabled={props.disabled as never}
    />
  )
}

export default CheckBoxGroup
