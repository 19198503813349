import { Outlet, RootRoute } from '@tanstack/react-router'

import { useAppVersioningChecker } from '../hooks/appVersioning'
import { useTrackerMetadata } from '../hooks/tracking'
import { useSelector } from '../store/store'
import { getPersonFullName } from '../utils/person'

const Root = () => {
  useAppVersioningChecker()

  const clinicName = useSelector(state => state.userContext.clinic.name)
  const location = useSelector(state => state.userContext.location.description)
  const clinicianName = useSelector(state => getPersonFullName(state.userContext.clinician))

  useTrackerMetadata('clinic', clinicName)
  useTrackerMetadata('location', location)
  useTrackerMetadata('clinician', clinicianName)

  return <Outlet />
}

Root.route = new RootRoute({
  component: Root,
})

export default Root
