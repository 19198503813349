
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Box, Button, Nav, Spinner, Tip } from 'grommet'
import { Menu } from 'grommet-icons'

import SignOut from '../../../components/SignOut'
import { useLoading } from '../../../hooks/loading'
import { userContextActions } from '../../../store/actions/userContext'
import { useDispatch, useSelector } from '../../../store/store'
import SearchBar from '../SearchBar'

import HeaderLogo from './logo'
import Selector from './selector'

type Props = {
  onHandleSettingsPressed: () => unknown,
}

const AdminHeader = (props: Props) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const { isLoading } = useLoading()
  const dispatch = useDispatch()

  const ordersView = useSelector(state => state.userContext.ordersView)
  const handleShippingViewToggle = () => {
    dispatch(userContextActions.setOrdersView(ordersView === 'shippingView' ? 'adminView' : 'shippingView'))
  }

  return (
    <>
      <Nav
        direction="row"
        align="center"
        gap="1rem"
      >
        <Menu
          onClick={props.onHandleSettingsPressed}
        />
        <HeaderLogo />
        <Selector />
        {isLoading && <Spinner size="medium" />}
      </Nav>

      <Nav
        direction="row"
        align="center"
        gap="1rem"
      >
        <Box
          gap="0.5rem"
          direction="row"
          height="1.75rem"
        >
          <Tip
            content={
              ordersView === 'shippingView'
                ? t(i18n)`Shipping view: on`
                : t(i18n)`Shipping view: off`
            }
          >
            <Button
              label={t(i18n)`Shipping view`}
              primary={ordersView === 'shippingView'}
              onClick={handleShippingViewToggle}
            />
          </Tip>
        </Box>
        <SearchBar />
        {/* <LanguageSelector /> */}
        <SignOut />
      </Nav>
    </>

  )
}

export default AdminHeader
