import { ReactNode } from 'react'

const notificationActionTypes = {
  clearToast: 'clearToast',
  showSuccessToast: 'showSuccessToast',
  showErrorToast: 'showErrorToast',
  showWarningToast: 'showWarningToast',
  setLatestAppVersion: 'setLatestAppVersion',
} as const

type ClearToast = {
  type: typeof notificationActionTypes.clearToast,
  id: string,
}

type ShowSuccessToast = {
  type: typeof notificationActionTypes.showSuccessToast,
  id: string,
  title?: string | undefined,
  message: ReactNode,
}

type ShowErrorToast = {
  type: typeof notificationActionTypes.showErrorToast,
  id: string,
  title?: string | undefined,
  message: ReactNode,
}

type ShowWarningToast = {
  type: typeof notificationActionTypes.showWarningToast,
  id: string,
  title?: string | undefined,
  message: ReactNode,
}

type SetLatestAppVersion = {
  type: typeof notificationActionTypes.setLatestAppVersion,
  version: string,
}

export type NotificationAction =
  | ClearToast
  | ShowSuccessToast
  | ShowErrorToast
  | ShowWarningToast
  | SetLatestAppVersion

const clearToast = (id: string): ClearToast => ({
  type: notificationActionTypes.clearToast,
  id,
})

const showSuccessToast = (id: string, message: ReactNode, title?: string): ShowSuccessToast => ({
  type: notificationActionTypes.showSuccessToast,
  id,
  title,
  message,
})

const showErrorToast = (id: string, message: ReactNode, title?: string): ShowErrorToast => ({
  type: notificationActionTypes.showErrorToast,
  id,
  title,
  message,
})

const showWarningToast = (id: string, message: ReactNode, title?: string): ShowWarningToast => ({
  type: notificationActionTypes.showWarningToast,
  id,
  title,
  message,
})

const setLatestAppVersion = (version: string): SetLatestAppVersion => ({
  type: notificationActionTypes.setLatestAppVersion,
  version,
})

const notificationActions = {
  clearToast,
  showSuccessToast,
  showErrorToast,
  showWarningToast,
  setLatestAppVersion,
}

export {
  notificationActionTypes,
  notificationActions,
}
