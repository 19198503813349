import { Order, OrderForManufacturer, isOrder } from '../../types/Order'
import { UpdateOmsPrescription } from '../../types/Prescription'
import { productTypes } from '../../types/PrescriptionEnums/ProductType'
import { requestedSides } from '../../types/PrescriptionEnums/RequestedSide'
import { TopCoverLength } from '../../types/PrescriptionEnums/TopCoverLength'

const getOrderItemsCount = (order: Order | OrderForManufacturer) =>
  order.requestedSide !== requestedSides.both ? 1 : 2

const getProductType = (
  order: Order | OrderForManufacturer,
  prescription: UpdateOmsPrescription | null,
) => {
  if (!isOrder(order)) return order.productType
  else if (prescription) return prescription.productType
  else if (order.prescription) return order.prescription.productType
  else return productTypes.shell
}

const getTopCoverLength = (
  order: Order | OrderForManufacturer,
  prescription: UpdateOmsPrescription | null,
): TopCoverLength | null => {
  if (!isOrder(order)) return order.topCoverLength
  else if (prescription) return prescription.rxOrthoticBilateral.topCoverLength
  else if (order.prescription) return order.prescription.rxOrthoticBilateral.topCoverLength
  else return null
}

export {
  getOrderItemsCount,
  getProductType,
  getTopCoverLength,
}
