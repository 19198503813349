import { File } from '../types/File'

const guidSuffixRegex = /-[a-f0-9]{8}-(?:[a-f0-9]{4}-){3}[a-f0-9]{12}/i

const getFilename = (file: File): string =>
  file.fileName.replace(guidSuffixRegex, '')

const getFilenameAndExtension = (name: string): {fileName: string; extension: string} => {
  const lastDotIndex = name.lastIndexOf('.')

  let fileName
  let extension
  if (
    lastDotIndex === -1 || // Does not have an extension.
    lastDotIndex === 0 // Starts with '.', must be a hidden file.
  ) {
    fileName = name
    extension = ''
  } else {
    fileName = name.substring(0, lastDotIndex)
    extension = name.substring(lastDotIndex + 1)
  }

  return {fileName, extension}
}

export type FileStls = {
  left: File | null,
  right: File | null,
  leftScan: File | null,
  rightScan: File | null,
}

const findStls = (files: File[]): FileStls => {
  const stls: FileStls = {
    left: null,
    right: null,
    leftScan: null,
    rightScan: null,
  }

  for (const file of files) {
    if (file.extension !== 'stl') continue

    const name = file.fileName
    if (name.includes('-left')) {
      if (name.includes('-scan')) {
        stls.leftScan = file
      } else {
        stls.left = file
      }
    } else if (name.includes('-right')) {
      if (name.includes('-scan')) {
        stls.rightScan = file
      } else {
        stls.right = file
      }
    }
  }

  return stls
}

export {
  guidSuffixRegex,
  getFilename,
  getFilenameAndExtension,
  findStls,
}
