import { getOmsApiUri } from '../constants/runtime'
import { OmsPrescription, UpdateOmsPrescription } from '../types/Prescription'

import { fetch } from './fetch'
import { getHeaders, getMutationHeaders } from './utils'

const prescriptionsKeys = {
  all: () => ['prescriptions'],
  details: (clinicId: number, orderId: number) => [...prescriptionsKeys.all(), 'details', clinicId, orderId],
}

const getBaseUri = (clinicId: number, orderId: number) =>
  `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/prescription`

export type PostPrescription = {
  clinicId: number,
  orderId: number,
  prescription: OmsPrescription,
}

export type PutPrescription = {
  clinicId: number,
  orderId: number,
  prescription: UpdateOmsPrescription,
}

const getPrescription = async (
  clinicId: number,
  orderId: number,
  signal: AbortSignal | null,
): Promise<UpdateOmsPrescription> => {
  const response = await fetch(
    getBaseUri(clinicId, orderId),
    {
      headers: await getHeaders(),
      signal,
    },
  )

  if (response.status !== 200 && response.status !== 404) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as UpdateOmsPrescription

  return dto
}

const postPrescription = async (
  prescription: PostPrescription,
): Promise<UpdateOmsPrescription> => {
  const response = await fetch(
    getBaseUri(prescription.clinicId, prescription.orderId),
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify(prescription.prescription),
    },
  )

  if (response.status !== 201) {
    console.warn(response)
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as UpdateOmsPrescription

  return dto
}

const updatePrescription = async (
  prescription: PutPrescription,
): Promise<UpdateOmsPrescription> => {
  const response = await fetch(
    `${getBaseUri(prescription.clinicId, prescription.orderId)}/${prescription.prescription.id}`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify(prescription.prescription),
    },
  )

  if (response.status < 200 || response.status > 299) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as UpdateOmsPrescription

  return dto
}

export {
  prescriptionsKeys,
  getPrescription,
  postPrescription,
  updatePrescription,
}
