import { Trans } from '@lingui/macro'
import { Box, Heading, RangeInput } from 'grommet'

type Props<TValue extends string | number> = {
  title?: string,
  // name: string,
  options: TValue[],
  selector: TValue,
  onChange: (value: TValue | 'none') => unknown,
}

function Slider<TValue extends string | number>(props: Props<TValue>) {

  return (
    <Box
      fill
      margin="none"
      pad="none"
    >
      {props.title !== undefined &&
        <Heading
          level="5"
          margin="none"
        >
          <Trans>
            {props.title}
          </Trans>
        </Heading>
      }
      <Box
        direction="row"
        gap="small"
        pad="xsmall"
        margin={{ left: 'small', vertical: 'none' }}
      >
        <Box
          width="80%"
        >
          <RangeInput
            min={0}
            max={props.options.length - 1}
            onChange={(event) => {
              if (typeof props.selector === 'string') {
                props.onChange(props.options[Number(event.currentTarget.value)] ?? 'none')
              }
            }
            }
            value={props.options.findIndex(item => item === props.selector)}
          />
        </Box>
        <Box
          width="20%"
        >
          {props.selector}
        </Box>
      </Box>
    </Box>
  )
}

export default Slider
