import { notificationActions } from '../store/actions/notifications'

import { useWrapAction } from './actions'

const useShowSuccessToast = () => {
  return useWrapAction(notificationActions.showSuccessToast)
}

const useShowErrorToast = () => {
  return useWrapAction(notificationActions.showErrorToast)
}

const useShowWarningToast = () => {
  return useWrapAction(notificationActions.showWarningToast)
}

const useClearToast = () => {
  return useWrapAction(notificationActions.clearToast)
}

export {
  useShowSuccessToast,
  useShowErrorToast,
  useShowWarningToast,
  useClearToast,
}
