
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Box, Button, Layer } from 'grommet'
import { useState } from 'react'

import { useLoading } from '../../hooks/loading'
import { useQueryClinicians } from '../../queries/clinicians'
import { useQueryLocations } from '../../queries/locations'
import { useCreateOrder } from '../../queries/orders'
import { useCreatePatient } from '../../queries/patients'
import { notificationActions } from '../../store/actions/notifications'
import { useDispatch } from '../../store/store'
import { NewPatient } from '../../types/Patient'
import { getPersonFullName } from '../../utils/person'
import { Select } from '../grommet-sub'

import AddPatient from './AddPatient'

type Props = ModalProps | NonModalProps
type ModalProps = {
  showModal: true,
  clinicId: number,
  clinicianName: string | undefined,
  locationName: string | undefined,
  onClose: () => unknown,
}

type NonModalProps = {
  showModal: false,
  clinicId: number,
  clinicianName: string | undefined,
  locationName: string | undefined,
}

const AddOrder = (props: Props) => {

  const lingui = useLingui()
  const i18n = lingui.i18n

  const [patient, setPatient] = useState<NewPatient>({
    firstName: '',
    lastName: '',
    patientId: '',
    birthdate: null,
  })
  const [location, setLocation] = useState<string>(props.locationName ?? '')
  const [clinicianName, setClinicianName] = useState<string>(props.clinicianName ?? '')

  const createPatient = useCreatePatient()
  const createOrder = useCreateOrder()
  const queryLocation = useQueryLocations(props.clinicId)
  const queryClinicians = useQueryClinicians(props.clinicId)

  const dispatch = useDispatch()

  const { setLoading } = useLoading()

  const handleLocationSelected = (locationName: string) => {
    setLocation(locationName)
  }

  const handleClinicianSelected = (clinicianName: string) => {
    setClinicianName(clinicianName)
  }
  const handleCreateOrder = async () => {
    setLoading(true)

    const locationId = queryLocation.data?.find(l => l.description === location)?.id ?? 0
    if (locationId === 0) {
      dispatch(notificationActions.showErrorToast(
        'location-error',
        <Trans>No location was selected</Trans>,
        t(i18n)`Missing information`,
      ))
      setLoading(false)
      // presentError('No location found with description: {clinicianName}')
      return
    }

    const clinicianId = queryClinicians.data?.find(c => getPersonFullName(c) === clinicianName)?.id ?? 0
    if (clinicianId === 0) {
      dispatch(notificationActions.showErrorToast(
        'clinician-error',
        <Trans>No clinician was selected</Trans>,
        t(i18n)`Missing information`,
      ))
      setLoading(false)
      return
    }

    const newPatient = await createPatient.mutateAsync({
      clinicId: props.clinicId,
      patient: patient,
    })

    await createOrder.mutateAsync({
      clinicId: props.clinicId,
      locationId: locationId,
      clinicianId: clinicianId,
      patientId: newPatient.id,
    })
    setLoading(false)

    dispatch(notificationActions.showSuccessToast(
      'created-order-success',
      <Trans>New order created</Trans>,
      t(i18n)`Success!`,
    ))

    if (props.showModal) props.onClose()
  }

  const handlePatient = (patientId: string, firstName: string, lastName: string, birthdate: string | null) => {
    setPatient(() => ({
      patientId: patientId,
      firstName: firstName,
      lastName: lastName,
      birthdate: birthdate,
    }))
  }

  return (
    <>
      {
        props.showModal &&
        <Layer
          onEsc={() => props.onClose()}
          onClickOutside={() => props.onClose()}
          margin="1rem"
        >
          <Box
            pad="1rem"
          >
            <Box
              direction="row"
              justify="evenly"
              gap="1rem"
            >
              <Select
                options={queryLocation.data?.map(l => l.description) ?? []}
                value={location}
                name="Location"
                onChange={value => handleLocationSelected(value)}
              />
              <Select
                options={queryClinicians.data?.map(c => getPersonFullName(c)) ?? []}
                value={clinicianName === '' ? '*' : clinicianName}
                name="Clinician"
                onChange={value => handleClinicianSelected(value)}
              />
            </Box>

            <AddPatient
              showPatientModal={false}
              clinicId={props.clinicId}
              onChange={handlePatient}
              vertical
            />
            <Button
              label={i18n.t(`Create Order`)}
              onClick={handleCreateOrder}
            />
          </Box>
        </Layer>
      }
    </>
  )

}

export default AddOrder
