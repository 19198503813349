
import { Box, CheckBox, FormField, Heading, RadioButton } from 'grommet'
import { FillType } from 'grommet/utils'
import { ReactElement, ReactNode } from 'react'

type ObjectOption<TOption> = {
  value: TOption,
  label: string,
}

function isObjectOption<TOption>(option: TOption | ObjectOption<TOption>): option is ObjectOption<TOption> {
  return typeof option === 'object'
}

function isValueArray<TOption>(value: TOption | TOption[]): boolean {
  return Array.isArray(value)
}

type BaseProps<TOption extends number | string> = Readonly<{
  value: TOption | TOption[] | undefined,
  options: TOption[] | Array<ObjectOption<TOption>>,
  label?: ReactNode,
  vertical?: boolean,
  hideLine?: boolean,
  fill?: FillType,
  noPad?: boolean,
  checkBox?: boolean,
}>

type LiveProps<TKey extends string, TOption extends number | string> = BaseProps<TOption> & Readonly<{
  name: TKey,
  disabled?: boolean,
  readOnly?: undefined,
  onChange?: (key: TKey, value: TOption) => unknown,
}>

type ReadOnlyProps<TOption extends number | string> = BaseProps<TOption> & Readonly<{
  readOnly: true,
  onChange?: undefined,
}>

type Props<TKey extends string, TOption extends number | string> =
  | LiveProps<TKey, TOption>
  | ReadOnlyProps<TOption>

function CustomRadioButton<TKey extends string, TOption extends number | string>(props: Props<TKey, TOption>) {
  const label: ReactNode =
    props.label != null
      ? (
        <Heading
          level={5}
          margin={{ top: 'none', bottom: 'xsmall' }}
          fill={typeof props.fill === 'string' || typeof props.fill === 'boolean'}
          truncate
        >
          {props.label}
        </Heading>
      )
      : null

  const title: string = props.label != null
    ? String(((props.label as ReactElement | undefined)
      ?.props as { message?: string } | undefined)?.message ?? 'no title')
    : 'no title'

  const gap = props.checkBox === true ? '0.8rem' : '0.3rem'

  return (
    <Box
      fill={props.fill ?? false}
      pad="none"
    >
      {
        props.hideLine === true
          ? label
          : (
            <FormField
              margin="none"
              fill={props.fill ?? false}
            >
              {label}
            </FormField>
          )
      }
      <>
        {
          props.readOnly === true ? (
            <>
              {props.options.map((option: ObjectOption<TOption> | TOption) => {
                if (isObjectOption(option)) {
                  return option.value === props.value ? option.label : ''
                }
                return option === props.value ? props.value : ''
              })}
            </>
          )
            : (
              <Box
                gap={gap}
                pad={props.noPad !== true ? '0.5rem' : (undefined as never)}
                direction={(props.vertical ?? false) && props.checkBox !== true ? 'column' : 'row'}
                fill={props.fill ?? false}
                justify="stretch"
              >
                {
                  props.options.map((option) => {
                    let value: TOption
                    let label: string
                    if (isObjectOption(option)) {
                      value = option.value
                      label = option.label
                    } else {
                      value = option
                      label = String(option)
                    }
                    const button = props.checkBox === true
                      ? (
                        <Box
                          gap=".3rem"
                          direction={props.vertical === true ? 'column' : 'row'}
                          key={String(value).concat(title)}
                        >
                          {label}
                          <CheckBox
                            // NOTE(antoine): This gentleman is used as a key, and in a form
                            // requires to be absolutely unique, otherwise, it'll bring your
                            // whole world crashing.
                            name={String(value).concat(title, label)}
                            checked={isValueArray(props.value)
                              ? (props.value as TOption[]).includes(value)
                              : false}
                            onChange={() => {
                              props.onChange?.(props.name, value)
                            }}
                          />
                        </Box>
                      )
                      : (
                        <RadioButton
                          key={String(value).concat(title, label)}
                          name={String(value)}
                          label={label}
                          checked={!isValueArray(props.value)
                            ? props.value === value
                            : false
                          }
                          onChange={() => props.onChange?.(props.name, value)}
                          disabled={props.disabled ?? false}
                        />
                      )
                    return button
                  })
                }
              </Box>
            )
        }
      </>
    </Box>
  )
}

export default CustomRadioButton
