export const orderStatuses = {
  incomplete: 0,
  cancelled: 1,
  onHold: 2,
  submitted: 3,
  processed: 4,
  validated: 5,
  verified: 10,
  printing: 550,
  printed: 6,
  finished: 600,
  delayed: 630,
  packaged: 650,
  shipped: 7,
  receivedByClinic: 750,
  deliveredToPatient: 8,
  completed: 9,
} as const

export const orderStatusNone = -1000 as const

export type OrderStatus = typeof orderStatuses[keyof typeof orderStatuses]

export const orderStatusesList = Object.values(orderStatuses)

export const orderStatusesForManufacturer: OrderStatus[] = [
  orderStatuses.verified,
  orderStatuses.printing,
  orderStatuses.printed,
  orderStatuses.finished,
]

export const nonCancelledOrderStatuses = Object
  .values(orderStatuses)
  .filter(status => status !== orderStatuses.cancelled)

export const getEmptyOrdersCounts = (): Record<OrderStatus, number> => ({
  [orderStatuses.incomplete]: 0,
  [orderStatuses.cancelled]: 0,
  [orderStatuses.onHold]: 0,
  [orderStatuses.submitted]: 0,
  [orderStatuses.processed]: 0,
  [orderStatuses.validated]: 0,
  [orderStatuses.verified]: 0,
  [orderStatuses.printing]: 0,
  [orderStatuses.printed]: 0,
  [orderStatuses.finished]: 0,
  [orderStatuses.delayed]: 0,
  [orderStatuses.packaged]: 0,
  [orderStatuses.shipped]: 0,
  [orderStatuses.receivedByClinic]: 0,
  [orderStatuses.deliveredToPatient]: 0,
  [orderStatuses.completed]: 0,
})

const toFloat = (status: OrderStatus): number => {
  // See backend OrderStatus.cs for explanation about this weird logic.
  if (status === orderStatuses.verified) return 5.25
  if (status === orderStatuses.finished) return 6.25
  if (status < 100) return status
  return status / 100
}

export const isStatusPastOrAt = (status: OrderStatus, target: OrderStatus): boolean =>
  toFloat(status) >= toFloat(target)

export const isStatusPast = (status: OrderStatus, target: OrderStatus): boolean =>
  toFloat(status) > toFloat(target)

export const isStatusBeforeOrAt = (status: OrderStatus, target: OrderStatus): boolean =>
  toFloat(status) <= toFloat(target)

export const isStatusBefore = (status: OrderStatus, target: OrderStatus): boolean =>
  toFloat(status) < toFloat(target)
