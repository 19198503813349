
import { Trans } from '@lingui/macro'
import { Box, Button, Heading, Layer, TextInput } from 'grommet'
import { useState } from 'react'

type ShippingLinkPromptProps = Readonly<{
  onConfirm: (shippingLink: string | null) => unknown,
  onCancel: () => unknown,
}>

const ShippingLinkPrompt = (props: ShippingLinkPromptProps) => {
  const [shippingLink, setShippingLink] = useState<string>('')

  return (
    <Layer modal={true}>
      <Box
        round={true}
        pad="1rem"
      >
        <Heading level="2">
          <Trans>Add shipment tracking link</Trans>
        </Heading>
        <Trans>
          Add an URL to a shipment tracking link, or leave empty if not applicable.
        </Trans>
        <TextInput
          placeholder="https://"
          value={shippingLink}
          onChange={(event) => setShippingLink(event.currentTarget.value)}
        />
        <Box
          direction="row"
          justify="end"
          gap="0.5rem"
          margin={{top: '1rem'}}
        >
          <Button
            label={<Trans>Cancel</Trans>}
            onClick={props.onCancel}
          />
          <Button
            primary={true}
            label={<Trans>Confirm</Trans>}
            onClick={() => {
              const link = shippingLink.trim()
              props.onConfirm(link === '' ? null : link)
            }}
          />
        </Box>
      </Box>
    </Layer>
  )
}

export default ShippingLinkPrompt
