import { AwsRum, AwsRumConfig } from 'aws-rum-web'

import { applicationVersion } from './version'

type Environment = 'local' | 'dev' | 'staging' | 'qa' | 'prod'

const getEnvironment = (): Environment => {
  // eslint-disable-next-line no-restricted-globals
  const host = location.host

  if (host.includes('localhost')) return 'local'
  if (host.startsWith('dev.')) return 'dev'
  if (host.startsWith('qa.')) return 'qa'
  if (host.startsWith('staging.')) return 'staging'

  return 'prod'
}

type EnvironmentSpecificVars<T> = {
  local: T,
  dev: T,
  staging: T,
  qa: T,
  prod: T,
}

function getEnvironmentSpecificVars<const T>(
  vars: EnvironmentSpecificVars<T>,
  localSwitch: string,
): T {
  switch (getEnvironment()) {
  case 'local':
    return process.env[localSwitch] === 'true'
      ? vars.dev
      : vars.local

  case 'dev': return vars.dev
  case 'staging': return vars.staging
  case 'qa': return vars.qa
  default: return vars.prod
  }
}

function makeGetEnvironmentSpecificVars<const T>(
  vars: EnvironmentSpecificVars<T>,
  localSwitch = 'REACT_APP_USE_DEV_AWS',
): () => T {
  return () => getEnvironmentSpecificVars(vars, localSwitch)
}

const getOmsApiUri = makeGetEnvironmentSpecificVars({
  local: 'http://localhost:5050', //'http://169.254.161.75:5050',
  dev: 'https://dev.api.oms.podform3d.io',
  staging: 'https://staging.api.oms.podform3d.io',
  qa: 'https://qa.api.oms.podform3d.io',
  prod: 'https://api.oms.podform3d.io',
}, 'REACT_APP_USE_DEV_BACKEND')

const getOnlineViewerUri = makeGetEnvironmentSpecificVars({
  local: typeof process.env['REACT_APP_ONLINE_VIEWER_URI'] === 'string'
    ? process.env['REACT_APP_ONLINE_VIEWER_URI']
    : 'http://localhost:8080',
  dev: 'https://dev.online-viewer.podform3d.io',
  staging: 'https://staging.online-viewer.podform3d.io',
  qa: 'https://qa.online-viewer.podform3d.io',
  prod: 'https://online-viewer.podform3d.io',
}, 'REACT_APP_ONLINE_VIEWER_URI')

const doStartAwsRum = (
  isProd: boolean,
  environmentConfig: {
    guestRoleArn: string,
    identityPoolId: string,
    applicationId: string,
    applicationRegion: string,
    allowCookies: boolean,
    enableXray: boolean,
  },
) => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: environmentConfig.guestRoleArn,
      identityPoolId: environmentConfig.identityPoolId,
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: environmentConfig.allowCookies,
      // Name is mandated by AWS, not our choice.
      // eslint-disable-next-line @typescript-eslint/naming-convention
      enableXRay: environmentConfig.enableXray,
    }

    return new AwsRum(
      environmentConfig.applicationId,
      applicationVersion,
      environmentConfig.applicationRegion,
      config,
    )

  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    if (!isProd) console.error(error)
    return null
  }
}

const getStartAwsRum = makeGetEnvironmentSpecificVars({
  dev: () => {
    return doStartAwsRum(false, {
      guestRoleArn: 'arn:aws:iam::534728778350:role/Cognito_podform3domsdevUnauth_Role',
      identityPoolId: 'us-east-1:ba5bff0b-f40b-4a43-bc85-02fc035176fb',
      allowCookies: true,
      enableXray: false,
      applicationId: '0f633a56-3a0b-488b-b9b7-60e13f6a8acd',
      applicationRegion: 'us-east-1',
    })
  },
  prod: () => {
    return doStartAwsRum(true, {
      guestRoleArn: 'arn:aws:iam::534728778350:role/Cognito_podform3domsprodUnauth_Role',
      identityPoolId: 'us-east-1:70339517-bb21-4b20-8c85-4be4c39e7bc6',
      allowCookies: false,
      enableXray: true,
      applicationId: '8a0daa1f-63aa-4eb9-a811-5651374fa8e7',
      applicationRegion: 'us-east-1',
    })
  },
  local: null,
  staging: null,
  qa: null,
})

export {
  getStartAwsRum,
  getOmsApiUri,
  getOnlineViewerUri,
}
