import { Box } from 'grommet'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'

type AnimatedBoxProps = Readonly<{
  open: boolean,
  shouldOpen: boolean,
  dimension: 'width' | 'height',
  speedProp: number,
  animate: boolean,
}>

const AnimatedBox = styled(Box)`
  ${(props: AnimatedBoxProps) =>
    props.shouldOpen
      ? (
        `visibility: hidden;
        position: absolute;
        pointer-events: none;`
      )
      : (
        // eslint-disable-next-line max-len
        `transition:
          ${`min-${props.dimension} ${props.speedProp}ms,
          max-${props.dimension} ${props.speedProp}ms,
          ${props.dimension} ${props.speedProp}ms,
          opacity ${props.speedProp}ms`};
        opacity: ${props.open ? 1 : 0};
        overflow: ${props.animate || !props.open ? 'hidden' : 'visible'};`
      )
}
`

type Props = Readonly<{
  open: boolean,
  animate?: boolean | undefined,
  dimension?: 'width' | 'height' | undefined,
  children: ReactNode,
}>

const HeightCollapsible = (props: Props) => {
  const [contentHeight, setContentHeight] = useState<number>(0)
  const [contentWidth, setContentWidth] = useState<number>(0)

  const animate = props.animate ?? true
  const dimension = props.dimension ?? 'height'

  const handleContentRef = (ref: HTMLDivElement | null) => {
    if (!ref) return

    const bounds = ref.getBoundingClientRect()
    setContentHeight(bounds.height)
    setContentWidth(bounds.width)
  }

  return (
    <AnimatedBox
      aria-hidden={!props.open}
      open={props.open}
      animate={animate}
      dimension={dimension}
      speedProp={200}
      shouldOpen={false}
      style={
        dimension === 'height'
          ? {
            minHeight: props.open ? `${contentHeight}px` : 0,
            maxHeight: props.open ? `${contentHeight}px` : 0,
            height: props.open ? `${contentHeight}px` : 0,
          }
          : {
            minWidth: props.open ? `${contentWidth}px` : 0,
            maxWidth: props.open ? `${contentWidth}px` : 0,
            width: props.open ? `${contentWidth}px` : 0,
          }
      }
    >
      <div ref={handleContentRef}>
        {props.children}
      </div>
    </AnimatedBox>
  )
}

export default HeightCollapsible
