import { FetchResponseEvent } from '../events/FetchResponseEvent'

const fetch: typeof window.fetch = async (...args) => {
  const response = await window.fetch(...args)

  window.dispatchEvent(new FetchResponseEvent(response))

  return response
}

export {
  fetch,
}
