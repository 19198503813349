
import { plural, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Widget } from '@typeform/embed-react'
import { Box, Button, Layer, Nav, Spinner, Stack } from 'grommet'
import { Close, Menu } from 'grommet-icons'
import { useMemo, useState } from 'react'

import MixedStatusFilterButtons, { MixedStatusFilterButton } from '../../../components/MixedStatusFilterButtons'
import SignOut from '../../../components/SignOut'
import { useLoading } from '../../../hooks/loading'
import { useSelector } from '../../../store/store'
import { orderStatuses } from '../../../types/OrderStatus'
import SearchBar from '../SearchBar'

import HeaderLogo from './logo'
import Selector from './selector'

type Props = {
  onHandleSettingsPressed: () => unknown,
}

const ClinicianHeader = (props: Props) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const { isLoading } = useLoading()

  const [showPanel, setShowPanel] = useState<boolean>(false)
  const ordersCountsByOrderStatuses = useSelector(state => state.userContext.ordersCountsByOrderStatuses)

  const ordersToComplete =
    (ordersCountsByOrderStatuses[orderStatuses.incomplete] ?? 0) +
    (ordersCountsByOrderStatuses[orderStatuses.onHold] ?? 0)

  const clinicianStatusToolbar: MixedStatusFilterButton[] = useMemo(
    () => {
      const config: MixedStatusFilterButton[] = [
        {
          id: 'header.clinicians.status-toolbar.incomplete-orders',
          label:
            plural(ordersToComplete, {
              one: 'Incomplete order (#)',
              other: 'Incomplete orders (#)',
            }),
          tip: t(i18n)`Click to show orders that need your attention`,
          activeTip: t(i18n)`Click to go back to all orders`,
          statuses: [orderStatuses.incomplete, orderStatuses.onHold],
          hide: ordersToComplete === 0,
        },
      ]

      return config
    },
    [
      ordersToComplete,
      i18n,
    ],
  )

  return (
    <>
      <Nav
        direction="row"
        align="center"
        gap="1rem"
      >
        <Menu
          onClick={props.onHandleSettingsPressed}
        />
        <HeaderLogo />
        <Selector />
        {isLoading && <Spinner size="medium" />}
      </Nav>

      <Nav
        direction="row"
        align="center"
        gap="1rem"
      >
        <Box
          height="1.75rem"
        >
          <Button
            size="medium"
            label="Refer and Earn"
            fill
            onClick={() => setShowPanel(b => !b)}
          />
        </Box>
        <MixedStatusFilterButtons
          statuses={clinicianStatusToolbar}
          activeByDefault
        />
        <SearchBar />
        {/* <LanguageSelector /> */}
        <SignOut />
        {
          showPanel &&
          <Layer
            onClickOutside={() => setShowPanel(false)}
            modal
          >
            <Stack anchor="top-right">
              <Box
                width={'80vw'}
                height={'80vh'}
              >
                <Widget
                  id="hmjeNZmn"
                  style={{ width: '100%', height: '100%' }}
                  className="Refer & Earn"
                />
              </Box>
              <Box
                pad="medium"
              >
                <Close
                  size="more-medium-than-large"
                  onClick={() => setShowPanel(false)}
                />
              </Box>
            </Stack>
          </Layer>
        }
      </Nav>
    </>

  )
}

export default ClinicianHeader
