import { getOmsApiUri } from '../constants/runtime'
import { Location, NewLocation } from '../types/Location'

import { fetch } from './fetch'
import { getHeaders, getMutationHeaders } from './utils'

const locationsKeys = {
  all: () => ['locations'],
  list: (clinicId: number | 'all' | null, filters?: Record<string, unknown> | undefined) =>
    filters
      ? [...locationsKeys.all(), 'list', clinicId, filters]
      : [...locationsKeys.all(), 'list', clinicId],
  details: (locationId: number) => [...locationsKeys.all(), 'details', locationId],
}

const getLocations = async (clinicId: number | 'all', signal: AbortSignal | null): Promise<Location[]> => {
  const response = await fetch(
    clinicId === 'all'
      ? `${getOmsApiUri()}/locations`
      : `${getOmsApiUri()}/clinics/${clinicId}/locations`,
    {
      signal,
      headers: await getHeaders(),
    },
  )

  const dtos = await response.json() as Location[]

  return dtos
}

const postLocation = async (clinicId: number, location: NewLocation): Promise<Location> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/locations`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify(location),
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as Location

  return dto
}

const putLocation = async (clinicId: number, location: Location): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/locations/${location.id}`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify(location),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

const deleteLocation = async (clinicId: number, locationId: number): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/locations/${locationId}`,
    {
      method: 'DELETE',
      headers: await getMutationHeaders(),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

export {
  locationsKeys,
  getLocations,
  postLocation,
  putLocation,
  deleteLocation,
}
