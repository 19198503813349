import { Trans } from '@lingui/macro'
import { useState } from 'react'

import ConfirmModal from '../../components/modals/ConfirmModal'
import { useDeleteClinic, useQueryClinics } from '../../queries/clinics'
import { AdminClinic, getNewClinic } from '../../types/Clinic'

import { ClinicEditorImpl } from './ClinicEditor'
import EditableList from './EditableList'

const ClinicsEditor = () => {
  const clinics = useQueryClinics({
    includeInactive: true,
  })

  const [confirmDelete, setConfirmDelete] = useState<AdminClinic | null>(null)
  const deleteClinic = useDeleteClinic()

  return (
    <>
      <EditableList
        query={clinics}
        editor={ClinicEditorImpl}
        checkClinicSet={false}
        onNewItem={getNewClinic}
        onSort={(a, b) => a.name.localeCompare(b.name)}
        onShowItem={clinic => clinic.name}
        onDelete={clinic => setConfirmDelete(clinic)}
      />

      <ConfirmModal
        show={confirmDelete !== null}
        title={<Trans>Delete clinic?</Trans>}
        message={
          <Trans>
            Are you sure you want to delete this clinic?
            This operation can be very hard to undo.
          </Trans>
        }
        confirmLabel={<Trans>Yes, delete this clinic</Trans>}
        onConfirm={() => {
          if (!confirmDelete) return

          deleteClinic.mutate(
            confirmDelete.id,
            {
              onSuccess: () => {
                setConfirmDelete(null)
              },
            },
          )
        }}
        onCancel={() => setConfirmDelete(null)}
      />
    </>
  )
}

export default ClinicsEditor
