
import { Trans } from '@lingui/macro'
import { Box, CheckBox, Collapsible } from 'grommet'
import { ReactNode, useState } from 'react'

import { PrescriptionRecursiveKey, PrescriptionRecursiveValue } from '../types/Prescription'
import { requestedSides } from '../types/PrescriptionEnums/RequestedSide'

import FormHeader from './FormHeader'

type SectionProps = {
  title: string,
  isChecked?: boolean,
  isBilateral?: boolean,
  getValue?: <TKey extends PrescriptionRecursiveKey>
    (key: TKey) => PrescriptionRecursiveValue<TKey>,
  setValue?: <TKey extends PrescriptionRecursiveKey>
    (key: TKey, value: PrescriptionRecursiveValue<TKey>) => unknown,
  sideKey?: PrescriptionRecursiveKey,
  level?: 1 | 2 | 3 | 4 | 5 | 6,
  printing?: boolean,
  children: ReactNode,
  leftSideTag?: string,
  rightSideTag?: string,
  fill?: boolean,
  onCheck?: (checked: boolean) => unknown,
  helper?: ReactNode,
}

const FormSection = (props: SectionProps) => {

  const [showSection, setShowSection] = useState<boolean>(props.isChecked === undefined ? true : props.isChecked)

  const smallLevel = props.level === 4 || props.level === 5 || props.level === 6
  const marginSize = smallLevel || props.printing === true ? 'small' : 'medium'
  const margin = smallLevel || props.printing === true ? 'none' : 'medium'

  return (
    <Box
      pad="none"
      margin={{ left: margin, top: margin, bottom: 'none', right: margin }}
      gap="none"

      fill={props.fill === true ? 'horizontal' : false}
    >
      <Box
        direction="row"
        pad="none"
        margin="none"
        gap="medium"
        align="end"
      >
        <FormHeader
          label={props.title}
          level={props.level ?? 3}
          printing={props.printing === true || smallLevel}
          isChecked={props.isChecked ?? true}
          onCheck={props.onCheck ? (check) => {
            if (check === props.isChecked) return
            props.onCheck?.(check)
            setShowSection(check)
          } : null}
          noFill={false}
        />
        {props.sideKey !== undefined &&
          <Collapsible
            open={showSection}
            direction="horizontal"
          >
            <Box
              direction="row"
              margin="none"
              pad="0.1rem"
              gap="small"
              fill
              alignSelf="end"
            >
              <Trans>{props.leftSideTag ?? 'Different'}</Trans>
              <CheckBox
                checked={props.isBilateral
                  ?? props.getValue?.(props.sideKey) === requestedSides.both}
                onChange={(event) => {
                  if (props.sideKey === undefined) return
                  props.setValue?.(props.sideKey, event.currentTarget.checked
                    ? requestedSides.both
                    : requestedSides.left)
                }}
                toggle={true}
                pad={{ horizontal: '0.2rem' }}
              />
              <Trans>{props.rightSideTag ?? 'Bilateral'}</Trans>
            </Box>
          </Collapsible>
        }
        <Collapsible
          open={showSection}
          direction="horizontal"
        >
          {props.helper}
        </Collapsible>
      </Box>
      <Collapsible
        open={showSection}
      >
        <Box
          gap={props.printing === true ? 'none' : 'small'}
          margin={{
            top: marginSize,
            left: marginSize,
            bottom: props.printing === true ? marginSize : 'none',
            right: marginSize,
          }}
          fill
          style={{
            breakInside: 'avoid-page',
          }}
        >
          {props.children}
        </Box>
      </Collapsible>
    </Box>
  )

}
export default FormSection
