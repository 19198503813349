// Allowing import here, since it's here we define the replacement!
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Select as GrommetSelect } from 'grommet'

type Option<TValue extends string | number> = {
  value: TValue,
  label: string,
}

type BaseProps<TValue extends string | number> = Readonly<{
  name?: string | undefined,
  required?: boolean | undefined,
  value: TValue,
  onChange: (value: TValue) => unknown,
  margin?: Parameters<typeof GrommetSelect>[0]['margin'] | undefined,
  size?: 'small' | 'medium' | 'large' | 'xlarge' | string,
}>

type LiteralProps<TValue extends string | number> = BaseProps<TValue> & Readonly<{
  options: TValue[],
}>

type OptionsProps<TValue extends string | number> = BaseProps<TValue> & Readonly<{
  options: Array<Option<TValue>>,
}>

type Props<TValue extends string | number> =
  | LiteralProps<TValue>
  | OptionsProps<TValue>

function Select<TValue extends string | number>(props: Props<TValue>) {
  const handleChange = ({ value }: { value: TValue | Option<TValue> }) => {
    props.onChange(
      typeof value === 'object'
        ? value.value
        : value,
    )
  }

  const handleLabel = (value: Option<TValue> | TValue): string => {
    if (typeof value === 'object') {
      return value.label
    }

    const firstOption = props.options[0]
    if (firstOption === undefined) return String(value)

    if (typeof firstOption !== 'object') {
      return String(value)
    }

    const options = props.options as Array<Option<TValue>>
    const option = options.find(option => option.value === value)
    if (!option) return String(value)
    return option.label
  }

  return (
    <GrommetSelect
      {
      // NOTE(pascal): It may look like we don't expect more props but...
      // If we don't spread all our props, we won't be styled properly.
      // Grommet's FormField looks directly at its children and decides,
      // based on the component's displayName, if it should style it
      // or not, and does so via cloning and passing extra props,
      // we then need to propagate to the real Select here...
        ...props
      }
      name={props.name as never}
      required={props.required}
      options={props.options}
      value={props.value}
      onChange={handleChange}
      labelKey={handleLabel}
      margin={props.margin as never}
      size={props.size as never}
    />
  )
}

Select.displayName = 'Select'

export default Select
