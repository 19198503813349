
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { UseQueryResult } from '@tanstack/react-query'
import { Box, Button, Drop, Grid, Spinner, Text } from 'grommet'
import { FormEdit } from 'grommet-icons'
import { ComponentType, ReactNode, useRef, useState } from 'react'

import { useClinicId } from '../../hooks/clinic'
import { useIsAdmin } from '../../hooks/roles'
import { allClinic } from '../../types/Clinic'

import settingsStyles from './Settings.module.css'

export type EditorProps<TItem, TNewItem> = Readonly<{
  item: TItem | TNewItem,
  onSaved?: (() => unknown) | undefined,
  onCancel?: (() => unknown) | undefined,
  onDelete?: (() => unknown) | undefined,
}>

type EditLineProps<TItem extends { id: number }, TNewItem> = Readonly<{
  item: TItem,
  editor: ComponentType<EditorProps<TItem, TNewItem>>,
  onShowItem: (item: TItem) => ReactNode,
  onDelete: (item: TItem) => unknown,
}>

function EditLine<TItem extends { id: number }, TNewItem>(props: EditLineProps<TItem, TNewItem>) {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const buttonRef = useRef<HTMLElement | null>(null)

  const [edit, setEdit] = useState<boolean>(false)

  const Editor = props.editor

  return (
    <>
      <Grid
        className={settingsStyles['row']}
        style={{
          gridTemplateColumns: '9fr 1fr',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
        pad={{left: '0.75rem'}}
      >
        <Text>{props.onShowItem(props.item)}</Text>
        <Button
          ref={buttonRef as never}
          a11yTitle={t(i18n)`Edit clinician`}
          icon={<FormEdit />}
          onClick={() => setEdit(edit => !edit)}
        />
      </Grid>
      {
        edit &&
        buttonRef.current &&
        <Drop
          target={buttonRef.current}
          round
          pad=".8rem"
          width={{min: '300px'}}
          margin={{top: '1rem', right: '4rem'}}
          align={{top: 'top', right: 'left'}}
          elevation="medium"
        >
          <Editor
            key={props.item.id}
            item={props.item}
            onSaved={() => setEdit(false)}
            onCancel={() => setEdit(false)}
            onDelete={() => props.onDelete(props.item)}
          />
        </Drop>
      }
    </>
  )
}

type EditListProps<TItem extends { id: number }, TNewItem> = Readonly<{
  items: TItem[],
  editor: ComponentType<EditorProps<TItem, TNewItem>>,
  onNewItem: () => TNewItem,
  onSort: (a: TItem, b: TItem) => number,
  onShowItem: (item: TItem) => ReactNode,
  onDelete: (item: TItem) => unknown,
}>

function EditList<TItem extends { id: number }, TNewItem>(props: EditListProps<TItem, TNewItem>) {
  const isAdmin = useIsAdmin()

  const buttonRef = useRef<HTMLElement | null>(null)

  const [createItem, setCreateItem] = useState<TNewItem | null>(null)

  const Editor = props.editor

  return (
    <>
      <Box
        direction="column"
        align="start"
      >
        {
          isAdmin &&
          <Button
            ref={buttonRef as never}
            margin={{bottom: '1rem'}}
            label={<Trans>Create</Trans>}
            onClick={() => setCreateItem(props.onNewItem())}
          />
        }
        {
          props
            .items
            .slice()
            .sort(props.onSort)
            .map(item =>
              <EditLine
                key={item.id}
                item={item}
                editor={props.editor}
                onShowItem={props.onShowItem}
                onDelete={props.onDelete}
              />,
            )
        }
      </Box>
      {
        createItem !== null &&
        buttonRef.current &&
        <Drop
          target={buttonRef.current}
          round
          pad=".8rem"
          width={{min: '300px'}}
          margin={{top: '1rem', right: '4rem'}}
          align={{top: 'top', right: 'left'}}
          elevation="medium"
        >
          <Editor
            item={createItem}
            onSaved={() => setCreateItem(null)}
            onCancel={() => setCreateItem(null)}
          />
        </Drop>
      }
    </>
  )
}

type Props<TItem extends { id: number }, TNewItem> = Readonly<{
  query: UseQueryResult<TItem[]>,
  editor: ComponentType<EditorProps<TItem, TNewItem>>,
  checkClinicSet: boolean,
  onNewItem: () => TNewItem,
  onSort: (a: TItem, b: TItem) => number,
  onShowItem: (item: TItem) => ReactNode,
  onDelete: (item: TItem) => unknown,
}>

function EditableList<TItem extends { id: number }, TNewItem>(props: Props<TItem, TNewItem>) {
  const clinicId = useClinicId()

  return (
    <Box>
      {
        props.checkClinicSet && clinicId === allClinic.id
          ? <Trans>No clinic selected</Trans>
          : (
            props.query.data
              ? (
                <EditList
                  items={props.query.data}
                  editor={props.editor}
                  onNewItem={props.onNewItem}
                  onSort={props.onSort}
                  onShowItem={props.onShowItem}
                  onDelete={props.onDelete}
                />
              )
              : (
                <Box>
                  <Spinner />
                </Box>
              )
          )
      }
    </Box>
  )
}

export default EditableList
