class FetchResponseEvent extends Event {
  #response: Response

  public static readonly eventType = 'fetchResponse'

  public get response(): Response {
    return this.#response
  }

  public constructor(
    response: Response,
    eventInitDict?: EventInit | undefined,
  ) {
    super(FetchResponseEvent.eventType, eventInitDict)
    this.#response = response
  }
}

export {
  FetchResponseEvent,
}
