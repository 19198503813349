import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'

type LoadingContextType = {
  isLoading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
}

export const UseLoadingContext = createContext<LoadingContextType>({
  isLoading: false,
  setLoading: () => {/* Void */},
})

type Props = {
  children: ReactNode,
}

const LoadingProvider = (props: Props) => {
  const [isLoading, setLoading] = useState(false)

  return (
    <UseLoadingContext.Provider value={{ isLoading, setLoading }}>
      {props.children}
    </UseLoadingContext.Provider>
  )
}

export default LoadingProvider

export const useLoading = () => useContext(UseLoadingContext)
