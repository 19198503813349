import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const postingPositions = {
  none: 0,
  rearfootAndForefoot: 1,
  forefoot: 2,
  rearfoot: 3,
} as const

export type PostingPosition = typeof postingPositions[keyof typeof postingPositions]

export const getPostingPositionLabel = (i18n: I18n, value: PostingPosition) => {
  switch (value) {
  case postingPositions.none: return t(i18n)`None`
  case postingPositions.rearfootAndForefoot: return t(i18n)`RF & FF`
  case postingPositions.forefoot: return t(i18n)`FF only`
  case postingPositions.rearfoot: return t(i18n)`RF only`
  default: return t(i18n)`Unknown posting position option`
  }
}
