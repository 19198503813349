import { Auth } from '@aws-amplify/auth'
import { Plural, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Route, useNavigate, useParams } from '@tanstack/react-router'
import { Box, Button, DataTable, FileInput, Form, FormField, Heading, Layer, Spinner, TextInput } from 'grommet'
import { useEffect, useMemo, useRef, useState } from 'react'

import CollapsiblePanel from '../../components/CollapsiblePanel'
import { Select } from '../../components/grommet-sub'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { getOnlineViewerUri } from '../../constants/runtime'
import { getOrder, ordersKeys } from '../../fetchers/orders'
import { downloadScan, getScans, scansKeys } from '../../fetchers/scans'
import { useClinicFromId } from '../../hooks/clinic'
import { useLoading } from '../../hooks/loading'
import { useIsAdmin } from '../../hooks/roles'
import { usePushToVirtualRhino, useQueryOrder } from '../../queries/orders'
import { useCreateScan, useCreateScans, useDeleteScan, useQueryScans, useUpdateScanStatus } from '../../queries/scans'
import { getQueryClient } from '../../queryClient'
import { notificationActions } from '../../store/actions/notifications'
import { useDispatch } from '../../store/store'
import { activeSides } from '../../types/ActiveSide'
import { Order } from '../../types/Order'
import { Scan } from '../../types/Scan'
import { getScanStatusLabel, getScanStatusOptions, scanStatuses } from '../../types/ScanStatus'
import { toTableDateTime } from '../../utils/date'
import { triggerDownload } from '../../utils/download'
import { getFilename, getFilenameAndExtension } from '../../utils/files'
import MainPanel from '../home/MainPanel'

const sendToVrToastId = 'toasts.send-to-vr'

const fileTypes = new Set(['stl', 'obj', 'ply'])

let fileTypesNames = ''
for (const fileType of fileTypes) {
  fileTypesNames += fileType + ' '
}

const validateFileTypes = (files: File[] | undefined): File[] => {
  if (!files) return []

  const validFiles: File[] = []
  for (const file of files) {
    const extensions = file.name.split('.')
    if (fileTypes.has(extensions[extensions.length - 1] ?? 'no')) {
      validFiles.push(file)
    }
  }

  return validFiles
}

const openOnlineViewer = async (
  setMesherJwtToken: (token: string) => unknown,
) => {
  const session = await Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()

  setMesherJwtToken(token)
}

const downloadScanForViewer = async (
  clinicId: number,
  scanId: number,
  viewerRef: HTMLIFrameElement,
) => {
  const scan = await downloadScan(clinicId, scanId)
  const data = await scan.arrayBuffer()
  const message = { message: 'sendPlyFile', value: data }

  let listened = false
  const origin = getOnlineViewerUri()

  const pongListener = (event: MessageEvent<{ message: string }>) => {
    if (event.origin !== origin) return

    if (event.data.message === 'pong') {
      listened = true
    }
  }

  const ping = () => {
    viewerRef.contentWindow?.postMessage({ message: 'ping' }, '*')
  }

  const wait = (resolve: (value: undefined) => unknown) => {
    if (listened) {
      resolve(undefined)
    } else {
      ping()
      setTimeout(() => wait(resolve), 10)
    }
  }

  window.addEventListener('message', pongListener)
  ping()
  new Promise(resolve => wait(resolve))
    .then(() => {
      window.removeEventListener('message', pongListener)

      viewerRef.contentWindow
        ?.postMessage(message, origin)
    })
    .catch(console.error)
}

type Props = {
  order: Order,
  scans: Scan[],
}

const ScanPanel = (props: Props) => {
  const params = useParams({ from: '/orders/clinics/$clinicId/orders/$orderId/scans' })
  const clinic = useClinicFromId(params.clinicId)

  const lingui = useLingui()
  const i18n = lingui.i18n

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isAdmin = useIsAdmin()

  const { isLoading, setLoading } = useLoading()

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [isValid, setValid] = useState(false)
  const [mesherJwtToken, setMesherJwtToken] = useState<string | null>(null)
  const [rxFile, setRxFile] = useState<string>('')

  const [sendingScansToVr, setSendingScansToVr] = useState<boolean>(false)
  const [scansSentToVr, setScansSentToVr] = useState<boolean>(false)

  const viewerRef = useRef<HTMLIFrameElement | null>(null)
  const [clinicId, setClinicId] = useState<number>(0)
  const [scan, setScan] = useState<Scan | null>(null)

  const scanStatusOptions = useMemo(
    () => getScanStatusOptions(i18n),
    [i18n],
  )

  const createScan = useCreateScan()
  const createScans = useCreateScans()
  const updateScanStatus = useUpdateScanStatus()
  const deleteScan = useDeleteScan()

  const pushToVirtualRhino = usePushToVirtualRhino()

  const uploadFiles = async () => {
    setLoading(true)

    const scans = files.map(file => {
      const { fileName, extension } = getFilenameAndExtension(file.name)

      return {
        fileName: fileName,
        extension: extension,
        filePath: `${clinic.name}/${props.order.location?.description ?? ''}`,
        side: fileName.toLocaleUpperCase().includes('LEFT')
          ? activeSides.left
          : activeSides.right,
        status: fileName.toLocaleUpperCase().includes('_MODIF')
          ? scanStatuses.treated
          : scanStatuses.uploaded,
        file,
      }
    })

    await createScans.mutateAsync({
      clinicId: params.clinicId,
      orderId: params.orderId,
      scans: scans,
    })

    setLoading(false)
    await navigate({ to: '/' })
  }

  const handleViewerRef = (ref: HTMLIFrameElement | null) => {
    const current = viewerRef.current
    viewerRef.current = ref

    if (current) return
    if (!ref) return
    if (!scan) return

    downloadScanForViewer(clinicId, scan.id, ref)
      .catch(console.error)
  }

  const columns = useMemo(
    () => {
      const columns = [
        {
          property: 'status',
          header: 'Status',
          render: (scan: Scan) => (
            <Select
              options={scanStatusOptions}
              value={scan.status}
              onChange={value => {
                setLoading(true)
                updateScanStatus.mutate(
                  {
                    clinicId: params.clinicId,
                    orderId: params.orderId,
                    scanId: scan.id,
                    status: value,
                  },
                  {
                    onSettled: () => {
                      setLoading(false)
                    },
                  },
                )
              }}
            />
          ),
        },
        {
          property: 'createdAt',
          header: 'Date Created',
          render: (order: { createdAt: Date }) => {
            return toTableDateTime(i18n, order.createdAt)
          },
        },
        {
          property: 'download',
          header: 'Filename / Download',
          render: (scan: Scan) => (
            <Box>
              <Button
                onClick={async () => {
                  const blob = await downloadScan(props.order.clinicId, scan.id)
                  triggerDownload(blob, scan.fileName, scan.extension)
                }}
                label={getFilename(scan).substring(0, 35)}
              />
            </Box>
          ),
        },
      ]

      if (isAdmin) {
        columns.push({
          property: 'mesher',
          header: 'Scan2Mesh',
          render: (scan: Scan) => (
            <Box>
              <Button
                disabled={scan.status !== scanStatuses.raw}
                label={getScanStatusLabel(i18n, scan.status)}
                onClick={async () => {
                  await openOnlineViewer(
                    setMesherJwtToken,
                  )
                  setClinicId(props.order.clinicId)
                  setScan(scan)
                }}
              />
            </Box>
          ),
        })
      }

      return columns
    },
    [
      i18n,
      params,
      scanStatusOptions,
      setLoading,
      isAdmin,
      updateScanStatus,
      props.order.clinicId,
    ],
  )

  let mesherArgs = null
  if (mesherJwtToken !== null && scan !== null) {
    const slash = scan.filePath.endsWith('/') ? '' : '/'
    const basename = `public/${scan.filePath}${slash}${scan.fileName}`

    const params = new URLSearchParams([
      ['scanId', String(scan.id)],
      ['bucket', String(scan.awsBucket)],
      ['inputPath', `${basename}.${scan.extension}`],
      ['outputPath', `${basename}_MODIF.obj`],
      ['viewerOnly', String(!isAdmin)],
      ['token', mesherJwtToken],
    ])

    mesherArgs = `?${params.toString()}`
  }

  useEffect(
    () => {
      const origin = getOnlineViewerUri()

      const handler = (event: MessageEvent<{ message: string }>) => {
        if (event.origin !== origin) return

        if (event.data.message === 'scanTreated') {
          if (!scan) return

          createScan.mutate(
            {
              clinicId: clinicId,
              orderId: params.orderId,
              scan: {
                fileName: scan.fileName,
                extension: 'obj',
                filePath: `${clinic.name}/${props.order.location?.description ?? ''}`,
                side: scan.fileName.toLocaleUpperCase().includes('-LEFT')
                  ? activeSides.left
                  : activeSides.right,
                status: scanStatuses.treated,
              },
            },
            {
              onSuccess: () => {
                setLoading(false)
                setMesherJwtToken(null)
                setScan(null)
              },
            },
          )
        }
      }

      window.addEventListener('message', handler)
      return () => window.removeEventListener('message', handler)
    },
    [
      clinic.name,
      clinicId,
      createScan,
      params.orderId,
      props.order.location?.description,
      scan,
      setLoading,
    ],
  )

  return (
    <>
      <CollapsiblePanel>
        <Box>
          <Heading level="2">
            <Box
              direction="row"
              gap="0.3rem"
              alignContent="center"
              height={'48px'}
            >
              Scans - {props.order.patient.lastName},{' '}
              {props.order.patient.firstName}
              {isLoading && <Spinner size="medium" />}
            </Box>
          </Heading>
          <DataTable<Scan>
            background={{ pinned: 'light-2' }}
            columns={columns}
            verticalAlign="top"
            align="left"
            primaryKey="id"
            data={props.scans}
            sortable
            pin
            select={selectedIds}
            onSelect={(ids) => setSelectedIds(ids.map(Number))}
          />
          <Form validate="change">
            <FormField
              name="scans"
              validate={[
                (files: File[]) => {
                  let valid = true
                  let error: string | undefined = undefined
                  for (const file of files) {
                    const extention = file.name.split('.')
                    if (!fileTypes.has(extention[extention.length - 1] ?? 'no ext')) {
                      valid = false
                      error = `Uploaded files can only be of type ${fileTypesNames}`
                      break
                    }
                  }
                  setValid(valid)
                  return error
                },
              ]}
            >
              <FileInput
                messages={{
                  browse: 'Browse',
                  files: 'files',
                  dropPrompt: 'Drag file to upload here',
                  dropPromptMultiple: 'Drag file to upload here',
                  remove: 'Remove',
                  removeAll: 'Remove All',
                }}
                multiple={true}
                name="scans"
                onChange={(_, target) => {
                  const files = validateFileTypes(target?.files)
                  setFiles(files)
                }}
              />
            </FormField>
            <Button
              disabled={!isValid}
              primary
              onClick={uploadFiles}
              label="Upload"
            />
            <Button
              secondary
              disabled={sendingScansToVr || scansSentToVr}
              onClick={() => {
                if (sendingScansToVr || scansSentToVr) return

                setSendingScansToVr(true)
                dispatch(notificationActions.clearToast(sendToVrToastId))
                pushToVirtualRhino.mutate(
                  {
                    clinicId: props.order.clinicId,
                    orderId: props.order.id,
                    rxPath: rxFile,
                  },
                  {
                    onSuccess: () => {
                      setScansSentToVr(true)
                      dispatch(notificationActions.showSuccessToast(
                        sendToVrToastId,
                        <Trans>Scans sent to VirtualRhino for processing</Trans>,
                      ))
                    },
                    onError: (error) => {
                      let message
                      if (error.message.includes('Body: "rx"')) {
                        message = <Trans>Could not send scans to VirtualRhino: missing prescription JSON</Trans>
                      } else {
                        message = <Trans>Could not send scans to VirtualRhino</Trans>
                      }

                      dispatch(notificationActions.showErrorToast(
                        sendToVrToastId,
                        message,
                      ))
                    },
                    onSettled: () => {
                      setSendingScansToVr(false)
                    },
                  },
                )
              }}
              label={<Trans>Send to VirtualRhino</Trans>}
            />
            <TextInput
              value={rxFile}
              onChange={(change) => setRxFile(change.currentTarget.value.trim())}
            />
            {
              selectedIds.length > 0 &&
              <Button
                onClick={() => setConfirmDelete(true)}
                label="Delete Selection"
              />
            }
          </Form>
        </Box>
        {
          mesherJwtToken === null ||
          mesherArgs === null
            ? null
            : (
              <Layer
                onClickOutside={() => {
                  setMesherJwtToken(null)
                }}
              >
                <iframe
                  id="OnlineViewer"
                  title="OnlineViewer"
                  ref={handleViewerRef}
                  src={
                    `${getOnlineViewerUri()}/public/index.html${mesherArgs}`
                  }
                  style={{
                    width: '90vh',
                    height: '90vh',
                  }}
                />
              </Layer>
            )
        }
      </CollapsiblePanel>
      <ConfirmModal
        show={confirmDelete}
        title={
          <Plural
            value={selectedIds.length}
            one="Delete file?"
            other="Delete files?"
          />
        }
        message={
          <Plural
            value={selectedIds.length}
            one="Are you sure you want to delete this file? This operation is IRREVERSIBLE."
            other="Are you sure you want to delete # files? This operation is IRREVERSIBLE."
          />
        }
        confirmLabel={
          <Plural
            value={selectedIds.length}
            one="Yes, delete this file forever"
            other="Yes, delete these files forever"
          />
        }
        onConfirm={() => {
          setLoading(true)

          Promise
            .all(
              selectedIds.map(scanId => {
                return deleteScan.mutateAsync({
                  clinicId: params.clinicId,
                  orderId: params.orderId,
                  scanId: scanId,
                })
              }),
            )
            .then(() => {
              setSelectedIds([])
            })
            .catch(error => console.error(error))
            .finally(() => {
              setLoading(false)
              setConfirmDelete(false)
            })
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  )
}

const ScanPanelLoader = () => {
  const params = useParams({ from: '/orders/clinics/$clinicId/orders/$orderId/scans' })
  const order = useQueryOrder(params.clinicId, params.orderId)
  const scans = useQueryScans(params.clinicId, params.orderId)

  if (!order.data) return null
  if (!scans.data) return null

  return (
    <ScanPanel
      order={order.data}
      scans={scans.data}
    />
  )
}

ScanPanel.route = new Route({
  getParentRoute: () => MainPanel.route,
  path: 'clinics/$clinicId/orders/$orderId/scans',
  parseParams: params => ({
    clinicId: Number(params.clinicId),
    orderId: Number(params.orderId),
  }),
  onLoad: async ({ params, signal }) => {
    const queryClient = getQueryClient()

    await queryClient.prefetchQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ordersKeys.details(params.clinicId, params.orderId),
      queryFn: () => getOrder(params.clinicId, params.orderId, signal ?? null),
    })

    await queryClient.prefetchQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: scansKeys.list(params.clinicId, params.orderId),
      queryFn: () => getScans(params.clinicId, params.orderId, signal ?? null),
    })
  },
  component: ScanPanelLoader,
})

export default ScanPanel
