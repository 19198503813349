import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const shoeSizeSystems = {
  us: 0,
  eu: 1,
} as const

export type ShoeSizeSystem = typeof shoeSizeSystems[keyof typeof shoeSizeSystems]

export const getShoeSizeSystemLabel = (i18n: I18n, system: ShoeSizeSystem) => {
  switch (system) {
  case shoeSizeSystems.us: return t(i18n)`US`
  case shoeSizeSystems.eu: return t(i18n)`EU`
  default: return t(i18n)`Unknown`
  }
}
