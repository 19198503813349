import { useEffect, useState } from 'react'

function useDebounce<T>(value: T, delayMs?: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(
    () => {
      const timer = setTimeout(() => setDebouncedValue(value), delayMs ?? 500)

      return () => {
        clearTimeout(timer)
      }
    },
    [
      value,
      delayMs,
    ],
  )

  return debouncedValue
}

export {
  useDebounce,
}
