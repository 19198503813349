import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const postingOptions = {
  none: 0,
  neutral: 1,
  varus: 2, //(Anti-Pron.)
  valgus: 3, //(Anti-Sup.)
} as const

export type PostingOption = typeof postingOptions[keyof typeof postingOptions]

export const getPostingOptionLabel = (i18n: I18n, value: PostingOption) => {
  switch (value) {
  case postingOptions.none: return t(i18n)`None`
  case postingOptions.neutral: return t(i18n)`Neutral`
  case postingOptions.varus: return t(i18n)`Varus (Anti-Pron.)`
  case postingOptions.valgus: return t(i18n)`Valgus (Anti-Sup.)`
  default: return t(i18n)`Unknown posting option`
  }
}
