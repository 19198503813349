import { AwsRum } from 'aws-rum-web'
import { createContext, useContext, useLayoutEffect } from 'react'

type TrackerContextType = {
  awsRum: AwsRum | null,
}

const TrackerContext = createContext<TrackerContextType>({
  awsRum: null,
})

const useTrackerMetadata = (key: string, value: string) => {
  const context = useContext(TrackerContext)

  useLayoutEffect(() => {
    context.awsRum?.addSessionAttributes({ [key]: value })
  }, [key, value, context.awsRum])
}

export {
  useTrackerMetadata,
  TrackerContext,
}
