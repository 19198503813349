
import { devToolsEnhancer } from '@redux-devtools/extension'
// Forbid this import elsewhere so we have the pre-typed versions used instead!
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { EqualityFn, useDispatch as useDispatchOrig, useSelector as useSelectorOrig } from 'react-redux'
import { combineReducers, createStore } from 'redux'

import notificationsReducer from './reducers/notifications'
import userContextReducer from './reducers/userContext'

const reducer = combineReducers({
  userContext: userContextReducer,
  notifications: notificationsReducer,
})

const store = createStore(
  reducer,
  devToolsEnhancer(),
)

export type State = ReturnType<typeof store.getState>
export type Action = Parameters<typeof store.dispatch>[0]
export type Dispatch = typeof store.dispatch

const useDispatch = () => {
  return useDispatchOrig<Dispatch>()
}

function useSelector<T>(selector: (state: State) => T, equalityFn?: EqualityFn<T> | undefined): T {
  return useSelectorOrig<State, T>(selector, equalityFn)
}

export {
  store,
  useDispatch,
  useSelector,
}
