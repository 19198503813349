import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { SelectOption } from './SelectOption'

export const scanMethods = {
  foambox: 0,
  direct: 1,
  fiberglass: 2,
} as const

export type ScanMethod = typeof scanMethods[keyof typeof scanMethods]

export const getScanMethodOptions = (i18n: I18n): Array<SelectOption<ScanMethod>> => [
  {
    label: t(i18n)`Non-Weight Bearing (Direct)`,
    value: scanMethods.direct,
  },
  {
    label: t(i18n)`Semi-Weight Bearing (FoamBox)`,
    value: scanMethods.foambox,
  },
  {
    label: t(i18n)`Non-Weight Bearing (Fiberglass)`,
    value: scanMethods.fiberglass,
  },
]
