import { Clinician } from '../types/Clinician'
import { Patient } from '../types/Patient'

const memoizedFullNames: Record<number, string> = {}

const getPersonFullName = (person: Patient | Clinician | null | undefined): string => {
  if (!person) return ''

  let name = memoizedFullNames[person.id]
  if (name !== undefined) return name

  name =
    person.firstName === '' &&
    person.lastName === ''
      ? ''
      : `${person.firstName} ${person.lastName}`

  name = name.trim()

  memoizedFullNames[person.id] = name
  return name
}

export {
  getPersonFullName,
}
