import { Router } from '@tanstack/react-router'

import Root from './pages/Root'
import MainPanel from './pages/home/MainPanel'
import CommentPanel from './pages/orders/CommentPanel'
import ErrorsPanel from './pages/orders/ErrorsPanel'
import ModelPanel from './pages/orders/FilesPanel'
import InternalClinicNotesPanel from './pages/orders/InternalClinicNotesPanel'
import ScanPanel from './pages/orders/ScansPanel'
import PrescriptionPanel from './pages/prescriptions/PrescriptionPanel'
import ValidationPage from './pages/validation/ValidationPage'

const routeTree = Root.route.addChildren([
  MainPanel.route.addChildren([
    PrescriptionPanel.route,
    ScanPanel.route,
    ModelPanel.route,
    CommentPanel.route,
    InternalClinicNotesPanel.route,
    ErrorsPanel.route,
  ]),
  ValidationPage.route,
])

const router = new Router({
  routeTree,
  defaultPreload: 'intent',
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router,
  }
}

export {
  router,
}
