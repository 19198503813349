
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Accordion, AccordionPanel, Box } from 'grommet'
import { ComponentType } from 'react'

import { useClinic } from '../../hooks/clinic'
import { useIsAdmin, useIsOverridingRole, useIsTrulyAdmin } from '../../hooks/roles'
import { allClinic } from '../../types/Clinic'

import ClinicEditor from './ClinicEditor'
import CliniciansEditor from './CliniciansEditor'
import ClinicsEditor from './ClinicsEditor'
import LocationsEditor from './LocationsEditor'
import LogoSelector from './LogoSelector'
import OverrideRole from './OverrideRole'

const getComponentAccordion = (label: string, Component: ComponentType) => () =>
  <AccordionPanel label={label}>
    <Box pad={{ horizontal: '1rem', bottom: '1rem' }}>
      <Component />
    </Box>
  </AccordionPanel>

const showComponent = (Component: ComponentType) =>
  <Component />

const showComponentIfAdmin = (isAdmin: boolean, Component: ComponentType) =>
  isAdmin ? <Component /> : null

const SettingsPanel = () => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const isAdmin = useIsAdmin()
  const isTrulyAdmin = useIsTrulyAdmin()
  const isOverriding = useIsOverridingRole()
  const clinic = useClinic()

  const showIfAdmin = (component: ComponentType) => showComponentIfAdmin(isAdmin, component)
  const showIfTrulyAdmin = (component: ComponentType) => showComponentIfAdmin(isTrulyAdmin && !isOverriding, component)

  return (
    <Box
      height="100vh"
      pad="1rem"
    >
      <Accordion
        animate={true}
      >
        {showIfAdmin(getComponentAccordion(`${t(i18n)`Edit current clinic`}
          ${isAdmin && clinic !== allClinic? (` - (${clinic.id})`) : ''}`, ClinicEditor))}
        {showIfAdmin(getComponentAccordion(t(i18n)`Clinics`, ClinicsEditor))}
        {showComponent(getComponentAccordion(t(i18n)`Clinicians`, CliniciansEditor))}
        {showComponent(getComponentAccordion(t(i18n)`Locations`, LocationsEditor))}
        {showIfAdmin(getComponentAccordion(t(i18n)`Logos`, LogoSelector))}
        {showIfTrulyAdmin(getComponentAccordion(t(i18n)`Override role`, OverrideRole))}
      </Accordion>
    </Box>
  )
}

export default SettingsPanel
