
import { Trans } from '@lingui/macro'
import { Box, Image, Text } from 'grommet'
import { ReactNode } from 'react'

import { Location } from '../types/Location'

type Props = {
  id: string,
  name: ReactNode,
  location: Location,
  clinicName: string,
  title: ReactNode,
  getDate: () => ReactNode,
}

const PrintableCertificate = (props: Props) => {
  return (
    <Box
      id={props.id}
      margin="3rem"
      alignContent="center"
      width="80%"
      className="printableCertificate"
    >
      <Box
        margin={{ top: '3rem' }}
        direction="row"
        width="100%"
      >
        <Image
          width="30%"
          height="30%"
          src="/15.png"
        />
        <Box
          align="end"
          width="80%"
        >
          <Text
            margin="none"
            size="xxlarge"
            max-width="80%"
            textAlign="end"
          >
            Date: {props.getDate()}<br />
            {props.clinicName}<br />
            {props.location.civicNumber}&nbsp;
            {props.location.streetName}&nbsp;
            {props.location.city},<br />
            {props.location.state},&nbsp;
            {props.location.country},&nbsp;
            {props.location.zipCode.toUpperCase()}
          </Text>
        </Box>
      </Box>
      <Box
        margin={{ top: '3rem' }}
        align="center"
      >
        <Text
          margin="none"
          size="xxlarge"
          max-width="80%"
        >
          {props.title}
        </Text>
      </Box>
      <Box margin={{ top: '3rem' }}>
        <Text
          margin="none"
          size="xxlarge"
          max-width="80%"
        >
          <Trans>To whom it may concern, </Trans>
        </Text>
      </Box>
      <Box
        margin={{ top: '1.5rem' }}
        max-width="80%"
      >
        <Text
          size="xxlarge"
          max-width="80%"
        >
          <Trans>
            The following certifies that the plantar orthosis for Mr./Mrs. {props.name} were
            made by Podform3D. The orthosis are always made from the 3D digitization
            of the foot, the plastered imprint or the imprinted foambox taken by the
            professionnal with the associated prescription.
          </Trans>
        </Text>
      </Box>
      <Box
        margin={{ top: '1.5rem' }}
        max-width="80%"
      >
        <Text
          size="xxlarge"
          max-width="80%"
        >
          <Trans>
            The orthosis' 3D model is then generated, respecting perfectly the shape
            from the scan as well as the podiatric prescription. The model is printed
            using selective laser sintering with nylon 12 material. If required by
            the professionnal, the orthosis is assembled with our best quality cover
            materials. Podform3D products are able to stand out from the
            competition with its reliable modelisation along the top of the line
            engineering.
          </Trans>
        </Text>
      </Box>
      <Box
        margin={{ top: '3rem' }}
        width="80%"
        align="start"
      >
        <Text
          margin="none"
          size="xxlarge"
          max-width="80%"
        >
          <Trans>Hoping everything complies with your request.</Trans>
        </Text>
      </Box>
      <Box
        margin={{ top: '2rem' }}
        width="80%"
        align="start"
      >
        <Text
          margin="none"
          size="xxlarge"
          max-width="80%"
        >
          Dr Antonin G.Bérubé, <Trans>Podiatrist</Trans> #17008
        </Text>
      </Box>
      <Box
        width="80%"
        align="start"
      >
        <Text
          margin="none"
          size="medium"
          max-width="80%"
          weight={200}
        >
          <Trans>
            Podform3D's Director-Owner
          </Trans>
          <br /><br />
          <Trans>51 Sherbrooke Street W</Trans><br />
          Montréal QC H2X 1X2
        </Text>
      </Box>
    </Box>
  )
}

export default PrintableCertificate
