import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { FlexMidfootPerforated, flexMidfootPerforatedValues, getFlexMidfootPerforatedLabel } from '../../types/PrescriptionEnums/FlexMidfootPerforated'
import { ForefootWidth, forefootWidthValues, getForefootWidthLabel } from '../../types/PrescriptionEnums/ForefootWidth'
import { Gender, genders, getShortLabel } from '../../types/PrescriptionEnums/Gender'
import { KineticExtensions, getKineticExtensionsLabel, kineticExtensions } from '../../types/PrescriptionEnums/KineticExtension'
import { LowerMedialArch, getLowerMedialArchLabel, lowerMedialArches } from '../../types/PrescriptionEnums/LowerMedialArch'
import { PostingCorrection, postingCorrections } from '../../types/PrescriptionEnums/PostingCorrection'
import { PostingOption, getPostingOptionLabel, postingOptions } from '../../types/PrescriptionEnums/PostingOption'
import { PostingPosition, getPostingPositionLabel, postingPositions } from '../../types/PrescriptionEnums/PostingPosition'
import { RequestedSide, requestedSides } from '../../types/PrescriptionEnums/RequestedSide'
import { RetroPadOrientations, RetroPadSizes, getRetroPadOrientationsLabel, getRetroPadSizesLabel, retroPadOrientations, retroPadSizes } from '../../types/PrescriptionEnums/RetroPads'
import { ShellRigidity, getShellRigidityLabel, shellRigidities } from '../../types/PrescriptionEnums/ShellRigidity'
import { StiffMidfootBar, getStiffMidfootBarLabel, stiffMidfootBars } from '../../types/PrescriptionEnums/StiffMidFootBar'
import { TopCoverLength, TopCoverMaterial, getTopCoverLengthLabel, getTopCoverMaterialLabel, topCoverLengths, topCoverMaterials } from '../../types/PrescriptionEnums/TopCoverLength'
import { TopCoverThickness, getTopCoverThicknessLabel, topCoverThicknesses } from '../../types/PrescriptionEnums/TopCoverThickness'

function makeOptionsLabel<T>(...options: Array<[T, string]>): Array<{ value: T; label: string }> {
  return options
    .map(([value, label]) => ({ value, label }))
}

function makeOptions<T>(
  labelizer: (i18n: I18n, option: T) => string,
  ...options: T[]
): (i18n: I18n) => Array<{ value: T; label: string }> {
  return i18n => options
    .map(value => ({ value, label: labelizer(i18n, value) }))
}

const getProductionOptions = (i18n: I18n): Array<{ value: RequestedSide; label: string }> =>
  makeOptionsLabel(
    [requestedSides.left, t(i18n)`Left Only`],
    [requestedSides.right, t(i18n)`Right Only`],
  )

const getGenderOptions = makeOptions<Gender>(
  getShortLabel,
  genders.man,
  genders.woman,
  genders.kid,
)

const getSideOptions = (i18n: I18n): Array<{ value: RequestedSide; label: string }> =>
  makeOptionsLabel(
    [requestedSides.left, t(i18n)`L`],
    [requestedSides.right, t(i18n)`R`],
    [requestedSides.both, t(i18n)`B/L`],
  )

const getSideWithNoneOptions = (i18n: I18n): Array<{ value: RequestedSide | 'none'; label: string }> => [
  ...makeOptionsLabel(
    ['none' as const, t(i18n)`None`],
  ),
  ...getSideOptions(i18n),
]

const getShellRigidityOptions = makeOptions<ShellRigidity>(
  getShellRigidityLabel,
  shellRigidities.flexible,
  shellRigidities.semiFlex,
  shellRigidities.standard,
  shellRigidities.semiRigid,
  shellRigidities.rigid,
)

const getForefootWidthOptions = makeOptions<ForefootWidth>(
  getForefootWidthLabel,
  forefootWidthValues.narrow,
  forefootWidthValues.standard,
  forefootWidthValues.wide,
)

const getFlexMidfootPerforatedOptions = makeOptions<FlexMidfootPerforated>(
  getFlexMidfootPerforatedLabel,
  flexMidfootPerforatedValues.none,
  flexMidfootPerforatedValues.flexMedialArch,
  flexMidfootPerforatedValues.flexLateralArch,
  flexMidfootPerforatedValues.flexMedialAndLateralArch,
)

const getStiffMidfootBarOptions = makeOptions<StiffMidfootBar>(
  getStiffMidfootBarLabel,
  stiffMidfootBars.none,
  stiffMidfootBars.stiffMedialArch,
  stiffMidfootBars.stiffLateralArch,
)

const getLowerMedialArchOptions = makeOptions<LowerMedialArch>(
  getLowerMedialArchLabel,
  lowerMedialArches.noFlex,
  lowerMedialArches.flexible,
  lowerMedialArches.veryFlexible,
)

const getPostingOptions = makeOptions<PostingOption>(
  getPostingOptionLabel,
  postingOptions.none,
  postingOptions.neutral,
  postingOptions.varus,
  postingOptions.valgus,
)

const getPostingPositionOptions = makeOptions<PostingPosition>(
  getPostingPositionLabel,
  postingPositions.rearfootAndForefoot,
  postingPositions.rearfoot,
  postingPositions.forefoot,
)

const getPostingCorrectionOptions = makeOptions<PostingCorrection>(
  (_, value) => String(value),
  postingCorrections.three,
  postingCorrections.six,
)

const getTopCoverLengthOptions = makeOptions<TopCoverLength>(
  getTopCoverLengthLabel,
  topCoverLengths.mets,
  topCoverLengths.sulcus,
  topCoverLengths.toes,
)

const getTopCoverMaterialOptions = makeOptions<TopCoverMaterial>(
  getTopCoverMaterialLabel,
  topCoverMaterials.active,
  topCoverMaterials.cushion,
)

const getAllTopCoverMaterialOptions = makeOptions<TopCoverMaterial>(
  getTopCoverMaterialLabel,
  topCoverMaterials.thin,
  topCoverMaterials.active,
  topCoverMaterials.cushion,
)

const getTopCoverThicknessOptions = makeOptions<TopCoverThickness>(
  getTopCoverThicknessLabel,
  topCoverThicknesses.none,
  topCoverThicknesses['1mm'],
  topCoverThicknesses['2mm'],
  topCoverThicknesses['3mm'],
)

const getKineticExtensionOptions = makeOptions<KineticExtensions>(
  getKineticExtensionsLabel,
  kineticExtensions['1'],
  kineticExtensions['2'],
  kineticExtensions['3'],
  kineticExtensions['4'],
  kineticExtensions['5'],
)

const getRetroPadSizeOptions = makeOptions<RetroPadSizes>(
  getRetroPadSizesLabel,
  retroPadSizes.none,
  retroPadSizes.small,
  retroPadSizes.medium,
)

const getRetroPadOrientations = makeOptions<RetroPadOrientations>(
  getRetroPadOrientationsLabel,
  retroPadOrientations['2-3 mets'],
  retroPadOrientations['3-4 mets'],
)

export {
  getProductionOptions,
  getGenderOptions,
  getSideOptions,
  getSideWithNoneOptions,
  getShellRigidityOptions,
  getForefootWidthOptions,
  getFlexMidfootPerforatedOptions,
  getStiffMidfootBarOptions,
  getLowerMedialArchOptions,
  getPostingOptions,
  getPostingPositionOptions,
  getPostingCorrectionOptions,
  getTopCoverLengthOptions,
  getAllTopCoverMaterialOptions,
  getTopCoverMaterialOptions,
  getTopCoverThicknessOptions,
  getKineticExtensionOptions,
  getRetroPadSizeOptions,
  getRetroPadOrientations,
}
