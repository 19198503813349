import { Box, CheckBox, Heading, Text } from 'grommet'
import { ReactNode } from 'react'

type Props = {
  header: ReactNode,
  checked: boolean,
}

const PrintableCheckbox = (props: Props) => {
  return (
    <Box
      margin="none"
      pad="none"
      width={'100%'}
    >
      <Text>
        <Heading
          margin={{ top: 'none', bottom: 'xsmall' }}
          level={5}
        >
          {props.header}
        </Heading>
        <CheckBox
          type={'checkbox'}
          disabled
          // margin="none"
          checked={props.checked}
        />
      </Text>
    </Box>
  )
}

export default PrintableCheckbox
