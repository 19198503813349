import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const shellRigidities = {
  flexible: 24, // (2.4mm=<100lbs)
  semiFlex: 26, // (2.6mm=100-150 lbs)
  standard: 28, // (2.8mm=150-200 lbs)
  semiRigid: 30, // (3mm=200-250 lbs)
  rigid: 32, // (3.2mm =>250 lbs)
} as const

export type ShellRigidity = typeof shellRigidities[keyof typeof shellRigidities]

export const getShellRigidityLabel = (i18n: I18n, value: ShellRigidity) => {
  switch (value) {
  case shellRigidities.flexible: return t(i18n)`Flexible (2.4mm < 100lbs)`
  case shellRigidities.semiFlex: return t(i18n)`Semi-Flex (2.6mm = 100-150 lbs)`
  case shellRigidities.standard: return t(i18n)`Standard (2.8mm = 150-200 lbs)`
  case shellRigidities.semiRigid: return t(i18n)`Semi-Rigid (3mm = 200-250 lbs)`
  case shellRigidities.rigid: return t(i18n)`Rigid (3.2mm > 250 lbs)`
  default: return t(i18n)`Unknown shell rigidity`
  }
}
