import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { BillingStatus, billingStatuses } from '../types/BillingStatus'
import { OrderStatus, orderStatuses } from '../types/OrderStatus'
import { PrescriptionStatus, prescriptionStatuses } from '../types/PrescriptionEnums/PrescriptionStatus'

export type StatusDisplay = { label: string; color: `#${string}`; tip: string }

export const getOrderStatusDisplays = (i18n: I18n): Record<OrderStatus, StatusDisplay> => ({
  [orderStatuses.incomplete]: {
    // Waiting for Clinician's input
    label: t(i18n)`INCOMPLETE`,
    color: '#FBCFCF',
    tip: t(i18n)`Order was only just created and does not have a prescription yet.`,
  },
  [orderStatuses.cancelled]: {
    // Cancelled by clinician
    label: t(i18n)`CANCELLED`,
    color: '#BE3227',
    tip: t(i18n)`Order was only just created and does not have a prescription yet.`,
  },
  [orderStatuses.onHold]: {
    // On Hold by clinician (pending validation of orderForm)
    label: t(i18n)`ON HOLD`,
    color: '#F9A241',
    tip: t(i18n)`Order and prescriptions awaits submition by its responsable.`,
  },
  [orderStatuses.submitted]: {
    // Form completed and submitted by clinician
    label: t(i18n)`SUBMITTED`,
    color: '#F9CF62',
    tip: t(i18n)`The order is submitted and awaits pre-processing.`,
  },
  [orderStatuses.processed]: {
    // 3d Model generated
    label: t(i18n)`PROCESSED`,
    color: '#CFDFEC',
    tip: t(i18n)`The 3d models are being generated and await validation.`,
  },
  [orderStatuses.validated]: {
    // 3d Model validated
    label: t(i18n)`VALIDATED`,
    color: '#B873D2',
    tip: t(i18n)`The order was validated and awaits quality control.`,
  },
  [orderStatuses.verified]: {
    // 3d Model validated
    label: t(i18n)`VERIFIED`,
    color: '#20C9C1',
    tip: t(i18n)`The order was verified and is ready to be printed.`,
  },
  [orderStatuses.printing]: {
    label: t(i18n)`PRINTING`,
    color: '#71c1d3',
    tip: t(i18n)`The order is currently being printed.`,
  },
  [orderStatuses.printed]: {
    // product is in shipping bay
    label: t(i18n)`PRINTED`,
    color: '#71A6D3',
    tip: t(i18n)`The orthosis was printed and is ready to be post-processed.`,
  },
  [orderStatuses.finished]: {
    label: t(i18n)`FINISHED`,
    color: '#7185d3',
    tip: t(i18n)`The orthosis was finalized and is ready to be packaged.`,
  },
  [orderStatuses.delayed]: {
    label: t(i18n)`DELAYED`,
    color: '#000000',
    tip: t(i18n)`The order is delayed by unexpected events.`,
  },
  [orderStatuses.packaged]: {
    label: t(i18n)`PACKAGED`,
    color: '#7c71d3',
    tip: t(i18n)`Order is packaged and ready to be shipped.`,
  },
  [orderStatuses.shipped]: {
    // Adds shipping information (tracking number on icon)
    label: t(i18n)`SHIPPED`,
    color: '#D1ED7E',
    tip: t(i18n)`Order is in transit to the clinic.`,
  },
  [orderStatuses.receivedByClinic]: {
    label: t(i18n)`RECEIVED BY CLINIC`,
    color: '#B1ED7E',
    tip: t(i18n)`The orthosis was received by the clinic.`,
  },
  [orderStatuses.deliveredToPatient]: {
    label: t(i18n)`DELIVERED TO PATIENT`,
    color: '#A1ED7E',
    tip: t(i18n)`The orthosis was delivered to the patient.`,
  },
  [orderStatuses.completed]: {
    // Order is delivered and payed for
    label: t(i18n)`COMPLETED`,
    color: '#85CC3F',
    // eslint-disable-next-line max-len
    tip: t(i18n)`Order is completed. To create an order from this one, click the "Order another pair" button found in its prescription.`,
  },
})

export const getBillingStatusDisplays = (i18n: I18n): Record<BillingStatus, StatusDisplay> => ({
  [billingStatuses.unbilled]: {
    // Will be moved to "billed"
    label: t(i18n)`Unbilled`,
    color: '#D1ED7E',
    tip: t(i18n)`Order is not yet billed.`,
  },
  [billingStatuses.billed]: {
    // Awaiting payment by client
    label: t(i18n)`Billed`,
    color: '#0C72CF',
    tip: t(i18n)`Order was billed and awaits payment.`,
  },
  [billingStatuses.paid]: {
    // Order is delivered and payed for
    label: t(i18n)`Paid`,
    color: '#95CC3F',
    tip: t(i18n)`Order has been paid`,
  },
  [billingStatuses.noCharge]: {
    // 3d Model generated
    label: t(i18n)`N/C`,
    color: '#B873D2',
    tip: t(i18n)`Order is free and will not be charged for its production.`,
  },
  [billingStatuses.reimbursed]: {
    label: t(i18n)`Reimbursed`,
    color: '#71A6D3',
    tip: t(i18n)`Order was reimbursed after being paid.`,
  },
})

export const getPrescriptionStatusDisplays = (i18n: I18n): Record<PrescriptionStatus, StatusDisplay> => ({
  [prescriptionStatuses.incomplete]: {
    // Waiting for Clinician's input
    label: t(i18n)`INCOMPLETE`,
    color: '#FBCFCF',
    tip: t(i18n)`Prescription doesn\'t yet exist for this order.`,
  },
  [prescriptionStatuses.onHold]: {
    // On Hold by clinician (pending validation of orderForm)
    label: t(i18n)`ON HOLD`,
    color: '#F9A241',
    tip: t(i18n)`Prescription is on hold, awaiting clinician\'s input and submittion.`,
  },
  [prescriptionStatuses.completed]: {
    // Order is delivered and payed for
    label: t(i18n)`COMPLETED`,
    color: '#95CC3F',
    tip: t(i18n)`Prescription is completed and locked`,
  },
})
