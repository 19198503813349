
import { Trans } from '@lingui/macro'
import { useNavigate } from '@tanstack/react-router'
import { Box, Button, Form, FormField, Heading, Layer, TextInput } from 'grommet'
import { useEffect, useRef, useState } from 'react'

import { useDebounce } from '../../hooks/debounce'
import { useCreatePatient } from '../../queries/patients'
import PastSingleDateInput from '../grommet-sub/PastSingleDateInput'

type ModalProps = {
  showPatientModal: true,
  clinicId: number,
  onClose: () => unknown,
  onChange?: undefined,
  vertical?: boolean,
}

type NonModalProps = {
  showPatientModal: false,
  clinicId: number,
  onChange: (patientId: string, firstName: string, lastName: string, birthdate: string | null) => unknown,
  vertical?: boolean,
}

type Props = ModalProps | NonModalProps

const AddPatient = (props: Props) => {
  const [patientId, setPatientId] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [birthdate, setBirthdate] = useState<string | null>(null)

  const debouncedPatientId = useDebounce(patientId)
  const debouncedFirstName = useDebounce(firstName)
  const debouncedLastName = useDebounce(lastName)
  const debouncedBirthdate = useDebounce(birthdate)

  const navigate = useNavigate()

  const createPatient = useCreatePatient()

  const onChangeRef = useRef<typeof props.onChange>(props.onChange)
  const propsOnChange = props.onChange
  useEffect(() => {
    onChangeRef.current = propsOnChange
  }, [propsOnChange])

  useEffect(
    () => {
      onChangeRef.current?.(debouncedPatientId, debouncedFirstName, debouncedLastName, debouncedBirthdate)
    },
    [
      debouncedPatientId,
      debouncedFirstName,
      debouncedLastName,
      debouncedBirthdate,
    ],
  )

  const formContent =
    <>
      <FormField>
        <Heading
          margin={{ top: 'small', bottom: 'xsmall' }}
          level={4}
        >
          <Trans>Patient ID</Trans>
        </Heading>
        <TextInput
          type="number"
          onChange={(input) => {
            if (Number.isNaN(Number(input.currentTarget.value))) return
            setPatientId(input.currentTarget.value)
          }}
        />
      </FormField>
      <FormField>
        <Heading
          margin={{ top: 'small', bottom: 'xsmall' }}
          level={4}
        >
          <Trans>First name</Trans>
        </Heading>

        <TextInput
          onChange={(input) => {
            setFirstName(input.currentTarget.value)
          }}
        />
      </FormField>
      <FormField>
        <Heading
          margin={{ top: 'small', bottom: 'xsmall' }}
          level={4}
        >
          <Trans>Last name</Trans>
        </Heading>

        <TextInput onChange={(input) => setLastName(input.currentTarget.value)} />
      </FormField>
      <PastSingleDateInput
        wrapFormField={true}
        label={
          <Heading
            margin={{ top: 'small', bottom: 'xsmall' }}
            level={4}
          >
            <Trans>Birthdate</Trans>
          </Heading>
        }
        date={birthdate}
        onChange={(date) => setBirthdate(date)}
      />
    </>

  return (
    <>
      {
        props.showPatientModal
          ? (
            <Layer
              onEsc={() => props.onClose()}
              onClickOutside={() => props.onClose()}
            >
              <Box
                pad="1rem"
                gap="1rem"
                direction={props.vertical === true ? 'column' : 'row'}
              >
                <Form>
                  {formContent}
                  <Button
                    label="submit"
                    onClick={() => {
                      createPatient.mutate(
                        {
                          clinicId: props.clinicId,
                          patient: {
                            patientId,
                            firstName,
                            lastName,
                            birthdate,
                          },
                        },
                        {
                          onSettled: () => {
                            props.onClose()
                            navigate({ to: '/' }).catch(console.error)
                          },
                        },
                      )
                    }}
                  />
                </Form>
              </Box>
            </Layer>
          )
          : (
            <Box
              pad="1rem"
              gap="1rem"
              direction={props.vertical === true ? 'column' : 'row'}
            >
              <Form>
                {formContent}
              </Form>
            </Box>
          )
      }
    </>
  )
}

export default AddPatient
