
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Box, Collapsible, FormField, SelectMultiple } from 'grommet'
import { Filter } from 'grommet-icons'
import { ReactNode, useMemo, useState } from 'react'

import { SelectMultipleOption } from './grommet-sub/SelectMultiple'

function getValue<TValue extends string | number>(item: unknown): TValue {
  if (typeof item === 'object' && item !== null) {
    return (item as SelectMultipleOption<TValue>).value
  } else {
    return item as TValue
  }
}

getValue.reduce = true

type BaseProps<TValue extends string | number> = Readonly<{
  label: ReactNode,
  options: Array<SelectMultipleOption<TValue>>,
  onExpand: (expanded: boolean) => unknown,
  onChange: (excluded: TValue[]) => unknown,
}>

type ExcludedProps<TValue extends string | number> = Readonly<{
  excluded: TValue[],
  included?: undefined,
}>

type IncludedProps<TValue extends string | number> = Readonly<{
  included: TValue[],
  excluded?: undefined,
}>

type Props<TValue extends string | number> =
  | (BaseProps<TValue> & ExcludedProps<TValue>)
  | (BaseProps<TValue> & IncludedProps<TValue>)

function ColumnFilter<TValue extends string | number>(props: Props<TValue>) {
  const [expanded, setExpanded] = useState(false)

  const handleToggleOpen = () => {
    setExpanded(expanded => !expanded)
    props.onExpand(!expanded)
  }

  const value = useMemo(
    () => {
      if (props.excluded === undefined) return props.included

      const value: TValue[] = []
      for (const option of props.options) {
        // Flip the displayed options to be those not excluded.
        if (!props.excluded.includes(option.value)) value.push(option.value)
      }

      return value
    },
    [
      props.options,
      props.excluded,
      props.included,
    ],
  )

  const handleSelected = (event: {value: TValue[]}) => {
    if (props.excluded === undefined) {
      props.onChange(event.value)
      return
    }

    const excluded: TValue[] = []
    for (const option of props.options) {
      // Flip the selected values, push those not selected.
      if (!event.value.includes(option.value)) excluded.push(option.value)
    }

    props.onChange(excluded)
  }

  return (
    <Box pad="0.2rem">
      <Box width="100%">
        <Box
          width="100%"
          alignContent="stretch"
          direction="row"
        >
          <Box width="100%">
            {props.label}
          </Box>
          <Filter
            size="16px"
            onClick={handleToggleOpen}
          />
        </Box>
        <Collapsible open={expanded}>
          <FormField>
            <SelectMultiple
              options={props.options}
              value={value}
              valueKey={getValue}
              labelKey="label"
              sortSelectedOnClose={false}
              onChange={handleSelected}
            />
          </FormField>
        </Collapsible>
      </Box>
    </Box>
  )
}

export default ColumnFilter
