import { useEffect, useState } from 'react'

import { localStorageKeys } from '../constants/localStorage'
import { userContextActions } from '../store/actions/userContext'
import { useDispatch } from '../store/store'
import { UserRole, UserRoleManager, userRoles } from '../types/UserRole'
import { getCognitoGroups } from '../utils/cognito'

const useRoleManager = (): [UserRoleManager | null, UserRoleManager | null, boolean] => {
  const [roleManager, setRoleManager] = useState<UserRoleManager | null>(null)
  const [overrideRoleManager, setOverrideRoleManager] = useState<UserRoleManager | null>(null)

  useEffect(
    () => {
      getCognitoGroups()
        .then(groups => {
          const roleManager = new UserRoleManager(groups)
          setRoleManager(roleManager)
          return roleManager
        })
        .then(roleManager => {
          if (!roleManager.isAdmin) return

          const roleOverride = localStorage.getItem(localStorageKeys.roleOverride)
          if (roleOverride === null) return

          const groups = JSON.parse(roleOverride) as string[]

          setOverrideRoleManager(new UserRoleManager(groups))
        })
        .catch(console.error)
    },
    [],
  )

  return [overrideRoleManager ?? roleManager, roleManager, overrideRoleManager !== null]
}

const useRoleManagerPublic = (): UserRoleManager | null => {
  return useRoleManager()[0]
}

const useIsOverridingRole = (): boolean => {
  return useRoleManager()[2]
}

const useIsTrulyAdmin = (): boolean => {
  const [, trueManager] = useRoleManager()
  return trueManager?.isAdmin ?? false
}

const useIsRole = (targetRole: UserRole): boolean => {
  const [manager] = useRoleManager()
  return manager?.isRole(targetRole) ?? false
}

const useGetBestRole = (): UserRole => {
  const [manager] = useRoleManager()
  if (!manager) return userRoles.unknown

  return manager.getBestRole() ?? userRoles.clinician
}

const useIsAdmin = (): boolean => {
  const [manager] = useRoleManager()
  return manager?.isAdmin ?? false
}

const useIsManufacturer = (): boolean => {
  const [manager] = useRoleManager()
  return manager?.isManufacturer ?? false
}

const useIsClinicOwner = (): boolean => {
  const [manager] = useRoleManager()
  return manager?.isClinicOwner ?? false
}

const useIsClinician = (): boolean => {
  const [manager] = useRoleManager()
  return manager?.isClinician ?? false
}

const useHasFullOrdersAccess = (): boolean => {
  const [manager] = useRoleManager()
  if (!manager) return false

  return manager.isAdmin || manager.isManufacturer
}

const useDispatchUserRole = () => {
  const dispatch = useDispatch()

  useEffect(
    () => {
      getCognitoGroups()
        .then(groups => new UserRoleManager(groups))
        .then(roleManager => {
          if (!roleManager.isAdmin) return roleManager

          const roleOverride = localStorage.getItem(localStorageKeys.roleOverride)
          if (roleOverride === null) return roleManager

          const groups = JSON.parse(roleOverride) as string[]

          return new UserRoleManager(groups)
        })
        .then(roleManager => {
          dispatch(userContextActions.setUserRole(roleManager.getBestRole()))
        })
        .catch(console.error)
    },
    [dispatch],
  )
}

export {
  useRoleManagerPublic as useRoleManager,
  useIsOverridingRole,
  useIsTrulyAdmin,
  useIsRole,
  useGetBestRole,
  useIsAdmin,
  useIsManufacturer,
  useIsClinicOwner,
  useIsClinician,
  useHasFullOrdersAccess,
  useDispatchUserRole,
}
