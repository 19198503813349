import { Address } from './Address'

export type Location = Address & {
  description: string,
  clinicId: number,
}

export const allLocation: Location = {
  id: -1,
  civicNumber: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  description: '*',
  clinicId: -1,
}

export type NewLocation = Omit<Location, 'id' | 'clinicId'>

export const getNewLocation = (): NewLocation => ({
  civicNumber: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  description: '',
})
