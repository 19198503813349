import { getOmsApiUri } from '../constants/runtime'
import { FileDto, NewFile, File as PF3DFile, dtoToFile } from '../types/File'

import { fetch } from './fetch'
import { getHeaders, getMutationHeaders } from './utils'

const filesKeys = {
  all: () => ['files'],
  list: (clinicId: number, orderId: number, filters?: Record<string, unknown> | undefined) =>
    filters
      ? [...filesKeys.all(), 'list', clinicId, orderId, filters]
      : [...filesKeys.all(), 'list', clinicId, orderId],
  details: (clinicId: number, orderId: number, fileId: number) =>
    [...filesKeys.all(), 'details', clinicId, orderId, fileId],
}

const getFiles = async (
  clinicId: number,
  orderId: number,
  signal: AbortSignal | null,
): Promise<PF3DFile[]> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/files`,
    {
      signal,
      headers: await getHeaders(),
    },
  )

  const dtos = await response.json() as FileDto[]

  return dtos.map(dtoToFile)
}

const downloadFile = async (
  clinicId: number,
  fileId: number,
  signal?: AbortSignal | null | undefined,
): Promise<Blob> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/files/${fileId}`,
    {
      headers: await getHeaders(),
      signal: signal ?? null,
    },
  )

  return await response.blob()
}

const postFile = async (
  clinicId: number,
  orderId: number,
  file: NewFile,
): Promise<PF3DFile> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/files`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify(file),
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as FileDto

  return dtoToFile(dto)
}

const uploadFile = async (
  clinicId: number,
  scanId: number,
  file: File,
): Promise<void> => {
  const formData = new FormData()
  formData.append('file', file)

  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/files/${scanId}`,
    {
      method: 'POST',
      headers: await getHeaders(),
      body: formData,
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

const deleteFile = async (
  clinicId: number,
  orderId: number,
  fileId: number,
): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}/orders/${orderId}/files/${fileId}`,
    {
      method: 'DELETE',
      headers: await getMutationHeaders(),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

export {
  filesKeys,
  getFiles,
  downloadFile,
  postFile,
  uploadFile,
  deleteFile,
}
