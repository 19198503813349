import { useMutation, useQueryClient } from '@tanstack/react-query'

import { patientsKeys, postPatient, putPatient } from '../fetchers/patients'
import { NewPatient, Patient } from '../types/Patient'

const useCreatePatient = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; patient: NewPatient}) =>
      postPatient(data.clinicId, data.patient),
    onSuccess: (patient, data) => {
      void queryClient.invalidateQueries({queryKey: patientsKeys.list(data.clinicId)})
      queryClient.setQueryData(patientsKeys.details(patient.id), patient)
    },
  })
}

const useSavePatient = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; patient: Patient}) =>
      putPatient(data.clinicId, data.patient),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: patientsKeys.list(data.clinicId)})
      queryClient.setQueryData(patientsKeys.details(data.patient.id), data.patient)
    },
  })
}

export {
  useCreatePatient,
  useSavePatient,
}
