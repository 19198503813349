import { useCallback, useEffect, useRef } from 'react'

import { Action, useDispatch } from '../store/store'

type WrappedAction<TAction extends () => Action> =
  (...args: Parameters<TAction>) => unknown

function useWrapAction<TAction extends (...args: never[]) => Action>(
  reduxAction: TAction,
): WrappedAction<TAction> {
  const dispatch = useDispatch()

  const actionRef = useRef<TAction>(reduxAction)
  useEffect(
    () => {
      actionRef.current = reduxAction
    },
    [reduxAction],
  )

  const action: WrappedAction<TAction> = useCallback(
    (...args: Parameters<TAction>) => {
      dispatch(actionRef.current(...args))
    },
    [dispatch],
  )

  return action
}

export {
  useWrapAction,
}
