import { Box, Button, Tip } from 'grommet'
import { Fragment, useEffect } from 'react'

import { ThemeColor } from '../css/Theme'
import { userContextActions } from '../store/actions/userContext'
import { useDispatch, useSelector } from '../store/store'
import { OrderStatus, nonCancelledOrderStatuses } from '../types/OrderStatus'
import { StatusDisplay } from '../utils/statusDisplays'

export type MixedStatusFilterButton = {
  id: string,
  label: string,
  activeLabel?: string,
  tip?: string | undefined,
  activeTip?: string | undefined,
  color?: ThemeColor | StatusDisplay['color'] | undefined,
  statuses: OrderStatus[],
  hide?: boolean | undefined,
}

type Props = {
  statuses: MixedStatusFilterButton[],
  activeByDefault?: true | undefined,
}

const MixedStatusFilterButtons = (props: Props) => {
  const dispatch = useDispatch()

  const currentFilters = useSelector(state => state.userContext.orderStatusFilters)
  const activeStatuses = new Set(currentFilters)

  const areStatusesSet = (statuses: OrderStatus[]) => {
    return (
      activeStatuses.size === statuses.length &&
      statuses.every(status => activeStatuses.has(status))
    )
  }

  const handleSelection = (statuses: OrderStatus[]) => {
    const newFilters = areStatusesSet(statuses)
      ? nonCancelledOrderStatuses
      : statuses

    dispatch(userContextActions.setOrderStatusFilters(newFilters))
  }

  useEffect(
    () => {
      if (props.activeByDefault === true) {
        props.statuses
          .forEach(config => {
            if (config.hide !== true && !areStatusesSet(config.statuses)) {
              dispatch(userContextActions.setOrderStatusFilters(config.statuses))
            } else {
              dispatch(userContextActions.setOrderStatusFilters(nonCancelledOrderStatuses))
            }
          })
      }
    },
    [dispatch, props.activeByDefault, props.statuses], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <Box
      gap="1rem"
      direction="row"
      height="1.75rem"
    >
      {
        props
          .statuses
          .filter(config => config.hide !== true)
          .map(config => {
            const active = areStatusesSet(config.statuses)

            const button =
              <Button
                label={active && config.activeLabel !== undefined ? config.activeLabel : config.label}
                color={config.color ?? undefined}
                primary={active}
                onClick={() => handleSelection(config.statuses)}
              />

            return (
              config.tip !== undefined
                ? (
                  <Tip
                    key={config.id}
                    content={
                      active && config.activeTip !== undefined
                        ? config.activeTip
                        : config.tip
                    }
                  >
                    {button}
                  </Tip>
                )
                : (
                  <Fragment key={config.id}>
                    {button}
                  </Fragment>
                )
            )
          })
      }
    </Box>
  )
}

export default MixedStatusFilterButtons
