import { useSelector } from '../store/store'
import { Clinic, allClinic } from '../types/Clinic'

const useClinic = (): Clinic => {
  return useSelector(state => state.userContext.clinic)
}

const useClinicId = (): number => {
  return useClinic().id
}

const useClinicFromId = (clinicId: number): Clinic => {
  const clinic = useSelector(
    state =>
      state
        .userContext
        .availableClinics
        .find(clinic => clinic.id === clinicId),
  )

  return clinic ?? allClinic
}

export {
  useClinic,
  useClinicId,
  useClinicFromId,
}
