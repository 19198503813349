export type FileBase<TDate> = {
  id: number,
  awsBucket?: string,
  extension: string,
  fileName: string,
  filePath: string,
  createdAt: TDate,
  updatedAt: TDate,
}

export type File = FileBase<Date>
export type FileDto = FileBase<string>

export const dtoToFile = (dto: FileDto): File => ({
  ...dto,
  createdAt: new Date(dto.createdAt),
  updatedAt: new Date(dto.updatedAt),
})

export type NewFile = Omit<File, 'id' | 'createdAt' | 'updatedAt'>

export const leftScanFinder = (file: File) => leftFinder(file) &&
  scanFinder(file)

export const rightScanFinder = (file: File) => rightFinder(file) &&
  scanFinder(file)

export const leftFinder = (file: File) => file.fileName.toLocaleLowerCase().includes('left')
export const rightFinder = (file: File) => file.fileName.toLocaleLowerCase().includes('right')

export const scanFinder = (file: File) => !file.fileName.toLocaleLowerCase().includes('scan') &&
  (file.extension.toLocaleLowerCase().includes('stl') ||
    file.extension.toLocaleLowerCase().includes('ply'))
