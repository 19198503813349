import { ReactNode } from 'react'

import { applicationVersion } from '../../constants/version'
import { NotificationAction, notificationActionTypes } from '../actions/notifications'

export type ToastState = {
  id: string,
  title: string | undefined,
  message: ReactNode,
  status: 'info' | 'success' | 'warning' | 'error',
}

export type NotificationsState = {
  latestAppVersion: string,
  toasts: Record<string, ToastState | undefined>,
}

const defaultNotificationsState: NotificationsState = {
  latestAppVersion: applicationVersion,
  toasts: {},
}

const reducer = (state = defaultNotificationsState, action: NotificationAction): NotificationsState => {
  switch (action.type) {
  case notificationActionTypes.clearToast:
    return {
      ...state,
      toasts: {
        ...state.toasts,
        [action.id]: undefined,
      },
    }

  case notificationActionTypes.showSuccessToast:
    return {
      ...state,
      toasts: {
        ...state.toasts,
        [action.id]: {
          id: action.id,
          title: action.title,
          message: action.message,
          status: 'success',
        },
      },
    }

  case notificationActionTypes.showErrorToast:
    return {
      ...state,
      toasts: {
        ...state.toasts,
        [action.id]: {
          id: action.id,
          title: action.title,
          message: action.message,
          status: 'error',
        },
      },
    }

  case notificationActionTypes.showWarningToast:
    return {
      ...state,
      toasts: {
        ...state.toasts,
        [action.id]: {
          id: action.id,
          title: action.title,
          message: action.message,
          status: 'warning',
        },
      },
    }

  case notificationActionTypes.setLatestAppVersion:
    return {
      ...state,
      latestAppVersion: action.version,
    }

  default:
    return state
  }
}

export default reducer
