import { Trans } from '@lingui/macro'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, Heading, Layer, Spinner } from 'grommet'

import { downloadAllFilesByOrder, downloadVerifiedFiles, ordersKeys } from '../../../fetchers/orders'
import { useLoading } from '../../../hooks/loading'
import { OrderStatus, orderStatuses } from '../../../types/OrderStatus'

type Props = Readonly<{
  selectedOrderIds: number[] | null,
  onClose: () => unknown,
}>

const ManufacturerBulkDownload = (props: Props) => {
  const queryClient = useQueryClient()

  const { isLoading, setLoading } = useLoading()

  const handleDownload = (statusUpdateMode: 'keep' | 'change') => {
    if (isLoading) return

    setLoading(true)

    const updateStatus: OrderStatus | undefined = statusUpdateMode === 'change'
      ? orderStatuses.printing
      : undefined

    let downloadFiles: () => Promise<void>
    if (props.selectedOrderIds) {
      const orderIds = props.selectedOrderIds
      downloadFiles = () => downloadAllFilesByOrder(orderIds, updateStatus)
    } else {
      downloadFiles = () => downloadVerifiedFiles(updateStatus)
    }

    downloadFiles()
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)

        if (statusUpdateMode === 'change') {
          void queryClient.invalidateQueries({queryKey: ordersKeys.all()})
        }

        props.onClose()
      })
  }

  return (
    <Layer modal={true}>
      <Box
        round={true}
        pad="1rem"
        width="30dvw"
      >
        {
          isLoading
            ? (
              <>
                <Heading
                  level="2"
                  margin={{top: 'none'}}
                >
                  <Trans>Preparing download...</Trans>
                </Heading>
                <Trans>
                  This may take some time.
                </Trans>
                <Box
                  direction="row"
                  justify="center"
                  margin={{top: '1rem', bottom: '0.5rem'}}
                >
                  <Spinner size="large" />
                </Box>
              </>
            )
            : (
              <>
                <Heading
                  level="2"
                  margin={{top: 'none'}}
                >
                  <Trans>Download and start printing</Trans>
                </Heading>
                {
                  props.selectedOrderIds === null
                    ? (
                      <Trans>
                        All currently VERIFIED orders will be set to PRINTING.
                      </Trans>
                    )
                    : (
                      <Trans>
                        All selected orders will be set to PRINTING.
                      </Trans>
                    )
                }
                <Box
                  direction="row"
                  justify="end"
                  gap="0.5rem"
                  margin={{top: '1rem'}}
                >
                  <Button
                    color="red"
                    label={<Trans>Cancel</Trans>}
                    onClick={props.onClose}
                  />
                  <Button
                    color="gray"
                    secondary={true}
                    label={<Trans>Keep current status</Trans>}
                    onClick={() => handleDownload('keep')}
                  />
                  <Button
                    color="green"
                    primary={true}
                    label={<Trans>Download</Trans>}
                    onClick={() => handleDownload('change')}
                  />
                </Box>
              </>
            )
        }
      </Box>
    </Layer>
  )
}

export default ManufacturerBulkDownload
