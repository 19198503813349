import { BillingStatus } from './BillingStatus'
import { Clinician } from './Clinician'
import { Location } from './Location'
import { OrderErrorBase, dtoToOrderError } from './OrderError'
import { OrderStatus } from './OrderStatus'
import { Patient, PatientForManufacturer } from './Patient'
import { OmsPrescription } from './Prescription'
import { PrescriptionStatus } from './PrescriptionEnums/PrescriptionStatus'
import { ProductType } from './PrescriptionEnums/ProductType'
import { RequestedSide } from './PrescriptionEnums/RequestedSide'
import { TopCoverLength } from './PrescriptionEnums/TopCoverLength'
import { ScanStatus } from './ScanStatus'

type OrderBase<TDate> = {
  id: number,
  originalOrderId: number | null,
  orderNumber: string,
  clinicId: number,
  clinicName: string | null,
  location: Location | null,
  patient: Patient,
  clinician: Clinician | null,
  activeStatus: OrderStatus,
  billingStatus: BillingStatus,
  scanStatus: ScanStatus,
  requestedSide: RequestedSide,
  prescriptionId: number | null,
  prescription: OmsPrescription | null,
  prescriptionStatus: PrescriptionStatus,
  comments: string | null,
  shippingLink: string | null,
  manufacturerShippingLink: string | null,
  cancellationReason: string | null,
  internalClinicNotes: string | null,

  createdAt: TDate,
  submittedAt: TDate | null,
  errors: Array<OrderErrorBase<TDate>>,

  productType: ProductType,
  topCoverLength: TopCoverLength,

}

export type Order = OrderBase<Date> & {filesCount: number}
export type OrderDto = OrderBase<string> & {modelsCount: number}

export type OrderForManufacturerBase = {
  id: number,
  originalOrderId: number | null,
  orderNumber: string,
  clinicId: number,
  clinicName: string | null,
  activeStatus: OrderStatus,
  patient: PatientForManufacturer,
  requestedSide: RequestedSide,
  productType: ProductType,
  topCoverLength: TopCoverLength,

  manufacturerShippingLink: string | null,
}

export type OrderForManufacturer = OrderForManufacturerBase & {filesCount: number}
export type OrderForManufacturerDto = OrderForManufacturerBase & {modelsCount: number}

export const dtoToOrder = (dto: OrderDto): Order => ({
  ...dto,
  filesCount: dto.modelsCount,
  createdAt: new Date(dto.createdAt),
  submittedAt: dto.submittedAt !== null ? new Date(dto.submittedAt) : null,
  errors: dto.errors.map(dtoToOrderError),
})

export const dtoToOrderForManufacturer = (dto: OrderForManufacturerDto): OrderForManufacturer => ({
  ...dto,
  filesCount: dto.modelsCount,
})

export const isOrder = (order: Order | OrderForManufacturer): order is Order =>
  typeof (order as Order).errors !== 'undefined'
