import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { clinicsKeys } from '../fetchers/clinics'
import { deleteLogo, getLogos, logosKeys, postLogo, putLogo } from '../fetchers/logos'
import { LogoData, NewLogo } from '../types/Logo'

const useQueryLogos = (
  id: {clinicId: number; logoId?: undefined} | {clinicId?: undefined; logoId: number},
) => {
  return useQuery({
    // Weird query rule here, linter is not able to detect
    // that the query dependency is truly passed.
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: id.clinicId !== undefined
      ? logosKeys.list(id.clinicId)
      : logosKeys.details(id.logoId),
    queryFn: ({signal}) => getLogos(id, signal),
  })
}

const useCreateLogo = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; logo: NewLogo}) =>
      postLogo(data.clinicId, data.logo),
    onSuccess: (logo) => {
      void queryClient.invalidateQueries({queryKey: clinicsKeys.all()})
      void queryClient.invalidateQueries({queryKey: logosKeys.all()})

      queryClient.setQueryData(logosKeys.details(logo.id), logo)
    },
  })
}

const useUpdateLogo = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; logo: LogoData}) =>
      putLogo(data.clinicId, data.logo),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: clinicsKeys.all()})
      void queryClient.invalidateQueries({queryKey: logosKeys.all()})

      queryClient.setQueryData(logosKeys.details(data.logo.id), data.logo)
    },
  })
}

const useDeleteLogo = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; logoId: number}) =>
      deleteLogo(data.clinicId, data.logoId),
    onSuccess: () => {
      void queryClient.invalidateQueries({queryKey: clinicsKeys.all()})
      void queryClient.invalidateQueries({queryKey: logosKeys.all()})
    },
  })
}

export {
  useQueryLogos,
  useCreateLogo,
  useUpdateLogo,
  useDeleteLogo,
}
