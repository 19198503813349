import { GetOrderStatusFilters } from '../../fetchers/orders'
import { Clinic, allClinic } from '../../types/Clinic'
import { Clinician, allClinician } from '../../types/Clinician'
import { Location, allLocation } from '../../types/Location'
import { Logo, defaultLogo } from '../../types/Logo'
import { GetOrdersFilters, defaultGetOrdersFilters, defaultPageSize } from '../../types/OrderFilters'
import { OrderStatus, getEmptyOrdersCounts, orderStatuses } from '../../types/OrderStatus'
import { OrdersView } from '../../types/OrdersView'
import { userRoles } from '../../types/UserRole'
import { UserContextAction, userContextActionTypes } from '../actions/userContext'

export type UserContextState = Readonly<{
  availableClinics: Clinic[],
  clinic: Clinic,
  availableLocations: Location[],
  location: Location,
  availableClinicians: Clinician[],
  clinician: Clinician,
  orderFilters: GetOrdersFilters,
  orderStatusFilters: GetOrderStatusFilters,
  activeLogo: Logo,
  ordersCountsByOrderStatuses: Record<OrderStatus, number | undefined>,
  ordersView: OrdersView,
}>

const defaultContext: UserContextState = {
  availableClinics: [],
  clinic: allClinic,
  availableLocations: [],
  location: allLocation,
  availableClinicians: [],
  clinician: allClinician,
  orderFilters: {
    ...defaultGetOrdersFilters,
    includeStatuses: [],
  },
  orderStatusFilters: [],
  activeLogo: defaultLogo,
  ordersCountsByOrderStatuses: getEmptyOrdersCounts(),
  ordersView: 'adminView',
}

const updateClinic = (oldClinic: Clinic, newClinic: Clinic): Clinic => ({
  ...oldClinic,
  name: newClinic.name,
})

const resetPageSize = (state: UserContextState, newFilters?: GetOrdersFilters): GetOrdersFilters => {
  if (newFilters) {
    return {
      ...newFilters,
      pageSize: defaultPageSize,
    }
  }

  if (state.orderFilters.pageSize === defaultPageSize) return state.orderFilters

  return {
    ...state.orderFilters,
    pageSize: defaultPageSize,
  }
}

const reducer = (state: UserContextState = defaultContext, action: UserContextAction): UserContextState => {
  switch (action.type) {
  case userContextActionTypes.clearContext:
    return defaultContext

  case userContextActionTypes.setUserRole:
    return {
      ...state,
      orderFilters: action.role === userRoles.manufacturer
        ? {
          ...defaultGetOrdersFilters,
          includeStatuses: [orderStatuses.verified],
        }
        : defaultGetOrdersFilters,
      orderStatusFilters: action.role === userRoles.manufacturer
        ? [orderStatuses.verified]
        : defaultGetOrdersFilters.includeStatuses ?? [],
    }

  case userContextActionTypes.setAvailableClinics:
    return {
      ...state,
      availableClinics: action.clinics,
    }

  case userContextActionTypes.setClinic:
    return {
      ...state,
      clinic: action.clinic,
      orderFilters: resetPageSize(state),
    }

  case userContextActionTypes.setAvailableLocations:
    return {
      ...state,
      availableLocations: action.locations,
    }

  case userContextActionTypes.setLocation:
    return {
      ...state,
      location: action.location,
      orderFilters: resetPageSize(state),
    }

  case userContextActionTypes.setAvailableClinicians:
    return {
      ...state,
      availableClinicians: action.clinicians,
    }

  case userContextActionTypes.setClinician:
    return {
      ...state,
      clinician: action.clinician,
      orderFilters: resetPageSize(state),
    }

  case userContextActionTypes.setOrderFilters:
    return {
      ...state,
      orderFilters: resetPageSize(state, action.orderFilters),
    }

  case userContextActionTypes.setOrderStatusFilters:
    return {
      ...state,
      orderFilters: resetPageSize(state, {
        ...state.orderFilters,
        includeStatuses: action.orderStatusFilters,
      }),
      orderStatusFilters: action.orderStatusFilters,
    }

  case userContextActionTypes.setPageSize:
    return {
      ...state,
      orderFilters: {
        ...state.orderFilters,
        pageSize: action.size,
      },
    }

  case userContextActionTypes.createClinic:
    return {
      ...state,
      availableClinics: [...state.availableClinics, action.item],
    }

  case userContextActionTypes.updateClinic: {
    let newClinic = state.clinic
    if (state.clinic.id === action.item.id) {
      newClinic = updateClinic(state.clinic, action.item)
    }

    return {
      ...state,
      clinic: newClinic,
      availableClinics: state
        .availableClinics
        .map(clinic =>
          clinic.id === action.item.id
            ? updateClinic(clinic, action.item)
            : clinic,
        ),
    }
  }

  case userContextActionTypes.deleteClinic:
    return {
      ...state,
      clinic: state.clinic.id === action.id
        ? allClinic
        : state.clinic,
      availableClinics: state
        .availableClinics
        .filter(clinic => clinic.id !== action.id),
    }

  case userContextActionTypes.setOrdersCountsByStatuses:
    return {
      ...state,
      ordersCountsByOrderStatuses: action.counts,
    }

  case userContextActionTypes.setOrdersView:
    return {
      ...state,
      ordersView: action.view,
    }

  default:
    return state
  }
}

export default reducer
