import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const genders = {
  kid: 0,
  man: 1,
  woman: 2,
} as const

export type Gender = typeof genders[keyof typeof genders]

export const getShortLabel = (i18n: I18n, gender: Gender) => {
  switch (gender) {
  case genders.kid: return t(i18n)`Kid`
  case genders.man: return t(i18n)`M`
  case genders.woman: return t(i18n)`W`
  default: return t(i18n)`Unknown`
  }
}

export const getGenderLabel = (i18n: I18n, gender: Gender) => {
  switch (gender) {
  case genders.kid: return t(i18n)`Kid`
  case genders.man: return t(i18n)`Man`
  case genders.woman: return t(i18n)`Woman`
  default: return t(i18n)`Unknown`
  }
}
