import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const retroPadSizes = {
  'none': 0,
  'small': 1,
  'medium': 2,
} as const

export const retroPadOrientations = {
  'none': 0,
  '2-3 mets': 1,
  '3-4 mets': 2,
} as const

export type RetroPadSizes = typeof retroPadSizes[keyof typeof retroPadSizes]
export type RetroPadOrientations = typeof retroPadOrientations[keyof typeof retroPadOrientations]

export const getRetroPadSizesLabel = (i18n: I18n, value: RetroPadSizes) => {
  switch (value) {
  case retroPadSizes.none: return t(i18n)`none`
  case retroPadSizes.small: return t(i18n)`small`
  case retroPadSizes.medium: return t(i18n)`medium`
  default: return t(i18n)`Unknown retro pad size option`
  }
}

export const getRetroPadOrientationsLabel = (i18n: I18n, value: RetroPadOrientations) => {
  switch (value) {
  case retroPadOrientations['2-3 mets']: return t(i18n)`2-3 mets`
  case retroPadOrientations['3-4 mets']: return t(i18n)`3-4 mets`
  default: return t(i18n)`Unknown retro pad orientation option`
  }
}

export const getDefaultRetroPadOrientation = () => retroPadOrientations['2-3 mets']
export const getDefaultRetroPadSize = () => retroPadSizes.small
