import { Notification } from 'grommet'

import { notificationActions } from './store/actions/notifications'
import { ToastState } from './store/reducers/notifications'
import { useDispatch, useSelector } from './store/store'

const mapStatus = (
  status: ToastState['status'],
): 'unknown' | 'normal' | 'warning' | 'info' | 'critical' => {
  switch (status) {
  case 'info': return 'info'
  case 'error': return 'critical'
  case 'warning': return 'warning'
  case 'success': return 'normal'
  default: return 'unknown'
  }
}

const Notifications = () => {
  const dispatch = useDispatch()

  const toasts = useSelector(state => state.notifications.toasts)

  return (
    <>
      {
        Object
          .values(toasts)
          .map(toast => {
            if (!toast) return null

            return (
              <Notification
                key={toast.id}
                toast={true}
                status={mapStatus(toast.status)}
                title={toast.title ?? ''}
                message={toast.message}
                onClose={() => {
                  dispatch(notificationActions.clearToast(toast.id))
                }}
              />
            )
          })
      }
    </>
  )
}

export default Notifications
