
import { Trans } from '@lingui/macro'
import { Box, Button, Heading, Text } from 'grommet'
import { useState } from 'react'

import { CheckBoxGroup } from '../../components/grommet-sub'
import { localStorageKeys } from '../../constants/localStorage'
import { CognitoGroup, cognitoGroups } from '../../types/UserRole'

const roleOptions = Object.values(cognitoGroups)

const OverrideRole = () => {
  const currentOverride = localStorage.getItem(localStorageKeys.roleOverride)
  const currentOverrideGroups = currentOverride !== null ? JSON.parse(currentOverride) as CognitoGroup[] : []

  const [selectedRoles, setSelectedRoles] = useState<CognitoGroup[]>(currentOverrideGroups)

  const handleApplyOverride = (roles: string[] | null) => {
    if (roles) {
      localStorage.setItem(localStorageKeys.roleOverride, JSON.stringify(selectedRoles))
    } else {
      localStorage.removeItem(localStorageKeys.roleOverride)
    }

    window.location.reload()
  }

  return (
    <Box>
      <Text>
        <Trans>
          Important note: this only shows an approximation of the point of view of users.
          You need to change your role in Cognito to truly test new user features.
        </Trans>
      </Text>
      <Box margin={{bottom: '0.5rem'}}>
        <Heading level="4">
          <Trans>Pick roles</Trans>
        </Heading>
        <CheckBoxGroup<CognitoGroup>
          options={roleOptions}
          value={selectedRoles}
          onChange={roles => setSelectedRoles(roles)}
        />
      </Box>
      <Box
        direction="row"
        gap="0.5rem"
      >
        <Button
          label={<Trans>Reset</Trans>}
          onClick={() => {
            setSelectedRoles([])
            handleApplyOverride(null)
          }}
        />
        <Button
          label={<Trans>Apply</Trans>}
          onClick={() => handleApplyOverride(selectedRoles)}
        />
      </Box>
    </Box>
  )
}

export default OverrideRole
