import { I18n } from '@lingui/core'
import { Plural, Trans, t } from '@lingui/macro'
import { ReactNode } from 'react'

import logo from '../../assets/logo.png'
import { getPrescription } from '../../fetchers/prescriptions'
import { Order, OrderForManufacturer, isOrder } from '../../types/Order'
import { UpdateOmsPrescription } from '../../types/Prescription'
import { getProductTypeLabel, productTypes } from '../../types/PrescriptionEnums/ProductType'
import { topCoverLengths } from '../../types/PrescriptionEnums/TopCoverLength'
import { UserRoleManager } from '../../types/UserRole'
import { toTableDateTime } from '../../utils/date'
import { getPersonFullName } from '../../utils/person'
import { printReact } from '../../utils/print'
import { getBillingStatusDisplays, getOrderStatusDisplays } from '../../utils/statusDisplays'
import { TextCheckBox } from '../PrintableComponents'

import { getOrderItemsCount, getProductType, getTopCoverLength } from './helpers'

type Totals = {
  totalOrders: number,
  totalQuantity: number,
  totalShell: number,
  totalHybrid: number,
  totalTopCover: number,
  totalNoTopCover: number,
  totalDuplicates: number,
  totalOriginals: number,
}

type ColumnDef<TOrder> = {
  enabled?: boolean,
  key: string,
  header: ReactNode,
  getValue: (item: TOrder, prescription: UpdateOmsPrescription | null) => ReactNode,
  getFooter?: (totals: Totals) => ReactNode,
}

const PageHeader = (props: { i18n: I18n; date: Date }) => {
  return (
    <thead>
      <tr>
        <td>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src={logo}
                alt="Podform3D logo"
                width="200px"
                style={{
                  marginBottom: '0.5rem',
                }}
              />
              <div>
                {
                  props
                    .date
                    .toLocaleString(
                      props.i18n.locale,
                      {
                        dateStyle: 'long',
                        timeStyle: 'medium',
                      },
                    )
                }
              </div>
            </div>
          </div>
        </td>
      </tr>
    </thead>
  )
}

const TotalsSection = (props: { totals: Totals }) => {
  const totals = props.totals

  return (
    <div>
      <h3>
        <Trans>Totals</Trans>
      </h3>
      <ul>
        <li>
          <Plural
            value={totals.totalQuantity}
            one="# orthotic"
            other="# orthotics"
          />
          {' '}<Trans>in</Trans>{' '}
          <Plural
            value={totals.totalOrders}
            one="# order"
            other="# orders"
          />
        </li>
        <li>
          <Plural
            value={totals.totalShell}
            one="# shell"
            other="# shells"
          />
          {' '}/{' '}
          <Plural
            value={totals.totalHybrid}
            one="# hybrid"
            other="# hybrids"
          />
        </li>
        <li>
          {totals.totalTopCover} <Trans>with top cover</Trans>
          {' '}/{' '}
          {totals.totalNoTopCover} <Trans>without</Trans>
        </li>
        <li>
          <Plural
            value={totals.totalDuplicates}
            one="# additional pair"
            other="# additional pairs"
          />
          {' '}/{' '}
          <Plural
            value={totals.totalOriginals}
            one="# new order"
            other="# new orders"
          />
        </li>
      </ul>
    </div>
  )
}

async function processOrders<TOrder extends Order | OrderForManufacturer>(
  orders: TOrder[],
  columns: Array<ColumnDef<TOrder>>,
  totals: Totals,
): Promise<ReactNode[]> {
  const printRows: ReactNode[] = []
  for (const order of orders) {
    const items = getOrderItemsCount(order)

    const prescription = isOrder(order) && order.prescriptionId !== null
      ? await getPrescription(order.clinicId, order.id, null)
      : null

    const productType = getProductType(order, prescription)
    if (productType === productTypes.hybrid) {
      totals.totalHybrid += items
    } else {
      totals.totalShell += items
    }

    const topCoverLength = getTopCoverLength(order, prescription)
    if (topCoverLength !== topCoverLengths.none) {
      totals.totalTopCover += items
    } else {
      totals.totalNoTopCover += items
    }

    if (order.originalOrderId === null) totals.totalOriginals++
    else totals.totalDuplicates++

    printRows.push(
      <tr key={order.id}>
        {
          columns.map(columnDef => {
            if (columnDef.enabled === false) return null
            return (
              <td key={columnDef.key}>
                {columnDef.getValue(order, prescription)}
              </td>
            )
          })
        }
      </tr>,
    )

    totals.totalQuantity += items
  }

  return printRows
}

async function doPrintOrders<TOrder extends Order | OrderForManufacturer>(
  i18n: I18n,
  orders: TOrder[],
  columns: Array<ColumnDef<TOrder>>,
): Promise<void> {
  const totals: Totals = {
    totalOrders: orders.length,
    totalQuantity: 0,
    totalShell: 0,
    totalHybrid: 0,
    totalTopCover: 0,
    totalNoTopCover: 0,
    totalOriginals: 0,
    totalDuplicates: 0,
  }

  const printRows = await processOrders(orders, columns, totals)

  const now = new Date()
  const date = toTableDateTime(i18n, now)

  // NOTE(pascal): The "double-table" layout is to allow for content to be
  // repeated on multiple pages, such as the header.
  // The outer table is used for repeating content,
  // the inner table is used for the actual orders data.
  printReact(
    t(i18n)`Podform3D - Orders report - ${date}`,
    <div>
      <table
        style={{
          width: '100%',
        }}
      >
        <PageHeader
          i18n={i18n}
          date={now}
        />
        <tbody>
          <tr>
            <td>
              <table
                style={{
                  width: '100%',
                  tableLayout: 'auto',
                }}
              >
                <thead>
                  <tr
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    {
                      columns.map(columnDef => {
                        if (columnDef.enabled === false) return null
                        return (
                          <th key={columnDef.key}>
                            {columnDef.header}
                          </th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {printRows}
                </tbody>
                <tfoot>
                  <tr
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    {
                      columns.map(columnDef => {
                        if (columnDef.enabled === false) return null
                        return (
                          <th key={columnDef.key}>
                            {columnDef.getFooter?.(totals) ?? null}
                          </th>
                        )
                      })
                    }
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <TotalsSection totals={totals} />
    </div>,
    'landscape',
  )
}

const printOrders = async (
  i18n: I18n,
  orders: Order[] | OrderForManufacturer[],
  roleManager: UserRoleManager,
): Promise<void> => {
  return doPrintOrders<Order | OrderForManufacturer>(
    i18n,
    orders,
    [
      {
        key: 'orderNumber',
        header: <Trans>Order #</Trans>,
        getValue: order => order.orderNumber,
        getFooter: totals =>
          <Plural
            value={totals.totalOrders}
            one="# order"
            other="# orders"
          />,
      },
      {
        key: 'orderStatus',
        header: <Trans>Order status</Trans>,
        getValue: order => getOrderStatusDisplays(i18n)[order.activeStatus].label,
      },
      {
        enabled: roleManager.isAdmin,
        key: 'billingStatus',
        header: <Trans>Billing status</Trans>,
        getValue: order => isOrder(order) ? getBillingStatusDisplays(i18n)[order.billingStatus].label : null,
      },
      {
        key: 'firstName',
        header: <Trans>First name</Trans>,
        getValue: order => order.patient.firstName,
      },
      {
        key: 'lastName',
        header: <Trans>Last name</Trans>,
        getValue: order => order.patient.lastName,
      },
      {
        key: 'clinic',
        header: <Trans>Clinic</Trans>,
        getValue: order => order.clinicName,
      },
      {
        enabled: roleManager.isAdmin,
        key: 'clinician',
        header: <Trans>Clinician</Trans>,
        getValue: order => isOrder(order) ? getPersonFullName(order.clinician) : null,
      },
      {
        key: 'productType',
        header: <Trans>Product type</Trans>,
        getValue: (order, prescription) =>
          getProductTypeLabel(i18n, getProductType(order, prescription)),
      },
      {
        key: 'topCover',
        header: <Trans>Top cover</Trans>,
        getValue: (order, prescription) => {
          const topCoverLength = getTopCoverLength(order, prescription)
          return (
            <TextCheckBox
              checked={topCoverLength !== null && topCoverLength !== topCoverLengths.none}
            />
          )
        },
      },
      {
        key: 'additionalPair',
        header: <Trans>Additional pair</Trans>,
        getValue: order => <TextCheckBox checked={order.originalOrderId !== null} />,
      },
      {
        key: 'qty',
        header: <Trans>Qty</Trans>,
        getValue: order => getOrderItemsCount(order),
        getFooter: totals => <Trans>{totals.totalQuantity} total</Trans>,
      },
    ],
  )
}

export {
  printOrders,
}
