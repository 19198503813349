
import { Trans } from '@lingui/macro'
import { Route, useParams } from '@tanstack/react-router'
import { Box } from 'grommet'
import { useRef } from 'react'

import CollapsiblePanel from '../../components/CollapsiblePanel'
import NotesEditor from '../../components/NotesEditor'
import { getOrder, ordersKeys } from '../../fetchers/orders'
import { useQueryOrder, useUpdateOrderComments } from '../../queries/orders'
import { getQueryClient } from '../../queryClient'
import { Order } from '../../types/Order'
import MainPanel from '../home/MainPanel'

type Props = {
  order: Order,
}

const CommentPanel = (props: Props) => {
  const params = useParams({ from: '/orders/clinics/$clinicId/orders/$orderId/comments' })

  const updateOrderComments = useUpdateOrderComments()

  const getValueRef = useRef<(() => string) | null>(null)

  const handleCommentsChange = (comments: string) => {
    updateOrderComments.mutate({
      clinicId: params.clinicId,
      orderId: params.orderId,
      value: comments,
    })
  }

  return (
    <CollapsiblePanel
      onClose={() => {
        updateOrderComments.mutate({
          clinicId: params.clinicId,
          orderId: params.orderId,
          value: getValueRef.current?.() ?? '',
        })
      }}
    >
      <Box height={'90vh'}>
        <NotesEditor
          debounce={true}
          wrapHeader={true}
          header={
            <Trans>Comments - {props.order.patient.lastName}, {props.order.patient.firstName}</Trans>
          }
          notes={props.order.comments}
          onChange={handleCommentsChange}
          getValue={ref => getValueRef.current = ref}
        />
      </Box>
    </CollapsiblePanel>
  )
}

const CommentPanelLoader = () => {
  const params = useParams({ from: '/orders/clinics/$clinicId/orders/$orderId/comments' })
  const order = useQueryOrder(params.clinicId, params.orderId)

  if (!order.data) return null

  return <CommentPanel order={order.data} />
}

CommentPanel.route = new Route({
  getParentRoute: () => MainPanel.route,
  path: 'clinics/$clinicId/orders/$orderId/comments',
  parseParams: params => ({
    clinicId: Number(params.clinicId),
    orderId: Number(params.orderId),
  }),
  onLoad: async ({ params, signal }) => {
    const queryClient = getQueryClient()

    await queryClient.prefetchQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ordersKeys.details(params.clinicId, params.orderId),
      queryFn: () => getOrder(params.clinicId, params.orderId, signal ?? null),
    })
  },
  component: CommentPanelLoader,
})

export default CommentPanel
