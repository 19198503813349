import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { cliniciansKeys, deleteClinician, getClinicians, postClinician, putClinician } from '../fetchers/clinicians'
import { AdminClinician, Clinician, NewClinician } from '../types/Clinician'

function useQueryClinicians<TQueryAdmin extends boolean>(
  clinicId: number | 'all' | null,
  queryAdmin?: TQueryAdmin,
): UseQueryResult<TQueryAdmin extends true ? AdminClinician[] : Clinician[]> {
  return useQuery({
    queryKey: cliniciansKeys.list(clinicId, {queryAdmin}),
    queryFn: ({signal}) => getClinicians(clinicId ?? -1, queryAdmin ?? false, signal),
    enabled: clinicId !== null && clinicId !== -1,
  })
}

const useCreateClinician = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; clinician: NewClinician}) =>
      postClinician(data.clinicId, data.clinician),
    onSuccess: (clinician, data) => {
      void queryClient.invalidateQueries({queryKey: cliniciansKeys.list(data.clinicId)})
      queryClient.setQueryData(cliniciansKeys.details(clinician.id), clinician)
    },
  })
}

const useUpdateClinician = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; clinician: Clinician | AdminClinician}) =>
      putClinician(data.clinicId, data.clinician),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: cliniciansKeys.list(data.clinicId)})
      void queryClient.invalidateQueries({queryKey: cliniciansKeys.details(data.clinician.id)})
      queryClient.setQueryData(cliniciansKeys.details(data.clinician.id), data.clinician)
    },
  })
}

const useDeleteClinician = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; clinicianId: number}) =>
      deleteClinician(data.clinicId, data.clinicianId),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: cliniciansKeys.list(data.clinicId)})
      void queryClient.invalidateQueries({queryKey: cliniciansKeys.details(data.clinicianId)})
    },
  })
}

export {
  useQueryClinicians,
  useCreateClinician,
  useUpdateClinician,
  useDeleteClinician,
}
