import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const topCoverLengths = {
  none: 0,
  mets: 1,
  sulcus: 2,
  toes: 3,
} as const

export const notAvailable = 'Not available'

export const footbedLength: string[] = [
  notAvailable,
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
  '13.5',
  '14',
  '14.5',
  '15',
  '15.5',
  '16',
  '16.5',
  '17',
  '17.5',
  '18',
  '18.5',
  '19',
  '19.5',
  '20',
  '20.5',
  '21',
  '21.5',
  '22',
  '22.5',
  '23',
  '23.5',
  '24',
  '24.5',
  '25',
  '25.5',
  '26',
  '26.5',
  '27',
  '27.5',
  '28',
  '28.5',
  '29',
  '29.5',
  '30',
  '30.5',
  '31',
  '31.5',
  '32',
  '32.5',
  '33',
  '33.5',
  '34',
  '34.5',
  '35',
  '35.5',
  '36',
  '36.5',
  '37',
  '37.5',
  '38',
  '38.5',
  '39',
  '39.5',
  '40',
]

export const footbedWidth: string[] = [
  notAvailable,
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
  '13.5',
  '14',
  '14.5',
  '15',
]

export const topCoverMaterials = {
  'none': 0,
  'thin': 1,
  'active': 2,
  'cushion': 3,
} as const

export type TopCoverLength = typeof topCoverLengths[keyof typeof topCoverLengths]
export type TopCoverMaterial = typeof topCoverMaterials[keyof typeof topCoverMaterials]

export const getTopCoverLengthLabel = (i18n: I18n, value: TopCoverLength) => {
  switch (value) {
  case topCoverLengths.none: return t(i18n)`None`
  case topCoverLengths.mets: return t(i18n)`Mets`
  case topCoverLengths.sulcus: return t(i18n)`Sulcus`
  case topCoverLengths.toes: return t(i18n)`Toes`
  default: return t(i18n)`Unknown top cover length option`
  }
}

export const getTopCoverMaterialLabel = (i18n: I18n, value: TopCoverMaterial) => {
  switch (value) {
  case topCoverMaterials.none: return t(i18n)`None`
  case topCoverMaterials.thin: return t(i18n)`Thin (WSL 1mm Black)`
  case topCoverMaterials.active: return t(i18n)`Active (Multifoam MP 2 mm Black)`
  case topCoverMaterials.cushion: return t(i18n)`Cushion (Foam 3mm Black)`
  default: return t(i18n)`Unknown top cover material option`
  }
}
