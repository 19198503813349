import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { deleteLocation, getLocations, locationsKeys, postLocation, putLocation } from '../fetchers/locations'
import { Location, NewLocation } from '../types/Location'

const useQueryLocations = (clinicId: number | 'all' | null) => {
  return useQuery({
    queryKey: locationsKeys.list(clinicId),
    queryFn: ({signal}) => getLocations(clinicId ?? -1, signal),
    enabled: clinicId !== null,
  })
}

const useCreateLocation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; location: NewLocation}) =>
      postLocation(data.clinicId, data.location),
    onSuccess: (location, data) => {
      void queryClient.invalidateQueries({queryKey: locationsKeys.list(data.clinicId)})
      queryClient.setQueryData(locationsKeys.details(location.id), location)
    },
  })
}

const useUpdateLocation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; location: Location}) =>
      putLocation(data.clinicId, data.location),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: locationsKeys.list(data.clinicId)})
      void queryClient.invalidateQueries({queryKey: locationsKeys.details(data.location.id)})
      queryClient.setQueryData(locationsKeys.details(data.location.id), data.location)
    },
  })
}

const useDeleteLocation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; locationId: number}) =>
      deleteLocation(data.clinicId, data.locationId),
    onSuccess: (_, data) => {
      void queryClient.invalidateQueries({queryKey: locationsKeys.list(data.clinicId)})
      void queryClient.invalidateQueries({queryKey: locationsKeys.details(data.locationId)})
    },
  })
}

export {
  useQueryLocations,
  useCreateLocation,
  useUpdateLocation,
  useDeleteLocation,
}
