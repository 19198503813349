
import { Trans } from '@lingui/macro'
import { Box, Button, Heading, Layer } from 'grommet'
import { ReactNode } from 'react'

type Props = Readonly<{
  show: boolean,
  title: ReactNode,
  message?: ReactNode,
  confirmLabel?: ReactNode,
  onConfirm: () => unknown,
  onCancel: () => unknown,
}>

const ConfirmModal = (props: Props) => {
  if (!props.show) return null

  return (
    <Layer modal={true}>
      <Box
        round={true}
        pad="1rem"
      >
        <Heading level="2">
          {props.title}
        </Heading>
        {props.message}
        <Box
          direction="row"
          justify="end"
          gap="0.5rem"
          margin={{top: '1rem'}}
        >
          <Button
            primary={true}
            color="red"
            label={props.confirmLabel ?? <Trans>Confirm</Trans>}
            onClick={props.onConfirm}
          />
          <Button
            label={<Trans>Cancel</Trans>}
            onClick={props.onCancel}
          />
        </Box>
      </Box>
    </Layer>
  )
}

export default ConfirmModal
