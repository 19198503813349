// Only index (and wrappers) should be allowed access to restricted import!

import { I18nProvider } from '@lingui/react'
import { QueryClientProvider } from '@tanstack/react-query'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Amplify } from 'aws-amplify'
import { Grommet } from 'grommet'
import { generate } from 'grommet/themes'
import { deepMerge } from 'grommet/utils'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Provider } from 'react-redux'

import App from './App'
import awsConfig from './aws-exports'
import { getStartAwsRum } from './constants/runtime'
import { themeColour } from './css/Theme'
import { TrackerContext } from './hooks/tracking'
import { i18n, initI18n } from './i18n'
import { createQueryClient } from './queryClient'
import reportWebVitals from './reportWebVitals'
import { store } from './store/store'

import '@aws-amplify/ui/dist/styles.css'
import './index.css'
import './amplify-login.css'

const startAwsRum = getStartAwsRum()
const awsRum = startAwsRum?.() ?? null

const domRoot = document.getElementById('root')

if (domRoot) {
  initI18n()
  Amplify.configure(awsConfig)
  const updatedTheme = deepMerge(generate(16), themeColour)

  const root = createRoot(domRoot)

  root.render(
    <TrackerContext.Provider value={{ awsRum }}>
      <StrictMode>
        <Provider store={store}>
          <I18nProvider i18n={i18n}>
            <QueryClientProvider client={createQueryClient(store.dispatch)}>
              <Grommet theme={updatedTheme}>
                <App />
              </Grommet>
            </QueryClientProvider>
          </I18nProvider>
        </Provider>
      </StrictMode>
    </TrackerContext.Provider>,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
