
import { Trans } from '@lingui/macro'
import { Box, Button, Form, FormField, Heading, TextInput } from 'grommet'
import { useState } from 'react'

import ConfirmModal from '../../components/modals/ConfirmModal'
import { useClinicId } from '../../hooks/clinic'
import { useIsAdmin } from '../../hooks/roles'
import { useCreateClinician, useDeleteClinician, useQueryClinicians, useUpdateClinician } from '../../queries/clinicians'
import { AdminClinician, NewClinician, getNewClinician } from '../../types/Clinician'
import { getPersonFullName } from '../../utils/person'

import EditableList, { EditorProps } from './EditableList'

const isExistingClinician = (value: AdminClinician | NewClinician): value is AdminClinician =>
  (value as Record<string, unknown>)['id'] !== undefined

const ClinicianEditor = (props: EditorProps<AdminClinician, NewClinician>) => {
  const clinicId = useClinicId()
  const isAdmin = useIsAdmin()

  const [firstName, setFirstName] = useState<string>(props.item.firstName)
  const [lastName, setLastName] = useState<string>(props.item.lastName)
  const [userId, setUserId] = useState<string>(props.item.userId)

  const createClinician = useCreateClinician()
  const updateClinician = useUpdateClinician()

  const isDirty =
    firstName !== props.item.firstName ||
    lastName !== props.item.lastName ||
    userId !== props.item.userId

  const handleSave = () => {
    if (!isDirty) return

    if (isExistingClinician(props.item)) {
      updateClinician.mutate(
        {
          clinicId,
          clinician: {
            id: props.item.id,
            firstName,
            lastName,
            userId,
            clinicId,
          },
        },
        {
          onSuccess: () => {
            props.onSaved?.()
          },
        },
      )
    } else {
      createClinician.mutate(
        {
          clinicId,
          clinician: {
            firstName,
            lastName,
            userId,
          },
        },
        {
          onSuccess: () => {
            props.onSaved?.()
          },
        },
      )
    }
  }

  const handleRevert = () => {
    if (!isDirty && !props.onCancel) return

    setFirstName(props.item.firstName)
    setLastName(props.item.lastName)
    setUserId(props.item.userId)

    props.onCancel?.()
  }

  return (
    <Form>
      <FormField
        label={
          <Heading
            level="4"
            margin="none"
          >
            <Trans>First name</Trans>
          </Heading>
        }
      >
        <TextInput
          value={firstName}
          onChange={event => setFirstName(event.currentTarget.value)}
        />
      </FormField>

      <FormField
        label={
          <Heading
            level="4"
            margin="none"
          >
            <Trans>Last name</Trans>
          </Heading>
        }
      >
        <TextInput
          value={lastName}
          onChange={event => setLastName(event.currentTarget.value)}
        />
      </FormField>

      {
        isAdmin &&
        <FormField
          label={
            <Heading
              level="4"
              margin="none"
            >
              <Trans>User ID</Trans>
            </Heading>
          }
        >
          <TextInput
            value={userId}
            onChange={event => setUserId(event.currentTarget.value)}
          />
        </FormField>
      }

      <Box
        direction="row"
        justify="end"
        gap="0.5rem"
      >
        <Button
          primary={true}
          label={
            updateClinician.isPending
              ? <Trans>Saving...</Trans>
              : <Trans>Save</Trans>
          }
          onClick={handleSave}
          disabled={!isDirty || updateClinician.isPending}
        />
        {
          props.onDelete &&
          <Button
            secondary
            color="red"
            label={<Trans>Delete</Trans>}
            onClick={props.onDelete}
          />
        }
        <Button
          label={<Trans>Cancel</Trans>}
          onClick={handleRevert}
          disabled={(!isDirty && !props.onCancel) || updateClinician.isPending}
        />
      </Box>
    </Form>
  )
}

const CliniciansEditor = () => {
  const clinicId = useClinicId()
  const clinicians = useQueryClinicians(clinicId, true)

  const [confirmDelete, setConfirmDelete] = useState<AdminClinician | null>(null)

  const deleteClinician = useDeleteClinician()

  return (
    <>
      <EditableList
        query={clinicians}
        editor={ClinicianEditor}
        checkClinicSet={true}
        onNewItem={getNewClinician}
        onSort={(a, b) => getPersonFullName(a).localeCompare(getPersonFullName(b))}
        onShowItem={getPersonFullName}
        onDelete={(clinician) => setConfirmDelete(clinician)}
      />

      <ConfirmModal
        show={confirmDelete !== null}
        title={<Trans>Delete clinician?</Trans>}
        message={
          <Trans>
            Are you sure you want to delete this clinician?
            This operation can be very hard to undo.
          </Trans>
        }
        confirmLabel={<Trans>Yes, delete this clinician</Trans>}
        onConfirm={() => {
          if (!confirmDelete) return

          deleteClinician.mutate(
            {clinicId, clinicianId: confirmDelete.id},
            {
              onSuccess: () => {
                setConfirmDelete(null)
              },
            },
          )
        }}
        onCancel={() => setConfirmDelete(null)}
      />
    </>
  )
}

export default CliniciansEditor
