import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const forefootWidthValues = {
  standard: 0,
  wide: 1,
  narrow: 2,
} as const

export type ForefootWidth = typeof forefootWidthValues[keyof typeof forefootWidthValues]

export const getForefootWidthLabel = (i18n: I18n, value: ForefootWidth) => {
  switch (value) {
  case forefootWidthValues.standard: return t(i18n)`Standard`
  case forefootWidthValues.narrow: return t(i18n)`Narrow`
  case forefootWidthValues.wide: return t(i18n)`Wide`
  default: return t(i18n)`Standard`
  }
}
