async function forEachSelected<TOrder extends {id: number; clinicId: number}>(
  orders: TOrder[],
  currentId: number,
  ids: number[],
  action: (order: TOrder) => Promise<unknown>,
): Promise<void> {
  const items = new Set<TOrder>()

  const currentOrder = orders.find(order => order.id === currentId)
  if (currentOrder) items.add(currentOrder)

  for (const id of ids) {
    const order = orders.find(order => order.id === id)
    if (order) items.add(order)
  }

  for (const order of items) {
    await action(order)
  }
}

export {
  forEachSelected,
}
