import { guidSuffixRegex } from './files'

const triggerDownload = (
  blob: Blob,
  filename: string,
  extension: string,
) => {
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')

  a.href = url
  a.download = `${filename.replace(guidSuffixRegex, '')}.${extension}`

  const clickHandler = () => {
    setTimeout(
      () => {
        URL.revokeObjectURL(url)
        a.removeEventListener('click', clickHandler)
      },
      500,
    )
  }

  a.addEventListener('click', clickHandler, false)

  a.click()
}

export {
  triggerDownload,
}
