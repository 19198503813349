import { I18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { CheckBox, ColumnConfig } from 'grommet'

import ColumnFilter from '../../../components/ColumnFilter'
import { SelectMultipleOption } from '../../../components/grommet-sub/SelectMultiple'
import { Order } from '../../../types/Order'
import { getGenderLabel } from '../../../types/PrescriptionEnums/Gender'
import { getShoeSizeSystemLabel, shoeSizeSystems } from '../../../types/PrescriptionEnums/ShoeSizeSystem'
import { TopCoverMaterial, getTopCoverLengthLabel, getTopCoverMaterialLabel, topCoverLengths, topCoverMaterials } from '../../../types/PrescriptionEnums/TopCoverLength'

import { getBillingStatusColumn, getClinicNameColumn, getOrderNumberColumn, getPatientFirstNameColumn, getPatientLastNameColumn, getProductInfoColumn, getSelectColumn, getStatusColumn, getSubmittedAtColumn } from './Columns'
import { Data } from './Data'

function getShoeSizeColumn(): ColumnConfig<Order> {
  return {
    property: 'shoeSize',
    header: <Trans>Shoe size</Trans>,
    render: function Render(order) {
      const {i18n} = useLingui()

      if (!order.prescription) return null

      return (
        <>
          {getShoeSizeSystemLabel(i18n, order.prescription.patientInformation.shoeSizeSystem)}
          {' '}
          {order.prescription.patientInformation.shoeSize}
        </>
      )
    },
  }
}

function getGenderColumn(): ColumnConfig<Order> {
  return {
    property: 'gender',
    header: <Trans>Gender</Trans>,
    render: function Render(order) {
      const {i18n} = useLingui()

      if (!order.prescription) return null

      return (
        order.prescription.patientInformation.shoeSizeSystem === shoeSizeSystems.eu
          ? t(i18n)`N/A` // A bit weird, but the gender is only relevant (hence collected) in the US shoe sizes...
          : getGenderLabel(i18n, order.prescription.patientInformation.gender)
      )
    },
  }
}

function getTopCoverLength(): ColumnConfig<Order> {
  return {
    property: 'topCoverLength',
    header: <Trans>Top cover length</Trans>,
    render: function Render(order) {
      const {i18n} = useLingui()

      if (!order.prescription) return null

      return getTopCoverLengthLabel(i18n, order.prescription.rxOrthoticBilateral.topCoverLength)
    },
  }
}

const getTopCoverMaterialOptions = (i18n: I18n): Array<SelectMultipleOption<TopCoverMaterial>> =>
  Object
    .values(topCoverMaterials)
    .map(topCoverMaterial => ({
      label: getTopCoverMaterialLabel(i18n, topCoverMaterial),
      value: topCoverMaterial,
    }))

function getTopCoverMaterial(i18n: I18n, data: Data<false, Order>): ColumnConfig<Order> {
  const {
    onHeaderExpand,
    orderFilters,
    setOrderFilters,
  } = data

  return {
    property: 'topCoverMaterial',
    header:
      <ColumnFilter
        label={<Trans>Material</Trans>}
        excluded={orderFilters.excludeTopCoverMaterials}
        options={getTopCoverMaterialOptions(i18n)}
        onExpand={onHeaderExpand}
        onChange={excluded => setOrderFilters({...orderFilters, excludeTopCoverMaterials: excluded})}
      />,
    render: function Render(order) {
      const {i18n} = useLingui()

      if (!order.prescription) return null

      return getTopCoverMaterialLabel(
        i18n,
        order.prescription.rxOrthoticBilateral.topCoverLength === topCoverLengths.none
          ? topCoverMaterials.none
          : order.prescription.rxOrthoticBilateral.topCoverMaterial,
      )
    },
  }
}

function getTopCoverApart(): ColumnConfig<Order> {
  return {
    property: 'topCoverApart',
    header: <Trans>Apart</Trans>,
    render: (order) => {
      if (!order.prescription) return null

      return (
        <CheckBox
          readOnly={true}
          disabled={true}
          defaultChecked={order.prescription.rxOrthoticBilateral.topCoverSeperatedParts}
        />
      )
    },
  }
}

function getShippingColumns(
  i18n: I18n,
  data: Data<false, Order>,
): Array<ColumnConfig<Order>> {
  return [
    getSelectColumn(data),
    getStatusColumn(data),
    getBillingStatusColumn(data),
    getProductInfoColumn(data),
    getOrderNumberColumn(),
    getClinicNameColumn(),
    getPatientFirstNameColumn(),
    getPatientLastNameColumn(),
    getShoeSizeColumn(),
    getGenderColumn(),
    getTopCoverLength(),
    getTopCoverMaterial(i18n, data),
    getTopCoverApart(),
    getSubmittedAtColumn(),
  ]
}

export {
  getShippingColumns,
}
