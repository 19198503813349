import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const requestedSides = {
  both: 0,
  left: 1,
  right: 2,
} as const

export type RequestedSide = typeof requestedSides[keyof typeof requestedSides]

export const getRequestedSideProductLabel = (i18n: I18n, side: RequestedSide) => {
  switch (side) {
  case requestedSides.both: return t(i18n)`Pair`
  case requestedSides.left: return t(i18n)`Left only`
  case requestedSides.right: return t(i18n)`Right only`
  default: return t(i18n)`Unknown requested side`
  }
}
