
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useNavigate } from '@tanstack/react-router'
import { Box, Button, Form, FormField, Heading } from 'grommet'
import printJS from 'print-js'
import { useMemo } from 'react'
import { renderToString } from 'react-dom/server'

import logoImage from '../../assets/logo.png'
import CollapsiblePanel from '../../components/CollapsiblePanel'
import FormSection from '../../components/FormSection'
import PrintableCertificate from '../../components/PrintableCertificate'
import PrintableCheckbox from '../../components/PrintableCheckbox'
import PrintableLabel from '../../components/PrintableLabel'
import InformationPresenter from '../../components/modals/InformationPresenter'
import { useLoading } from '../../hooks/loading'
import { useShowSuccessToast } from '../../hooks/toasts'
import { useDuplicateOrder } from '../../queries/orders'
import { allLocation } from '../../types/Location'
import { Order } from '../../types/Order'
import { OmsPrescription, RxOrthotic, getHeelCupHeightKey } from '../../types/Prescription'
import { flexMidfootPerforatedValues, getFlexMidfootPerforatedLabel } from '../../types/PrescriptionEnums/FlexMidfootPerforated'
import { getForefootWidthLabel } from '../../types/PrescriptionEnums/ForefootWidth'
import { getShortLabel } from '../../types/PrescriptionEnums/Gender'
import { getLowerMedialArchLabel, lowerMedialArches } from '../../types/PrescriptionEnums/LowerMedialArch'
import { getPostingOptionLabel } from '../../types/PrescriptionEnums/PostingOption'
import { getPostingPositionLabel, postingPositions } from '../../types/PrescriptionEnums/PostingPosition'
import { RequestedSide, requestedSides } from '../../types/PrescriptionEnums/RequestedSide'
import { retroPadSizes } from '../../types/PrescriptionEnums/RetroPads'
import { getShellRigidityLabel } from '../../types/PrescriptionEnums/ShellRigidity'
import { getStiffMidfootBarLabel, stiffMidfootBars } from '../../types/PrescriptionEnums/StiffMidFootBar'
import { notAvailable, topCoverLengths } from '../../types/PrescriptionEnums/TopCoverLength'
import { findHeelLift } from '../../utils/PrescriptionMapper'
import { dateNowIso } from '../../utils/date'
import { getPersonFullName } from '../../utils/person'

import { getAllTopCoverMaterialOptions, getProductionOptions, getRetroPadOrientations, getRetroPadSizeOptions, getTopCoverLengthOptions, getTopCoverThicknessOptions } from './options'

const poundSign = '#'

const getLeftRightBilateralOrNone = (
  isLeft: boolean,
  isRight: boolean,
): RequestedSide | 'none' => {
  if (isLeft && isRight) return requestedSides.both
  if (isLeft) return requestedSides.left
  if (isRight) return requestedSides.right
  return 'none'
}

const areaNames = {
  header: 'header',
  patient: 'patient',
  description: 'description',
  orderType: 'orderType',
  diagnostic: 'diagnostic',
  production: 'production',
  prescription: 'prescription',
  modifications: 'modifications',
  posting: 'posting',
  comments: 'comments',
} as const

const HiddenHeaderId = 'HiddenHeaderId'

type Props = {
  printContainerId: string,
  order: Order,
  prescription: OmsPrescription,
  size?: string,
}

const PrescriptionViewerImpl = (props: Props) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const isShellModificationSet = (left: RxOrthotic, right: RxOrthotic): boolean =>
    left.modification.fasciaGroove ||
    left.modification.firstRayCutOut ||
    left.modification.gaitPlate ||
    left.modification.halluxExtension ||
    left.modification.heelHole ||
    left.modification.heelFork ||
    left.modification.lateralFlange ||
    left.modification.medialFlange ||
    left.modification.medialHeelSkive ||
    left.modification.retroPad ||
    left.modification.retroPadSize !== retroPadSizes.none ||
    left.rxOrthotic.flexMidfootPerforated !== flexMidfootPerforatedValues.none ||
    left.rxOrthotic.heelLift > 0 ||
    left.rxOrthotic.kineticExtension.length > 0 ||
    left.rxOrthotic.lowerMedialArch !== lowerMedialArches.noFlex ||
    left.rxOrthotic.stiffMidfootBar !== stiffMidfootBars.none ||
    right.modification.fasciaGroove ||
    right.modification.firstRayCutOut ||
    right.modification.gaitPlate ||
    right.modification.halluxExtension ||
    right.modification.heelHole ||
    right.modification.heelFork ||
    right.modification.lateralFlange ||
    right.modification.medialFlange ||
    right.modification.medialHeelSkive ||
    right.modification.retroPad ||
    right.modification.retroPadSize !== retroPadSizes.none ||
    right.rxOrthotic.flexMidfootPerforated !== flexMidfootPerforatedValues.none ||
    right.rxOrthotic.heelLift > 0 ||
    right.rxOrthotic.kineticExtension.length > 0 ||
    right.rxOrthotic.lowerMedialArch !== lowerMedialArches.noFlex ||
    right.rxOrthotic.stiffMidfootBar !== stiffMidfootBars.none

  const productionOptions = useMemo(() => getProductionOptions(i18n), [i18n])
  const topCoverLengthOptions = useMemo(() => getTopCoverLengthOptions(i18n), [i18n])
  const topCoverMaterialOptions = useMemo(() => getAllTopCoverMaterialOptions(i18n), [i18n])
  const topCoverThicknessOptions = useMemo(() => getTopCoverThicknessOptions(i18n), [i18n])
  const retroPadSizeOptions = useMemo(() => getRetroPadSizeOptions(i18n), [i18n])
  const retroPadOrientationOptions = useMemo(() => getRetroPadOrientations(i18n), [i18n])

  const leftRxOrthotic = props.prescription.leftFootRx.rxOrthotic
  const rightRxOrthotic = props.prescription.rightFootRx.rxOrthotic

  const leftModifications = props.prescription.leftFootRx.modification
  const rightModifications = props.prescription.rightFootRx.modification

  const leftPosting = props.prescription.leftFootRx.posting
  const rightPosting = props.prescription.rightFootRx.posting

  return (
    <Box
      width={props.size ?? 'xlarge'}
      overflow="auto"
    >
      <Box
        width={props.size ?? 'xlarge'}
        align="start"
        background="white"
        pad="small"
      >
        <InformationPresenter
          name="RxInformation"
          url="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFtCp02Ev8&#x2F;view?embed"
        />
        <Form
          id={props.printContainerId}
          className="formViewer"
          style={{
            width: '100%',
            margin: 'none',
            padding: 'none',
            flex: '1',
          }}
        >
          {/* // Patient information Section */}
          <Box
            margin="none"
            pad="none"
            direction="column"
            id={HiddenHeaderId}
          >
            <FormSection
              title="Clinic Information"
              printing
            >
              <Box
                gap="0.2rem"
                margin="none"
                direction="row"
              >
                <PrintableLabel
                  header={<Trans>Location</Trans>}
                  value={props.order.location?.description ?? ''}
                />
                <PrintableLabel
                  header={<Trans>Clinician</Trans>}
                  value={getPersonFullName(props.order.clinician)}
                />
                <PrintableLabel
                  header={<Trans>Consultation Date</Trans>}
                  value={props.prescription.consultationDate.substring(0, 10)}
                />
              </Box>
            </FormSection>
          </Box>
          <FormSection
            title="Patient Information"
            printing
          >
            <Box
              gap="0.2rem"
              margin="none"
              pad="none"
              direction="row"
            >
              <PrintableLabel
                header={<Trans>First name</Trans>}
                value={props.order.patient.firstName}
              />
              <PrintableLabel
                header={<Trans>Last name</Trans>}
                value={props.order.patient.lastName}
              />
              {props.order.patient.patientId !== null &&
                props.order.patient.patientId.length > 0
                ? (
                  <PrintableLabel
                    header={<Trans>Patient ID</Trans>}
                    value={props.order.patient.patientId}
                  />
                )
                : <></>
              }
            </Box>
            <Box
              gap="0.2rem"
              margin="none"
              pad="none"
              direction="row"
            >
              {props.order.patient.birthdate !== null &&
                <PrintableLabel
                  header={<Trans>Birthdate</Trans>}
                  value={props.order.patient.birthdate}
                />
              }
              {props.prescription.patientInformation.shoeSize !== 0 &&
                <Box
                  direction="row"
                  width={'100%'}
                >
                  <PrintableLabel
                    header={
                      props.prescription.patientInformation.shoeSizeSystem === 0
                        ? 'Shoe Size US'
                        : 'Shoe Size EU'
                    }
                    value={props.prescription.patientInformation.shoeSize}
                  />
                  {
                    props.prescription.patientInformation.shoeSizeSystem === 0 &&
                    <PrintableLabel
                      header={<Trans>Gender</Trans>}
                      value={getShortLabel(i18n, props.prescription.patientInformation.gender)}
                    />
                  }
                </Box>
              }
              {props.prescription.patientInformation.childGrowth &&
                <PrintableCheckbox
                  header={<Trans>Child Growth</Trans>}
                  checked={props.prescription.patientInformation.childGrowth}
                />
              }
            </Box>
            {/* // Diagnostic Section */}
            {
              (props.prescription.leftFootRx.diagnostic !== 'None' ||
                props.prescription.rightFootRx.diagnostic !== 'None') &&
              <Box
                gap="1rem"
                margin="none"
                pad="none"
                direction="row"
                width="100%"
              >
                <Box
                  direction="row"
                  gap="none"
                  margin="none"
                  pad="none"
                  width="100%"
                >
                  <PrintableLabel
                    header={<Trans>Left Diagnostic</Trans>}
                    value={props.prescription.leftFootRx.diagnostic}
                  />
                  <PrintableLabel
                    header={<Trans>Right Diagnostic</Trans>}
                    value={props.prescription.rightFootRx.diagnostic}
                  />
                </Box>
              </Box>
            }
          </FormSection>
          <FormSection
            title="Product"
            printing
          >
            <Box
              direction="column"
              gap="small"
            >
              <Box
                margin="none"
                gap="none"
                direction="row"
                width="100%"
              >
                <PrintableLabel
                  header={<Trans>Product Type</Trans>}
                  value={'Shell'}
                />
                {
                  props.prescription.side !== requestedSides.both &&
                  <PrintableLabel
                    header={<Trans>Produce Only One-Side</Trans>}
                    value={productionOptions
                      .find(option => option.value === props.prescription.side)?.label}
                  />
                }
              </Box>
              <Box
                margin="none"
                gap="none"
                direction="row"
                width="100%"
              >
                <PrintableLabel
                  header={<Trans>Shell Rigidity</Trans>}
                  value={getShellRigidityLabel(i18n, props.prescription.rxOrthoticBilateral.shellRigidity)}
                />
                <PrintableLabel
                  header={<Trans>Heel Cup Height</Trans>}
                  value={getHeelCupHeightKey(props.prescription.rxOrthoticBilateral.heelCupHeight)}
                />
                <PrintableLabel
                  header={<Trans>Fore foot Width</Trans>}
                  value={getForefootWidthLabel(i18n, props.prescription.rxOrthoticBilateral.footwearWidthForefoot)}
                />

              </Box>
            </Box>
          </FormSection>
          {/* // Order Section */}
          {/* // Order Specification Section */}
          {
            (isShellModificationSet(
              props.prescription.leftFootRx,
              props.prescription.rightFootRx) ||
              props.prescription.rxOrthoticBilateral.topCoverMaterial !== 0) &&
            <FormSection
              title="Modifications"
              printing
            >
              {props.prescription.rxOrthoticBilateral.topCoverThickness !== 0 &&
                <PrintableLabel
                  header={<Trans>Scan Expand</Trans>}
                  value={topCoverThicknessOptions
                    .find(option => option.value ===
                      props.prescription.rxOrthoticBilateral.topCoverThickness)?.label}
                />
              }
              {isShellModificationSet(
                props.prescription.leftFootRx,
                props.prescription.rightFootRx) &&
                <Box
                  margin="none"
                  pad="none"
                  direction="column"
                >
                  <Box
                    direction="row"
                  >
                    <Box
                      margin="none"
                      width="100%"
                    >

                      <Heading
                        margin="none"
                        level={4}
                        fill
                      >
                        Left
                      </Heading>
                    </Box>
                    <Box
                      margin="none"
                      width="100%"
                    >
                      <Heading
                        margin="none"
                        level={4}
                        fill
                      >
                        Right
                      </Heading>
                    </Box>
                  </Box>
                  {
                    (leftRxOrthotic.flexMidfootPerforated !==
                      flexMidfootPerforatedValues.none ||
                      rightRxOrthotic.flexMidfootPerforated !==
                      flexMidfootPerforatedValues.none) &&
                    (
                      <Box
                        direction="row"
                        pad={{ left: 'small', right: 'small', vertical: 'none' }}
                        gap="medium"
                      >
                        <PrintableLabel
                          header={<Trans>Perforations</Trans>}
                          value={
                            getFlexMidfootPerforatedLabel(
                              i18n,
                              leftRxOrthotic.flexMidfootPerforated,
                            )
                          }
                          inList
                        />
                        <PrintableLabel
                          header={<Trans>Perforations</Trans>}
                          value={
                            getFlexMidfootPerforatedLabel(
                              i18n,
                              rightRxOrthotic.flexMidfootPerforated,
                            )
                          }
                          inList
                        />
                      </Box>
                    )
                  }
                  {(leftRxOrthotic.stiffMidfootBar !==
                    stiffMidfootBars.none ||
                    rightRxOrthotic.stiffMidfootBar !==
                    stiffMidfootBars.none) &&
                    (
                      <Box
                        direction="row"
                        pad={{ left: 'small', right: 'small', vertical: 'none' }}
                        gap="medium"
                      >
                        <PrintableLabel
                          header={<Trans>Bar midfoot</Trans>}
                          value={getStiffMidfootBarLabel(i18n,
                            leftRxOrthotic.stiffMidfootBar)}
                          inList
                        />
                        <PrintableLabel
                          header={<Trans>Bar midfoot</Trans>}
                          value={getStiffMidfootBarLabel(i18n,
                            rightRxOrthotic.stiffMidfootBar)}
                          inList
                        />
                      </Box>)
                  }
                  {(leftRxOrthotic.lowerMedialArch !==
                    lowerMedialArches.noFlex ||
                    rightRxOrthotic.lowerMedialArch !==
                    lowerMedialArches.noFlex) &&
                    (
                      <Box
                        direction="row"
                        pad={{ left: 'small', right: 'small', vertical: 'none' }}
                        gap="medium"
                      >
                        <PrintableLabel
                          header={<Trans>Lower Medial Arch</Trans>}
                          value={getLowerMedialArchLabel(i18n,
                            leftRxOrthotic.lowerMedialArch)}
                          inList
                        />
                        <PrintableLabel
                          header={<Trans>Lower Medial Arch</Trans>}
                          value={getLowerMedialArchLabel(i18n,
                            rightRxOrthotic.lowerMedialArch)}
                          inList
                        />
                      </Box>
                    )
                  }
                  {(leftRxOrthotic.heelLift !== 0 ||
                    rightRxOrthotic.heelLift !== 0) &&
                    (
                      <Box
                        direction="row"
                        pad={{ left: 'small', right: 'small', vertical: 'none' }}
                        gap="medium"
                      >
                        <PrintableLabel
                          header={<Trans>Heel Lift</Trans>}
                          value={findHeelLift(leftRxOrthotic.heelLift)}
                          inList
                        />
                        <PrintableLabel
                          header={<Trans>Heel Lift</Trans>}
                          value={findHeelLift(props.prescription.rightFootRx.rxOrthotic.heelLift)}
                          inList
                        />
                      </Box>
                    )}
                </Box>

              }

              {
                (leftRxOrthotic.kineticExtension.length > 0 ||
                  rightRxOrthotic.kineticExtension.length > 0) &&
                <Box
                  direction="row"
                  pad={{ left: 'small', right: 'small', vertical: 'none' }}
                  width="100%"
                  gap="medium"
                >
                  <PrintableLabel
                    header={leftRxOrthotic.kineticExtension.length > 0
                      ? <Trans>Kinetic Extension</Trans>
                      : ''}
                    value={leftRxOrthotic.kineticExtension.join()}
                    inList
                  />
                  <PrintableLabel
                    header={props.prescription.rightFootRx.rxOrthotic.kineticExtension.length > 0
                      ? <Trans>Kinetic Extension</Trans>
                      : ''}
                    value={props.prescription.rightFootRx.rxOrthotic.kineticExtension.join()}
                    inList
                  />
                </Box>
              }
              {(leftModifications.retroPadSize !==
                retroPadSizes.none ||
                rightModifications.retroPadSize !==
                retroPadSizes.none) &&
                (
                  <Box
                    direction="row"
                    pad={{ left: 'small', right: 'small', vertical: 'none' }}
                    gap="medium"
                  >
                    <PrintableLabel
                      header={
                        leftModifications.retroPadSize !==
                          retroPadSizes.none
                          ? <Trans>Retro pad</Trans>
                          : ''}
                      value={
                        leftModifications.retroPadSize !== 0
                          ? (retroPadSizeOptions.find(option => option.value ===
                            leftModifications.retroPadSize,
                          )?.label ?? '') +
                          (`${retroPadOrientationOptions.find(option => option.value ===
                            leftModifications.retroPadOrientation,
                          )?.label ?? ''}`) : ''}
                      inList
                    />
                    <PrintableLabel
                      header={
                        rightModifications.retroPadSize !==
                          retroPadSizes.none
                          ? <Trans>Retro pad</Trans>
                          : ''}
                      value={
                        rightModifications.retroPadSize !== 0
                          ? (retroPadSizeOptions.find(option => option.value ===
                            rightModifications.retroPadSize,
                          )?.label ?? '') +
                          ' ' +
                          (retroPadOrientationOptions.find(option => option.value ===
                            rightModifications.retroPadOrientation,
                          )?.label ?? '')
                          : ''}
                      inList
                    />
                  </Box>
                )
              }
              {/* // Order Modification Section */}
              {
                Object
                  .values(leftModifications)
                  .concat(Object.values(rightModifications))
                  .some(item => item) &&
                <Box
                  margin="none"
                  pad={{ left: 'small', right: 'small', vertical: 'none' }}
                  gap="none"
                  direction="column"
                  gridArea={areaNames.modifications}
                >
                  {getLeftRightBilateralOrNone(
                    leftModifications.fasciaGroove,
                    rightModifications.fasciaGroove,
                  ) !== 'none' &&
                    (
                      <Box
                        direction="row"
                        gap="medium"
                      >
                        <PrintableLabel
                          header={leftModifications.fasciaGroove
                            ? <Trans>Fascia Groove</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                        <PrintableLabel
                          header={rightModifications.fasciaGroove
                            ? <Trans>Fascia Groove</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                      </Box>
                    )}
                  {getLeftRightBilateralOrNone(
                    leftModifications.heelHole,
                    rightModifications.heelHole,
                  ) !== 'none' &&
                    (
                      <Box
                        direction="row"
                        gap="medium"
                      >
                        <PrintableLabel
                          header={leftModifications.heelHole
                            ? <Trans>Heel Hole</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                        <PrintableLabel
                          header={rightModifications.heelHole
                            ? <Trans>Heel Hole</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                      </Box>
                    )}
                  {getLeftRightBilateralOrNone(
                    leftModifications.medialHeelSkive,
                    rightModifications.medialHeelSkive,
                  ) !== 'none' &&
                    (
                      <Box
                        direction="row"
                        gap="medium"
                      >
                        <PrintableLabel
                          header={leftModifications.medialHeelSkive
                            ? <Trans>Medial Heel Skive</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                        <PrintableLabel
                          header={rightModifications.medialHeelSkive
                            ? <Trans>Medial Heel Skive</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                      </Box>
                    )}
                  {getLeftRightBilateralOrNone(
                    leftModifications.halluxExtension,
                    rightModifications.halluxExtension,
                  ) !== 'none' &&
                    (
                      <Box
                        direction="row"
                        gap="medium"
                      >
                        <PrintableLabel
                          header={leftModifications.halluxExtension
                            ? <Trans>Hallux Extension</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                        <PrintableLabel
                          header={rightModifications.halluxExtension
                            ? <Trans>Hallux Extension</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                      </Box>
                    )}
                  {getLeftRightBilateralOrNone(
                    leftModifications.gaitPlate,
                    rightModifications.gaitPlate,
                  ) !== 'none' &&
                    (
                      <Box
                        direction="row"
                        gap="medium"
                      >
                        <PrintableLabel
                          header={leftModifications.gaitPlate
                            ? <Trans>Gait Plate</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                        <PrintableLabel
                          header={rightModifications.gaitPlate
                            ? <Trans>Gait Plate</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                      </Box>
                    )}
                  {getLeftRightBilateralOrNone(
                    leftModifications.medialFlange,
                    rightModifications.medialFlange,
                  ) !== 'none' &&
                    (
                      <Box
                        direction="row"
                        gap="medium"
                      >
                        <PrintableLabel
                          header={leftModifications.medialFlange
                            ? <Trans>Medial Flange</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                        <PrintableLabel
                          header={rightModifications.medialFlange
                            ? <Trans>Medial Flange</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                      </Box>
                    )}
                  {getLeftRightBilateralOrNone(
                    leftModifications.lateralFlange,
                    rightModifications.lateralFlange,
                  ) !== 'none' &&
                    (
                      <Box
                        direction="row"
                        gap="medium"
                      >
                        <PrintableLabel
                          header={leftModifications.lateralFlange
                            ? <Trans>Lateral Flange</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                        <PrintableLabel
                          header={rightModifications.lateralFlange
                            ? <Trans>Lateral Flange</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                      </Box>
                    )}
                  {getLeftRightBilateralOrNone(
                    leftModifications.firstRayCutOut,
                    rightModifications.firstRayCutOut,
                  ) !== 'none' &&
                    (
                      <Box
                        direction="row"
                        gap="medium"
                      >
                        <PrintableLabel
                          header={leftModifications.firstRayCutOut
                            ? <Trans>First Ray Cut Out</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                        <PrintableLabel
                          header={rightModifications.firstRayCutOut
                            ? <Trans>First Ray Cut Out</Trans>
                            : ''}
                          value={''}
                          inList
                        />
                      </Box>
                    )}
                  {
                    (leftModifications.heelFork ||
                      rightModifications.heelFork) &&
                    <Box
                      direction="row"
                      gap="medium"
                      width="100%"
                    >
                      <PrintableLabel
                        header={leftModifications.heelFork
                          ? <Trans>Heel Fork</Trans>
                          : ''}
                        value={''}
                        inList
                      />
                      <PrintableLabel
                        header={rightModifications.heelFork
                          ? <Trans>Heel Fork</Trans>
                          : ''}
                        value={''}
                        inList
                      />
                    </Box>
                  }
                </Box>
              }
            </FormSection>
          }
          {/* // Order Posting Section */}
          {(leftPosting.option !== 0 ||
            rightPosting.option !== 0) &&
            (
              <FormSection
                title="Posting"
                printing
              >
                <Box
                  direction="row"
                >
                  <Box
                    direction="column"
                    margin="none"
                    pad="none"
                    width="100%"
                  >
                    <Heading
                      margin="none"
                      level={4}
                    >
                      Left
                    </Heading>
                    <Box
                      direction="row"
                      width="250%"
                    >
                      <Box
                        direction="column"
                        margin="none"
                        pad="none"
                        width="250%"
                      >
                        <Heading
                          margin="none"
                          level={5}
                        >
                          Options
                        </Heading>
                        <Box
                          gap="1rem"
                          direction="column"
                        >
                          {getPostingOptionLabel(i18n, leftPosting.option)}
                        </Box>
                      </Box>
                      {leftPosting.position !== postingPositions.none &&
                        <Box
                          direction="column"
                          margin="none"
                          pad="none"
                          width="200%"
                        >
                          <Heading
                            margin="none"
                            level={5}
                          >
                            Position
                          </Heading>
                          <Box
                            gap="1rem"
                            direction="column"
                          >
                            {getPostingPositionLabel(i18n, leftPosting.position)}
                          </Box>
                        </Box>
                      }
                      {(leftPosting.correction === 3 ||
                        leftPosting.correction === 6) &&
                        (
                          <Box
                            direction="column"
                            margin="none"
                            pad="none"
                            width="250%"
                          >
                            <Heading
                              margin="none"
                              level={5}
                            >
                              Correction
                            </Heading>
                            <Box
                              gap="1rem"
                              direction="column"
                            >
                              {leftPosting.correction}
                            </Box>
                          </Box>
                        )}
                    </Box>
                  </Box>
                  <Box
                    direction="column"
                    margin="none"
                    pad="none"
                    width="100%"
                  >
                    <Heading
                      margin="none"
                      level={4}
                    >
                      Right
                    </Heading>
                    <Box
                      direction="row"
                      margin="none"
                      pad="none"
                      width="100%"
                    >
                      <Box
                        direction="column"
                        margin="none"
                        pad="none"
                        width="250%"
                      >
                        <Heading
                          margin="none"
                          level={5}
                        >
                          Options
                        </Heading>
                        <Box
                          gap="1rem"
                          direction="column"
                        >
                          {getPostingOptionLabel(i18n, rightPosting.option)}
                        </Box>
                      </Box>
                      {rightPosting.position !== postingPositions.none &&
                        <Box
                          direction="column"
                          margin="none"
                          pad="none"
                          width="200%"
                        >
                          <Heading
                            margin="none"
                            level={5}
                          >
                            Position
                          </Heading>
                          <Box
                            gap="1rem"
                            direction="column"
                          >
                            {getPostingPositionLabel(i18n, rightPosting.position)}
                          </Box>
                        </Box>
                      }
                      {(rightPosting.correction === 3 ||
                        rightPosting.correction === 6) &&
                        (
                          <Box
                            direction="column"
                            margin="none"
                            pad="none"
                            width="250%"
                          >
                            <Heading
                              margin="none"
                              level={5}
                            >
                              Correction
                            </Heading>
                            <Box
                              gap="1rem"
                              direction="column"
                            >
                              {rightPosting.correction}
                            </Box>
                          </Box>
                        )}
                    </Box>
                  </Box>
                </Box>
              </FormSection>
            )}
          <Box
            direction="column"
            gridArea={areaNames.comments}
          >
            {
              props.prescription.rxOrthoticBilateral.topCoverLength !== topCoverLengths.none &&
              <FormSection
                title="Top cover"
                printing
                helper={
                  <InformationPresenter
                    id={HiddenHeaderId}
                    name="TopCover-Information"
                    url={`https://www.canva.com/design/DAFwZrUU6I0/d92SdvV4Dt1LgaTL06-Jrg/view?embed`}
                  />
                }
              >
                <Box
                  direction="row"
                >

                  <PrintableLabel
                    header={<Trans>Length</Trans>}
                    value={topCoverLengthOptions
                      .find(option => option.value ===
                        props.prescription.rxOrthoticBilateral.topCoverLength)?.label}
                  />
                  <PrintableLabel
                    header={<Trans>Footbed length (cm)</Trans>}
                    value={
                      props.prescription.rxOrthoticBilateral.footbedLength === 0
                        ? notAvailable
                        : props.prescription.rxOrthoticBilateral.footbedLength
                    }
                  />
                  <PrintableLabel
                    header={<Trans>Footbed width (cm)</Trans>}
                    value={
                      props.prescription.rxOrthoticBilateral.footbedWidth === 0
                        ? notAvailable
                        : props.prescription.rxOrthoticBilateral.footbedWidth
                    }
                  />
                  <PrintableLabel
                    header={<Trans>Material</Trans>}
                    value={topCoverMaterialOptions
                      .find(option => option.value ===
                        props.prescription.rxOrthoticBilateral.topCoverMaterial)?.label}
                  />
                  <PrintableLabel
                    header={<Trans>Finished</Trans>}
                    value={props.prescription.rxOrthoticBilateral.topCoverSeperatedParts ? 'Not glued' : 'Glued'}
                  />
                </Box>
              </FormSection>
            }
            {props.prescription.specialInstructions.length > 0 && (
              <>
                <FormField margin="none">
                  <Heading
                    margin={{ top: 'xsmall', bottom: 'none' }}
                    level={4}
                  >
                    Special Instructions
                  </Heading>
                </FormField>
                <Box
                  wrap
                  margin={{ top: 'small', bottom: 'xsmall' }}
                >
                  {props.prescription.specialInstructions}
                </Box>
              </>
            )}
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

const printContainerId = 'prescription-viewer'

const PrescriptionViewer = (props: Omit<Props, 'printContainerId'>) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const navigate = useNavigate()
  const showSuccessToast = useShowSuccessToast()

  const { setLoading } = useLoading()

  const duplicateOrder = useDuplicateOrder()

  const handleOrderAnother = () => {
    setLoading(true)

    duplicateOrder.mutate(
      {
        clinicId: props.order.clinicId,
        orderId: props.order.id,
      },
      {
        onSuccess: (newOrder) => {
          navigate({
            to: '/orders/clinics/$clinicId/orders/$orderId/prescription',
            params: {
              clinicId: newOrder.clinicId,
              orderId: newOrder.id,
            },
          })
            .then(() => {
              setTimeout(
                () => {
                  showSuccessToast(
                    'duplicate-order-success',
                    <Trans>New pair ordered</Trans>,
                  )
                },
                750,
              )
            })
            .catch(error => console.error(error))
        },
        onSettled: () => {
          setLoading(false)
        },
      },
    )
  }

  const patientName = getPersonFullName(props.order.patient)

  return (
    <CollapsiblePanel>
      <PrescriptionViewerImpl
        {...props}
        printContainerId={printContainerId}

      />
      <Box
        direction="row"
        pad={{ top: '0.5rem', bottom: '0.5rem' }}
        gap="1rem"
        height={'3rem'}
      >
        <Button
          label="Download Prescription"
          primary
          onClick={() => {
            document.title = `${patientName} - Prescription`

            const orderNumberLabel = i18n._(t(i18n)`Order ${poundSign}${props.order.orderNumber}`)

            printJS({
              printable: printContainerId,
              ignoreElements: [HiddenHeaderId],
              type: 'html',
              header: renderToString(
                <div>
                  <img
                    src={logoImage}
                    alt="Podform3d logo"
                    height={'20px'}
                  />
                  <h2 style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'none',
                    padding: 'none',
                  }}
                  >
                    Custom Foot Orthotics Prescription
                  </h2>

                  <h4 style={{
                    fontWeight: 'normal',
                    margin: 'none',
                    padding: 'none',
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                  >
                    <div>
                      <h1 style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: 'none',
                        padding: 'none',
                        fontWeight: 'normal',
                      }}
                      >
                        {props.order.clinicName}
                      </h1>
                      {props.order.location?.civicNumber}&nbsp;
                      {props.order.location?.streetName}&nbsp;
                      {props.order.location?.city}
                      <br />
                      {props.order.location?.state}&nbsp;
                      {props.order.location?.country}&nbsp;
                      {props.order.location?.zipCode.toUpperCase()}
                    </div>
                    <div style={{
                      alignContent: 'end',
                      alignSelf: 'end',
                      alignItems: 'end',
                      justifyContent: 'space-between',
                      justifySelf: 'end',
                      justifyItems: 'end',
                      margin: 'none',
                      padding: 'none',
                      // display: 'flex',
                    }}
                    >
                      <h3>
                        {orderNumberLabel}
                      </h3>
                      <div>
                        Clinician: {getPersonFullName(props.order.clinician)}
                      </div>
                      <div>
                        Consultation Date : {props.prescription.consultationDate}
                      </div>
                    </div>
                  </h4>
                </div>,
              ),
              targetStyles: ['*'],
              documentTitle: `Podform3D - ${patientName}`,
              onPrintDialogClose: () => {
                document.title = 'Podform3D'
              },
            })
          }}
        />
        <Button
          label="Download Certificate"
          onClick={() => {
            document.title = `${patientName} - Certificate`

            printJS({
              printable: 'printableCertificate',
              type: 'html',
              targetStyles: ['*'],
              documentTitle: `Podform3D - ${patientName}`,
              onPrintDialogClose: () => {
                document.title = 'Podform3D'
              },
            })
          }}
        />
        <Button
          label={<Trans>Order another pair</Trans>}
          onClick={handleOrderAnother}
          disabled={duplicateOrder.isPending}
        />
        <div hidden>
          <PrintableCertificate
            id="printableCertificate"
            title={<Trans>Subject: Certificate of manufacture</Trans>}
            clinicName={props.order.clinicName?.toString() ?? ''}
            name={patientName}
            location={props.order.location ?? allLocation}
            getDate={dateNowIso}
          />
        </div>
      </Box>
    </CollapsiblePanel>
  )
}

export default PrescriptionViewer

export {
  PrescriptionViewerImpl,
}
