
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Box, Button, Heading, Layer, Text, TextInput } from 'grommet'
import { useMemo, useState } from 'react'

import { Select } from '../../components/grommet-sub'
import { CancellationReason, cancellationReasons, getCancellationReasonLabel } from '../../types/CancellationReason'

type Props = Readonly<{
  show: boolean,
  onConfirm: (reason: CancellationReason | string) => unknown,
  onCancel: () => unknown,
}>

const OrderCancellationReasonPrompt = (props: Props) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const [cancellationReason, setCancellationReason] = useState<CancellationReason | null>(null)
  const [otherReason, setOtherReason] = useState<string>('')

  const options: Array<{value: CancellationReason; label: string}> = useMemo(
    () => {
      return Object
        .values(cancellationReasons)
        .map(reason => ({
          value: reason,
          label: getCancellationReasonLabel(i18n, reason),
        }))
    },
    [
      i18n,
    ],
  )

  if (!props.show) return null

  return (
    <Layer modal={true}>
      <Box
        round={true}
        pad="1rem"
      >
        <Heading
          level="2"
          margin={{top: '0', bottom: '0.5rem'}}
        >
          <Trans>Cancel order</Trans>
        </Heading>
        <Text>
          <Trans>
            Please select the reason for cancelling this order:
          </Trans>
        </Text>
        <Select
          options={options}
          value={cancellationReason ?? ''}
          onChange={reason => {
            setCancellationReason(reason === '' ? null : reason)
            setOtherReason('')
          }}
        />
        {
          cancellationReason === cancellationReasons.other &&
          <Box
            margin={{top: '0.5rem'}}
          >
            <Text>
              <Trans>Reason:</Trans>
            </Text>
            <TextInput
              value={otherReason}
              onChange={event => setOtherReason(event.currentTarget.value)}
            />
          </Box>
        }
        <Box
          direction="row"
          justify="end"
          gap="0.5rem"
          margin={{top: '1rem'}}
        >
          <Button
            label={<Trans>Cancel</Trans>}
            onClick={() => {
              props.onCancel()
              setCancellationReason(null)
              setOtherReason('')
            }}
          />
          <Button
            primary={true}
            label={<Trans>Confirm</Trans>}
            disabled={
              cancellationReason === null ||
              (
                cancellationReason === cancellationReasons.other &&
                otherReason.trim() === ''
              )
            }
            onClick={() => {
              const other = otherReason.trim()
              props.onConfirm(other !== '' ? other : cancellationReason ?? '')
            }}
          />
        </Box>
      </Box>
    </Layer>
  )
}

export default OrderCancellationReasonPrompt
