import { ActiveSide } from './ActiveSide'
import { FlexMidfootPerforated } from './PrescriptionEnums/FlexMidfootPerforated'
import { ForefootWidth } from './PrescriptionEnums/ForefootWidth'
import { Gender } from './PrescriptionEnums/Gender'
import { KineticExtensions } from './PrescriptionEnums/KineticExtension'
import { LowerMedialArch } from './PrescriptionEnums/LowerMedialArch'
import { PostingCorrection, postingCorrections } from './PrescriptionEnums/PostingCorrection'
import { PostingOption, postingOptions } from './PrescriptionEnums/PostingOption'
import { PostingPosition, postingPositions } from './PrescriptionEnums/PostingPosition'
import { PrescriptionStatus } from './PrescriptionEnums/PrescriptionStatus'
import { ProductType } from './PrescriptionEnums/ProductType'
import { RequestedSide } from './PrescriptionEnums/RequestedSide'
import { RetroPadOrientations, RetroPadSizes } from './PrescriptionEnums/RetroPads'
import { ShellRigidity } from './PrescriptionEnums/ShellRigidity'
import { ShoeSizeSystem } from './PrescriptionEnums/ShoeSizeSystem'
import { StiffMidfootBar } from './PrescriptionEnums/StiffMidFootBar'
import { TopCoverLength, TopCoverMaterial } from './PrescriptionEnums/TopCoverLength'
import { TopCoverThickness } from './PrescriptionEnums/TopCoverThickness'

export type Prescription = {
  id: number,
  version: number,
  description: string,
  name: string,
  consultationDate: string,
  status: PrescriptionStatus,
  type: ProductType,
  specialInstructions: string,
  section: {
    shoeSizeSystem: ShoeSizeSystem,
    shoeSizeValue: string,
    gender: Gender,
    childGrowth: boolean,
    sideDiagnostic: RequestedSide | 'none',
    diagnostic: string,
    specifyDiagnostic: string,
    diagnosticOtherSide: string,
    specifyDiagnosticOtherSide: string,
    production: RequestedSide,
    productType: 'shell',
  },
  rxOrthotic: {
    shellRigidity: ShellRigidity,
    heelCupHeight: keyof typeof heelCupHeights,
    forefootWidth: ForefootWidth,
    flexMidfootPerforated: FlexMidfootPerforated,
    sideFlexMidfootPerforated: RequestedSide | 'none',
    flexMidfootOtherSide: FlexMidfootPerforated,
    stiffMidfootBar: StiffMidfootBar,
    sideStiffMidfootBar: RequestedSide | 'none',
    stiffMidfootOtherSide: StiffMidfootBar,
    lowerMedialArch: LowerMedialArch,
    sideLowerMedialArch: RequestedSide | 'none',
    lowerMedialArchOtherSide: LowerMedialArch,
    heelLift: string,
    sideHeelLift: RequestedSide | 'none',
    heelLiftOtherSide: string,
    topCoverActive: boolean,
    topCoverLength: TopCoverLength,
    footbedWidth: string,
    footbedLength: string,
    topCoverMaterial: TopCoverMaterial,
    apart: boolean,
    shellModificationOptions: boolean,
    postingOptions: boolean,
  },
  shellModification: {
    halluxExtension: RequestedSide | 'none',
    gaitPlate: RequestedSide | 'none',
    fasciaGroove: RequestedSide | 'none',
    medialHeelSkive: RequestedSide | 'none',
    medialFlange: RequestedSide | 'none',
    lateralFlange: RequestedSide | 'none',
    firstRayCutOut: RequestedSide | 'none',
    heelHole: RequestedSide | 'none',
    heelFork: RequestedSide | 'none',
    topCoverThickness: TopCoverThickness,
    kineticExtensionLeft: KineticExtensions[],
    kineticExtensionRight: KineticExtensions[],
    sideKineticExtension: RequestedSide | 'none',
    retroPadOrientationLeft: RetroPadOrientations,
    retroPadOrientationRight: RetroPadOrientations,
    retroPadSizeLeft: RetroPadSizes,
    retroPadSizeRight: RetroPadSizes,
    retroPad: boolean,
    sideRetroPad: RequestedSide | 'none',
    kineticExtension: boolean,
  },
  posting: {
    sidePosting: RequestedSide | 'none',
    leftPostingOption: PostingOption,
    leftPostingPosition: PostingPosition,
    leftPostingCorrection: PostingCorrection,
    rightPostingOption: PostingOption,
    rightPostingPosition: PostingPosition,
    rightPostingCorrection: PostingCorrection,
  },
}

export type PrescriptionRecursiveKey =
  | keyof Prescription
  | keyof Prescription['section']
  | keyof Prescription['rxOrthotic']
  | keyof Prescription['shellModification']
  | keyof Prescription['posting']

export type PrescriptionRecursiveValue<TKey extends PrescriptionRecursiveKey> =
  TKey extends keyof Prescription ? Prescription[TKey]
  : TKey extends keyof Prescription['section'] ? Prescription['section'][TKey]
  : TKey extends keyof Prescription['rxOrthotic'] ? Prescription['rxOrthotic'][TKey]
  : TKey extends keyof Prescription['shellModification'] ? Prescription['shellModification'][TKey]
  : TKey extends keyof Prescription['posting'] ? Prescription['posting'][TKey]
  : never

export type SidedRxOrthotic = {
  diagnostic: string,
  specifiedDiagnostic: string,
  posting: {
    option: PostingOption,
    position: PostingPosition,
    correction: PostingCorrection,
  },
  rxOrthotic: {
    flexMidfootPerforated: FlexMidfootPerforated,
    stiffMidfootBar: StiffMidfootBar,
    lowerMedialArch: LowerMedialArch,
    heelLift: number,
  },
}

export type UpdateOmsPrescription = OmsPrescription & {
  id: number,
}

export type OmsPrescription = {
  description: string,
  name: string,
  consultationDate: string,
  prescriptionStatus: PrescriptionStatus,
  productType: ProductType,
  patientInformation: {
    gender: Gender,
    shoeSize: number,
    shoeSizeSystem: ShoeSizeSystem,
    childGrowth: boolean,
  },
  rxOrthoticBilateral: RxOrthoticBilateral
  ,
  leftFootRx: RxOrthotic,
  rightFootRx: RxOrthotic,
  side: RequestedSide,
  specialInstructions: string,
  activeSide: ActiveSide,
}

export type RxOrthoticBilateral = {
  balanceFeetToNeutral: boolean,
  topCoverThickness: number,
  shellRigidity: ShellRigidity,
  footwearWidthForefoot: ForefootWidth,
  heelCupHeight: number,
  topCoverLength: TopCoverLength,
  footbedWidth: number,
  footbedLength: number,
  topCoverMaterial: TopCoverMaterial,
  topCoverSeperatedParts: boolean,
}

export type RxOrthotic = {
  diagnostic: string,
  activeSide: ActiveSide,
  rxOrthotic: {
    heelLift: number,
    lowerMedialArch: LowerMedialArch,
    flexMidfootPerforated: FlexMidfootPerforated,
    stiffMidfootBar: StiffMidfootBar,
    kineticExtension: number[],
  },
  modification: {
    halluxExtension: boolean,
    gaitPlate: boolean,
    fasciaGroove: boolean,
    medialHeelSkive: boolean,
    medialFlange: boolean,
    lateralFlange: boolean,
    firstRayCutOut: boolean,
    heelHole: boolean,
    retroPad: boolean,
    retroPadOrientation: RetroPadOrientations,
    retroPadSize: RetroPadSizes,
    heelFork: boolean,
  },
  posting: {
    option: PostingOption,
    position: PostingPosition,
    correction: PostingCorrection,
  },
}

export type Posting = {
  option: PostingOption,
  position: PostingPosition,
  correction: PostingCorrection,
}

export type PartialRxOrthotics = {
  flexMidfootPerforated: FlexMidfootPerforated,
  flexMidfootOtherSide: FlexMidfootPerforated,
  stiffMidfootBar: StiffMidfootBar,
  stiffMidfootOtherSide: StiffMidfootBar,
  lowerMedialArch: LowerMedialArch,
  lowerMedialArchOtherSide: LowerMedialArch,
  heelLift: string,
  heelLiftOtherSide: string,
}

export const standardShoeSizeEu: string[] = [
  '36.5',
  '37',
  '37.5',
  '38',
  '38.5',
  '39',
  '39.5',
  '40',
  '40.5',
  '41',
  '41.5',
  '42',
  '42.5',
  '43',
  '43.5',
  '44',
  '44.5',
  '45',
  '45.5',
  '46',
  '46.5',
  '47',
]

export const standardShoeSizeUs: string[] = [
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '4.5',
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
]

export const heelCupHeights = {
  'Dress - 5 mm': 5,
  'Shallow - 10 mm': 10,
  'Standard - 15 mm': 15,
  'Deep - 20 mm': 20,
  'Extra Deep - 25 mm': 25,
  'UCBL - 30 mm': 30,
} as const

export const getHeelCupHeightValue = (
  key: string,
): typeof heelCupHeights[keyof typeof heelCupHeights] =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  heelCupHeights[key as never] ?? heelCupHeights['Standard - 15 mm']

export const getHeelCupHeightKey = (
  value: number,
): keyof typeof heelCupHeights =>
  Object
    .entries(heelCupHeights)
    .find(([_, objValue]) => objValue === value)
    ?.[0] as keyof typeof heelCupHeights | undefined
  ?? 'Standard - 15 mm'

export const standardHeelLift: string[] = [
  'None',
  '+2 mm',
  '+3 mm',
  '+4 mm',
  '+5 mm',
  '+6 mm',
  '+7 mm',
  '+8 mm',
  '+9 mm',
]

export const standardDiagnosticOptions: string[] = [
  'None',
  'Specify:',
  'Pes Plano-Valgus',
  'Pas Cavo-Varus',
  'Hyperpronation',
  'Hypersupination',
  'Plantar Fasciitis',
  'Fibular tendonitis',
  'Posterior tibial dysfunction',
  'Achilles insertion tendonitis',
  'Plantar base dysfunction',
  'Achilles tendinopathy',
  'Hallux Abducto-Valgus',
  'Hallux Limitus/Rigidus',
  'Hallux Rigidus',
  'Stress fracture',
  'Morton Neuroma',
  'Medial tibial stress syndrome',
  'Sesamoiditis',
  'Chronic Plantar Heel Pain',
  'Capsulitis',
  'Chronic lateral ankle instability',
]

export const handlePostingChange = (
  option: PostingOption,
  currentPosition: PostingPosition,
  currentCorrection: PostingCorrection): Posting => {

  let newPosition: PostingPosition = currentPosition
  let newCorrection: PostingCorrection = currentCorrection

  switch (option) {
  case postingOptions.none:
    newPosition = postingPositions.none
    newCorrection = postingCorrections.none
    break
  case postingOptions.neutral:
    if (currentPosition === postingPositions.none) {
      newPosition = postingPositions.rearfootAndForefoot
    }
    newCorrection = postingCorrections.none
    break
  case postingOptions.valgus:
    if (currentCorrection === postingCorrections.none) {
      newCorrection = postingCorrections.three
    }
    if (currentPosition === postingPositions.none) {
      newPosition = postingPositions.rearfootAndForefoot
    }
    break
  case postingOptions.varus:
    if (currentCorrection === postingCorrections.none) {
      newCorrection = postingCorrections.three
    }
    if (currentPosition === postingPositions.none) {
      newPosition = postingPositions.rearfootAndForefoot
    }
    break
  default:
    break
  }
  return {
    option: option,
    correction: newCorrection,
    position: newPosition,
  }
}
