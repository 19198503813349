import { Auth } from '@aws-amplify/auth'

import { applicationVersion } from '../constants/version'

const getHeaders = async (extraHeaders?: Record<string, unknown>): Promise<HeadersInit> => {
  const session = await Auth.currentSession()

  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Authorization': `Bearer ${session.getIdToken().getJwtToken()}`,
    'X-Portal-Version': applicationVersion,
    ...extraHeaders,
  }
}

const getMutationHeaders = async (extraHeaders?: Record<string, unknown>): Promise<HeadersInit> => {
  return await getHeaders({
    'Content-Type': 'application/json',
    ...extraHeaders,
  })
}

export {
  getHeaders,
  getMutationHeaders,
}
