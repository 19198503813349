
import { MakeLinkOptions, RegisteredRoutesInfo, useLinkProps } from '@tanstack/react-router'
import { Button, ButtonProps } from 'grommet'
import { ReactNode } from 'react'

type CustomLinkFn<
  TDefaultFrom extends RegisteredRoutesInfo['routePaths'] = '/',
  TDefaultTo extends string = '',
> = <
    TFrom extends RegisteredRoutesInfo['routePaths'] = TDefaultFrom,
    TTo extends string = TDefaultTo,
  >(
    props:
      & MakeLinkOptions<TFrom, TTo>
      & React.RefAttributes<HTMLAnchorElement>
      & Omit<ButtonProps, 'href'>,
  ) => ReactNode

const ButtonLinkV2: CustomLinkFn = (props) => {
  const {to, params, ...buttonProps} = props

  const linkProps = useLinkProps({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    to: to as any,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    params: params as any,
  })

  let hrefProps = {}
  if (props.disabled !== true) {
    hrefProps = {
      href: linkProps.href as never,
    }
  }

  return (
    <Button
      {...buttonProps as Omit<ButtonProps, 'href'>}
      {...hrefProps}
    />
  )
}

export default ButtonLinkV2
