
import { Box, Layer } from 'grommet'
import { WidthType } from 'grommet/utils'
import { CircleQuestion } from 'grommet-icons'
import { useState } from 'react'

type Props = {
  name: string,
  url?: string,
  icon?: JSX.Element,
  title?: string,
  content?: JSX.Element,
  width?: WidthType,
  id?: string,
}

const InformationPresenter = (props: Props) => {

  const [showInformation, setShowInformation] = useState<boolean>(false)

  return (
    <Box
      align="end"
      justify="end"
      fill
      id={props.id}
    >
      <Box
        onClick={() => setShowInformation(b => !b)}
      >
        {props.icon ?? <CircleQuestion />}
      </Box>
      {showInformation &&
        <Layer
          modal
          onClickOutside={() => setShowInformation(false)}
        >
          <Box
            width={props.width ?? '50vw'}
            height="90vh"
          >
            {
              props.url !== undefined
                ? (
                  <iframe
                    title={props.title ?? 'Basic-modal-information-presenter'}
                    src={props.url}
                    width="100%"
                    height="100%"
                  />
                )
                : props.content
            }

          </Box>
        </Layer>
      }
    </Box>
  )
}

export default InformationPresenter
