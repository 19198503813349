
// NOTE(pascal): Making an exception here for the Selects,
// since this has its own complex rendering logic that was
// also created before the Select substitutes.
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Box, Collapsible, FormField, Select, SelectMultiple } from 'grommet'
import { Filter } from 'grommet-icons'
import { ReactNode, useMemo, useState } from 'react'

import { useIsManufacturer } from '../hooks/roles'
import { BillingStatus, billingStatuses } from '../types/BillingStatus'
import { Order } from '../types/Order'
import { OrderStatus, orderStatusesForManufacturer, orderStatusesList } from '../types/OrderStatus'

import { BillingStatusLabel, OrderStatusLabel } from './StatusLabel'

type CommonProps = Readonly<{
  title?: ReactNode | undefined,
}>

type SingleProps<TStatus extends number> = CommonProps & Readonly<{
  value: TStatus,
  multiple?: undefined,
  onChange: (status: TStatus) => unknown,
}>

type MultipleProps<TStatus extends number> = CommonProps & Readonly<{
  values: TStatus[],
  multiple: true,
  showFilter?: boolean | undefined,
  onChange: (status: TStatus[]) => unknown,
}>

type ParentProps<TStatus extends number> =
  & (SingleProps<TStatus> | MultipleProps<TStatus>)
  & {
    onOpen?: (isOpen: boolean) => unknown,
  }

type OwnProps<TStatus extends number> = Readonly<{
  options: TStatus[],
  getLabel: (status: TStatus) => ReactNode,
}>

type Props<TStatus extends number> = ParentProps<TStatus> & OwnProps<TStatus>

function StatusDropdown<TStatus extends number>(props: Props<TStatus>) {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleOpen = () => {
    setIsOpen(open => !open)
    props.onOpen?.(!isOpen)
  }

  const handleChangeSingle = ({value}: {value: TStatus}) => {
    if (props.multiple === true) return
    props.onChange(value)
  }

  const handleChangeMultiple = ({value}: {value: TStatus[]}) => {
    if (props.multiple !== true) return
    props.onChange(value)
  }

  let header: ReactNode
  if (props.multiple && props.showFilter === true) {
    header =
      <Box
        width="100%"
        alignContent="stretch"
        direction="row"
      >
        <Box width="100%">{props.title}</Box>
        <Filter
          size="16px"
          onClick={handleToggleOpen}
        />
      </Box>
  } else {
    header = <>{props.title}</>
  }

  let selector
  if (props.multiple) {
    selector =
      <SelectMultiple
        options={props.options}
        value={props.values}
        labelKey={props.getLabel}
        sortSelectedOnClose={false}
        onChange={handleChangeMultiple}
      />
  } else {
    selector =
      <Select
        options={props.options}
        value={props.value}
        valueLabel={
          // NOTE(pascal): Bug in Grommet:
          // They do a check `if (value)` before calling our valueLabel
          // function, which means 0 is coerced to false by JS
          // and we don't render anything when the enum value is 0...
          // This is a temporary fix until they can fix it on their side.
          // Issue: https://github.com/grommet/grommet/issues/6829
          props.value === 0
            ? props.getLabel(0 as TStatus)
            : props.getLabel}
        labelKey={props.getLabel}
        onChange={handleChangeSingle}
      />
  }

  return (
    <Box
      pad="0.2rem"
    >
      {
        props.multiple && props.showFilter === true
          ? (
            <Box width="100%">
              {header}
              <Collapsible open={isOpen}>
                <FormField>
                  {selector}
                </FormField>
              </Collapsible>
            </Box>
          )
          : (
            <Box
              gap="0.4rem"
              direction="column"
            >
              {header}
              <FormField>
                {selector}
              </FormField>
            </Box>
          )
      }
    </Box>
  )
}

const OrderStatusDropdown = (
  props:
    & ParentProps<OrderStatus>
    & {
      counts?: Partial<Record<OrderStatus, number | undefined>>,
      order?: Order | null,
    },
) => {

  const isManufacturer = useIsManufacturer()

  const options = useMemo(
    () => {
      if (isManufacturer) return orderStatusesForManufacturer
      return orderStatusesList
    },
    [
      isManufacturer,
    ],
  )

  return (
    <StatusDropdown
      {...props}
      options={options}
      getLabel={status => {
        return (
          <OrderStatusLabel
            order={props.order ?? null}
            status={status}
            hideLabel
            count={props.counts ? (props.counts[status] ?? 0) : undefined}
          />
        )
      }}
    />
  )
}

const BillingStatusDropdown = (props: ParentProps<BillingStatus>) => {
  const options = useMemo(() => Object.values(billingStatuses), [])

  return (
    <StatusDropdown
      {...props}
      options={options}
      getLabel={status => <BillingStatusLabel status={status} />}
    />
  )
}

export {
  StatusDropdown as FilterDropdown,
  OrderStatusDropdown,
  BillingStatusDropdown,
}
