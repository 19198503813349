import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const stiffMidfootBars = {
  none: 0,
  stiffMedialArch: 1,
  stiffLateralArch: 2,
} as const

export type StiffMidfootBar = typeof stiffMidfootBars[keyof typeof stiffMidfootBars]

export const getStiffMidfootBarLabel = (i18n: I18n, value: StiffMidfootBar) => {
  switch (value) {
  case stiffMidfootBars.none: return t(i18n)`None`
  case stiffMidfootBars.stiffMedialArch: return t(i18n)`Stiff Medial Arch`
  case stiffMidfootBars.stiffLateralArch: return t(i18n)`Stiff Lateral Arch`
  default: return t(i18n)`Unknown stiff midfoot bar option`
  }
}
