import { BillingStatus } from './BillingStatus'
import { OrderStatus, nonCancelledOrderStatuses } from './OrderStatus'
import { TopCoverMaterial } from './PrescriptionEnums/TopCoverLength'

export type GetOrdersFilters = {
  pageSize: number,
  clinicId?: number | null,
  locationId?: number | null,
  clinicianId?: number | null,
  from?: Date,
  to?: Date,
  fromUpdated?: Date,
  toUpdated?: Date,
  includeStatuses: OrderStatus[] | null,
  includeBillingStatuses?: BillingStatus[] | null,
  patientFirstName?: string,
  patientLastName?: string,
  patientBirthdate?: string,
  orderNumber?: string,
  includeNoPrescription?: boolean,

  onlyHybrid?: boolean,
  onlyTopCover?: boolean,
  onlySingle?: boolean,

  // NOTE(pascal): From now on, I strongly recommend adding new
  // filters using the "exclude" mentality, instead of "include".
  // The biggest reason is that having an empty "exclude" array
  // is very clear about the fact that every single DB entry should be included.
  // With "include", on "no filters", we receive "null" in the backend,
  // which says nothing about if the user did not interact with filters and
  // all results should be included, or if the user unchecked everything,
  // meaning no result should be returned.
  // It also makes it to have default values "include everything" by default.
  excludeTopCoverMaterials: TopCoverMaterial[],
}

export const defaultPageSize = 20

export const defaultGetOrdersFilters: GetOrdersFilters = {
  pageSize: defaultPageSize,
  includeStatuses: nonCancelledOrderStatuses,
  excludeTopCoverMaterials: [],
}
