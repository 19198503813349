
import { Trans, t} from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Box, FormField, Layer, Stack } from 'grommet'
import { Close } from 'grommet-icons'
import { MutableRefObject, useEffect, useMemo, useRef } from 'react'

import { getOnlineViewerUri } from '../../constants/runtime'
import { downloadFile } from '../../fetchers/files'
import { downloadScan } from '../../fetchers/scans'
import { useJwtOnce } from '../../hooks/cognito'
import { useLoading } from '../../hooks/loading'
import { useQueryFiles } from '../../queries/files'
import { useQueryScans, useUpdateMultipleScanScanMethod } from '../../queries/scans'
import { File, leftFinder, rightFinder } from '../../types/File'
import { Order } from '../../types/Order'
import { orderStatuses } from '../../types/OrderStatus'
import { getScanMethodOptions } from '../../types/ScanMethod'
import Select from '../grommet-sub/Select'

type ViewerFile = {
  name: string,
  arrayBuffer: ArrayBuffer,
}

const sendFileToViewer = (
  foot: ViewerFile | null,
  viewer: HTMLIFrameElement | null,
) => {
  if (!viewer) return
  if (!foot) return

  const origin = getOnlineViewerUri()

  const message = foot.name.endsWith('.ply')
    ? { message: 'sendPlyFile', value: foot.arrayBuffer }
    : { message: 'sendFiles', value: [foot] }

  let listened = false
  const pongListener = (event: MessageEvent<{ message: string }>) => {
    if (event.origin !== origin) return

    if (event.data.message === 'pong') {
      listened = true
    }
  }

  const ping = () => {
    viewer.contentWindow?.postMessage({ message: 'ping' }, '*')
  }

  const wait = (resolve: (value: undefined) => unknown) => {
    if (listened) {
      resolve(undefined)
    } else {
      ping()
      setTimeout(() => wait(resolve), 30)
    }
  }

  window.addEventListener('message', pongListener)

  ping()
  new Promise(resolve => wait(resolve))
    .then(() => {
      window.removeEventListener('message', pongListener)

      viewer
        .contentWindow
        ?.postMessage(message, origin)
    })
    .catch(console.error)
}

const getRequestIf = (
  file: File | null | undefined,
  ref: MutableRefObject<ViewerFile | null>,
  type: string,
  downloader: (fileId: number) => Promise<Blob>,
): Promise<unknown> => {
  if (!file) return Promise.resolve(undefined)

  return downloader(file.id)
    .then(blob => blob.arrayBuffer())
    .then(buffer => {
      ref.current = {
        name: `${type}-${file.fileName}.${file.extension}`,
        arrayBuffer: buffer,
      }
    })
}

const emptySet = new Set<number>()

type Props = Readonly<{
  show: boolean,
  onClose: () => unknown,
  order: Order,
  files: 'scans' | 'files',
}>

const OnlineViewerModal = (props: Props) => {
  const jwt = useJwtOnce()

  const lingui = useLingui()
  const i18n = lingui.i18n

  const scanMethods = useMemo(
    () => getScanMethodOptions(i18n),
    [i18n],
  )
  const updateMultipleScanScanMethod = useUpdateMultipleScanScanMethod()
  const { setLoading } = useLoading()

  const leftViewerRef = useRef<HTMLIFrameElement | null>(null)
  const rightViewerRef = useRef<HTMLIFrameElement | null>(null)

  const fileIds = useRef<Set<number>>(emptySet)
  const leftRef = useRef<ViewerFile | null>(null)
  const rightRef = useRef<ViewerFile | null>(null)

  const scansQuery = useQueryScans(props.order.clinicId, props.order.id, !props.show || props.files !== 'scans')
  const filesQuery = useQueryFiles(props.order.clinicId, props.order.id, !props.show || props.files !== 'files')

  const files = (props.files === 'scans' ? scansQuery.data : filesQuery.data)

  const left = files ? files.find(leftFinder) : '...loading...'
  const right = files ? files.find(rightFinder) : '...loading...'

  const leftScan = scansQuery.data?.find(scans => scans.id === (left !== '...loading...' ? left?.id : undefined))
  const rightScan = scansQuery.data?.find(scans => scans.id === (right !== '...loading...' ? right?.id : undefined))

  useEffect(
    () => {
      if (!props.show) return
      if (!files) return
      if (left === '...loading...') return
      if (right === '...loading...') return

      // Prevent files auto-refresh to re-trigger downloads,
      // as downloads are more expensive.
      if (files.every(file => fileIds.current.has(file.id))) return
      fileIds.current = new Set(files.map(file => file.id))

      let controller: AbortController | null = new AbortController()

      const downloader = props.files === 'scans'
        ? (fileId: number) => downloadScan(props.order.clinicId, fileId, controller?.signal)
        : (fileId: number) => downloadFile(props.order.clinicId, fileId, controller?.signal)

      Promise
        .allSettled([
          getRequestIf(left, leftRef, props.files, downloader),
          getRequestIf(right, rightRef, props.files, downloader),
        ])
        .then(() => {
          sendFileToViewer(
            leftRef.current,
            leftViewerRef.current,
          )

          sendFileToViewer(
            rightRef.current,
            rightViewerRef.current,
          )
        })
        .finally(() => {
          controller = null
        })

      return () => {
        controller?.abort()
        controller = null
      }
    },
    [
      props.show,
      props.files,
      props.order.clinicId,
      files,
      left,
      right,
    ],
  )

  if (!props.show) return null

  const handleLeftViewerRef = (ref: HTMLIFrameElement | null) => {
    const current = leftViewerRef.current
    leftViewerRef.current = ref

    if (current) return
    if (!ref) return

    sendFileToViewer(leftRef.current, ref)
  }

  const handleRightViewerRef = (ref: HTMLIFrameElement | null) => {
    const current = rightViewerRef.current
    rightViewerRef.current = ref

    if (current) return
    if (!ref) return

    sendFileToViewer(rightRef.current, ref)
  }

  const handleScanMethodChange = (value: string) => {

    const newScanMethod = scanMethods.find(item => item.label.includes(value))?.value
    if (newScanMethod === undefined) return

    setLoading(true)

    updateMultipleScanScanMethod.mutate(
      {
        clinicId: props.order.clinicId,
        orderId: props.order.id,
        scanIds: Array.from(fileIds.current),
        scanMethod: newScanMethod,
      },
      {
        onSettled: () => {
          setLoading(false)
        },
      },
    )

  }

  const title = `online-viewer-modal.${props.files}.${props.order.id}`

  return (
    <Layer onClickOutside={props.onClose}>
      <Stack anchor="top-right">
        <Box
          width="80vw"
          height="80vh"
          direction="row"
        >
          {
            left !== undefined &&
            <iframe
              key={`${title}.left`}
              ref={handleLeftViewerRef}
              title={`${title}.left`}
              src={`${getOnlineViewerUri()}/public/index.html?token=${jwt}&viewerOnly=true`}
              width={right === undefined ? '100%' : '50%'}
              height="100%"
              style={{
                border: 'none',
                opacity: left === '...loading...' ? 0 : 1,
              }}
            />
          }
          {
            right !== undefined &&
            <iframe
              key={`${title}.right`}
              ref={handleRightViewerRef}
              title={`${title}.right`}
              src={`${getOnlineViewerUri()}/public/index.html?token=${jwt}&viewerOnly=true`}
              width={left === undefined ? '100%' : '50%'}
              height="100%"
              style={{
                border: 'none',
                opacity: right === '...loading...' ? 0 : 1,
              }}
            />
          }
          {
            right === undefined &&
            left === undefined &&
            <Box
              pad="large"
              margin="large"
            >
              <Trans>Could not find the requested files</Trans>
            </Box>
          }
        </Box>
        <Box
          direction="row"
          align="center"
          gap="large"
        >
          {
            props.files === 'scans' &&
            props.order.activeStatus < orderStatuses.processed &&
            <FormField>
              <Select
                onChange={handleScanMethodChange}
                options={scanMethods.map(item => item.label)}
                value={scanMethods
                  .find(method => method.value === (leftScan ?? rightScan)?.scanMethod)?.label ?? t(i18n)`unknown`}
              />
            </FormField>
          }
          {
            props.files === 'scans' &&
            props.order.activeStatus >= 4 &&
            <Trans>
              Saved scan method:
              {scanMethods.find(method => method.value ===
                (leftScan ?? rightScan)?.scanMethod)?.label ?? t(i18n)`unknown`}
            </Trans>
          }
          <Box
            pad="small"
            onClick={props.onClose}
          >
            <Close size="more-medium-than-large" />
          </Box>
        </Box>
      </Stack>
    </Layer>
  )
}

export default OnlineViewerModal
