import { I18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Route, useParams } from '@tanstack/react-router'
import { Box, DataTable, Heading, SortType } from 'grommet'
import { useMemo } from 'react'

import CollapsiblePanel from '../../components/CollapsiblePanel'
import { getOrder, ordersKeys } from '../../fetchers/orders'
import { useQueryOrder } from '../../queries/orders'
import { getQueryClient } from '../../queryClient'
import { Order } from '../../types/Order'
import { OrderError } from '../../types/OrderError'
import { toTableDateTime } from '../../utils/date'
import MainPanel from '../home/MainPanel'

const getColumns = (i18n: I18n) => [
  {
    property: 'source',
    header: <Trans>Source</Trans>,
  },
  {
    property: 'error',
    header: <Trans>Error</Trans>,
  },
  {
    property: 'timestamp',
    header: <Trans>Timestamp</Trans>,
    render: (error: OrderError) => {
      return toTableDateTime(i18n, error.timestamp)
    },
  },
]

const defaultSort: SortType = {
  direction: 'desc',
  property: 'timestamp',
}

type Props = {
  order: Order,
}

const ErrorsPanel = (props: Props) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const columns = useMemo(
    () => getColumns(i18n),
    [i18n],
  )

  return (
    <CollapsiblePanel>
      <Box
        width="33dvw"
      >
        <Heading level={2}>
          <Box
            direction="row"
            gap="0.3rem"
            alignContent="center"
            height={'48px'}
          >
            Errors - {props.order.patient.lastName},{' '}
            {props.order.patient.firstName}
          </Box>
        </Heading>
        <DataTable<OrderError>
          background={{ pinned: 'light-2' }}
          columns={columns}
          verticalAlign="top"
          align="left"
          primaryKey="id"
          data={props.order.errors}
          sort={defaultSort}
          sortable
          pin
        />
      </Box>
    </CollapsiblePanel>
  )
}

const ErrorsPanelLoader = () => {
  const params = useParams({from: '/orders/clinics/$clinicId/orders/$orderId/errors'})
  const order = useQueryOrder(params.clinicId, params.orderId)

  if (!order.data) return null

  return (
    <ErrorsPanel
      order={order.data}
    />
  )
}

ErrorsPanel.route = new Route({
  getParentRoute: () => MainPanel.route,
  path: 'clinics/$clinicId/orders/$orderId/errors',
  parseParams: params => ({
    clinicId: Number(params.clinicId),
    orderId: Number(params.orderId),
  }),
  onLoad: async ({ params, signal }) => {
    const queryClient = getQueryClient()

    await queryClient.prefetchQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ordersKeys.details(params.clinicId, params.orderId),
      queryFn: () => getOrder(params.clinicId, params.orderId, signal ?? null),
    })
  },
  component: ErrorsPanelLoader,
})

export default ErrorsPanel
