import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { TopCoverLength, topCoverLengths } from './TopCoverLength'

export const topCoverThicknesses = {
  'none': 0,
  '1mm': 1,
  '2mm': 2,
  '3mm': 3,
} as const

export type TopCoverThickness = typeof topCoverThicknesses[keyof typeof topCoverThicknesses]

export const getDefaultTopCoverThickness = (topCoverLength: TopCoverLength) =>
  topCoverLength === topCoverLengths.none
    ? topCoverThicknesses.none
    : topCoverThicknesses['2mm']

export const getTopCoverThicknessFromNumber = (number: number) => {
  switch (number) {
  case 1: return topCoverThicknesses['1mm']
  case 2: return topCoverThicknesses['2mm']
  case 3: return topCoverThicknesses['3mm']
  default: return topCoverThicknesses.none
  }
}

export const getTopCoverThicknessLabel = (i18n: I18n, value: TopCoverThickness) => {
  switch (value) {
  case topCoverThicknesses.none: return t(i18n)`None`
  case topCoverThicknesses['1mm']: return t(i18n)`1mm`
  case topCoverThicknesses['2mm']: return t(i18n)`2mm`
  case topCoverThicknesses['3mm']: return t(i18n)`3mm`
  default: return t(i18n)`Unknown top cover thickness option`
  }
}
