import { Auth } from '@aws-amplify/auth'
import { useEffect, useState } from 'react'

const useJwt = (): string => {
  const [ttl, setTtl] = useState<number | null>(null)
  const [jwt, setJwt] = useState<string>('')

  useEffect(
    () => {
      const getJwt = () => {
        Auth
          .currentSession()
          .then(session => {
            setJwt(session.getAccessToken().getJwtToken())

            setTtl(
              // Cognito's expiration time is in seconds, we need it in ms.
              (session.getAccessToken().getExpiration() * 1_000) - new Date().getTime(),
            )
          })
          .catch(console.error)
      }

      if (ttl === null) {
        // First load!
        getJwt()
        return
      }

      const id = setTimeout(
        () => getJwt(),
        ttl,
      )

      return () => {
        clearTimeout(id)
      }
    },
    [ttl],
  )

  return jwt
}

const useJwtOnce = (): string => {
  const [jwt, setJwt] = useState<string>('')

  useEffect(
    () => {
      Auth
        .currentSession()
        .then(session => {
          setJwt(session.getAccessToken().getJwtToken())
        })
        .catch(console.error)
    },
    [],
  )

  return jwt
}

export {
  useJwt,
  useJwtOnce,
}
