import { useEffect } from 'react'

import { applicationVersion } from '../constants/version'
import { FetchResponseEvent } from '../events/FetchResponseEvent'
import { notificationActions } from '../store/actions/notifications'
import { useDispatch, useSelector } from '../store/store'

const latestVersionHeaderKey = 'X-Portal-Latest-Version'

const useAppVersioningChecker = () => {
  const dispatch = useDispatch()

  const latestAppVersion = useSelector(state => state.notifications.latestAppVersion)

  useEffect(
    () => {
      const handler = (e: Event) => {
        const event = e as FetchResponseEvent

        const newLatestVersion = event.response.headers.get(latestVersionHeaderKey)
        if (newLatestVersion === null) return
        if (newLatestVersion === applicationVersion) return
        if (newLatestVersion === latestAppVersion) return
        dispatch(notificationActions.setLatestAppVersion(newLatestVersion))
      }

      window.addEventListener(FetchResponseEvent.eventType, handler)

      return () => {
        window.removeEventListener(FetchResponseEvent.eventType, handler)
      }
    },
    [
      dispatch,
      latestAppVersion,
    ],
  )
}

export {
  useAppVersioningChecker,
}
