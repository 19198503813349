import { I18n } from '@lingui/core'
import { Plural, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Route, useNavigate, useParams } from '@tanstack/react-router'
import { Box, Button, ColumnConfig, DataTable, FileInput, Heading, Spinner } from 'grommet'
import { useMemo, useState } from 'react'

import CollapsiblePanel from '../../components/CollapsiblePanel'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { downloadFile, filesKeys, getFiles, uploadFile } from '../../fetchers/files'
import { getOrder, ordersKeys } from '../../fetchers/orders'
import { useClinicFromId } from '../../hooks/clinic'
import { useLoading } from '../../hooks/loading'
import { useGetBestRole, useIsManufacturer } from '../../hooks/roles'
import { useCreateFile, useDeleteFile, useQueryFiles } from '../../queries/files'
import { useQueryOrder } from '../../queries/orders'
import { getQueryClient } from '../../queryClient'
import { File as PF3DFile } from '../../types/File'
import { Order } from '../../types/Order'
import { UserRoleManager, userRoles } from '../../types/UserRole'
import { getCognitoGroups } from '../../utils/cognito'
import { toTableDateTime } from '../../utils/date'
import { triggerDownload } from '../../utils/download'
import { getFilename, getFilenameAndExtension } from '../../utils/files'
import MainPanel from '../home/MainPanel'

type FileUploaderProps = Readonly<{
  selectedIds: number[],
  files: File[],
  onFilesAdded: (files: File[]) => unknown,
  onUploadFiles: () => unknown,
  onDeleteFiles: () => unknown,
}>

const FileUploader = (props: FileUploaderProps) => {
  return (
    <>
      <FileInput
        messages={{
          browse: 'Browse',
          files: 'Files',
          dropPrompt: 'Drag file to upload here',
          dropPromptMultiple: 'Drag file to upload here',
          remove: 'Remove',
          removeAll: 'Remove All',
        }}
        multiple={true}
        onChange={(_, target) => {
          if (!target) return
          props.onFilesAdded([...target.files])
        }}
      />
      {

        <Box
          flex
          pad=".2rem"
          gap=".2rem"
          fill
          height={{ max: '20rem', min: '2.6rem' }}
          direction="row"
        >
          <Button
            label="Upload"
            onClick={props.onUploadFiles}
            disabled={props.files.length === 0}
          />
          {
            props.selectedIds.length > 0 &&
            <Button
              onClick={props.onDeleteFiles}
              label="Delete Selection"
            />
          }
        </Box>
      }
    </>
  )
}

const getFilesPanelColumns = (
  i18n: I18n,
  clinicId: number | null,
  isManufacturer: boolean,
): Array<ColumnConfig<PF3DFile>> => [
  {
    property: 'createdAt',
    header: 'Date Created',
    render: (order: {createdAt: Date}) => {
      return toTableDateTime(i18n, order.createdAt)
    },
  },
  {
    property: 'download',
    header: 'Download',
    render: (file: PF3DFile) => (
      <Button
        onClick={async () => {
          if (isManufacturer) return
          if (clinicId === null) return

          const blob = await downloadFile(clinicId, file.id)
          triggerDownload(blob, file.fileName, file.extension)
        }}
        label={`${getFilename(file).substring(0, 31)}.${file.extension}`}
      />
    ),
  },
]

type Props = {
  order: Order | null,
  files: PF3DFile[],
}

const FilesPanel = (props: Props) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const params = useParams({from: '/orders/clinics/$clinicId/orders/$orderId/files'})
  const clinic = useClinicFromId(params.clinicId)

  const isManufacturer = useIsManufacturer()

  const navigate = useNavigate()

  const { isLoading, setLoading } = useLoading()

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [files, setFiles] = useState<File[]>([])

  const createFile = useCreateFile()
  const deleteFile = useDeleteFile()

  const handleUploadFiles = async () => {
    if (isManufacturer) return

    const order = props.order
    if (!order) return

    setLoading(true)

    await Promise.all(
      files.map(async (file) => {
        const {fileName, extension} = getFilenameAndExtension(file.name)

        const newFile = await createFile.mutateAsync({
          clinicId: params.clinicId,
          orderId: params.orderId,
          file: {
            fileName: fileName,
            extension: extension,
            filePath: `${clinic.name}/${order.location?.description ?? ''}`,
          },
        })

        await uploadFile(
          order.clinicId,
          newFile.id,
          file,
        )
      }),
    )

    setLoading(false)
    await navigate({to: '/'})
  }

  const handleDeleteFiles = () => {
    if (isManufacturer) return
    setConfirmDelete(true)
  }

  const columns = useMemo(
    () => getFilesPanelColumns(i18n, props.order?.clinicId ?? null, isManufacturer),
    [i18n, props.order?.clinicId, isManufacturer],
  )

  return (
    <>
      <CollapsiblePanel>
        <Box>
          <Heading level={2}>
            <Box
              direction="row"
              gap="0.3rem"
              alignContent="center"
              height={'48px'}
            >
              <Trans>Files</Trans>
              {
                props.order &&
                <>- {props.order.patient.lastName},{' '}{props.order.patient.firstName}</>
              }
              {isLoading && <Spinner size="medium" />}
            </Box>
          </Heading>
          <DataTable<PF3DFile>
            background={{ pinned: 'light-2' }}
            columns={columns}
            verticalAlign="top"
            align="left"
            primaryKey="id"
            data={props.files}
            sortable
            pin
            {
              ...isManufacturer
                ? undefined
                : {
                  select: selectedIds,
                  onSelect: ids => setSelectedIds(ids.map(Number)),
                }
            }
          />
          {
            !isManufacturer &&
            <FileUploader
              selectedIds={selectedIds}
              files={files}
              onFilesAdded={files => setFiles(files)}
              onUploadFiles={handleUploadFiles}
              onDeleteFiles={handleDeleteFiles}
            />
          }
        </Box>
      </CollapsiblePanel>
      <ConfirmModal
        show={confirmDelete}
        title={
          <Plural
            value={selectedIds.length}
            one="Delete file?"
            other="Delete files?"
          />
        }
        message={
          <Plural
            value={selectedIds.length}
            one="Are you sure you want to delete this file? This operation is IRREVERSIBLE."
            other="Are you sure you want to delete # files? This operation is IRREVERSIBLE."
          />
        }
        confirmLabel={
          <Plural
            value={selectedIds.length}
            one="Yes, delete this file forever"
            other="Yes, delete these files forever"
          />
        }
        onConfirm={() => {
          if (isManufacturer) return

          setLoading(true)

          Promise
            .all(
              selectedIds.map(fileId => {
                return deleteFile.mutateAsync({
                  clinicId: params.clinicId,
                  orderId: params.orderId,
                  fileId: fileId,
                })
              }),
            )
            .then(async () => {
              setSelectedIds([])
              await navigate({to: '/'})
            })
            .catch(error => console.error(error))
            .finally(() => {
              setLoading(false)
              setConfirmDelete(false)
            })
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  )
}

const FilesPanelLoader = () => {
  const params = useParams({from: '/orders/clinics/$clinicId/orders/$orderId/files'})

  const role = useGetBestRole()

  const disableOrderQuery = role === userRoles.unknown || role === userRoles.manufacturer

  const order = useQueryOrder(params.clinicId, params.orderId, disableOrderQuery)
  const files = useQueryFiles(params.clinicId, params.orderId)

  if (!disableOrderQuery && !order.data) return null
  if (!files.data) return null

  return (
    <FilesPanel
      order={order.data ?? null}
      files={files.data}
    />
  )
}

FilesPanel.route = new Route({
  getParentRoute: () => MainPanel.route,
  path: 'clinics/$clinicId/orders/$orderId/files',
  parseParams: params => ({
    clinicId: Number(params.clinicId),
    orderId: Number(params.orderId),
  }),
  onLoad: async ({ params, signal }) => {
    const queryClient = getQueryClient()

    const userRoles = await getCognitoGroups()
    const roleManager = new UserRoleManager(userRoles)

    if (!roleManager.isManufacturer) {
      await queryClient.prefetchQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ordersKeys.details(params.clinicId, params.orderId),
        queryFn: () => getOrder(params.clinicId, params.orderId, signal ?? null),
      })
    }

    await queryClient.prefetchQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: filesKeys.list(params.clinicId, params.orderId),
      queryFn: () => getFiles(params.clinicId, params.orderId, signal ?? null),
    })
  },
  component: FilesPanelLoader,
})

export default FilesPanel
