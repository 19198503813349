import { Box, Drop } from 'grommet'
import { ReactNode, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ThemeContext } from 'styled-components'

type Props = Readonly<{
  children: ReactNode,
  content: ReactNode,
  dropProps?: Record<string, unknown>,
  plain?: boolean,
  onMouseEnter?: (() => unknown) | undefined,
  onMouseLeave?: (() => unknown) | undefined,
}>

const NonDisabledTip = (props: Props) => {
  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const theme = useContext(ThemeContext)
  /* eslint-enable @typescript-eslint/no-unsafe-argument */
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */

  const containerRef = useRef<HTMLDivElement | null>(null)

  const [over, setOver] = useState(false)

  const onMouseRefs = useRef<{enter: Props['onMouseEnter']; leave: Props['onMouseLeave']}>({
    enter: props.onMouseEnter,
    leave: props.onMouseLeave,
  })

  useEffect(
    () => {
      onMouseRefs.current.enter = props.onMouseEnter
      onMouseRefs.current.leave = props.onMouseLeave
    },
    [
      props.onMouseEnter,
      props.onMouseLeave,
    ],
  )

  useLayoutEffect(
    () => {
      const handler = (event: PointerEvent) => {
        if (!containerRef.current) return
        const content = containerRef.current.getBoundingClientRect()

        const startX = content.x
        const endX = content.x + content.width
        const startY = content.y
        const endY = content.y + content.height

        setOver(over => {
          if (over) {
            // Try and capture the moment when we stop being over!
            if (
              event.pageX < startX ||
              event.pageX > endX ||
              event.pageY < startY ||
              event.pageY > endY
            ) {
              onMouseRefs.current.leave?.()
              return false
            }
          } else {
            // Try and capture the moment when we start being over!
            if (
              event.pageX >= startX &&
              event.pageX <= endX &&
              event.pageY >= startY &&
              event.pageY <= endY
            ) {
              onMouseRefs.current.enter?.()
              return true
            }
          }

          return over
        })
      }

      window.addEventListener('pointermove', handler)

      return () => {
        window.removeEventListener('pointermove', handler)
      }
    },
    [],
  )

  return (
    <>
      <div ref={containerRef}>
        {props.children}
      </div>
      {
        over &&
        <Drop
          target={containerRef}
          trapFocus={false}
          key="tip-drop"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          {...theme.tip.drop}
          {...props.dropProps}
        >
          {
            props.plain === true
              ? props.content
              : (
                <Box
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  {...theme.tip.content}
                >
                  {props.content}
                </Box>
              )
          }
        </Drop>
      }
    </>
  )
}

NonDisabledTip.displayName = 'NonDisabledTip'

export default NonDisabledTip
