import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { deleteFile, filesKeys, getFiles, postFile } from '../fetchers/files'
import { ordersKeys } from '../fetchers/orders'
import { NewFile } from '../types/File'

const useQueryFiles = (
  clinicId: number,
  orderId: number,
  disabled?: boolean | undefined,
) => {
  return useQuery({
    queryKey: filesKeys.list(clinicId, orderId),
    queryFn: ({signal}) => getFiles(clinicId, orderId, signal),
    enabled: disabled !== true,
  })
}

const useCreateFile = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; orderId: number; file: NewFile}) =>
      postFile(data.clinicId, data.orderId, data.file),
    onSuccess: (file, data) => {
      void queryClient.invalidateQueries({queryKey: filesKeys.list(data.clinicId, data.orderId)})
      void queryClient.invalidateQueries({queryKey: ordersKeys.all()})
      queryClient.setQueryData(filesKeys.details(data.clinicId, data.orderId, file.id), file)
    },
  })
}

const useDeleteFile = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {clinicId: number; orderId: number; fileId: number}) =>
      deleteFile(data.clinicId, data.orderId, data.fileId),
    onSuccess: (_, data) => {
      queryClient.removeQueries({queryKey: filesKeys.details(data.clinicId, data.orderId, data.fileId)})
      queryClient.removeQueries({queryKey: filesKeys.list(data.clinicId, data.orderId)})
      void queryClient.invalidateQueries({queryKey: ordersKeys.all()})
    },
  })
}

export {
  useQueryFiles,
  useCreateFile,
  useDeleteFile,
}
