
import { I18nProvider } from '@lingui/react'
import { ReactElement } from 'react'
import { renderToString } from 'react-dom/server'

import { printHtml } from '../external/print-js'
import { i18n } from '../i18n'

const printReact = (
  title: string,
  node: ReactElement,
  orientation?: 'portrait' | 'landscape',
) => {
  const content = renderToString(
    <I18nProvider i18n={i18n}>
      {node}
    </I18nProvider>,
  )

  let head = `
    <style type="text/css" media="print">
      * {
        font-size: 10px;
      }

      @page {
        size: US;
        margin: 0.5in 0.25in;
      }
    </style>
  `

  if (orientation === 'landscape') {
    head += `
      <style type="text/css" media="print">
        @page {
          size: landscape;
        }
      </style>
    `
  }

  printHtml(title, content, head)
}

export {
  printReact,
}
