// Imported from https://github.com/grommet/grommet/blob/master/src/js/components/Calendar/Calendar.js#L580 under Apache License 2.0
// As such, eslint and TypeScript rules are relaxed due to the base version not being written for our codebase.
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

// eslint-disable-next-line @typescript-eslint/no-restricted-imports

import { useLingui } from '@lingui/react'
import { Box, FormField, Header, Text, defaultProps } from 'grommet'
import { useMemo } from 'react'

import { getAllMonthsOptions, getYearOptions } from '../../types/DateTypes'

import Select from './Select'

const headingPadMap = {
  small: 'xsmall',
  medium: 'small',
  large: 'medium',
}

type Props = Readonly<{
  date: Date,
  locale?: string,
  onPreviousMonth: () => unknown,
  onNextMonth: () => unknown,
  previousInBound: boolean,
  nextInBound: boolean,
}>

/**
 * Returns a CalendarHeader component that allows users to skip through the years.
 * Grommet's Calendar header only allows to go through months at a time, which
 * is inconvenient for picking a date that represents a birth date, among others.
 * Indeed, picking the date of a patient born in 1960 from the year 2023 means
 * that the clinician would have to click about 756 times on the left arrow to
 * get to the year their patient was born. This is more than inconvenient: it's
 * actually useless, meaning that the only way to use the DatePicker would be
 * to set the date manually in text.
 *
 * As such, this opt-in replacement CalendarHeader implements three years-related
 * time-skipping features:
 * - Last year
 * - Next year
 * - Pick year
 *
 * Making the DatePicker's Calendar useful again.
 * This feature was requested in Grommet back in 2020. A first PR was opened
 * in 2021 with a proposed fix, but was eventually rejected due to inactivity
 * after the maintainers requested changes.
 * The last activity about this is 2021, where the maintainers expressed they
 * won't work on this until they determine a proper design.
 * Fast-forward to 2023, and there is still no activity about this, so I guess
 * we can't count on this feature to be delivered anytime soon.
 *
 * You'll find the issue here: https://github.com/grommet/grommet/issues/4095.
 *
 * You'll find the rejected PR here: https://github.com/grommet/grommet/pull/4604.
 * @param setOverrideDate Pass a date to the caller that should correspond to the user's selected year.
 * @returns A CalendarHeader component, meant to be used as the header render function of a calendar's props.
 */
const getCalendarHeader = (setOverrideDate: (date: Date) => unknown) => {
  return function CalendarHeader(props: Props) {

    const lingui = useLingui()
    const i18n = lingui.i18n

    const allMonthOptions = useMemo(() => getAllMonthsOptions(i18n), [i18n])
    const suggestedYears = useMemo(() => getYearOptions(), [])

    const theme = defaultProps.theme
    const size = 'medium' as 'small' | 'medium' | 'large'

    const handleSelectYear = (value: string) => {
      // You could be tempted to use `props.onPrevious/NextMonth`... don't.
      // You have to call them repeatedly *on every render* for them to work,
      // as if you call the function in a loop, it's hardwired to go back to
      // the previous/next month based off the currently rendered month.
      // As such, the only way to make these work (and I tried) is to add
      // a weird combo of useRef, useLayoutEffect and setTimeout, then to call
      // repeatedly the updater at every render, but taking pauses every 12 months
      // to avoid crashing React's update-on-render, and then it still
      // doesn't work for more than ~20 years jumps, after which it stops
      // and ends up on an unpredictable month/year combo, and it also fully
      // shows up changes on the calendar while doing all of this, so for many
      // tens of seconds, you see the calendar "scrolling" through the years
      // which is very weird.
      // Hence this version instead: let us be a function that can close on
      // a date updating function, then let the caller store that date and
      // use it correctly in the calendarProps so that it passes back to us
      // the desired date based on the user's selected year.
      const target = new Date(props.date)
      target.setFullYear(Number(value))
      setOverrideDate(target)
    }

    const handleSelectMonth = (value: string) => {
      const target = new Date(props.date)
      const newMonth = allMonthOptions.find(month => month.label.includes(value))?.value
      if (newMonth === undefined || Number.isNaN(newMonth)) return
      target.setMonth(newMonth)
      setOverrideDate(target)
    }

    const allYearOptions = suggestedYears.includes(String(props.date.getFullYear()))
      ? suggestedYears
      : [String(props.date.getFullYear()), ...suggestedYears]

    return (
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{ horizontal: 'none', vertical: '0.5rem' }}
      >
        <Header
          pad={{ horizontal: headingPadMap[size] }}
          align="center"
          alignContent="center"
        >
          {
            theme.calendar?.[size]?.title !== undefined
              ? (
                <Box
                  direction="row"
                  align="baseline"
                >
                  <Text {...theme.calendar[size]!.title}>
                    {allMonthOptions.find(month => month.value === props.date.getMonth())?.label}
                  </Text>
                  <Select
                    value={props.date.getFullYear().toString()}
                    options={allYearOptions}
                    onChange={handleSelectYear}
                  />
                </Box>
              )
              : (
                <Box
                  direction="row"
                  align="baseline"
                  gap="small"
                >
                  <FormField>
                    <Select
                      value={allMonthOptions.find(month => month.value === props.date.getMonth())?.label ?? 'error'}
                      options={allMonthOptions.map(month => month.label)}
                      onChange={handleSelectMonth}
                    />
                  </FormField>
                  <FormField>
                    <Select
                      value={props.date.getFullYear().toString()}
                      options={allYearOptions}
                      onChange={handleSelectYear}
                    />
                  </FormField>
                </Box>
              )
          }
        </Header>
      </Box>
    )
  }
}

export default getCalendarHeader
