import { getOmsApiUri } from '../constants/runtime'
import { AdminClinic, Clinic, NewClinic } from '../types/Clinic'

import { fetch } from './fetch'
import { getHeaders, getMutationHeaders } from './utils'

const clinicsKeys = {
  all: () => ['clinics'],
  list: (filters?: Record<string, unknown> | undefined) =>
    filters
      ? [...clinicsKeys.all(), 'list', filters]
      : [...clinicsKeys.all(), 'list'],
  details: (clinicId: number) => [...clinicsKeys.all(), 'details', clinicId],
}

const getClinics = async <TIncludeInactive extends boolean>(
  includeInactive: TIncludeInactive,
  signal: AbortSignal | null,
): Promise<TIncludeInactive extends true ? AdminClinic[] : Clinic[]> => {
  let uri = `${getOmsApiUri()}/clinics`

  if (includeInactive) {
    uri += `?includeInactive=true`
  }

  const response = await fetch(
    uri,
    {
      signal,
      headers: await getHeaders(),
    },
  )

  const dtos = await response.json() as (TIncludeInactive extends true ? AdminClinic[] : Clinic[])

  return dtos
}

const getClinicAsAdmin = async (clinicId: number, signal: AbortSignal | null): Promise<AdminClinic> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}`,
    {
      signal,
      headers: await getHeaders(),
    },
  )

  const dtos = await response.json() as AdminClinic

  return dtos
}

const postClinic = async (clinic: NewClinic): Promise<Clinic> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics`,
    {
      method: 'POST',
      headers: await getMutationHeaders(),
      body: JSON.stringify(clinic),
    },
  )

  if (response.status !== 201) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }

  const dto = await response.json() as Clinic

  return dto
}

const putClinic = async (clinic: AdminClinic): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinic.id}`,
    {
      method: 'PUT',
      headers: await getMutationHeaders(),
      body: JSON.stringify(clinic),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

const deleteClinic = async (clinicId: number): Promise<void> => {
  const response = await fetch(
    `${getOmsApiUri()}/clinics/${clinicId}`,
    {
      method: 'DELETE',
      headers: await getMutationHeaders(),
    },
  )

  if (response.status !== 204) {
    throw new Error(`Unexpected response status ${response.status}.`)
  }
}

export {
  clinicsKeys,
  getClinics,
  getClinicAsAdmin,
  postClinic,
  putClinic,
  deleteClinic,
}
