const reload = () => {
  window.location.reload()
}

const hardReload = () => {
  // Non-standard method, so untyped.
  // Use it where we can. (`true` will be silently ignored if not supported)
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/unbound-method
  (window.location.reload as any as (hard: boolean) => unknown)(true)
}

export {
  reload,
  hardReload,
}
