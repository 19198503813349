import { I18n } from '@lingui/core'

export const msInSecond = 1000
export const msInMinute = 60 * msInSecond
export const msInHour = 60 * msInMinute
export const msInDay = 24 * msInHour

const dayFriday = 5

const dateNowIso = () => {
  const now = new Date()
  return now.toISOString().substring(0, 10)
}

const toTableDate = (date: Date) => {
  let day = String(date.getDate())
  let month = String(date.getMonth() + 1) // Months are indexed.

  if (day.length === 1) day = `0${day}`
  if (month.length === 1) month = `0${month}`

  return `${date.getFullYear()}-${month}-${day}`
}

const toTableDateTime = (i18n: I18n, date: Date) => {
  let day = String(date.getDate())
  let month = String(date.getMonth() + 1) // Months are indexed.

  if (day.length === 1) day = `0${day}`
  if (month.length === 1) month = `0${month}`

  const time = date.toLocaleTimeString(
    i18n.locale,
    {
      timeStyle: 'medium',
    },
  )

  return `${date.getFullYear()}-${month}-${day} ${time}`
}

const fromUtcStringToLocalStartOfDay = (date: string) => {
  const utc = new Date(date)

  const local = new Date(
    utc.getUTCFullYear(),
    utc.getUTCMonth(),
    utc.getUTCDate(),
    0,
    0,
    0,
    0,
  )

  return local
}

const fromUtcStringToLocalEndOfDay = (date: string) => {
  const utc = new Date(date)

  const local = new Date(
    utc.getUTCFullYear(),
    utc.getUTCMonth(),
    utc.getUTCDate(),
    23,
    59,
    59,
    999,
  )

  return local
}

const getEstimatedDate = (date: Date, workdays: number): Date => {

  const copy = new Date(date.getTime())
  let daysUntilFriday = dayFriday - copy.getDay()
  if (daysUntilFriday <= 0) daysUntilFriday += 7

  copy.setDate(copy.getDate() + daysUntilFriday + workdays )

  return copy
}

const dateOnlyRegex = /(?<dateOnly>(?:\d{4})-(?:\d{2})-(?:\d{2}))/

const fromDateIsoToDateOnly = (date: string): string | null => {
  const match = dateOnlyRegex.exec(date)
  if (!match?.groups) return null
  return match.groups['dateOnly'] ?? null
}

export {
  dateNowIso,
  toTableDate,
  toTableDateTime,
  fromUtcStringToLocalStartOfDay,
  fromUtcStringToLocalEndOfDay,
  getEstimatedDate,
  fromDateIsoToDateOnly,
}
