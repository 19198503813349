import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { clinicsKeys, deleteClinic, getClinicAsAdmin, getClinics, postClinic, putClinic } from '../fetchers/clinics'
import { userContextActions } from '../store/actions/userContext'
import { useDispatch } from '../store/store'
import { AdminClinic, Clinic, NewClinic } from '../types/Clinic'

const useQueryClinics = <TIncludeInactive extends boolean = false>(
  filters?: {includeInactive?: TIncludeInactive},
): UseQueryResult<TIncludeInactive extends true ? AdminClinic[] : Clinic[]> => {
  return useQuery({
    // NOTE(pascal): Don't know why it's complaining, we're providing the whole object as a dependency!
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: clinicsKeys.list(filters),
    queryFn: ({signal}) => getClinics((filters?.includeInactive ?? false) as TIncludeInactive, signal),
  })
}

const useQueryClinicAdmin = (clinicId: number) => {
  return useQuery({
    queryKey: clinicsKeys.details(clinicId),
    queryFn: ({signal}) => getClinicAsAdmin(clinicId, signal),
    enabled: clinicId !== -1,
  })
}

const useCreateClinic = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (clinic: NewClinic) => postClinic(clinic),
    onSuccess: (clinic) => {
      void queryClient.invalidateQueries({queryKey: clinicsKeys.list()})
      queryClient.setQueryData(clinicsKeys.details(clinic.id), clinic)
      dispatch(userContextActions.createClinic(clinic))
    },
  })
}

const useUpdateClinic = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (clinic: AdminClinic) => putClinic(clinic),
    onSuccess: (_, oldClinic) => {
      void queryClient.invalidateQueries({queryKey: clinicsKeys.list()})
      void queryClient.invalidateQueries({queryKey: clinicsKeys.details(oldClinic.id)})

      queryClient.setQueryData(clinicsKeys.details(oldClinic.id), oldClinic)
      dispatch(userContextActions.updateClinic(oldClinic))
    },
  })
}

const useDeleteClinic = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (clinicId: number) => deleteClinic(clinicId),
    onSuccess: (_, clinicId) => {
      void queryClient.invalidateQueries({queryKey: clinicsKeys.list()})
      void queryClient.invalidateQueries({queryKey: clinicsKeys.details(clinicId)})

      dispatch(userContextActions.deleteClinic(clinicId))
    },
  })
}

export {
  useQueryClinics,
  useQueryClinicAdmin,
  useCreateClinic,
  useUpdateClinic,
  useDeleteClinic,
}
