import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const kineticExtensions = {
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
} as const

export type KineticExtensions = typeof kineticExtensions[keyof typeof kineticExtensions]

export const getKineticExtensionsLabel = (i18n: I18n, value: KineticExtensions) => {
  switch (value) {
  case kineticExtensions['1']: return t(i18n)`1`
  case kineticExtensions['2']: return t(i18n)`2`
  case kineticExtensions['3']: return t(i18n)`3`
  case kineticExtensions['4']: return t(i18n)`4`
  case kineticExtensions['5']: return t(i18n)`5`
  default: return t(i18n)`Unknown kinetic extention mets option`
  }
}

export const getKineticExtensions = (i18n: I18n, numbers: KineticExtensions[]) => {
  return numbers.map(number => {
    switch (number) {
    case 1: return getKineticExtensionsLabel(i18n, kineticExtensions[1])
    case 2: return getKineticExtensionsLabel(i18n, kineticExtensions[2])
    case 3: return getKineticExtensionsLabel(i18n, kineticExtensions[3])
    case 4: return getKineticExtensionsLabel(i18n, kineticExtensions[4])
    case 5: return getKineticExtensionsLabel(i18n, kineticExtensions[5])
    default: return 1
    }
  })
}

export const getKineticExtensionFromNumbers = (numbers: number[]): KineticExtensions[] => {
  return numbers.map(number => {
    switch (number) {
    case 1: return kineticExtensions[1]
    case 2: return kineticExtensions[2]
    case 3: return kineticExtensions[3]
    case 4: return kineticExtensions[4]
    case 5: return kineticExtensions[5]
    default: return 1
    }
  })
}
