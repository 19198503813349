
import { useNavigate } from '@tanstack/react-router'
import { Box, Layer } from 'grommet'
import { Next } from 'grommet-icons'
import { ReactNode, useState } from 'react'

type Props = {
  children: ReactNode,
  hideArrow?: boolean,
  onClose?: () => unknown,
}

const CollapsiblePanel = (props: Props) => {
  const [isOpen, setOpen] = useState(true)

  const navigate = useNavigate()

  const onClose = async () => {
    setOpen(false)
    props.onClose?.()
    await navigate({ to: '/' })
  }

  if (!isOpen) return null

  return (
    <Layer
      position="right"
      full="vertical"
      modal={false}
      background="light-1"
    >
      <Box
        justify="center"
        align="center"
        alignContent="center"
        alignSelf="center"
        gap="0.3rem"
        // Known issue currently with the layout, some buttons can be hard to click.
        // Issue is documented here: https://github.com/Podform3D/OMS_portal/issues/410
        pad={{ left: 'medium', top: 'medium', bottom: 'medium', right: '3rem' }}
        direction="row"
        elevation="small"
        fill
      >
        {
          props.hideArrow !== true &&
          <Next
            size="large"
            onClick={onClose}
          />
        }
        <Box
          direction="column"
          overflow="auto"
          fill
        >
          {props.children}
        </Box>
      </Box>
    </Layer>
  )
}

export default CollapsiblePanel
