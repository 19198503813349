
import { Nav, Spinner } from 'grommet'
import { Menu } from 'grommet-icons'

import SignOut from '../../../components/SignOut'
import StatusButtonFilter from '../../../components/StatusButtonFilter'
import { useLoading } from '../../../hooks/loading'
import { useSelector } from '../../../store/store'
import { orderStatusesForManufacturer } from '../../../types/OrderStatus'
import SearchBar from '../SearchBar'

import HeaderLogo from './logo'

type Props = {
  onHandleSettingsPressed?: () => unknown,
}

const ManufacturerHeader = (props: Props) => {

  const { isLoading } = useLoading()
  const ordersCountsByOrderStatuses = useSelector(state => state.userContext.ordersCountsByOrderStatuses)

  return (
    <>
      <Nav
        direction="row"
        align="center"
        gap="1rem"
      >
        <Menu
          onClick={props.onHandleSettingsPressed}
        />
        <HeaderLogo />
        {isLoading && <Spinner size="medium" />}
      </Nav>

      <Nav
        direction="row"
        align="center"
      >
        <StatusButtonFilter
          toggle={true}
          statuses={orderStatusesForManufacturer}
          counts={ordersCountsByOrderStatuses}
        />
        <SearchBar />
        <SignOut />
      </Nav>
    </>

  )
}

export default ManufacturerHeader
