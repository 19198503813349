
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Box, Button, Collapsible, DateInput, Drop, Form, FormField, Heading, TextInput } from 'grommet'
import { Close, Search } from 'grommet-icons'
import { SyntheticEvent, useRef, useState } from 'react'

import { OrderStatusDropdown } from '../../components/StatusDropdown'
import PastSingleDateInput from '../../components/grommet-sub/PastSingleDateInput'
import { useGetBestRole } from '../../hooks/roles'
import { userContextActions } from '../../store/actions/userContext'
import { useDispatch, useSelector } from '../../store/store'
import { GetOrdersFilters, defaultGetOrdersFilters } from '../../types/OrderFilters'
import { orderStatuses } from '../../types/OrderStatus'
import { fromUtcStringToLocalEndOfDay, fromUtcStringToLocalStartOfDay } from '../../utils/date'
import { getVisibleOrderStatuses } from '../../utils/orderStatus'

type KeysMatching<TObj, TValue> = { [TKey in keyof TObj]-?: TObj[TKey] extends TValue ? TKey : never }[keyof TObj]

const SearchBarImpl = (props: { onReset: () => unknown }) => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const dispatch = useDispatch()

  const userRole = useGetBestRole()

  const currentFilters = useSelector(state => state.userContext.orderFilters)
  const countsByStatuses = useSelector(state => state.userContext.ordersCountsByOrderStatuses)

  const [newFilters, setFilters] = useState<GetOrdersFilters>({
    ...currentFilters,
    includeStatuses: [
      ...(currentFilters.includeStatuses ?? []),
      orderStatuses.cancelled,
    ],
  })

  const filters = currentFilters === newFilters ? currentFilters : newFilters

  function doSetFilters<TKey extends keyof GetOrdersFilters>(
    key: TKey,
    value: GetOrdersFilters[TKey],
  ) {
    setFilters(filters => ({
      ...filters,
      [key]: value,
    }))
  }

  function handleFiltersValue<TKey extends keyof GetOrdersFilters>(key: TKey) {
    return (value: GetOrdersFilters[TKey]) => doSetFilters(key, value)
  }

  function handleFiltersEvent<TKey extends KeysMatching<GetOrdersFilters, string | undefined>>(key: TKey) {
    return (event: SyntheticEvent<HTMLInputElement>) => doSetFilters(key, event.currentTarget.value)
  }

  const setCreatedDate = (date: string) => {
    const parts: string[] = date.split(',').flat()
    if (parts.length !== 2) return

    const [from, to] = parts as [string, string]

    setFilters(values => ({
      ...values,
      from: fromUtcStringToLocalStartOfDay(from),
      to: fromUtcStringToLocalEndOfDay(to),
    }))
  }

  const search = () => {
    dispatch(userContextActions.setOrderFilters(filters))
  }

  const reset = () => {
    setFilters(defaultGetOrdersFilters)
    dispatch(userContextActions.setOrderFilters(defaultGetOrdersFilters))
    props.onReset()
  }

  const filteredStatuses = getVisibleOrderStatuses(userRole, filters.includeStatuses)

  return (
    <>
      <Heading
        level={4}
        margin="none"
        alignSelf="center"
      >
        <Trans>Search</Trans>
      </Heading>
      <Form
        value={filters}
        onReset={reset}
        onSubmit={search}
      >
        <Box gap="0.3rem">
          <FormField
            name="patientFirstName"
            id="text-input-firstName"
            htmlFor="text-input-firstName"
          >
            <TextInput
              id="text-input-firstName"
              placeholder="Patient first name"
              name="patientFirstName"
              width="20vw"
              value={filters.patientFirstName ?? ''}
              onChange={handleFiltersEvent('patientFirstName')}
            />
          </FormField>
          <FormField
            name="patientLastName"
            id="text-input-lastName"
            htmlFor="text-input-lastName"
          >
            <TextInput
              id="text-input-lastName"
              placeholder="Patient last name"
              name="patientLastName"
              width="20vw"
              value={filters.patientLastName ?? ''}
              onChange={handleFiltersEvent('patientLastName')}
            />
          </FormField>
          <PastSingleDateInput
            wrapFormField={true}
            formFieldProps={{
              name: 'patientBirthDate',
              id: 'text-input-birthdate',
              htmlFor: 'text-input-birthdate',
            }}
            label={'Patient birthdate'}
            date={filters.patientBirthdate}
            onChange={(date) => handleFiltersValue('patientBirthdate')(date ?? undefined)}
          />
          <FormField
            name="order-number"
            htmlFor="search-bar-order-number"
          >
            <TextInput
              id="search-bar-order-number"
              name="order-number"
              width="20vw"
              placeholder={t(i18n)`Order #`}
              value={filters.orderNumber ?? ''}
              onChange={handleFiltersEvent('orderNumber')}
            />
          </FormField>
          <OrderStatusDropdown
            title={<Trans>Order Status</Trans>}
            multiple={true}
            showFilter={false}
            values={filteredStatuses}
            counts={countsByStatuses}
            onChange={handleFiltersValue('includeStatuses')}
          />
          <Box
            direction="column"
            justify="evenly"
            margin={{ bottom: '.9rem' }}
          >
            <Heading
              level={4}
              margin={'none'}
            >
              <Trans>Date created</Trans>
            </Heading>
            <DateInput
              format="yyyy/mm/dd - yyyy/mm/dd"
              value={
                (filters.from || filters.to)
                  ? [filters.from?.toISOString() ?? '', filters.to?.toISOString() ?? '']
                  : ''
              }
              onChange={(event) => {
                setCreatedDate(event.value.toString())
              }}
              dropProps={{ align: { top: 'bottom', right: 'right' } }}
            />
          </Box>
          <Box
            direction="row"
            gap="1rem"
            justify="end"
          >
            <Button
              primary
              type="submit"
              label="Submit"
            />
            <Button
              type="reset"
              label="Reset"
            />
          </Box>
        </Box>
      </Form>
    </>
  )
}

const SearchBar = () => {
  const iconRef = useRef<HTMLDivElement | null>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <Box
        ref={iconRef}
        onClick={() => setIsOpen(isOpen => !isOpen)}
      >
        <Collapsible open={isOpen}>
          <Close size="medium" />
        </Collapsible>
        <Collapsible open={!isOpen}>
          <Search size="medium" />
        </Collapsible>
      </Box>

      {
        isOpen &&
        <Drop
          target={iconRef.current ?? {}}
          round
          pad=".8rem"
          margin={{ top: '-.5rem', left: '-.5rem' }}
          align={{ top: 'top', right: 'left' }}
          elevation="medium"
        >
          <SearchBarImpl onReset={() => setIsOpen(false)} />
        </Drop>
      }
    </>
  )
}

export default SearchBar
