import { GetOrderStatusFilters } from '../../fetchers/orders'
import { Clinic } from '../../types/Clinic'
import { Clinician } from '../../types/Clinician'
import { Location } from '../../types/Location'
import { GetOrdersFilters } from '../../types/OrderFilters'
import { OrderStatus } from '../../types/OrderStatus'
import { OrdersView } from '../../types/OrdersView'
import { UserRole } from '../../types/UserRole'

const userContextActionTypes = {
  clearContext: 'clearContext',
  setUserRole: 'setUserRole',

  setAvailableClinics: 'setAvailableClinics',
  setClinic: 'setClinic',
  setAvailableLocations: 'setAvailableLocations',
  setLocation: 'setLocation',
  setAvailableClinicians: 'setAvailableClinicians',
  setClinician: 'setClinician',
  setOrderFilters: 'setOrderFilters',
  setOrderStatusFilters: 'setOrderStatusFilters',
  setActiveLogo: 'setActiveLogo',
  setPageSize: 'setPageSize',

  createClinic: 'createClinic',
  updateClinic: 'updateClinic',
  deleteClinic: 'deleteClinic',

  setOrdersCountsByStatuses: 'setOrdersCountsByStatuses',
  setOrdersView: 'setOrdersView',
} as const

type ClearContext = {
  type: typeof userContextActionTypes.clearContext,
}

type SetUserRole = {
  type: typeof userContextActionTypes.setUserRole,
  role: UserRole | null,
}

type SetAvailableClinics = {
  type: typeof userContextActionTypes.setAvailableClinics,
  clinics: Clinic[],
}

type SetClinic = {
  type: typeof userContextActionTypes.setClinic,
  clinic: Clinic,
}

type SetAvailableLocations = {
  type: typeof userContextActionTypes.setAvailableLocations,
  locations: Location[],
}

type SetLocation = {
  type: typeof userContextActionTypes.setLocation,
  location: Location,
}

type SetAvailableClinicians = {
  type: typeof userContextActionTypes.setAvailableClinicians,
  clinicians: Clinician[],
}

type SetClinician = {
  type: typeof userContextActionTypes.setClinician,
  clinician: Clinician,
}

type SetOrderFilters = {
  type: typeof userContextActionTypes.setOrderFilters,
  orderFilters: GetOrdersFilters,
}

type SetOrderStatusFilters = {
  type: typeof userContextActionTypes.setOrderStatusFilters,
  orderStatusFilters: GetOrderStatusFilters,
}

type WithItem<T, TKey> = {
  type: TKey,
  item: T,
}

type WithId<TKey> = {
  type: TKey,
  id: number,
}

type CreateClinic = WithItem<Clinic, typeof userContextActionTypes.createClinic>
type UpdateClinic = WithItem<Clinic, typeof userContextActionTypes.updateClinic>
type DeleteClinic = WithId<typeof userContextActionTypes.deleteClinic>

type SetOrdersCountsByStatuses = {
  type: typeof userContextActionTypes.setOrdersCountsByStatuses,
  counts: Record<OrderStatus, number>,
}

type SetPageSize = {
  type: typeof userContextActionTypes.setPageSize,
  size: number,
}

type SetOrdersView = {
  type: typeof userContextActionTypes.setOrdersView,
  view: OrdersView,
}

export type UserContextAction =
  | ClearContext
  | SetUserRole
  | SetAvailableClinics
  | SetClinic
  | SetAvailableLocations
  | SetLocation
  | SetAvailableClinicians
  | SetClinician
  | SetOrderFilters
  | SetOrderStatusFilters
  | CreateClinic
  | UpdateClinic
  | DeleteClinic
  | SetOrdersCountsByStatuses
  | SetPageSize
  | SetOrdersView

const clearContext = (): ClearContext => ({
  type: userContextActionTypes.clearContext,
})

const setUserRole = (role: UserRole | null): SetUserRole => ({
  type: userContextActionTypes.setUserRole,
  role,
})

const setAvailableClinics = (clinics: Clinic[]): SetAvailableClinics => ({
  type: userContextActionTypes.setAvailableClinics,
  clinics,
})

const setClinic = (clinic: Clinic): SetClinic => ({
  type: userContextActionTypes.setClinic,
  clinic,
})

const setAvailableLocations = (locations: Location[]): SetAvailableLocations => ({
  type: userContextActionTypes.setAvailableLocations,
  locations,
})

const setLocation = (location: Location): SetLocation => ({
  type: userContextActionTypes.setLocation,
  location,
})

const setAvailableClinicians = (clinicians: Clinician[]): SetAvailableClinicians => ({
  type: userContextActionTypes.setAvailableClinicians,
  clinicians,
})

const setClinician = (clinician: Clinician): SetClinician => ({
  type: userContextActionTypes.setClinician,
  clinician,
})

const setOrderFilters = (orderFilters: GetOrdersFilters): SetOrderFilters => ({
  type: userContextActionTypes.setOrderFilters,
  orderFilters,
})

const setOrderStatusFilters = (orderStatusFilters: GetOrderStatusFilters): SetOrderStatusFilters => ({
  type: userContextActionTypes.setOrderStatusFilters,
  orderStatusFilters,
})

function makeWithItem<
  TAction extends WithItem<TAction['item'], TAction['type']>,
>(
  type: TAction['type'],
): (item: TAction['item']) => TAction {
  return (item: TAction['item']): TAction => ({
    type,
    item,
  } as TAction)
}

function makeWithId<TAction extends WithId<TAction['type']>>(type: TAction['type']): (id: number) => TAction {
  return (id: number): TAction => ({
    type,
    id,
  } as TAction)
}

const createClinic = makeWithItem<CreateClinic>(userContextActionTypes.createClinic)
const updateClinic = makeWithItem<UpdateClinic>(userContextActionTypes.updateClinic)
const deleteClinic = makeWithId<DeleteClinic>(userContextActionTypes.deleteClinic)

const setOrdersCountsByStatuses = (counts: Record<OrderStatus, number>): SetOrdersCountsByStatuses => ({
  type: userContextActionTypes.setOrdersCountsByStatuses,
  counts,
})

const setPageSize = (size: number): SetPageSize => ({
  type: userContextActionTypes.setPageSize,
  size,
})

const setOrdersView = (view: OrdersView): SetOrdersView => ({
  type: userContextActionTypes.setOrdersView,
  view,
})

const userContextActions = {
  clearContext,
  setUserRole,

  setAvailableClinics,
  setClinic,
  setAvailableLocations,
  setLocation,
  setAvailableClinicians,
  setClinician,

  setOrderFilters,
  setOrderStatusFilters,
  setPageSize,

  createClinic,
  updateClinic,
  deleteClinic,

  setOrdersCountsByStatuses,
  setOrdersView,
} as const

export {
  userContextActionTypes,
  userContextActions,
}
