import { ColumnConfig } from 'grommet'

import { OrderForManufacturer } from '../../../types/Order'

import { getFilesColumn, getManufacturerDeliveryColumn, getOrderNumberColumn, getPatientFirstNameColumn, getPatientLastNameColumn, getProductInfoColumn, getSelectColumn, getStatusColumn } from './Columns'
import { Data } from './Data'

function getManufacturerColumns(data: Data<true, OrderForManufacturer>): Array<ColumnConfig<OrderForManufacturer>> {
  return [
    getSelectColumn(data),
    getStatusColumn(data),
    getProductInfoColumn(data),
    getOrderNumberColumn(),
    getPatientFirstNameColumn(),
    getPatientLastNameColumn(),
    getFilesColumn(),
    getManufacturerDeliveryColumn(),
  ]
}

export {
  getManufacturerColumns,
}
