import { Box, Heading, Paragraph } from 'grommet'
import { ReactNode } from 'react'

type Props = {
  header: ReactNode,
  value: ReactNode,
  inList?: boolean,
}

const PrintableLabel = (props: Props) => {
  const pad = props.inList === true ? ({ bottom: '0.1rem' }) : ({ bottom: '0.6rem' })

  return (
    <Box
      margin="none"
      pad={pad}
      width={'100%'}
      fill
    >
      <Heading
        margin="none"
        level="5"
        truncate
        fill
      >
        {props.header}
      </Heading>
      <Paragraph
        margin={{ top: 'none', bottom: 'none', left: 'small' }}
        fill
      >
        {props.value}
      </Paragraph>
    </Box>
  )
}

export default PrintableLabel
