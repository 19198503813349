import { Messages, i18n } from '@lingui/core'

import { messages as enCaMessages } from './locales/en-CA/messages'

const getLocaleLazyImport = (locale: string): (() => Promise<{messages: Messages}>) | null => {
  switch (locale) {
  case 'en-CA': return () => import('./locales/en-CA/messages')
  case 'fr-CA': return () => import('./locales/fr-CA/messages')
  default: return null
  }
}

const loadedLocales = new Map<string, Messages>()

const initI18n = () => {
  i18n.load({
    'en-CA': enCaMessages as Messages,
  })

  loadedLocales.set('en-CA', enCaMessages as Messages)

  i18n.activate('en-CA')
}

const setLocale = (locale: 'en-CA' | 'fr-CA') => {
  if (loadedLocales.has(locale)) {
    i18n.activate(locale)
    return
  }

  const importer = getLocaleLazyImport(locale)
  if (!importer) return

  importer()
    .then(({messages}: {messages: Messages}) => {
      i18n.load(locale, messages)
      loadedLocales.set(locale, messages)
      i18n.activate(locale)
    })
    .catch(console.error)
}

export {
  i18n,
  initI18n,
  setLocale,
}
