import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const flexMidfootPerforatedValues = {
  none: 0,
  flexMedialArch: 1,
  flexLateralArch: 2,
  flexMedialAndLateralArch: 3,
} as const

export type FlexMidfootPerforated = typeof flexMidfootPerforatedValues[keyof typeof flexMidfootPerforatedValues]

export const getFlexMidfootPerforatedLabel = (i18n: I18n, value: FlexMidfootPerforated) => {
  switch (value) {
  case flexMidfootPerforatedValues.none: return t(i18n)`None`
  case flexMidfootPerforatedValues.flexMedialArch: return t(i18n)`Flex Medial Arch`
  case flexMidfootPerforatedValues.flexLateralArch: return t(i18n)`Flex Lateral Arch`
  case flexMidfootPerforatedValues.flexMedialAndLateralArch: return t(i18n)`Flex Medial & lateral Arch`
  default: return t(i18n)`Unknown flex midfoot perforated option`
  }
}
