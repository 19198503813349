/* eslint-disable max-len */
import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { StyledIcon } from 'grommet-icons/StyledIcon'
import { forwardRef } from 'react'

const getIcon = (getA11yTitle: (i18n: I18n) => string, pathD: string) => forwardRef((props, ref) => {
  const {i18n} = useLingui()

  return (
    <StyledIcon
      ref={ref}
      viewBox="0 -870 870 870"
      a11yTitle={getA11yTitle(i18n)}
      {...props}
    >
      <path
        stroke="none"
        strokeWidth="2"
        fill="#000"
        d={pathD}
      />
    </StyledIcon>
  )
})

export const Foot = getIcon(
  i18n => t(i18n)`Foot`,
  'M219.825-650Q207-650 198.5-658.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM400-80q-66 0-113-47t-47-113v-240q0-100 70-170t170-70h73q69 0 118 46.5T720-559q0 46-24.5 84.5T629-416q-32 15-50.5 44.5T560-307v67q0 67-46.5 113.5T400-80Zm-80.175-640Q307-720 298.5-728.625T290-750v-40q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T350-790v40q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM400-140q42 0 71-29t29-71v-67q0-52.232 27.557-96.359Q555.114-447.486 603-470q26-13 41.5-37.218Q660-531.435 660-560q0-42-31.5-71T553-660h-73q-75 0-127.5 52.5T300-480v240q0 42 29 71t71 29Zm39.825-620Q427-760 418.5-768.625T410-790v-40q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T470-830v40q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm120 0Q547-760 538.5-768.625T530-790v-60q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T590-850v60q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm140.057 40Q679-720 664.5-734.583 650-749.167 650-770v-60q0-20.833 14.618-35.417Q679.235-880 700.118-880 721-880 735.5-865.417 750-850.833 750-830v60q0 20.833-14.618 35.417Q720.765-720 699.882-720Z',
)

Foot.displayName = 'Foot'

export const Orthotic = getIcon(
  i18n => t(i18n)`Orthotic`,
  'M216-570q36 0 70 13.5t61 38.5l395 378h18q25.5 0 42.75-17.25T820-200q0-11.2-2.125-23.8Q815.75-236.4 803-249L623-429l-76-227-92 23q-29 7-52-10.5T380-691v-97l-54-27-175 235q-2 2-3.5 4.5T144-570h72Zm0 60h-78q3 11 9 20.176 6 9.177 14 16.824l338 307q14 13 31 19.5t36 6.5h89L306-474q-18-17-41.455-26.5Q241.091-510 216-510ZM566-80q-30 0-57-11t-50-31L134-417q-46-42-51.5-103T114-631l154-206q17-23 45.5-30.5T368-861l28 14q21 11 32.5 30t11.5 42v84l74-19q30-8 58 7.5t38 44.5l65 196 170 170q20 20 27.5 43t7.5 49q0 50-35 85t-85 35H566Z',
)

Orthotic.displayName = 'Orthotic'
