import { Auth } from '@aws-amplify/auth'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Tip } from 'grommet'
import { Logout } from 'grommet-icons'

import { userContextActions } from '../store/actions/userContext'
import { useDispatch } from '../store/store'

const SignOut = () => {
  const lingui = useLingui()
  const i18n = lingui.i18n

  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return (
    <Tip content={t(i18n)`Sign out`}>
      <Button
        a11yTitle={t(i18n)`Sign out`}
        size="large"
        // label="SIGN OUT"
        icon={<Logout />}
        onClick={async () => {
          await Auth.signOut()

          dispatch(userContextActions.clearContext())

          queryClient
            .invalidateQueries()
            .then(() => queryClient.clear())
            .catch(error => console.error(error))
        }}
      />
    </Tip>
  )
}

export default SignOut
