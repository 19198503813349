import { Trans } from '@lingui/macro'
import { Box, Button } from 'grommet'

import logo from '../../../assets/logo.png'
import { localStorageKeys } from '../../../constants/localStorage'
import { useIsOverridingRole, useIsTrulyAdmin } from '../../../hooks/roles'

const HeaderLogo = () => {
  const isOverriding = useIsOverridingRole()
  const isTrulyAdmin = useIsTrulyAdmin()

  const handleBackAsAdmin = () => {
    localStorage.removeItem(localStorageKeys.roleOverride)
    window.location.reload()
  }

  return (
    <Box>
      <img
        src={logo}
        alt="Podform3D logo"
        width="150px"
      />
      {
        isOverriding &&
        isTrulyAdmin &&
        <Button
          margin={{ top: '0.25rem' }}
          label={<Trans>Back as admin</Trans>}
          onClick={handleBackAsAdmin}
        />
      }
    </Box>
  )
}

export default HeaderLogo
