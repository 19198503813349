
import { OmsPrescription, PartialRxOrthotics, Prescription, UpdateOmsPrescription, getHeelCupHeightKey, heelCupHeights, standardDiagnosticOptions, standardHeelLift } from '../types/Prescription'
import { flexMidfootPerforatedValues } from '../types/PrescriptionEnums/FlexMidfootPerforated'
import { getKineticExtensionFromNumbers } from '../types/PrescriptionEnums/KineticExtension'
import { lowerMedialArches } from '../types/PrescriptionEnums/LowerMedialArch'
import { postingCorrections } from '../types/PrescriptionEnums/PostingCorrection'
import { postingOptions } from '../types/PrescriptionEnums/PostingOption'
import { postingPositions } from '../types/PrescriptionEnums/PostingPosition'
import { PrescriptionStatus } from '../types/PrescriptionEnums/PrescriptionStatus'
import { RequestedSide, requestedSides } from '../types/PrescriptionEnums/RequestedSide'
import { getDefaultRetroPadOrientation, getDefaultRetroPadSize, retroPadOrientations, retroPadSizes } from '../types/PrescriptionEnums/RetroPads'
import { stiffMidfootBars } from '../types/PrescriptionEnums/StiffMidFootBar'
import { notAvailable, topCoverLengths } from '../types/PrescriptionEnums/TopCoverLength'
import { getTopCoverThicknessFromNumber, topCoverThicknesses } from '../types/PrescriptionEnums/TopCoverThickness'

export const toUpdateOms = (prescription: Prescription, status: PrescriptionStatus) => {
  const omsPrescription = toOms(prescription, status)

  return {
    id: prescription.id,
    ...omsPrescription,
  }
}

export const toHeelLiftNum = (value: string): number =>
  Number(/\+(\d) mm/.exec(value)?.[1] ?? 0)

export const findHeelLift = (value: number): string =>
  standardHeelLift.find(x => toHeelLiftNum(x) === value) ?? 'None'

export const toOms = (prescription: Prescription, status: PrescriptionStatus) => {
  let leftDiagnostic = prescription.section.diagnostic as string | undefined
  if (prescription.section.diagnostic === 'Specify:') leftDiagnostic = prescription.section.specifyDiagnostic
  if (leftDiagnostic === '') leftDiagnostic = 'None'

  let rightDiagnostic = prescription.section.diagnosticOtherSide as string | undefined
  if (prescription.section.diagnosticOtherSide === 'Specify:') rightDiagnostic = prescription.section.specifyDiagnostic
  if (rightDiagnostic === '') rightDiagnostic = 'None'

  if (prescription.section.sideDiagnostic === requestedSides.right) {
    const t = leftDiagnostic
    leftDiagnostic = rightDiagnostic
    rightDiagnostic = t
  } else if (prescription.section.sideDiagnostic === requestedSides.both) {
    rightDiagnostic = leftDiagnostic
  }

  const leftFlexMidfootPerforated = prescription.rxOrthotic.flexMidfootPerforated
  const leftStiffMidfootBar = prescription.rxOrthotic.stiffMidfootBar
  const leftLowerMedialArch = prescription.rxOrthotic.lowerMedialArch
  const leftHeelLift = prescription.rxOrthotic.heelLift

  const rightFlexMidfootPerforated = prescription.rxOrthotic.flexMidfootOtherSide
  const rightStiffMidfootBar = prescription.rxOrthotic.stiffMidfootOtherSide
  const rightLowerMedialArch = prescription.rxOrthotic.lowerMedialArchOtherSide
  const rightHeelLift = prescription.rxOrthotic.heelLiftOtherSide

  const isModificationActive = prescription.rxOrthotic.shellModificationOptions
  const isPostingActive = prescription.rxOrthotic.postingOptions

  const omsPrescription: OmsPrescription = {
    description: prescription.description,
    name: prescription.name,
    consultationDate: prescription.consultationDate.substring(0, 10),
    prescriptionStatus: status,
    productType: prescription.type,
    patientInformation: {
      childGrowth: prescription.section.childGrowth,
      gender: prescription.section.gender,
      shoeSize: Number(prescription.section.shoeSizeValue),
      shoeSizeSystem: prescription.section.shoeSizeSystem,
    },
    rxOrthoticBilateral: {
      balanceFeetToNeutral: true,
      shellRigidity: prescription.rxOrthotic.shellRigidity,
      heelCupHeight: heelCupHeights[prescription.rxOrthotic.heelCupHeight],
      footwearWidthForefoot: prescription.rxOrthotic.forefootWidth,
      topCoverThickness: prescription.shellModification.topCoverThickness,
      footbedWidth: prescription.rxOrthotic.footbedWidth === notAvailable
        ? 0
        : Number(prescription.rxOrthotic.footbedWidth),
      footbedLength: prescription.rxOrthotic.footbedLength === notAvailable
        ? 0
        : Number(prescription.rxOrthotic.footbedLength),
      topCoverLength: prescription.rxOrthotic.topCoverActive
        ? prescription.rxOrthotic.topCoverLength
        : topCoverLengths.none,
      topCoverMaterial: prescription.rxOrthotic.topCoverActive
        ? prescription.rxOrthotic.topCoverMaterial
        : topCoverLengths.none,
      topCoverSeperatedParts: prescription.rxOrthotic.topCoverActive
        ? prescription.rxOrthotic.apart
        : false,
    },
    leftFootRx: {
      diagnostic: leftDiagnostic === undefined ? 'None' : leftDiagnostic,
      activeSide: 0,
      posting: {
        option: isPostingActive ? prescription.posting.leftPostingOption : postingOptions.none,
        position: isPostingActive ? prescription.posting.leftPostingPosition : postingPositions.none,
        correction: isPostingActive ? prescription.posting.leftPostingCorrection : postingCorrections.none,
      },
      rxOrthotic: {
        flexMidfootPerforated: leftFlexMidfootPerforated,
        stiffMidfootBar: leftStiffMidfootBar,
        lowerMedialArch: leftLowerMedialArch,
        heelLift: toHeelLiftNum(leftHeelLift),
        kineticExtension: prescription.shellModification.kineticExtensionLeft.map(Number),
      },
      modification: {
        halluxExtension: isModificationActive && isLeftSide(prescription.shellModification.halluxExtension),
        gaitPlate: isModificationActive && isLeftSide(prescription.shellModification.gaitPlate),
        fasciaGroove: isModificationActive && isLeftSide(prescription.shellModification.fasciaGroove),
        medialHeelSkive: isModificationActive && isLeftSide(prescription.shellModification.medialHeelSkive),
        medialFlange: isModificationActive && isLeftSide(prescription.shellModification.medialFlange),
        lateralFlange: isModificationActive && isLeftSide(prescription.shellModification.lateralFlange),
        firstRayCutOut: isModificationActive && isLeftSide(prescription.shellModification.firstRayCutOut),
        heelHole: isModificationActive && isLeftSide(prescription.shellModification.heelHole),
        heelFork: isModificationActive && isLeftSide(prescription.shellModification.heelFork),
        retroPadOrientation: prescription.shellModification.retroPad
          ? prescription.shellModification.retroPadOrientationLeft
          : retroPadOrientations.none,
        retroPadSize: prescription.shellModification.retroPad
          ? prescription.shellModification.retroPadSizeLeft
          : retroPadSizes.none,
        retroPad: prescription.shellModification.retroPad &&
          prescription.shellModification.retroPadSizeLeft !== retroPadSizes.none,
      },
    },
    rightFootRx: {
      activeSide: 1,
      diagnostic: rightDiagnostic === undefined ? 'None' : rightDiagnostic,
      posting: {
        option: isPostingActive ? prescription.posting.rightPostingOption : postingOptions.none,
        position: isPostingActive ? prescription.posting.rightPostingPosition : postingPositions.none,
        correction: isPostingActive ? prescription.posting.rightPostingCorrection : postingCorrections.none,
      },
      rxOrthotic: {
        flexMidfootPerforated: rightFlexMidfootPerforated,
        stiffMidfootBar: rightStiffMidfootBar,
        lowerMedialArch: rightLowerMedialArch,
        heelLift: toHeelLiftNum(rightHeelLift),
        kineticExtension: prescription.shellModification.kineticExtensionRight.map(Number),
      },
      modification: {
        halluxExtension: isModificationActive && isRightSide(prescription.shellModification.halluxExtension),
        gaitPlate: isModificationActive && isRightSide(prescription.shellModification.gaitPlate),
        fasciaGroove: isModificationActive && isRightSide(prescription.shellModification.fasciaGroove),
        medialHeelSkive: isModificationActive && isRightSide(prescription.shellModification.medialHeelSkive),
        medialFlange: isModificationActive && isRightSide(prescription.shellModification.medialFlange),
        lateralFlange: isModificationActive && isRightSide(prescription.shellModification.lateralFlange),
        firstRayCutOut: isModificationActive && isRightSide(prescription.shellModification.firstRayCutOut),
        heelHole: isModificationActive && isRightSide(prescription.shellModification.heelHole),
        heelFork: isModificationActive && isRightSide(prescription.shellModification.heelFork),
        retroPadOrientation: prescription.shellModification.retroPad
          ? prescription.shellModification.retroPadOrientationRight
          : retroPadOrientations.none,
        retroPadSize: prescription.shellModification.retroPad
          ? prescription.shellModification.retroPadSizeRight
          : retroPadSizes.none,
        retroPad: prescription.shellModification.retroPad &&
          prescription.shellModification.retroPadSizeRight !== retroPadSizes.none,
      },
    },
    specialInstructions: prescription.specialInstructions,
    activeSide: -1,
    side: prescription.section.production,
  }

  return omsPrescription
}

export const fromOms = (omsPrescription: UpdateOmsPrescription) => {
  const retroPadLegacy =
    omsPrescription.leftFootRx.modification.retroPadOrientation === retroPadOrientations.none &&
    omsPrescription.leftFootRx.modification.retroPadSize === retroPadSizes.none &&
    omsPrescription.rightFootRx.modification.retroPadOrientation === retroPadOrientations.none &&
    omsPrescription.rightFootRx.modification.retroPadSize === retroPadSizes.none &&
    omsPrescription.rightFootRx.modification.retroPad &&
    omsPrescription.leftFootRx.modification.retroPad

  const shellModifications: Prescription['shellModification'] = {
    halluxExtension: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.halluxExtension,
      omsPrescription.rightFootRx.modification.halluxExtension,
    ),
    gaitPlate: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.gaitPlate,
      omsPrescription.rightFootRx.modification.gaitPlate,
    ),
    fasciaGroove: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.fasciaGroove,
      omsPrescription.rightFootRx.modification.fasciaGroove,
    ),
    medialHeelSkive: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.medialHeelSkive,
      omsPrescription.rightFootRx.modification.medialHeelSkive,
    ),
    medialFlange: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.medialFlange,
      omsPrescription.rightFootRx.modification.medialFlange,
    ),
    lateralFlange: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.lateralFlange,
      omsPrescription.rightFootRx.modification.lateralFlange,
    ),
    firstRayCutOut: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.firstRayCutOut,
      omsPrescription.rightFootRx.modification.firstRayCutOut,
    ),
    heelHole: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.heelHole,
      omsPrescription.rightFootRx.modification.heelHole,
    ),
    heelFork: getLeftRightOrBiLateral(
      omsPrescription.leftFootRx.modification.heelFork,
      omsPrescription.rightFootRx.modification.heelFork,
    ),
    sideRetroPad: getLeftRightOrBiLateralFromNumber(
      omsPrescription.leftFootRx.modification.retroPadSize,
      omsPrescription.rightFootRx.modification.retroPadSize,
    ),
    sideKineticExtension: getBilateralFromNumbers(
      omsPrescription.leftFootRx.rxOrthotic.kineticExtension,
      omsPrescription.rightFootRx.rxOrthotic.kineticExtension,
    ),
    topCoverThickness: getTopCoverThicknessFromNumber(omsPrescription.rxOrthoticBilateral.topCoverThickness),
    kineticExtensionLeft: getKineticExtensionFromNumbers(omsPrescription.leftFootRx.rxOrthotic.kineticExtension),
    kineticExtensionRight: getKineticExtensionFromNumbers(omsPrescription.rightFootRx.rxOrthotic.kineticExtension),
    retroPadOrientationLeft: retroPadLegacy
      ? getDefaultRetroPadOrientation()
      : omsPrescription.leftFootRx.modification.retroPadOrientation,
    retroPadOrientationRight: retroPadLegacy
      ? getDefaultRetroPadOrientation()
      : omsPrescription.rightFootRx.modification.retroPadOrientation,
    retroPadSizeLeft: retroPadLegacy
      ? getDefaultRetroPadSize()
      : omsPrescription.leftFootRx.modification.retroPadSize,
    retroPadSizeRight: retroPadLegacy
      ? getDefaultRetroPadSize()
      : omsPrescription.rightFootRx.modification.retroPadSize,
    retroPad: omsPrescription.leftFootRx.modification.retroPad ||
      omsPrescription.rightFootRx.modification.retroPad,
    kineticExtension: omsPrescription.leftFootRx.rxOrthotic.kineticExtension.length > 0 &&
      omsPrescription.rightFootRx.rxOrthotic.kineticExtension.length > 0,
  }

  const postings: Prescription['posting'] = {
    sidePosting: requestedSides.both,
    leftPostingOption: omsPrescription.leftFootRx.posting.option,
    leftPostingPosition: omsPrescription.leftFootRx.posting.position,
    leftPostingCorrection: omsPrescription.leftFootRx.posting.correction,
    rightPostingOption: omsPrescription.rightFootRx.posting.option,
    rightPostingPosition: omsPrescription.rightFootRx.posting.position,
    rightPostingCorrection: omsPrescription.rightFootRx.posting.correction,
  }

  const sideDiagnostic = getLeftRightOrBiLateralFromString(
    omsPrescription.leftFootRx.diagnostic,
    omsPrescription.rightFootRx.diagnostic)

  const sideFlexMidFootPerforated = getLeftRightOrBiLateralFromNumber(
    omsPrescription.leftFootRx.rxOrthotic.flexMidfootPerforated,
    omsPrescription.rightFootRx.rxOrthotic.flexMidfootPerforated)

  const sideStiffMidfootBar = getLeftRightOrBiLateralFromNumber(
    omsPrescription.leftFootRx.rxOrthotic.stiffMidfootBar,
    omsPrescription.rightFootRx.rxOrthotic.stiffMidfootBar)

  const sideLowerMedialArch = getLeftRightOrBiLateralFromNumber(
    omsPrescription.leftFootRx.rxOrthotic.lowerMedialArch,
    omsPrescription.rightFootRx.rxOrthotic.lowerMedialArch)

  const sideHeelLift = getLeftRightOrBiLateralFromNumber(
    omsPrescription.leftFootRx.rxOrthotic.heelLift,
    omsPrescription.rightFootRx.rxOrthotic.heelLift)

  const partialRxOrthotics = {
    flexMidfootPerforated: omsPrescription.leftFootRx.rxOrthotic.flexMidfootPerforated,
    flexMidfootOtherSide: omsPrescription.rightFootRx.rxOrthotic.flexMidfootPerforated,
    stiffMidfootBar: omsPrescription.leftFootRx.rxOrthotic.stiffMidfootBar,
    stiffMidfootOtherSide: omsPrescription.rightFootRx.rxOrthotic.stiffMidfootBar,
    lowerMedialArch: omsPrescription.leftFootRx.rxOrthotic.lowerMedialArch,
    lowerMedialArchOtherSide: omsPrescription.rightFootRx.rxOrthotic.lowerMedialArch,
    heelLift: findHeelLift(omsPrescription.leftFootRx.rxOrthotic.heelLift),
    heelLiftOtherSide: findHeelLift(omsPrescription.rightFootRx.rxOrthotic.heelLift),
  }

  const prescription: Prescription = {
    id: omsPrescription.id,
    version: 0,
    description: omsPrescription.description,
    name: omsPrescription.name,
    consultationDate: omsPrescription.consultationDate,
    status: omsPrescription.prescriptionStatus,
    type: omsPrescription.productType,
    section: {
      gender: omsPrescription.patientInformation.gender,
      shoeSizeValue: omsPrescription.patientInformation.shoeSize.toString(),
      shoeSizeSystem: omsPrescription.patientInformation.shoeSizeSystem,
      childGrowth: omsPrescription.patientInformation.childGrowth,
      diagnosticOtherSide: standardDiagnosticOptions
        .findIndex(i => i === omsPrescription.rightFootRx.diagnostic) === -1
        ? (standardDiagnosticOptions[1] ?? 'Specify:')
        : omsPrescription.rightFootRx.diagnostic,
      diagnostic: standardDiagnosticOptions
        .findIndex(i => i === omsPrescription.leftFootRx.diagnostic) === -1
        ? (standardDiagnosticOptions[1] ?? 'Specify:')
        : omsPrescription.leftFootRx.diagnostic,
      specifyDiagnostic: omsPrescription.leftFootRx.diagnostic,
      specifyDiagnosticOtherSide: omsPrescription.rightFootRx.diagnostic,
      sideDiagnostic: sideDiagnostic,
      production: omsPrescription.side,
      productType: 'shell',
    },
    rxOrthotic: {
      ...partialRxOrthotics,
      shellRigidity: omsPrescription.rxOrthoticBilateral.shellRigidity,
      forefootWidth: omsPrescription.rxOrthoticBilateral.footwearWidthForefoot,
      heelCupHeight: getHeelCupHeightKey(omsPrescription.rxOrthoticBilateral.heelCupHeight),
      sideFlexMidfootPerforated: sideFlexMidFootPerforated,
      sideStiffMidfootBar: sideStiffMidfootBar,
      sideLowerMedialArch: sideLowerMedialArch,
      sideHeelLift: sideHeelLift,
      topCoverActive: omsPrescription.rxOrthoticBilateral.topCoverLength !== 0,
      topCoverLength: omsPrescription.rxOrthoticBilateral.topCoverLength,
      footbedWidth: omsPrescription.rxOrthoticBilateral.footbedWidth === 0
        ? notAvailable
        : omsPrescription.rxOrthoticBilateral.footbedWidth.toString(),
      footbedLength: omsPrescription.rxOrthoticBilateral.footbedLength === 0
        ? notAvailable
        : omsPrescription.rxOrthoticBilateral.footbedLength.toString(),
      topCoverMaterial: omsPrescription.rxOrthoticBilateral.topCoverMaterial,
      apart: omsPrescription.rxOrthoticBilateral.topCoverSeperatedParts,
      shellModificationOptions: isShellModificationSet(shellModifications, partialRxOrthotics),
      postingOptions: isPostingSet(postings),
    },
    posting: postings,
    specialInstructions: omsPrescription.specialInstructions,
    shellModification: shellModifications,
  }

  return prescription
}

const getLeftRightOrBiLateral = (
  isLeft: boolean | null,
  isRight: boolean | null,
): RequestedSide | 'none' => {
  if (isLeft === null || isRight === null) return 'none'
  if (isLeft && isRight) return requestedSides.both
  if (isLeft) return requestedSides.left
  if (isRight) return requestedSides.right
  return 'none'
}

const getLeftRightOrBiLateralFromNumber = (
  left: number,
  right: number,
): RequestedSide | 'none' => {
  if (left === 0 && right === 0) return 'none'
  if (left === right) return requestedSides.both
  if (left !== 0) return requestedSides.left
  if (right !== 0) return requestedSides.right
  return 'none'
}

const getBilateralFromNumbers = (
  left: number[],
  right: number[],
): RequestedSide | 'none' => {
  if (left.sort().join(',') === right.sort().join(',')) return requestedSides.both
  return 'none'
}

const getLeftRightOrBiLateralFromString = (
  left: string,
  right: string,
): RequestedSide | 'none' => {
  if (left === '' && right === '') return 'none'
  if (left === 'none' && right === 'none') return 'none'
  if (left === right) return requestedSides.both
  if (left !== '') return requestedSides.left
  if (right !== '') return requestedSides.right
  return 'none'
}

const isLeftSide = (side: RequestedSide | 'none') => side === requestedSides.left || side === requestedSides.both
const isRightSide = (side: RequestedSide | 'none') => side === requestedSides.right || side === requestedSides.both

export const isShellModificationSet = (x: Prescription['shellModification'], y: PartialRxOrthotics): boolean =>
  x.halluxExtension !== 'none' ||
  x.gaitPlate !== 'none' ||
  x.fasciaGroove !== 'none' ||
  x.medialHeelSkive !== 'none' ||
  x.medialFlange !== 'none' ||
  x.lateralFlange !== 'none' ||
  x.firstRayCutOut !== 'none' ||
  x.heelHole !== 'none' ||
  x.heelFork !== 'none' ||
  x.retroPad ||
  x.kineticExtension ||
  x.topCoverThickness !== topCoverThicknesses.none ||
  y.flexMidfootOtherSide !== flexMidfootPerforatedValues.none ||
  y.stiffMidfootOtherSide !== stiffMidfootBars.none ||
  y.heelLiftOtherSide !== standardHeelLift[0] ||
  y.lowerMedialArchOtherSide !== lowerMedialArches.noFlex ||
  y.flexMidfootPerforated !== flexMidfootPerforatedValues.none ||
  y.stiffMidfootBar !== stiffMidfootBars.none ||
  y.heelLift !== standardHeelLift[0] ||
  y.lowerMedialArch !== lowerMedialArches.noFlex

const isPostingSet = (x: Prescription['posting']): boolean =>
  x.leftPostingOption !== postingOptions.none ||
  x.rightPostingOption !== postingOptions.none
